import React from 'react';
import { useState } from 'react';
import { Paper, Stack, Button, TextField } from '@mui/material';

import Joi from 'joi';

function MissionNoteInputPanel(props: { onSubmit: Function, sx?: object }) {

    const [inputNote, setInputNote] = useState("");

    const isInputNoteValid = Joi.string().min(1).max(256).required().validate(inputNote);
    const isValid = (!isInputNoteValid.error);

    function onSubmit() {
        props.onSubmit(inputNote);
        setInputNote("");
    }

    return (
        <Paper sx={props.sx}>
            <Stack direction="row" spacing={1} padding={2}>
                <TextField sx={{ width: '100%' }} label="Enter Note" value={inputNote} onChange={(e) => setInputNote(e.target.value)} />
                <Button variant='contained' disabled={!isValid} onClick={onSubmit}>Submit</Button>
            </Stack>
        </Paper>
    );
}

export default MissionNoteInputPanel;