import React from 'react';
import { Modal, Paper } from '@mui/material';
import RenewReadinessRequirementLayout from '../layouts/RenewReadinessRequirementLayout';

function RenewReadinessRequirementModal(props: {
    isOpen: boolean,
    onClose: Function,
    onSubmit: Function
}) {
    return (
        <Modal
            open={props.isOpen}
            onClose={() => props.onClose()}
        >
            <Paper
                sx={{
                    position: 'absolute',
                    width: '600px',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    padding: 2
                }}
            >
                <RenewReadinessRequirementLayout
                    onSubmit={props.onSubmit}
                    onClose={props.onClose}
                />
            </Paper>
        </Modal>
    );
}

export default RenewReadinessRequirementModal;