import React, {ReactNode} from "react"
import { Typography, CircularProgress, Stack, Paper } from "@mui/material"
import InputPanel from "../components/InputPanel"
import { SysInfoBannerParams } from "../../classes/SysInfoBannerParams"
import PanelPageLayout from "./PanelPageLayout"

export default function InputPanelPageLayout(props: {
    sysInfoBanner: SysInfoBannerParams,
    title: string,
    leftControl?: React.ReactNode,
    rightControl?: React.ReactNode,
    children?: ReactNode
}) {

    const panelStyle = {
        position: "absolute",
        width: "550px",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        padding: 1
    }

    return (
        <PanelPageLayout sysInfoBanner={props.sysInfoBanner}>
            <InputPanel
                title={props.title}
                leftControl={props.leftControl}
                rightControl={props.rightControl}
                children={props.children}
                panelStyle={panelStyle}
            />
        </PanelPageLayout>
    )
}