import React from 'react';
import { Modal, Paper } from '@mui/material';
import ConfirmDialogLayout from '../../../common/layouts/ConfirmDialogLayout';

function ConfirmCompleteMissionModal(props: {
    isOpen: boolean,
    onClose: Function,
    onConfirm: Function
}) {

    return (
        <Modal
            open={props.isOpen}
            onClose={() => props.onClose()}
        >
            <Paper
                sx={{
                    position: 'absolute',
                    width: '400px',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    padding: 2
                }}
            >
                <ConfirmDialogLayout
                    title="Confirm Complete Mission"
                    message="Are you sure you want to confirm the completion of this mission?"
                    onConfirm={props.onConfirm}
                    onClose={props.onClose}
                />
            </Paper>
        </Modal>
    );
}

export default ConfirmCompleteMissionModal;