export class MissionObjective {
    id?: number
    missionId: number = 0
    description: string = ""
    accomplished?: boolean

    parseObject(input: any) {
        this.id = input.id
        this.missionId = input.missionId
        this.description = input.description
        this.accomplished = input.accomplished
    }

    objectify(): object {
        return {
            id: this.id,
            missionId: this.missionId,
            description: this.description,
            accomplished: this.accomplished
        }
    }
}