import React from 'react';
import { useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { DateTimePicker } from '@mui/x-date-pickers';

import Joi from 'joi';
import dayjs, {Dayjs} from 'dayjs';
import {Mission} from "../../../classes/Mission";

function EditMissionScheduleLayout(props: {
    title: string,
    initInfo: Mission,
    onSubmit: Function,
    onCancel: Function
}) {

    const [isPendingSubmit, setPendingSubmit] = useState<boolean>(false);
    const [inputTimeStart, setInputTimeStart] = useState<Dayjs>(props.initInfo.timeStartScheduled);
    const [inputTimeFinish, setInputTimeFinish] = useState<Dayjs>(props.initInfo.timeFinishScheduled);

    const timeStart = inputTimeStart
    const timeFinish = inputTimeFinish

    const isTimeStartValid = Joi.date().required().validate(timeStart.format());
    const isTimeFinishValid = Joi.date().required().validate(timeFinish.format());
    const isValid = (
        !isTimeStartValid.error
        && !isTimeFinishValid.error
        && (timeStart.isBefore(timeFinish))
    )

    function handleSubmit() {
        setPendingSubmit(true);
        props.onSubmit(timeStart, timeFinish);
    }

    const bottomControls = isPendingSubmit === true ? (<Typography>Loading...</Typography>) : [(<Button variant='text' onClick={() => props.onCancel()}>Cancel</Button>), (<Button variant='contained' disabled={!isValid} onClick={handleSubmit}>Submit</Button>)];

    return (
        <Stack direction="column" spacing={1}>
            <Typography variant="h6">{props.title}</Typography>
            <DateTimePicker
                sx={{ width: "100%" }}
                label="Start Time"
                slotProps={{
                    textField: {
                        error: isTimeStartValid.error !== undefined || timeStart.isAfter(timeFinish)
                    },
                }}
                ampm={false}
                disabled={isPendingSubmit}
                value={timeStart}
                onChange={(event: any) => setInputTimeStart(event)}
            />
            <DateTimePicker
                sx={{ width: "100%" }}
                label="Finish Time"
                slotProps={{
                    textField: {
                        error: isTimeFinishValid.error !== undefined || timeStart.isAfter(timeFinish)
                    },
                }}
                ampm={false}
                disabled={isPendingSubmit}
                value={timeFinish}
                onChange={(event: any) => setInputTimeFinish(event)}
            />
            <Stack direction="row" spacing={1} justifyContent="right">{bottomControls}</Stack>
        </Stack>
    );
}

export default EditMissionScheduleLayout;