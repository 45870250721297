import React from 'react';
import { useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import Joi from 'joi';
import {Organization} from "../../../classes/Organization";
import {OperationObjective} from "../../../classes/OperationObjective";

function EditOperationObjectiveLayout(props: {
    initInfo: OperationObjective,
    title: string,
    onCancel: Function,
    onSubmit: Function,
    organizationList?: Organization[]
}) {

    const [isPendingSubmit, setPendingSubmit] = useState<boolean>(false)
    const [inputDescription, setInputDescription] = useState<string>(props.initInfo.description)
    
    const isDescriptionValid = Joi.string().min(1).max(256).required().validate(inputDescription);
    const isValid = (!isDescriptionValid.error);

    function handleSubmit() {
        setPendingSubmit(true);

        const inputOperation = props.initInfo
        inputOperation.description = inputDescription

        props.onSubmit(inputOperation)
    }
    
    const bottomControls = isPendingSubmit === true ? (<Typography>Loading...</Typography>) : [(<Button variant='text' onClick={() => props.onCancel()}>Cancel</Button>), (<Button variant='contained' disabled={!isValid} onClick={handleSubmit}>Submit</Button>)];

    return (
        <Stack direction="column" spacing={1}>
            <Typography variant="h6">{props.title}</Typography>
            <TextField
                type="text"
                variant="outlined"
                label="Description"
                multiline
                rows={3}
                error={inputDescription.length > 0 && isDescriptionValid.error !== undefined}
                disabled={isPendingSubmit}
                value={inputDescription}
                onChange={(e) => setInputDescription(e.target.value)}
            />
            <Stack direction="row" spacing={1} justifyContent="right">{bottomControls}</Stack>
        </Stack>
    );
}

export default EditOperationObjectiveLayout;