import React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import { Typography, Stack, Divider } from '@mui/material';
import { Error, CheckCircle } from '@mui/icons-material';
import {Operator} from "../../../classes/Operator";

function OperatorListItem(props: { operatorObject: Operator, selected: boolean, onSelect: Function }) {

    function getFlagIcons() {

        const isErrorVisible = (props.operatorObject.readiness!.numberOutstandingRequirements! > 0 || props.operatorObject.readiness!.numberActiveProblems! > 0);

        return (
            <Stack direction="row">
                {isErrorVisible ? (<Error color="error" />) : (<CheckCircle color="success" />)}
            </Stack>
        );
    }

    return (
        <ListItemButton selected={props.selected} onClick={() => props.onSelect()}>
            <Stack direction="column" sx={{ width: '100%' }}>
                <Divider />
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body1">{props.operatorObject.callsign}</Typography>
                    {getFlagIcons()}
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body2">
                        {`${props.operatorObject.rank} ${props.operatorObject.firstName} ${props.operatorObject.lastName}`}
                    </Typography>
                    <Typography variant="body2">{props.operatorObject.officeObject!.name}</Typography>
                </Stack>
                <Divider />
            </Stack>
        </ListItemButton>
    );
}

export default OperatorListItem;