import React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

function OperatorReadinessActionPanel(props: {
    createProblemDisabled: boolean,
    createRequirementDisabled: boolean,
    resolveProblemDisabled: boolean,
    renewRequirementDisabled: boolean,
    onCreateProblem: Function,
    onCreateRequirement: Function,
    onResolveProblem: Function,
    onRenewRequirement: Function,
    sx?: object
}) {

    return (
        <Paper sx={props.sx}>
            <Stack direction="row" justifyContent="center" spacing={2} padding={2}>
                <Button variant="contained" disabled={props.createProblemDisabled} onClick={() => props.onCreateProblem()}>Create Problem</Button>
                <Button variant="contained" disabled={props.createRequirementDisabled} onClick={() => props.onCreateRequirement()}>Create Requirement</Button>
                <Button variant="contained" disabled={props.resolveProblemDisabled} onClick={() => props.onResolveProblem()}>Resolve Problem</Button>
                <Button variant="contained" disabled={props.renewRequirementDisabled} onClick={() => props.onRenewRequirement()}>Renew Requirement</Button>
            </Stack>
        </Paper>
    );
}

export default OperatorReadinessActionPanel;