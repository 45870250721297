import React from 'react';
import { Modal, Paper } from '@mui/material';
import EditReadinessRequirementLayout from '../layouts/EditReadinessRequirementLayout';
import {Operator} from "../../../classes/Operator";
import {OperatorReadinessRequirement} from "../../../classes/OperatorReadinessRequirement";

function NewReadinessReadinessModal(props: {
    operatorData: Operator,
    isOpen: boolean,
    onClose: Function,
    onSubmit: Function
}) {

    const newRequirement = new OperatorReadinessRequirement()
    newRequirement.operatorId = props.operatorData.id!
    
    return (
        <Modal
            open={props.isOpen}
            onClose={() => props.onClose()}
        >
            <Paper
                sx={{
                    position: 'absolute',
                    width: '600px',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    padding: 2
                }}
            >
                <EditReadinessRequirementLayout
                    title="New Readiness Requirement"
                    initData={newRequirement}
                    onCancel={props.onClose}
                    onSubmit={props.onSubmit}
                />
            </Paper>
        </Modal>
    );
}

export default NewReadinessReadinessModal;