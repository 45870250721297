import React, { useState, ReactNode } from "react"
import { Box, Stack } from "@mui/material"
import SysInfoBanner from "../components/SysInfoBanner"
import { SysInfoBannerParams } from "../../classes/SysInfoBannerParams"

export default function PanelPageLayout(props: { sysInfoBanner: SysInfoBannerParams, children?: ReactNode }) {

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100vw',
            height: '100vh'
        }}>
            <SysInfoBanner data={props.sysInfoBanner} />
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    overflow: 'hidden'
                }}
            >
                {props.children}
            </Box>
            <SysInfoBanner data={props.sysInfoBanner} />
        </Box>
    )
}