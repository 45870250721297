import React from 'react';
import { useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { DateTimePicker } from '@mui/x-date-pickers';

import Joi from 'joi';
import dayjs, {Dayjs} from 'dayjs';
import {Mission} from "../../../classes/Mission";
import {Operation} from "../../../classes/Operation";
import {Office} from "../../../classes/Office";

function EditMissionLayout(props: {
    title: string,
    initInfo: Mission,
    operationList?: Operation[],
    officeList?: Office[],
    onSubmit: Function,
    onCancel: Function
}) {
    
    const [isPendingSubmit, setPendingSubmit] = useState<boolean>(false);
    const [inputOperationId, setInputOperationId] = useState<number>(props.initInfo.operationId);
    const [inputOriginatingOfficeId, setInputOriginatingOfficeId] = useState<number>(props.initInfo.originatingOfficeId);
    const [inputName, setInputName] = useState<string>(props.initInfo.name);
    const [inputTimeStart, setInputTimeStart] = useState<Dayjs>(props.initInfo.timeStartScheduled);
    const [inputTimeFinish, setInputTimeFinish] = useState<Dayjs>(props.initInfo.timeFinishScheduled);
    const [inputPurpose, setInputPurpose] = useState<string>(props.initInfo.purpose);

    const timeStart = inputTimeStart
    const timeFinish = inputTimeFinish

    const isOperationId = Joi.number().integer().positive().required().validate(inputOperationId);
    const isOriginatingOfficeIdValid = Joi.number().integer().positive().required().validate(inputOriginatingOfficeId);
    const isNameValid = Joi.string().min(1).max(100).required().validate(inputName);
    const isTimeStartValid = Joi.date().required().validate(timeStart.format());
    const isTimeFinishValid = Joi.date().required().validate(timeFinish.format());
    const isPurposeValid = Joi.string().min(1).max(2000).required().validate(inputPurpose);
    const isValid = (
        !isOperationId.error
        && !isNameValid.error
        && !isTimeStartValid.error
        && !isTimeFinishValid.error
        && !isPurposeValid.error
        && !isOriginatingOfficeIdValid.error
        && (timeStart.isBefore(timeFinish))
    )

    function handleSubmit() {
        setPendingSubmit(true)

        const inputMission = props.initInfo
        inputMission.operationId = inputOperationId
        inputMission.name = inputName
        inputMission.purpose = inputPurpose
        inputMission.timeStartScheduled = timeStart
        inputMission.timeStartProjected = timeStart
        inputMission.timeFinishScheduled = timeFinish
        inputMission.timeFinishProjected = timeFinish
        inputMission.originatingOfficeId = inputOriginatingOfficeId

        props.onSubmit(inputMission)
    }

    const bottomControls = isPendingSubmit === true ? (<Typography>Loading...</Typography>) : [(<Button variant='text' onClick={() => props.onCancel()}>Cancel</Button>), (<Button variant='contained' disabled={!isValid} onClick={handleSubmit}>Submit</Button>)];

    // TODO: Allow the layout to be seen, just disable the Select Originating Office and Select Operation controls.
    if (props.operationList === undefined || props.operationList === null) {
        return (<Typography variant="h6">Loading...</Typography>)
    } else if (props.officeList === undefined || props.officeList === null) {
        return (<Typography variant="h6">Loading...</Typography>)
    }

    return (
        <Stack direction="column" spacing={1}>
            <Typography variant="h6">{props.title}</Typography>
            <FormControl fullWidth disabled={isPendingSubmit}>
                <InputLabel id="operation-select">Select Operation</InputLabel>
                <Select
                    labelId="operation-select"
                    label="Select Operation"
                    value={inputOperationId}
                    onChange={(event: any) => setInputOperationId(event.target.value)}
                >
                    {props.operationList.map((a) => (<MenuItem value={a.id}>{a.name}</MenuItem>))}
                </Select>
            </FormControl>
            <FormControl fullWidth disabled={isPendingSubmit}>
                <InputLabel id="originating-office-select">Select Originating Office</InputLabel>
                <Select
                    labelId="originating-office-select"
                    label="Select Originating Office"
                    value={inputOriginatingOfficeId}
                    onChange={(event: any) => setInputOriginatingOfficeId(event.target.value)}
                >
                    {props.officeList.map((a) => (<MenuItem value={a.id}>{`${a.name} (${a.symbol})`}</MenuItem>))}
                </Select>
            </FormControl>
            <TextField
                type="text"
                variant="outlined"
                label="Mission Name"
                error={inputName.length > 0 && isNameValid.error !== undefined}
                disabled={isPendingSubmit}
                value={inputName}
                onChange={(e) => setInputName(e.target.value)}
            />
            <Stack direction="row" spacing={1} justifyContent="space-evenly">
                <DateTimePicker
                    sx={{ width: "50%" }}
                    label="Start Time"
                    slotProps={{
                        textField: {
                            error: isTimeStartValid.error !== undefined || timeStart.isAfter(timeFinish)
                        },
                    }}
                    ampm={false}
                    disabled={isPendingSubmit}
                    value={timeStart}
                    onChange={(event: any) => setInputTimeStart(event)}
                />
                <DateTimePicker
                    sx={{ width: "50%" }}
                    label="Finish Time"
                    slotProps={{
                        textField: {
                            error: isTimeFinishValid.error !== undefined || timeStart.isAfter(timeFinish)
                        },
                    }}
                    ampm={false}
                    disabled={isPendingSubmit}
                    value={timeFinish}
                    onChange={(event: any) => setInputTimeFinish(event)}
                />
            </Stack>
            <TextField
                type="text"
                variant="outlined"
                label="Purpose"
                multiline
                rows={4}
                error={inputPurpose.length > 0 && isPurposeValid.error !== undefined}
                disabled={isPendingSubmit}
                value={inputPurpose}
                onChange={(e) => setInputPurpose(e.target.value)}
            />
            <Stack direction="row" spacing={1} justifyContent="right">{bottomControls}</Stack>
        </Stack>
    );
}

export default EditMissionLayout;