import React from 'react';
import { Modal, Paper } from '@mui/material';
import EditReadinessProblemLayout from '../layouts/EditReadinessProblemLayout';
import {Asset} from "../../../classes/Asset";
import {AssetReadinessProblem} from "../../../classes/AssetReadinessProblem";

function NewReadinessProblemModal(props: {
    assetData: Asset,
    isOpen: boolean,
    onSubmit: Function,
    onClose: Function
}) {

    const newProblem = new AssetReadinessProblem()
    newProblem.assetId = props.assetData.id!

    return (
        <Modal
            open={props.isOpen}
            onClose={() => props.onClose()}
        >
            <Paper
                sx={{
                    position: 'absolute',
                    width: '600px',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    padding: 2
                }}
            >
                <EditReadinessProblemLayout
                    title="New Readiness Problem"
                    initData={newProblem}
                    onCancel={props.onClose}
                    onSubmit={props.onSubmit}
                />
            </Paper>
        </Modal>
    );
}

export default NewReadinessProblemModal;