import React, {useEffect} from "react"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import PrimaryLayout from "../../../common/layouts/PrimaryLayout"
import AdministrationTabbedLayout from "../layouts/AdministrationTabbedLayout"

import NetworkError from "../../../common/components/NetworkError";
import {SysInfoBannerParams} from "../../../classes/SysInfoBannerParams";
import {User} from "../../../classes/User";
import getBanner from "../../../data/sysinfo/getBanner";
import self from "../../../data/user/self";

function Missions() {
    const navigate = useNavigate()

    const [bannerData, setBannerData] = useState<SysInfoBannerParams | undefined>(undefined)
    const [userData, setUserData] = useState<User | undefined>(undefined)

    const [isNetworkErrorSnackbarOpen, setNetworkErrorSnackbarOpen] = useState<boolean>(false)

    useEffect(() => {
        if (bannerData === undefined) {
            getBanner()
                .then((d) => setBannerData(d))
                .catch((err) => {
                    console.log(err)
                    setNetworkErrorSnackbarOpen(true)
                })
        }

        if (userData === undefined) {
            self()
                .then((d) => setUserData(d))
                .catch((err) => {
                    console.log(err)
                    setNetworkErrorSnackbarOpen(true)
                })
        }
    }, [])

    if (bannerData === undefined || userData === undefined) {
        return (
            <NetworkError
                open={isNetworkErrorSnackbarOpen}
                onClose={() => setNetworkErrorSnackbarOpen(false)}
            />
        ) // TODO: Make this into a loading page for all pages.
    }

    return (
        <PrimaryLayout
            sysInfoBanner={bannerData}
            userData={userData}
            navId="admin"
            title="Data Administration"
            content={<AdministrationTabbedLayout onNetworkError={() => setNetworkErrorSnackbarOpen(true)} />}
            modals={[(<NetworkError open={isNetworkErrorSnackbarOpen} onClose={() => setNetworkErrorSnackbarOpen(false)} />)]}
        />
    )
}

export default Missions
