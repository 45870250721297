import React from "react"
import { Modal, Paper } from "@mui/material"
import ConfirmDialogLayout from "../../../common/layouts/ConfirmDialogLayout"

function ConfirmMarkOperatorEmergencyReservationUnavailableModal(props: {
    isOpen: boolean,
    onConfirm: Function,
    onClose: Function
}) {

    return (
        <Modal
            open={props.isOpen}
            onClose={() => props.onClose()}
        >
            <Paper
                sx={{
                    position: "absolute",
                    width: "400px",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    padding: 2
                }}
            >
                <ConfirmDialogLayout
                    title="Mark Operator Unavailable"
                    message="Are you sure you want to mark this operator unavailable?"
                    onConfirm={props.onConfirm}
                    onClose={props.onClose}
                />
            </Paper>
        </Modal>
    )
}

export default ConfirmMarkOperatorEmergencyReservationUnavailableModal