import React, {useState, useEffect, useLayoutEffect} from "react"
import Stack from "@mui/material/Stack"
import OfficeFlatList from "../components/OfficeFlatList"

import getOffices from "../../../data/office/getOffices"
import {Typography} from "@mui/material"
import OfficeActionBar from "../components/OfficeActionBar";
import NewOfficeModal from "../modals/NewOfficeModal";
import UpdateOfficeModal from "../modals/UpdateOfficeModal";
import createOffice from "../../../data/office/createOffice";
import updateOfficeById from "../../../data/office/updateOfficeById";
import {Office} from "../../../classes/Office";

function OfficeTab(props: { onNetworkError: Function }) {
    const [isCreateOfficeModalOpen, setCreateOfficeModalOpen] = useState<boolean>(false)
    const [isUpdateOfficeModalOpen, setUpdateOfficeModalOpen] = useState<boolean>(false)
    // TODO: Manage members.

    const [officeList, setOfficeList] = useState<Office[] | undefined>(undefined)
    const [selectedOfficeId, setSelectedOfficeId] = useState<number | null>(null)
    const [selectedOfficeObject, setSelectedOfficeObject] = useState<Office | undefined>(undefined)

    useEffect(() => {
        getOffices()
            .then((result) => setOfficeList(result))
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }, [])

    useLayoutEffect(() => {
        if (selectedOfficeId === null) {
            setSelectedOfficeObject(undefined)
        } else if (officeList !== undefined) {
            officeList.map((o: Office) => {
                if (o.id == selectedOfficeId) {
                    setSelectedOfficeObject(o)
                }
            })
        }
    }, [selectedOfficeId, officeList])

    if (officeList === undefined) {
        return (<Typography textAlign="center" sx={{ transform: "translateY(250px)" }}>Loading office data...</Typography>)
    }

    function handleCreateOffice(inputOffice: Office) {
        createOffice(inputOffice)
            .then(() => getOffices())
            .then((result) => {
                setOfficeList(result)
                setCreateOfficeModalOpen(false)
            })
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    function handleUpdateOffice(inputOffice: Office) {
        updateOfficeById(inputOffice)
            .then(() => getOffices())
            .then((result) => {
                setOfficeList(result)
                setUpdateOfficeModalOpen(false)
            })
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    return (
        <Stack direction="column" spacing={2} padding={2}>
            <OfficeActionBar
                sx={{ width: "100%" }}
                onCreate={() => setCreateOfficeModalOpen(true)}
                onUpdate={() => setUpdateOfficeModalOpen(true)}
                createDisabled={false}
                updateDisabled={selectedOfficeId === null}
            />
            <OfficeFlatList
                sx={{ width: "100%" }}
                data={officeList}
                selectedOfficeId={selectedOfficeId === null ? undefined : selectedOfficeId}
                onOfficeSelectionChanged={(id: any) => setSelectedOfficeId(id)}
            />
            <NewOfficeModal
                isOpen={isCreateOfficeModalOpen}
                onClose={() => setCreateOfficeModalOpen(false)}
                onSubmit={handleCreateOffice}
                onNetworkError={props.onNetworkError}
            />
            <UpdateOfficeModal
                isOpen={isUpdateOfficeModalOpen}
                onClose={() => setUpdateOfficeModalOpen(false)}
                selectedOfficeObject={selectedOfficeObject === undefined ? new Office() : selectedOfficeObject}
                onSubmit={handleUpdateOffice}
                onNetworkError={props.onNetworkError}
            />
        </Stack>
    )
}

export default OfficeTab