import React, { useEffect, useRef } from "react"
import {
    Paper,
    Stack,
    Typography,
    Divider,
    Accordion,
    AccordionDetails,
    AccordionSummary
} from "@mui/material"
import { ExpandMore } from "@mui/icons-material"

import { Mission } from "../../../classes/Mission"
import { FlightPath } from "../../../classes/FlightPath"
import { OperatingArea } from "../../../classes/OperatingArea"
import { Point } from "../../../classes/Point"
import { Waypoint } from "../../../classes/Waypoint"
import { OperatingAreaPoint } from "../../../classes/OperatingAreaPoint"
import { MapRenderer } from "../../../classes/MapRenderer"

export default function MissionMapPanel(props: {
    missionData: Mission,
    sx?: object
}) {

    const canvasRef = useRef<HTMLCanvasElement>(null)

    useEffect(() => {
        const canvas = canvasRef.current
        const context = canvas!.getContext("2d")

        context!.canvas.width = 800
        context!.canvas.height = 600

        const renderer = new MapRenderer()
        renderer.width = context!.canvas.width
        renderer.height = context!.canvas.height
        renderer.points = props.missionData.points
        renderer.paths = props.missionData.flightPaths
        renderer.areas = props.missionData.operatingAreas

        renderer.setViewport()
        renderer.render2d(context!)
    }, [props.missionData])

    return (
        <Paper sx={props.sx}>
            <Stack direction="column" spacing={1} padding={2}>
                <Typography variant="h5">Map</Typography>
                <Divider />
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography>Expand</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <canvas ref={canvasRef} />
                    </AccordionDetails>
                </Accordion>
            </Stack>
        </Paper>
    )
}