import {Organization} from "./Organization";

export class Office {
    id?: number
    organizationId: number = 0
    name: string = ""
    symbol: string = ""
    canOriginateMissions: boolean = false

    organizationObject?: Organization

    parseObject(input: any) {
        this.id = input.id
        this.organizationId = input.organizationId
        this.name = input.name
        this.symbol = input.symbol
        this.canOriginateMissions = input.canOriginateMissions

        if (input.organizationObject !== undefined) {
            const o = new Organization()
            o.parseObject(input.organizationObject)
            this.organizationObject = o
        }
    }

    objectify(): object {
        return {
            id: this.id,
            organizationId: this.organizationId,
            name: this.name,
            symbol: this.symbol,
            canOriginateMissions: this.canOriginateMissions,
            organizationObject: this.organizationObject?.objectify()
        }
    }
}