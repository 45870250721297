const config = {
    isStandalone: false,
    apiAddr: '',
    uiRoute: '/ui',
    apiRoute: '/api'
};

const testConfig = {
    isStandalone: true,
    apiAddr: 'localhost:8081',
    uiRoute: '/ui',
    apiRoute: '/api'
};

//export default testConfig;
export default config;