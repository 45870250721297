import React, {useEffect} from "react"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import PrimaryLayout from "../../../common/layouts/PrimaryLayout"
import ResetPassphraseLayout from "../layouts/ResetPassphraseLayout"
import Paper from "@mui/material/Paper"
import Stack from "@mui/material/Stack"

import config from "../../../data/config"
import getCompleteUiUrl from "../../../data/getCompleteUiUrl"
import getBanner from "../../../data/sysinfo/getBanner"
import self from "../../../data/user/self"
import changeMyPassphrase from "../../../data/user/changeMyPassphrase"
import {SysInfoBannerParams} from "../../../classes/SysInfoBannerParams";
import {User} from "../../../classes/User";
import NetworkError from "../../../common/components/NetworkError";

function ResetPassphrase() {
    const navigate = useNavigate()

    const [bannerData, setBannerData] = useState<SysInfoBannerParams | undefined>(undefined)
    const [userData, setUserData] = useState<User | undefined>(undefined)

    // TODO: Add network alert here.

    useEffect(() => {
        if (bannerData === undefined) {
            getBanner()
                .then((d) => setBannerData(d))
                .catch((err) => {
                    console.log(err)
                    // setNetworkErrorSnackbarOpen(true)
                })
        }

        if (userData === undefined) {
            self()
                .then((d) => setUserData(d))
                .catch((err) => {
                    console.log(err)
                    // setNetworkErrorSnackbarOpen(true)
                })
        }
    }, [])

    function handleResetPassphrase(passphrase: string) {
        changeMyPassphrase(passphrase)
            .then(() => navigate(getCompleteUiUrl(config, "/account")))
            .catch((err) => console.log("Could not connect to the server." + err))
    }

    if (bannerData === undefined || userData === undefined) {
        return null // TODO: Make this into a loading page for all pages.
    }

    function content() {
        return (
            <Stack direction="column" padding={2}>
                <Paper sx={{ width: "400px" }}>
                    <ResetPassphraseLayout
                        title="Reset Passphrase"
                        onCancel={() => navigate(getCompleteUiUrl(config, "/account"))}
                        onSubmit={handleResetPassphrase}
                    />
                </Paper>
            </Stack>
        )
    }

    return (
        <PrimaryLayout
            sysInfoBanner={bannerData}
            userData={userData}
            navId=""
            title="Reset Passphrase"
            content={content()}
        />
    )
}

export default ResetPassphrase