import React from "react"
import Stack from "@mui/material/Stack"
import Button from "@mui/material/Button"
import Paper from "@mui/material/Paper"

function OperatorActionBar(props: {
    onCreate: Function,
    onUpdate: Function,
    createDisabled: boolean,
    updateDisabled: boolean,
    sx?: object
}) {

    // TODO: <Button variant="contained" disabled={props.updateDisabled} onClick={() => props.onUpdate()}>Update</Button>

    return (
        <Paper sx={props.sx}>
            <Stack direction="row" justifyContent="center" spacing={2} padding={2}>
                <Button variant="contained" disabled={props.createDisabled} onClick={() => props.onCreate()}>Create</Button>

            </Stack>
        </Paper>
    )
}

export default OperatorActionBar