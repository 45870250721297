import { Waypoint } from "./Waypoint"

export class FlightPath {
    id?: number
    missionId: number = 0
    label: string = ""
    waypoints: Waypoint[] = []

    parseObject(input: any) {
        this.id = input.id
        this.missionId = input.missionId
        this.label = input.label
        this.waypoints = input.waypoints.map((w: any) => {
            const waypoint = new Waypoint()
            waypoint.parseObject(w)
            return waypoint
        })
    }

    objectify(): object {
        return {
            id: this.id,
            missionId: this.missionId,
            label: this.label,
            waypoints: this.waypoints.map((w: Waypoint) => w.objectify())
        }
    }
}