import React from "react"
import { useState } from "react"
import Stack from "@mui/material/Stack"
import Divider from "@mui/material/Divider"
import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import List from "@mui/material/List"
import { IconButton, Typography } from "@mui/material"
import { ArrowBack, ArrowForward } from "@mui/icons-material"

function ListContentView(props: { list: any, buttonBar: any, content: any, selectionMessage: string }) {
    const [isListDrawerOpen, setListDrawerOpen] = useState(true)

    const openListComponent = (
        <Paper>
            <Stack
                direction="column"
                sx={{
                    height: "100%",
                }}
            >
                <Box sx={{ height: "100%" }}></Box>
                <Divider />
                <IconButton onClick={() => setListDrawerOpen(true)}><ArrowForward/></IconButton>
            </Stack>
        </Paper>
    );

    const listComponent = (
        <Paper>
            <Stack
                direction="column"
                sx={{
                    width: "400px",
                    minWidth: "400px",
                    height: "100%",
                }}
            >
                {props.buttonBar}
                <Divider />
                <List
                    sx={{
                        width: "100%",
                        height: "100%",
                        overflow: "scroll"
                    }}
                >
                    {props.list}
                </List>
                <Divider />
                <Stack direction="row" justifyContent="space-between">
                    <Typography alignSelf="center" paddingLeft={1}>{props.selectionMessage}</Typography>
                    <IconButton onClick={() => setListDrawerOpen(false)}><ArrowBack /></IconButton>
                </Stack>
            </Stack>
        </Paper>
    );

    return (
        <Stack
            direction="row"
            sx={{
                width: "100%",
                height: "100%"
            }}
        >
            {isListDrawerOpen ? listComponent : openListComponent}
            <Divider
                flexItem
                orientation="vertical"
            />
            <Box
                sx={{
                    width: "100%",
                    height: "100%",
                    overflow: "scroll",
                }}
            >
                {props.content}
            </Box>
        </Stack>
    );
}

export default ListContentView