import React from 'react';
import { Paper, Stack, Button, Box } from '@mui/material';

import { DataGrid } from '@mui/x-data-grid';
import {Operation} from "../../../classes/Operation";
import {OperationObjective} from "../../../classes/OperationObjective";

function OperationObjectivePanel(props: {
    operationData: Operation,
    objectiveData?: OperationObjective,
    onObjectiveSelectionChanged: Function,
    sx?: object
}) {

    const columns = [
        { field: 'description', headerName: 'Description', width: 500 },
        { field: 'accomplished', headerName: 'Accomplished?', width: 150 }
    ];

    return (
        <Paper sx={props.sx}>
            <Stack direction="column" spacing={1} padding={2}>
                <Box sx={{ height: 400 }}>
                    <DataGrid
                        rows={props.operationData.objectives!.map((o: OperationObjective) => o.objectify())}
                        columns={columns}
                        rowSelectionModel={props.objectiveData === undefined ? [] : props.objectiveData!.id}
                        onRowSelectionModelChange={(e) => props.onObjectiveSelectionChanged(e)}
                    />
                </Box>
            </Stack>
        </Paper>
    );
}

export default OperationObjectivePanel;