import React from 'react';
import { Paper, Stack, Typography, Divider, TextField, Button } from '@mui/material';
import {Mission} from "../../../classes/Mission";

function MissionTaskedOrganizationPanel(props: {
    missionData: Mission,
    onClickAssign: Function,
    sx?: object
}) {

    const dataFields = props.missionData.assignedOrganizationId === undefined ? (
        <Stack direction="row" justifyContent="center">
            <Typography variant='h6'>None Assigned</Typography>
        </Stack>
    ) : [
        (
            <TextField
                label="Name"
                InputProps={{ readOnly: true }}
                value={props.missionData.assignedOrganizationObject?.name}
            />
        ),
        (
            <TextField
                label="Symbol"
                InputProps={{ readOnly: true }}
                value={props.missionData.assignedOrganizationObject?.symbol}
            />
        )
    ]

    return (
        <Paper sx={props.sx}>
            <Stack direction="column" spacing={1} padding={2}>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant='h5'>Tasked Organization</Typography>
                    {props.missionData.userPermissions!.modify && props.missionData.isDraft ?
                        (<Button variant="contained" onClick={() => props.onClickAssign()}>Assign</Button>) : null}
                </Stack>
                <Divider />
                {dataFields}
            </Stack>
        </Paper>
    );
}

export default MissionTaskedOrganizationPanel;