import {Office} from "./Office";
import {DeconflictionUserPermissions} from "./DeconflictionUserPermissions";

export class Deconfliction {
    id?: number
    missionId: number = 0
    officeId: number = 0
    description: string = ""
    isConcur?: boolean
    reason?: string

    officeObject?: Office
    userPermissions?: DeconflictionUserPermissions

    parseObject(input: any) {
        this.id = input.id
        this.missionId = input.missionId
        this.officeId = input.officeId
        this.description = input.description
        this.isConcur = input.isConcur
        this.reason = input.reason

        if (input.officeObject !== undefined) {
            const o = new Office()
            o.parseObject(input.officeObject)
            this.officeObject = o
        }

        if (input.userPermissions !== undefined) {
            const p = new DeconflictionUserPermissions()
            p.parseObject(input.userPermissions)
            this.userPermissions = p
        }
    }

    objectify(): object {
        return {
            id: this.id,
            missionId: this.missionId,
            officeId: this.officeId,
            description: this.description,
            isConcur: this.isConcur,
            reason: this.reason,
            officeObject: this.officeObject?.objectify(),
            userPermissions: this.userPermissions?.objectify()
        }
    }
}