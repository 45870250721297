import React from "react"
import { Modal, Paper } from "@mui/material"
import EditMissionPointLayout from "../layouts/EditMissionPointLayout"
import { Point } from "../../../classes/Point"
import { Mission } from "../../../classes/Mission"

export default function EditMissionPointModal(props: {
    isOpen: boolean,
    missionData: Mission,
    selectedPointId?: number,
    onClose: Function,
    onSubmit: Function
}) {

    if (props.selectedPointId === undefined) {
        return null
    }

    function findPoint(): Point {
        for (const point of props.missionData.points!) {
            if (point.id === props.selectedPointId) {
                return point
            }
        }

        return new Point()
    }

    return (
        <Modal
            open={props.isOpen}
            onClose={() => props.onClose()}
        >
            <Paper
                sx={{
                    position: 'absolute',
                    width: '600px',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    padding: 2
                }}
            >
                <EditMissionPointLayout
                    title="Edit Point"
                    initData={findPoint()}
                    onCancel={() => props.onClose()}
                    onSubmit={props.onSubmit}
                />
            </Paper>
        </Modal>
    )
}