import React from 'react';
import { useState } from 'react';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';

import OperationSummaryLayout from './OperationSummaryLayout';
import OperationObjectivesLayout from './OperationObjectivesLayout';
import {Operation} from "../../../classes/Operation";
import {OperationObjective} from "../../../classes/OperationObjective";

function OperationTabbedLayout(props: {
    operationData: Operation,
    objectiveData?: OperationObjective,
    onClickNewObjective: Function,
    onClickEditObjective: Function,
    onClickEditOperation: Function,
    onObjectiveSelectionChanged: Function,
    modals?: any
}) {
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);

    function selectTab(event: any, newValue: any) {
        setSelectedTabIndex(newValue);
    }

    function renderTab() {
        if (selectedTabIndex === 0) {
            return (
                <OperationSummaryLayout
                    operationData={props.operationData}
                    onClickEdit={props.onClickEditOperation}
                />
            );
        } else if (selectedTabIndex === 1) {
            return (
                <OperationObjectivesLayout
                    operationData={props.operationData}
                    objectiveData={props.objectiveData}
                    onObjectiveSelectionChanged={props.onObjectiveSelectionChanged}
                    onClickNew={props.onClickNewObjective}
                    onClickEdit={props.onClickEditObjective}
                />
            );
        }
    }

    return (
        <Box sx={{ width: '100%' }}>
            {props.modals}
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={selectedTabIndex} onChange={selectTab} centered variant="scrollable" scrollButtons="auto">
                    <Tab label="Summary" />
                    <Tab label="Objectives" />
                </Tabs>
            </Box>
            {renderTab()}
        </Box>
    );
}

export default OperationTabbedLayout;