import React from "react"
import { useState } from "react"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import { DateTimePicker } from "@mui/x-date-pickers"
import { Checkbox, FormControlLabel } from "@mui/material"

import Joi from "joi"
import dayjs from "dayjs"
import {OperatorEmergencyReservation} from "../../../classes/OperatorEmergencyReservation";
import {Operator} from "../../../classes/Operator";

function EditOperatorEmergencyReservationLayout(props: {
    title: string,
    initInfo: OperatorEmergencyReservation,
    operatorList?: Operator[],
    onSubmit: Function,
    onCancel: Function
}) {

    const [isPendingSubmit, setPendingSubmit] = useState(false)
    const [inputOperatorId, setInputOperatorId] = useState(props.initInfo.operatorId)
    const [inputIsAvailable, setInputIsAvailable] = useState(props.initInfo.isAvailable)
    const [inputTimeBegin, setInputTimeBegin] = useState(props.initInfo.timeBegin)
    const [inputTimeEnd, setInputTimeEnd] = useState(props.initInfo.timeEnd)

    const timeBegin = dayjs(inputTimeBegin)
    const timeEnd = dayjs(inputTimeEnd)

    const isOperatorIdValid = Joi.number().integer().positive().required().validate(inputOperatorId)
    const isIsAvailableValid = Joi.boolean().required().validate(inputIsAvailable)
    const isInputTimeBeginValid = Joi.date().required().validate(timeBegin.format())
    const isInputTimeEndValid = Joi.date().required().validate(timeEnd.format())
    const isValid = (!isOperatorIdValid.error && !isIsAvailableValid.error && !isInputTimeBeginValid.error && !isInputTimeEndValid.error)

    function handleSubmit() {
        setPendingSubmit(true)

        const inputReservation = props.initInfo
        inputReservation.operatorId = inputOperatorId
        inputReservation.isAvailable = inputIsAvailable
        inputReservation.timeBegin = timeBegin
        inputReservation.timeEnd = timeEnd

        props.onSubmit(inputReservation)
    }

    const bottomControls = isPendingSubmit === true ? (<Typography>Loading...</Typography>) : [(<Button variant="text" onClick={() => props.onCancel()}>Cancel</Button>), (<Button variant="contained" disabled={!isValid} onClick={handleSubmit}>Submit</Button>)]

    if (props.operatorList === undefined || props.operatorList === null) {
        return (<Typography variant="h6">Loading...</Typography>)
    }

    return (
        <Stack direction="column" spacing={1}>
            <Typography variant="h6">{props.title}</Typography>
            <FormControl fullWidth disabled={isPendingSubmit}>
                <InputLabel id="operator-select">Select Operator</InputLabel>
                <Select
                    labelId="operator-select"
                    label="Select Operator"
                    value={inputOperatorId}
                    onChange={(event: any) => setInputOperatorId(event.target.value)}
                >
                    <MenuItem value={0}>none</MenuItem>
                    {props.operatorList.map((a: any) => (<MenuItem value={a.id}>{`${a.callsign} (${a.rank} ${a.firstName} ${a.lastName})`}</MenuItem>))}
                </Select>
            </FormControl>
            <Stack direction="row" spacing={1}>
                <DateTimePicker
                    sx={{ width: "50%" }}
                    label="Start Time"
                    slotProps={{
                        textField: {
                            error: isInputTimeBeginValid.error != null
                        },
                    }}
                    disabled={isPendingSubmit}
                    ampm={false}
                    value={timeBegin}
                    onChange={(event: any) => setInputTimeBegin(event)}
                />
                <DateTimePicker
                    sx={{ width: "50%" }}
                    label="End Time"
                    slotProps={{
                        textField: {
                            error: isInputTimeEndValid.error != null
                        },
                    }}
                    disabled={isPendingSubmit}
                    ampm={false}
                    value={timeEnd}
                    onChange={(event: any) => setInputTimeEnd(event)}
                />
            </Stack>
            <Stack direction="row" spacing={1}>
                <FormControlLabel
                    label="Available for tasking"
                    control={<Checkbox checked={inputIsAvailable} onChange={(e) => setInputIsAvailable(e.target.checked)} />}
                />
            </Stack>
            <Stack direction="row" spacing={1} justifyContent="right">{bottomControls}</Stack>
        </Stack>
    )
}

export default EditOperatorEmergencyReservationLayout