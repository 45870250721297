import React, {useState, useEffect, useLayoutEffect} from "react"
import Stack from "@mui/material/Stack"

import ProductActionBar from "../components/ProductActionBar"
import ProductFlatList from "../components/ProductFlatList"
import getProducts from "../../../data/product/getProducts"
import {Typography} from "@mui/material"
import createProduct from "../../../data/product/createProduct"
import updateProductById from "../../../data/product/updateProductById"
import NewProductModal from "../modals/NewProductModal"
import UpdateProductModal from "../modals/UpdateProductModal"
import {Product} from "../../../classes/Product";

function ProductTab(props: { onNetworkError: Function }) {
    const [isCreateProductModalOpen, setCreateProductModalOpen] = useState<boolean>(false)
    const [isUpdateProductModalOpen, setUpdateProductModalOpen] = useState<boolean>(false)

    const [productList, setProductList] = useState<Product[] | undefined>(undefined)
    const [selectedProductId, setSelectedProductId] = useState<number | null>(null)
    const [selectedProductObject, setSelectedProductObject] = useState<Product | undefined>(undefined)

    useEffect(() => {
        getProducts()
            .then((result) => setProductList(result))
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }, [])

    useLayoutEffect(() => {
        if (selectedProductId === null) {
            setSelectedProductObject(undefined)
        } else if (productList !== undefined) {
            productList.map((o: Product) => {
                if (o.id == selectedProductId) {
                    setSelectedProductObject(o)
                }
            })
        }
    }, [selectedProductId, productList])

    if (productList === undefined) {
        return (<Typography textAlign="center" sx={{ transform: "translateY(250px)" }}>Loading product data...</Typography>)
    }

    function handleCreateProduct(inputProduct: Product) {
        createProduct(inputProduct)
            .then(() => getProducts())
            .then((result) => {
                setProductList(result)
                setCreateProductModalOpen(false)
            })
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    function handleUpdateProduct(inputProduct: Product) {
        updateProductById(inputProduct)
            .then(() => getProducts())
            .then((result) => {
                setProductList(result)
                setUpdateProductModalOpen(false)
            })
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    return (
        <Stack direction="column" spacing={2} padding={2}>
            <ProductActionBar
                sx={{ width: "100%" }}
                onCreate={() => setCreateProductModalOpen(true)}
                onUpdate={() => setUpdateProductModalOpen(true)}
                createDisabled={false}
                updateDisabled={selectedProductId === null}
            />
            <ProductFlatList
                sx={{ width: "100%" }}
                data={productList}
                selectedProductId={selectedProductId === null ? undefined : selectedProductId}
                onProductSelectionChanged={(id: number) => setSelectedProductId(id)}
            />
            <NewProductModal
                isOpen={isCreateProductModalOpen}
                onClose={() => setCreateProductModalOpen(false)}
                onSubmit={handleCreateProduct}
                onNetworkError={props.onNetworkError}
            />
            <UpdateProductModal
                isOpen={isUpdateProductModalOpen}
                onClose={() => setUpdateProductModalOpen(false)}
                selectedProductObject={selectedProductObject === undefined ? new Product() : selectedProductObject}
                onSubmit={handleUpdateProduct}
                onNetworkError={props.onNetworkError}
            />
        </Stack>
    )
}

export default ProductTab