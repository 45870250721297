import React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import { Typography, Stack, Divider } from '@mui/material';

import {Operation} from "../../../classes/Operation";

function OperationListItem(props: {
    operationObject: Operation,
    selected: boolean,
    onSelect: Function
}) {

    const timeStart = props.operationObject.timeStart
    const timeEnd = props.operationObject.timeEnd

    return (
        <ListItemButton selected={props.selected} onClick={() => props.onSelect()}>
            <Stack direction="column" sx={{ width: '100%' }}>
                <Divider />
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body1">{props.operationObject.name}</Typography>
                    <Typography variant="body2">{props.operationObject.organizationObject?.symbol}</Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant='body2'>{timeStart.format('MM/DD/YYYY')}</Typography>
                    <Typography variant='body2'>{"-"}</Typography>
                    <Typography variant='body2'>{timeEnd.format('MM/DD/YYYY')}</Typography>
                </Stack>
                <Divider />
            </Stack>
        </ListItemButton>
    );
}

export default OperationListItem;