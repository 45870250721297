import { Mission } from "./Mission"
import { Asset } from "./Asset"

export class MissionAssetAssignment {
    id?: number
    missionId: number = 0
    assetId: number = 0

    missionObject?: Mission
    assetObject?: Asset

    parseObject(input: any) {
        this.id = input.id
        this.missionId = input.missionId
        this.assetId = input.assetId

        if (input.missionObject !== undefined) {
            const m = new Mission()
            m.parseObject(input.missionObject)
            this.missionObject = m
        }

        if (input.assetObject !== undefined) {
            const a = new Asset()
            a.parseObject(input.assetObject)
            this.assetObject = a
        }
    }

    objectify(): object {
        return {
            id: this.id,
            missionId: this.missionId,
            assetId: this.assetId,
            missionObject: this.missionObject?.objectify(),
            assetObject: this.assetObject?.objectify()
        }
    }
}