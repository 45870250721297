import Cookies from "universal-cookie"

import config from "../config"
import getCompleteApiUrl from "../getCompleteApiUrl"
import {AssetReadinessProblem} from "../../classes/AssetReadinessProblem"
import {ApiCallFailure} from "../../classes/exception/ApiCallFailure"

export default async function createReadinessProblemInAssetById(problem: AssetReadinessProblem) {
    const cookies = new Cookies()

    if (cookies.get("accessToken") === undefined) {
        const error = new ApiCallFailure()
        error.connectionSuccessful = false
        error.errorType = "NO_ACCESS_TOKEN"

        throw error
    }

    var res: Response
    try {
        res = await fetch(getCompleteApiUrl(config, `/assets/id/${problem.assetId}/readiness/problems`), {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${cookies.get("accessToken")}`
            },
            body: JSON.stringify({
                subject: problem.subject,
                description: problem.description,
                started: problem.started,
                resolutionExpected: problem.resolutionExpected.format(),
            }),
        })
    } catch (error) {
        const apiFailure = new ApiCallFailure()
        apiFailure.connectionSuccessful = false

        throw apiFailure
    }

    if (res!.status === 201) {
        const responseObject = await res!.json()

        const responseProblem = new AssetReadinessProblem()
        responseProblem.parseObject(responseObject)

        return responseProblem
    }

    const callFailure = new ApiCallFailure()
    callFailure.connectionSuccessful = true
    callFailure.responseCode = res!.status
    callFailure.errorType = "REQUEST_REJECTED"

    throw callFailure
}