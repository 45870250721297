import React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import AppBar from '@mui/material/AppBar'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import Toolbar from '@mui/material/Toolbar'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'

import NavigationBarItem from '../components/NavigationBarItem'
import SysInfoBanner from '../components/SysInfoBanner'

import MenuIcon from '@mui/icons-material/Menu'
import AccountIcon from '@mui/icons-material/AccountBox'

import {
    EventNote,
    People,
    Radar,
    ElectricBolt,
    Warehouse,
    CrisisAlert,
    AdminPanelSettings
} from "@mui/icons-material"

import config from '../../data/config'
import getCompleteUiUrl from '../../data/getCompleteUiUrl'
import logout from '../../data/auth/logout'
import getBanner from "../../data/sysinfo/getBanner";
import self from "../../data/user/self";
import NetworkError from "../components/NetworkError";

import { User } from "../../classes/User"
import { SysInfoBannerParams } from "../../classes/SysInfoBannerParams"

function PrimaryLayout(props: {
    sysInfoBanner: SysInfoBannerParams,
    userData: User,
    navId: string,
    title: string,
    modals?: any,
    content: any
}) {
    const navigate = useNavigate()

    document.title = 'SAMS - ' + props.title

    const [isNetworkErrorSnackbarOpen, setNetworkErrorSnackbarOpen] = useState<boolean>(false)
    const [isNavigationDrawerOpen, setNavigationDrawerOpen] = useState<boolean>(false)

    const NavigationSideBar = (
        <Paper>
            <Stack direction="column">
                <List>
                    <NavigationBarItem
                        open={isNavigationDrawerOpen}
                        selected={props.navId === "schedule"}
                        label="Schedule"
                        icon={<EventNote />}
                        onClick={() => navigate(getCompleteUiUrl(config, '/schedule'))}
                    />
                    <NavigationBarItem
                        open={isNavigationDrawerOpen}
                        selected={props.navId === "operations"}
                        label="Operations"
                        icon={<Radar />}
                        onClick={() => navigate(getCompleteUiUrl(config, '/operations'))}
                    />
                    <NavigationBarItem
                        open={isNavigationDrawerOpen}
                        selected={props.navId === "missions"}
                        label="Missions"
                        icon={<ElectricBolt />}
                        onClick={() => navigate(getCompleteUiUrl(config, '/missions'))}
                    />
                    <NavigationBarItem
                        open={isNavigationDrawerOpen}
                        selected={props.navId === "operators"}
                        label="Operators"
                        icon={<People />}
                        onClick={() => navigate(getCompleteUiUrl(config, '/operators'))}
                    />
                    <NavigationBarItem
                        open={isNavigationDrawerOpen}
                        selected={props.navId === "assets"}
                        label="Assets"
                        icon={<Warehouse />}
                        onClick={() => navigate(getCompleteUiUrl(config, '/assets'))}
                    />
                    <NavigationBarItem
                        open={isNavigationDrawerOpen}
                        selected={props.navId === "emergencies"}
                        label="Emergencies"
                        icon={<CrisisAlert />}
                        onClick={() => navigate(getCompleteUiUrl(config, '/emergencies'))}
                    />
                    {props.userData.isAdmin ? (<NavigationBarItem
                        open={isNavigationDrawerOpen}
                        selected={props.navId === "admin"}
                        label="Administration"
                        icon={<AdminPanelSettings />}
                        onClick={() => navigate(getCompleteUiUrl(config, '/admin'))}
                    />) : null}
                </List>
            </Stack>
        </Paper>
    );

    const AppBarLeft = (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start'
            }}
        >
            <Stack
                direction="row"
                alignItems="center"
                spacing={2}
            >
                <IconButton
                    size='large'
                    edge='start'
                    color='inherit'
                    onClick={() => setNavigationDrawerOpen(!isNavigationDrawerOpen)}
                >
                    <MenuIcon />
                </IconButton>
                <Typography
                    variant="h5"
                    color="inherit"
                >
                    SAMS
                </Typography>
            </Stack>
        </Box>
    );

    const AppBarRight = (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end'
            }}
        >
            <Stack
                direction="row"
                alignItems="center"
                spacing={2}
            >
                <Button color="inherit" variant="text" disableElevation endIcon={<AccountIcon />} onClick={() => { navigate(getCompleteUiUrl(config, "/logout")) }}>
                    {props.userData.username}
                </Button>
            </Stack>
        </Box>
    );

    const AppBarCenter = (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center'
            }}
        >
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={2}
            >
                <Typography variant="h6" color="inherit">{props.title}</Typography>
            </Stack>
        </Box>
    );

    const MainAppBar = (
        <AppBar position="static" sx={{ zIndex: 1 }}>
            <Toolbar>
                {AppBarLeft}
                {AppBarCenter}
                {AppBarRight}
            </Toolbar>
        </AppBar>
    );

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100vw',
                height: '100vh'
            }}
        >
            <NetworkError
                open={isNetworkErrorSnackbarOpen}
                onClose={() => setNetworkErrorSnackbarOpen(false)}
            />
            {props.modals}
            <SysInfoBanner data={props.sysInfoBanner} />
            {MainAppBar}
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    overflow: 'hidden'
                }}
            >
                <Stack direction="row" sx={{ width: '100%', height: '100%' }}>
                    {NavigationSideBar}
                    <Divider orientation='vertical' />
                    <Box overflow="scroll" sx={{ width: '100%' }}>{props.content}</Box>
                </Stack>
            </Box>
            <SysInfoBanner data={props.sysInfoBanner} />
        </Box>
    );
}

export default PrimaryLayout;