import React from "react"
import Stack from "@mui/material/Stack"
import Paper from "@mui/material/Paper"
import Box from "@mui/material/Box"
import { Typography, Divider } from "@mui/material"

import { DataGrid } from "@mui/x-data-grid"
import { Asset } from "../../../classes/Asset"
import { AssetAvailabilityStatus } from "../../../classes/AssetAvailabilityStatus"
import {OperatorAvailabilityStatus} from "../../../classes/OperatorAvailabilityStatus";

function AvailableAssetsPanel(props: {
    availableAssetList: AssetAvailabilityStatus[],
    sx?: object
}) {

    const filteredReservations = props.availableAssetList.filter((r) => {
        if (r.statusCode === AssetAvailabilityStatus.STATUS_UNSCHEDULED
            || r.statusCode === AssetAvailabilityStatus.STATUS_RESERVED_FOR_EMERGENCY_DISPATCH_AVAILABLE) {
            return true
        }
    })

    const columns = [
        { field: "callsign", headerName: "Callsign", width: 250, valueGetter: (params: any) => params.row.assetObject!.callsign},
        { field: "reason", headerName: "Reason", width: 300, valueGetter: (params: any) => {
            if (params.row.statusCode === AssetAvailabilityStatus.STATUS_UNSCHEDULED) {
                return "Unscheduled"
            } else if (params.row.statusCode === AssetAvailabilityStatus.STATUS_RESERVED_FOR_EMERGENCY_DISPATCH_AVAILABLE) {
                return "Reserved - Available for Tasking"
            } else if (params.row.statusCode === AssetAvailabilityStatus.STATUS_RESERVED_FOR_EMERGENCY_DISPATCH_UNAVAILABLE) {
                return "Reserved - Not Available for Tasking"
            }
        }}
    ]

    return (
        <Paper sx={props.sx}>
            <Stack direction="column" spacing={2} padding={2}>
                <Typography variant="h5">Available Assets</Typography>
                <Divider />
                <Box sx={{ height: 500 }}>
                    <DataGrid
                        rows={filteredReservations.map((asset: AssetAvailabilityStatus) => asset.objectify())}
                        columns={columns}
                    />
                </Box>
            </Stack>
        </Paper>
    )
}

export default AvailableAssetsPanel