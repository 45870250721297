import React from 'react';
import { useState } from 'react';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';

import UserTab from '../pages/UserTab';
import OperatorTab from '../pages/OperatorTab'
import ManufacturerTab from '../pages/ManufacturerTab';
import ProductTab from '../pages/ProductTab';
import AssetTab from '../pages/AssetTab';
import OrganizationTab from '../pages/OrganizationTab';
import OfficeTab from "../pages/OfficeTab"

function AdministrationTabbedLayout(props: { onNetworkError: Function }) {
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);

    function selectTab(event: any, newValue: any) {
        setSelectedTabIndex(newValue);
    }

    function renderTab() {
        if (selectedTabIndex === 0) {
            return (<UserTab onNetworkError={props.onNetworkError} />)
        } else if (selectedTabIndex === 1) {
            return (<OperatorTab onNetworkError={props.onNetworkError} />)
        } else if (selectedTabIndex === 2) {
            return (<ManufacturerTab onNetworkError={props.onNetworkError} />)
        } else if (selectedTabIndex === 3) {
            return (<ProductTab onNetworkError={props.onNetworkError} />)
        } else if (selectedTabIndex === 4) {
            return (<AssetTab onNetworkError={props.onNetworkError} />)
        } else if (selectedTabIndex === 5) {
            return (<OrganizationTab onNetworkError={props.onNetworkError} />)
        } else if (selectedTabIndex === 6) {
            return (<OfficeTab onNetworkError={props.onNetworkError} />)
        }
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={selectedTabIndex} onChange={selectTab} centered variant="scrollable" scrollButtons="auto">
                    <Tab label="Users" />
                    <Tab label="Operators" />
                    <Tab label="Manufacturers" />
                    <Tab label="Products" />
                    <Tab label="Assets" />
                    <Tab label="Organizations" />
                    <Tab label="Offices" />
                </Tabs>
            </Box>
            {renderTab()}
        </Box>
    );
}

export default AdministrationTabbedLayout;