import Cookies from 'universal-cookie';
import { sha512 } from 'js-sha512';

import config from '../config';
import getCompleteApiUrl from '../getCompleteApiUrl';
import { ApiCallFailure } from "../../classes/exception/ApiCallFailure";
import { User } from "../../classes/User";

async function createUser(username: string, passphrase: string, isEnabled: boolean, isAdmin: boolean) {
    const cookies = new Cookies();

    if (cookies.get('accessToken') === undefined) {
        const error = new ApiCallFailure()
        error.connectionSuccessful = false
        error.errorType = "NO_ACCESS_TOKEN"

        throw error
    }

    const passphraseHash = sha512.update(passphrase).hex()

    var res: Response
    try {
        res = await fetch(getCompleteApiUrl(config, `/users`), {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${cookies.get('accessToken')}`
            },
            body: JSON.stringify({
                username,
                passphraseHash,
                isEnabled,
                isAdmin
            }),
        })
    } catch (error) {
        const apiFailure = new ApiCallFailure()
        apiFailure.connectionSuccessful = false

        throw apiFailure
    }

    if (res!.status === 201) {
        const responseObject = await res!.json()

        const responseUser = new User()
        responseUser.parseObject(responseObject)

        return responseUser
    }

    const callFailure = new ApiCallFailure()
    callFailure.connectionSuccessful = true
    callFailure.responseCode = res!.status
    callFailure.errorType = "REQUEST_REJECTED"

    throw callFailure
}

export default createUser;