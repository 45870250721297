import React from "react"
import { Box } from "@mui/material"

function ScheduleItemTimeline(props: any) {

    return (
        <Box
            sx={{
                position: "relative",
                height: "30px",
            }}
        >
            {props.children}
        </Box>
    )
}

export default ScheduleItemTimeline