import React from 'react';
import { useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import Joi from 'joi';
import {Deconfliction} from "../../../classes/Deconfliction";
import {Office} from "../../../classes/Office";
import {Mission} from "../../../classes/Mission";

function EditMissionDeconflictionLayout(props: {
    title: string,
    initInfo: Deconfliction,
    officeList?: Office[],
    onSubmit: Function,
    onCancel: Function
}) {

    const [isPendingSubmit, setPendingSubmit] = useState<boolean>(false);
    const [inputOfficeId, setInputOfficeId] = useState<number>(props.initInfo.officeId);
    const [inputDescription, setInputDescription] = useState<string>(props.initInfo.description);

    // Joi validations.
    const isOfficeIdValid = Joi.number().integer().positive().required().validate(inputOfficeId);
    const isDescriptionValid = Joi.string().min(1).max(256).required().validate(inputDescription);
    const isValid = (!isOfficeIdValid.error && !isDescriptionValid.error);

    function handleSubmit() {
        setPendingSubmit(true);

        const inputDeconfliction = props.initInfo
        inputDeconfliction.officeId = inputOfficeId
        inputDeconfliction.description = inputDescription

        props.onSubmit(inputDeconfliction)
    }

    const bottomControls = isPendingSubmit === true ? (<Typography>Loading...</Typography>) : [(<Button variant='text' onClick={() => props.onCancel()}>Cancel</Button>), (<Button variant='contained' disabled={!isValid} onClick={handleSubmit}>Submit</Button>)];

    if (props.officeList === undefined || props.officeList === null) {
        return (<Typography variant="h6">Loading...</Typography>)
    }

    return (
        <Stack direction="column" spacing={1}>
            <Typography variant="h6">{props.title}</Typography>
            <FormControl fullWidth disabled={isPendingSubmit}>
                <InputLabel id="office-select">Office</InputLabel>
                <Select
                    labelId="office-select"
                    label="Office"
                    disabled={isPendingSubmit}
                    value={inputOfficeId}
                    onChange={(event: any) => setInputOfficeId(event.target.value)}
                >
                    <MenuItem value={undefined}>none</MenuItem>
                    {props.officeList.map((p) => (<MenuItem value={p.id}>{`${p.name} (${p.symbol})`}</MenuItem>))}
                </Select>
            </FormControl>
            <TextField
                type="text"
                variant="outlined"
                label="Description"
                multiline
                rows={3}
                error={inputDescription.length > 0 && isDescriptionValid.error !== undefined}
                disabled={isPendingSubmit}
                value={inputDescription}
                onChange={(e) => setInputDescription(e.target.value)}
            />
            <Stack direction="row" spacing={1} justifyContent="right">{bottomControls}</Stack>
        </Stack>
    );
}

export default EditMissionDeconflictionLayout;