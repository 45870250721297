import React, { useState } from "react"
import { Modal, Paper } from "@mui/material"
import EditAssetEmergencyReservationLayout from "../layouts/EditAssetEmergencyReservationLayout"
import getAssets from "../../../data/asset/getAssets"
import {AssetEmergencyReservation} from "../../../classes/AssetEmergencyReservation";

function NewAssetEmergencyReservationModal(props: {
    isOpen: boolean,
    onSubmit: Function,
    onClose: Function
}) {
    const [assetList, setAssetList] = useState<any>(undefined)

    if (assetList === undefined) {
        setAssetList(null)
        getAssets()
            .then((result) => setAssetList(result))
            .catch((err) => {
                console.error(err)
                // TODO: props.onNetworkError()
            })
    }

    return (
        <Modal
            open={props.isOpen}
            onClose={() => props.onClose()}
        >
            <Paper
                sx={{
                    position: "absolute",
                    width: "600px",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    padding: 2
                }}
            >
                <EditAssetEmergencyReservationLayout
                    title="Create Asset Emergency Reservation"
                    initInfo={new AssetEmergencyReservation()}
                    onCancel={() => props.onClose()}
                    onSubmit={props.onSubmit}
                    assetList={assetList}
                />
            </Paper>
        </Modal>
    )
}

export default NewAssetEmergencyReservationModal