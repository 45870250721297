import React from "react"
import dayjs from "dayjs"
import {
    Stack,
    Paper,
    Typography,
    Divider
} from "@mui/material"
import { Mission } from "../../../classes/Mission"

function MissionBoardItem(props: { missionObject: Mission }) {

    const currentTime = dayjs();
    const timeStartScheduled = props.missionObject.timeStartScheduled
    const timeStartProjected = props.missionObject.timeStartProjected
    const timeStartActual = props.missionObject.timeStartActual
    const timeFinishScheduled = props.missionObject.timeFinishScheduled
    const timeFinishProjected = props.missionObject.timeFinishProjected
    const timeFinishActual = props.missionObject.timeFinishActual

    function getStatusString() {
        if (props.missionObject.isComplete) {
            return "Complete";
        } else if (props.missionObject.isCanceled) {
            return "Canceled";
        } else if (props.missionObject.isInProgress) {
            return "In Progress";
        } else {
            if (timeStartProjected.isSame(timeStartScheduled) && currentTime.isBefore(timeStartScheduled)) {
                return "On Time";
            } else if (timeStartProjected.isAfter(timeStartScheduled) || currentTime.isAfter(timeStartScheduled)) {
                return "Delayed";
            } else if (timeStartProjected.isBefore(timeStartScheduled)) {
                return "Starting Early";
            }
        }
    }

    function getRPICString() {
        const rpicAssignments = props.missionObject.operatorAssignments!.filter((assignment) => assignment.isRPIC)

        if (rpicAssignments.length === 1) {
            return rpicAssignments[0].operatorObject!.callsign
        } else if (rpicAssignments.length > 1) {
            return "[Multiple RPIC]"
        }

        return " "
    }

    return (
        <Paper
            sx={{
                width: "100%"
            }}
        >
            <Stack direction="row" padding={2} justifyContent="space-between">
                <Typography variant="h6" sx={{ width: "30%" }}>{props.missionObject.name}</Typography>
                <Divider orientation="vertical" sx={{ height: "100%" }} />
                <Typography variant="body1" sx={{ width: "10%", textAlign: "center" }}>{props.missionObject.callsign}</Typography>
                <Typography variant="body1" sx={{ width: "10%", textAlign: "center" }}>{props.missionObject.missionNumber}</Typography>
                <Typography variant="body1" sx={{ width: "10%", textAlign: "center" }}>{props.missionObject.assignedOrganizationObject?.symbol}</Typography>
                <Typography variant="body1" sx={{ width: "10%", textAlign: "center" }}>{getRPICString()}</Typography>
                <Typography variant="body1" sx={{ width: "10%", textAlign: "center" }}>
                    {timeStartActual === undefined ? timeStartProjected.format("HH:mm") : timeStartActual!.format("HH:mm")}
                </Typography>
                <Divider orientation="vertical" sx={{ height: "100%" }} />
                <Typography variant="body1" sx={{ width: "10%", textAlign: "center" }}>
                    {timeFinishActual === undefined ? timeFinishProjected.format("HH:mm") : timeFinishActual!.format("HH:mm")}
                </Typography>
                <Divider orientation="vertical" sx={{ height: "100%" }} />
                <Typography variant="body1" sx={{ width: "10%", textAlign: "center" }}>{getStatusString()}</Typography>
            </Stack>
        </Paper>
    )
}

export default MissionBoardItem