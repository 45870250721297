import React, { useState, useEffect } from 'react';
import { Modal, Box, Paper } from '@mui/material';
import EditOrganizationLayout from '../layouts/EditOrganizationLayout';
import getOrganizations from "../../../data/organization/getOrganizations";
import {Organization} from "../../../classes/Organization";

function NewOrganizationModal(props: {
    isOpen: boolean,
    onClose: Function,
    onSubmit: Function,
    onNetworkError: Function
}) {
    const [organizationList, setOrganizationList] = useState<Organization[] | undefined>(undefined)

    useEffect(() => {
        getOrganizations()
            .then((result) => setOrganizationList(result))
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }, [])

    const newOrganization = new Organization()

    return (
        <Modal
            open={props.isOpen}
            onClose={() => props.onClose()}
        >
            <Paper
                sx={{
                    position: 'absolute',
                    width: '600px',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    padding: 2
                }}
            >
                <EditOrganizationLayout
                    title="Create Organization"
                    initInfo={newOrganization}
                    organizationList={organizationList}
                    onCancel={() => props.onClose()}
                    onSubmit={props.onSubmit}
                />
            </Paper>
        </Modal>
    );
}

export default NewOrganizationModal;