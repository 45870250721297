import React from "react"
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"

import { SysInfoBannerParams } from "../../classes/SysInfoBannerParams"

function InfoBanner(props: { data: SysInfoBannerParams }) {

    const textLeft = (
        <Box sx={{ width: "100%" }}>
            <Typography>
                <Box
                    sx={{
                        fontWeight: "bold",
                        color: props.data.textColor,
                        textAlign: "start"
                    }}
                    marginLeft={1}
                >
                    {props.data.textLeft}
                </Box>
            </Typography>
        </Box>
    );

    const textCenter = (
        <Box sx={{ width: "100%" }}>
            <Typography>
                <Box 
                    sx={{
                        fontWeight: "bold",
                        color: props.data.textColor,
                        textAlign: "center"
                    }}
                >
                    {props.data.textCenter}
                </Box>
            </Typography>
        </Box>
    );
    
    const textRight = (
        <Box sx={{ width: "100%", justifyContent: "end" }}>
            <Typography>
                <Box
                    sx={{
                        fontWeight: "bold",
                        color: props.data.textColor,
                        textAlign: "end"
                    }}
                    marginRight={1}
                >
                    {props.data.textRight}
                </Box>
            </Typography>
        </Box>
    );

    return (
        <Box sx={{ width: "100%", backgroundColor: props.data.bannerColor }}>
            <Stack direction="row" justifyContent="space-evenly">
                {textLeft}
                {textCenter}
                {textRight}
            </Stack>
        </Box>
    );
}

export default InfoBanner