import React from 'react';
import { Stack } from '@mui/material';

import OperationObjectiveActionPanel from '../components/OperationObjectiveActionPanel';
import OperationObjectivePanel from '../components/OperationObjectivePanel';
import {OperationObjective} from "../../../classes/OperationObjective";
import {Operation} from "../../../classes/Operation";

function OperationObjectivesLayout(props: {
    operationData: Operation,
    objectiveData?: OperationObjective,
    onClickNew: Function,
    onClickEdit: Function,
    onObjectiveSelectionChanged: Function
}) {

    return (
        <Stack direction="column" padding={2} spacing={2}>
            <OperationObjectiveActionPanel
                sx={{ width: "100%" }}
                onClickNew={props.onClickNew}
                onClickEdit={props.onClickEdit}
                objectiveData={props.objectiveData}
            />
            <OperationObjectivePanel
                sx={{ width: "100%" }}
                operationData={props.operationData}
                objectiveData={props.objectiveData}
                onObjectiveSelectionChanged={props.onObjectiveSelectionChanged}
            />
        </Stack>
    );
}

export default OperationObjectivesLayout;