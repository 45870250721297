import React from "react"
import { useState } from "react"
import { Stack, Typography, Button } from "@mui/material"

function ConfirmDialogLayout(props: { title: string, message: string, onConfirm: Function, onClose: Function }) {
    const [isPendingSubmit, setPendingSubmit] = useState(false)

    function handleConfirm() {
        setPendingSubmit(true)
        props.onConfirm()
    }

    const bottomControls = isPendingSubmit === true ? (<Typography>Loading...</Typography>) : [(<Button variant="text" onClick={() => props.onClose()}>Cancel</Button>), (<Button variant="contained" onClick={handleConfirm}>Proceed</Button>)]

    return (
        <Stack direction="column" spacing={1} padding={1}>
            <Typography variant="h6">{props.title}</Typography>
            <Typography>{props.message}</Typography>
            <Stack direction="row" spacing={1} justifyContent="right">{bottomControls}</Stack>
        </Stack>
    )
}

export default ConfirmDialogLayout