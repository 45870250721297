import React from 'react';
import {
    Paper,
    Stack,
    Typography,
    Divider,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    AccordionActions, Button
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import {Mission} from "../../../classes/Mission";
import {Waypoint} from "../../../classes/Waypoint";
import {FlightPath} from "../../../classes/FlightPath";

function MissionFlightPathPanel(props: {
    missionData: Mission,
    onEdit: Function,
    onDelete: Function,
    sx?: object
}) {

    const fltpths = props.missionData.flightPaths!.map((fp: FlightPath) => {
        const pts = fp.waypoints.map((p: Waypoint) => (
            <Stack direction="row" spacing={1} justifyContent="space-between">
                <Typography>{p.sequence}</Typography>
                <Typography>{p.latitude}</Typography>
                <Typography>{p.longitude}</Typography>
                <Typography>{p.altitude}</Typography>
            </Stack>
        ))

        return (
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography>{fp.label}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {pts}
                </AccordionDetails>
                {props.missionData.isDraft ? (
                    <AccordionActions>
                        <Button variant="contained" onClick={() => props.onDelete(fp.id)}>Delete</Button>
                        <Button variant="contained" onClick={() => props.onEdit(fp.id)}>Edit</Button>
                    </AccordionActions>
                ) : null}
            </Accordion>
        )
    });


    return (
        <Paper sx={props.sx}>
            <Stack direction="column" spacing={1} padding={2}>
                <Typography variant='h5'>Flight Paths</Typography>
                <Divider />
                {fltpths}
            </Stack>
        </Paper>
    );
}

export default MissionFlightPathPanel;