import React, { useState, useEffect } from 'react';
import { Modal, Paper } from '@mui/material';
import EditMissionDeconflictionLayout from '../layouts/EditMissionDeconflictionLayout';
import getOffices from "../../../data/office/getOffices"
import {Deconfliction} from "../../../classes/Deconfliction";
import {Mission} from "../../../classes/Mission";
import {Office} from "../../../classes/Office";

function NewMissionDeconflictionModal(props: {
    missionData: Mission,
    isOpen: boolean,
    onClose: Function,
    onSubmit: Function,
    onNetworkError: Function
}) {
    const [officeList, setOfficeList] = useState<Office[] | undefined>(undefined)

    // TODO: Need to implement a way to seek an office through organizations.
    useEffect(() => {
        if (props.isOpen) {
            getOffices()
                .then((result) => setOfficeList(result))
                .catch((err) => {
                    console.error(err)
                    props.onNetworkError()
                })
        }
    }, [props.isOpen])

    const newDeconfliction = new Deconfliction()
    newDeconfliction.missionId = props.missionData.id!

    return (
        <Modal
            open={props.isOpen}
            onClose={() => props.onClose()}
        >
            <Paper
                sx={{
                    position: 'absolute',
                    width: '600px',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    padding: 2
                }}
            >
                <EditMissionDeconflictionLayout
                    title="Create Deconfliction"
                    initInfo={newDeconfliction}
                    onCancel={() => props.onClose()}
                    onSubmit={props.onSubmit}
                    officeList={officeList}
                />
            </Paper>
        </Modal>
    );
}

export default NewMissionDeconflictionModal;