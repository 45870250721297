import React from "react"
import dayjs from "dayjs"

import SchedulePanel from "../components/SchedulePanel"
import { Mission } from "../../../classes/Mission"

function ChartLayout(props: { missionList: Mission[] }) {

    return (
        <SchedulePanel
            missionList={props.missionList}
            startTime={dayjs().subtract(1, "day").startOf("date")}
            endTime={dayjs().add(2, "day").endOf("date")}
            width={15000}
        />
    )
}

export default ChartLayout