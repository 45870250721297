import dayjs, { Dayjs } from "dayjs"
import { Mission } from "./Mission"
import { Asset } from "./Asset"
import { AssetReadiness } from "./AssetReadiness"
import { AssetEmergencyReservation } from "./AssetEmergencyReservation"

export class AssetAvailabilityStatus {
    static STATUS_UNSCHEDULED = 1
    static STATUS_RESERVED_FOR_EMERGENCY_DISPATCH_AVAILABLE = 2
    static STATUS_RESERVED_FOR_EMERGENCY_DISPATCH_UNAVAILABLE = 4
    static STATUS_CURRENTLY_EXECUTING_MISSION = 5
    static STATUS_NOT_READY = 6

    assetId: number = 0
    statusCode: number = 0

    assetObject?: Asset
    emergencyReservations?: AssetEmergencyReservation[]
    currentMissions?: Mission[]

    parseObject(input: any) {
        this.assetId = input.assetId
        this.statusCode = input.statusCode

        if (input.assetObject !== undefined) {
            this.assetObject = new Asset()
            this.assetObject.parseObject(input.assetObject)
        }

        this.emergencyReservations = input.emergencyReservations?.map((r: any) => {
            const reservationObject = new AssetEmergencyReservation()
            reservationObject.parseObject(r)
            return reservationObject
        })

        this.currentMissions = input.currentMissions?.map((r: any) => {
            const missionObject = new Mission()
            missionObject.parseObject(r)
            return missionObject
        })
    }

    objectify(): object {
        return {
            id: this.assetId,
            assetId: this.assetId,
            statusCode: this.statusCode,
            assetObject: this.assetObject?.objectify(),
            emergencyReservations: this.emergencyReservations?.map((r) => r.objectify()),
            currentMission: this.currentMissions?.map((m) => m.objectify())
        }
    }
}