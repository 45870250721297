import React, { useState } from "react"
import {
    Stack,
    Typography,
    TextField,
    Button
} from "@mui/material"

import Joi from "joi"

import { Mission } from "../../../classes/Mission"

export default function AssignCallsignLayout(props: {
    title: string,
    initInfo: Mission,
    onSubmit: Function,
    onCancel: Function
}) {

    const [isPendingSubmit, setPendingSubmit] = useState<boolean>(false)
    const [inputCallsign, setInputCallsign] = useState<string | undefined>(props.initInfo.callsign)
    const isCallsignValid = Joi.string().min(1).max(20).required().validate(inputCallsign)
    const isValid = (!isCallsignValid.error)

    function handleSubmit() {
        setPendingSubmit(true)
        props.onSubmit(inputCallsign)
    }

    const bottomControls = isPendingSubmit === true ? (<Typography>Loading...</Typography>) : [(<Button variant='text' onClick={() => props.onCancel()}>Cancel</Button>), (<Button variant='contained' disabled={!isValid} onClick={handleSubmit}>Submit</Button>)]

    return (
        <Stack direction="column" spacing={1}>
            <Typography variant="h6">{props.title}</Typography>
            <TextField
                type="text"
                variant="outlined"
                label="Callsign"
                disabled={isPendingSubmit}
                value={inputCallsign}
                onChange={(e) => setInputCallsign(e.target.value)}
            />
            <Stack direction="row" spacing={1} justifyContent="right">{bottomControls}</Stack>
        </Stack>
    )
}