import React, {useState, useEffect, useLayoutEffect} from "react"
import Stack from "@mui/material/Stack"

import OrganizationActionBar from "../components/OrganizationActionBar"
import OrganizationFlatList from "../components/OrganizationFlatList"
import getOrganizations from "../../../data/organization/getOrganizations"
import { Typography } from "@mui/material"
import createOrganization from "../../../data/organization/createOrganization"
import updateOrganizationById from "../../../data/organization/updateOrganizationById"
import NewOrganizationModal from "../modals/NewOrganizationModal"
import UpdateOrganizationModal from "../modals/UpdateOrganizationModal"
import {Organization} from "../../../classes/Organization";

function OrganizationTab(props: { onNetworkError: Function }) {
    const [isCreateOrganizationModalOpen, setCreateOrganizationModalOpen] = useState<boolean>(false)
    const [isUpdateOrganizationModalOpen, setUpdateOrganizationModalOpen] = useState<boolean>(false)
    // TODO: Manage members.

    const [organizationList, setOrganizationList] = useState<Organization[] | undefined>(undefined)
    const [selectedOrganizationId, setSelectedOrganizationId] = useState<number | null>(null)
    const [selectedOrganizationObject, setSelectedOrganizationObject] = useState<Organization | undefined>(undefined)

    useEffect(() => {
        getOrganizations()
            .then((result) => setOrganizationList(result))
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }, [])

    useLayoutEffect(() => {
        if (selectedOrganizationId === null) {
            setSelectedOrganizationObject(undefined)
        } else if (organizationList !== undefined) {
            organizationList.map((o: Organization) => {
                if (o.id == selectedOrganizationId) {
                    setSelectedOrganizationObject(o)
                }
            })
        }
    }, [selectedOrganizationId, organizationList]);

    if (organizationList === undefined) {
        return (<Typography textAlign="center" sx={{ transform: "translateY(250px)" }}>Loading organization data...</Typography>)
    }

    function handleCreateOrganization(inputOrganization: Organization) {
        createOrganization(inputOrganization)
            .then(() => getOrganizations())
            .then((result) => {
                setOrganizationList(result)
                setCreateOrganizationModalOpen(false)
            })
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    function handleUpdateOrganization(inputOrganization: Organization) {
        updateOrganizationById(inputOrganization)
            .then(() => getOrganizations())
            .then((result) => {
                setOrganizationList(result)
                setUpdateOrganizationModalOpen(false)
            })
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    return (
            <Stack direction="column" spacing={2} padding={2}>
                <OrganizationActionBar
                    sx={{ width: "100%" }}
                    onCreate={() => setCreateOrganizationModalOpen(true)}
                    onUpdate={() => setUpdateOrganizationModalOpen(true)}
                    createDisabled={false}
                    updateDisabled={selectedOrganizationId === null}
                />
                <OrganizationFlatList
                    sx={{ width: "100%" }}
                    data={organizationList}
                    selectedOrganizationId={selectedOrganizationId === null ? undefined : selectedOrganizationId}
                    onOrganizationSelectionChanged={(id: number) => setSelectedOrganizationId(id)}
                />
                <NewOrganizationModal
                    isOpen={isCreateOrganizationModalOpen}
                    onClose={() => setCreateOrganizationModalOpen(false)}
                    onSubmit={handleCreateOrganization}
                    onNetworkError={props.onNetworkError}
                />
                <UpdateOrganizationModal
                    isOpen={isUpdateOrganizationModalOpen}
                    onClose={() => setUpdateOrganizationModalOpen(false)}
                    selectedOrganizationObject={selectedOrganizationObject === undefined ? new Organization() : selectedOrganizationObject}
                    onSubmit={handleUpdateOrganization}
                    onNetworkError={props.onNetworkError}
                />
            </Stack>
        )
}

export default OrganizationTab