import React from "react"
import { useState, useEffect, useLayoutEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Typography, List, Stack } from "@mui/material"
import PrimaryLayout from "../../../common/layouts/PrimaryLayout"

import NewReadinessProblemModal from "../modals/NewReadinessProblemModal"
import NewReadinessRequirementModal from "../modals/NewReadinessRequirementModal"
import ConfirmResolveProblemModal from "../modals/ConfirmResolveProblemModal"
import RenewReadinessRequirementModal from "../modals/RenewReadinessRequirementModal"

import config from "../../../data/config"
import getCompleteUiUrl from "../../../data/getCompleteUiUrl"
import getAssetProfiles from "../../../data/asset/getAssetProfiles"
import createReadinessRequirementInAssetById from "../../../data/asset/createReadinessRequirementInAssetById"
import createReadinessProblemInAssetById from "../../../data/asset/createReadinessProblemInAssetById"
import resolveReadinessProblemById from "../../../data/asset/resolveReadinessProblemById"
import renewReadinessRequirementById from "../../../data/asset/renewReadinessRequirementById"
import ListContentView from "../../../common/layouts/ListContentView"
import AssetListItem from "../components/AssetListItem"
import AssetTabbedLayout from "../layouts/AssetTabbedLayout"
import {Asset} from "../../../classes/Asset";
import {AssetReadinessProblem} from "../../../classes/AssetReadinessProblem";
import {AssetReadinessRequirement} from "../../../classes/AssetReadinessRequirement";
import {Dayjs} from "dayjs";
import {SysInfoBannerParams} from "../../../classes/SysInfoBannerParams";
import {User} from "../../../classes/User";
import getBanner from "../../../data/sysinfo/getBanner";
import self from "../../../data/user/self";
import NetworkError from "../../../common/components/NetworkError";

function Assets() {
    const navigate = useNavigate()

    const [bannerData, setBannerData] = useState<SysInfoBannerParams | undefined>(undefined)
    const [userData, setUserData] = useState<User | undefined>(undefined)

    const [isCreateReadinessProblemModalOpen, setCreateReadinessProblemModalOpen] = useState<boolean>(false)
    const [isCreateReadinessRequirementModalOpen, setCreateReadinessRequirementModalOpen] = useState<boolean>(false)
    const [isConfirmResolveReadinessProblemModalOpen, setConfirmResolveReadinessProblemModalOpen] = useState<boolean>(false)
    const [isRenewReadinessRequirementModalOpen, setRenewReadinessRequirementModalOpen] = useState<boolean>(false)

    const [assetList, setAssetList] = useState<Asset[] | undefined>(undefined)
    const [selectedAssetId, setSelectedAssetId] = useState<number | null>(null)
    const [selectedAssetObject, setSelectedAssetObject] = useState<Asset | undefined>(undefined)
    const [selectedReadinessProblemId, setSelectedReadinessProblemId] = useState<number | null>(null)
    const [selectedReadinessProblemObject, setSelectedReadinessProblemObject] = useState<AssetReadinessProblem | undefined>(undefined)
    const [selectedReadinessRequirementId, setSelectedReadinessRequirementId] = useState<number | null>(null)
    const [selectedReadinessRequirementObject, setSelectedReadinessRequirementObject] = useState<AssetReadinessRequirement | undefined>(undefined)

    useEffect(() => {
        if (bannerData === undefined) {
            getBanner()
                .then((d) => setBannerData(d))
                .catch((err) => {
                    console.error(err)
                    // TODO: props.onNetworkError()
                })
        }

        if (userData === undefined) {
            self()
                .then((d) => setUserData(d))
                .catch((err) => {
                    console.error(err)
                    // TODO: props.onNetworkError()
                })
        }
    }, [])

    useEffect(() => {
        if (userData !== undefined) {
            getAssetProfiles()
                .then((result) => setAssetList(result))
                .catch((err) => {
                    console.error(err)
                    // TODO: props.onNetworkError()
                })
        }
    }, [userData])

    useLayoutEffect(() => {
        if (selectedAssetId === null) {
            setSelectedAssetObject(undefined)
        } else if (assetList !== undefined) {
            assetList.map((a: Asset) => {
                if (a.id == selectedAssetId) {
                    setSelectedAssetObject(a)
                }
            })
        }
    }, [selectedAssetId, assetList])

    useLayoutEffect(() => {
        if (selectedReadinessProblemId === null) {
            setSelectedReadinessProblemObject(undefined)
        } else if (selectedAssetObject !== undefined) {
            selectedAssetObject.readiness!.problems.map((p: AssetReadinessProblem) => {
                if (p.id == selectedReadinessProblemId) {
                    setSelectedReadinessProblemObject(p)
                }
            })
        }
    }, [selectedReadinessProblemId, selectedAssetObject])

    useLayoutEffect(() => {
        if (selectedReadinessRequirementId === null) {
            setSelectedReadinessRequirementObject(undefined)
        } else if (selectedAssetObject !== undefined) {
            selectedAssetObject.readiness!.requirements.map((p: AssetReadinessRequirement) => {
                if (p.id == selectedReadinessRequirementId) {
                    setSelectedReadinessRequirementObject(p)
                }
            })
        }
    }, [selectedReadinessRequirementId, selectedAssetObject])

    if (bannerData === undefined || userData === undefined) {
        return null // TODO: Make this into a loading page for all pages.
    }

    const buttonBar = (
        <Stack direction="row" padding={1} spacing={1}>
        </Stack>
    )

    const listMessage = assetList === undefined ? "Loading assets..." : `${assetList.length} assets loaded.`

    const assetListItems = assetList === undefined ? null : assetList.map((assetObject: any) => (
        <AssetListItem selected={selectedAssetId === assetObject.id} onSelect={() => setSelectedAssetId(assetObject.id)} assetObject={assetObject} />
    ))
    
    const list = (<List>{assetListItems}</List>)

    function content() {
        if (selectedAssetId === null) {
            return (<Typography textAlign="center" sx={{ transform: "translateY(250px)" }}>Select an asset to view its data.</Typography>)
        } else if (selectedAssetId != null && selectedAssetObject === null) {
            return (<Typography textAlign="center" sx={{ transform: "translateY(250px)" }}>Loading asset data...</Typography>)
        } else if (selectedAssetId != null && selectedAssetObject != null) {
            if (selectedAssetId == selectedAssetObject.id) {
                return (
                    <AssetTabbedLayout
                        assetData={selectedAssetObject}
                        readinessProblemData={selectedReadinessProblemObject}
                        readinessRequirementData={selectedReadinessRequirementObject}
                        onSelectReadinessProblem={(e: number[]) => setSelectedReadinessProblemId(e[0])}
                        onSelectReadinessRequirement={(e: number[]) => setSelectedReadinessRequirementId(e[0])}
                        onClickCreateReadinessProblem={() => setCreateReadinessProblemModalOpen(true)}
                        onClickCreateReadinessRequirement={() => setCreateReadinessRequirementModalOpen(true)}
                        onClickResolveReadinessProblem={() => setConfirmResolveReadinessProblemModalOpen(true)}
                        onClickRenewReadinessRequirement={() => setRenewReadinessRequirementModalOpen(true)}
                    />
                )
            }
        }
    }

    function handleCreateReadinessProblem(inputProblem: AssetReadinessProblem) {
        createReadinessProblemInAssetById(inputProblem)
            .then(() => getAssetProfiles())
            .then((result) => {
                setAssetList(result)
                setSelectedAssetObject(undefined)
                setCreateReadinessProblemModalOpen(false)
            })
            .catch((err) => {
                console.error(err)
                // TODO: props.onNetworkError()
            })
    }

    function handleCreateReadinessRequirement(inputRequirement: AssetReadinessRequirement) {
        createReadinessRequirementInAssetById(inputRequirement)
            .then(() => getAssetProfiles())
            .then((result) => {
                setAssetList(result)
                setSelectedAssetObject(undefined)
                setCreateReadinessRequirementModalOpen(false)
            })
            .catch((err) => {
                console.error(err)
                // TODO: props.onNetworkError()
            })
    }

    function handleResolveReadinessProblem() {
        resolveReadinessProblemById(selectedReadinessProblemId!)
            .then(() => getAssetProfiles())
            .then((result) => {
                setAssetList(result)
                setSelectedAssetObject(undefined)
                setConfirmResolveReadinessProblemModalOpen(false)
            })
            .catch((err) => {
                console.error(err)
                // TODO: props.onNetworkError()
            })
    }

    function handleRenewReadinessRequirement(expirationDate: Dayjs) {
        renewReadinessRequirementById(selectedReadinessRequirementId!, expirationDate)
            .then(() => getAssetProfiles())
            .then((result) => {
                setAssetList(result)
                setSelectedAssetObject(undefined)
                setRenewReadinessRequirementModalOpen(false)
            })
            .catch((err) => {
                console.error(err)
                // TODO: props.onNetworkError()
            })
    }

    return (
        <PrimaryLayout
            sysInfoBanner={bannerData}
            userData={userData}
            navId="assets"
            title="Assets"
            content={
                <ListContentView
                    buttonBar={buttonBar}
                    list={list}
                    content={content()}
                    selectionMessage={listMessage}
                />
            }
            modals={[
                (
                    <NewReadinessProblemModal
                        assetData={selectedAssetObject === undefined ? new Asset() : selectedAssetObject}
                        isOpen={isCreateReadinessProblemModalOpen}
                        onClose={() => setCreateReadinessProblemModalOpen(false)}
                        onSubmit={handleCreateReadinessProblem}
                    />
                ),
                (
                    <NewReadinessRequirementModal
                        assetData={selectedAssetObject === undefined ? new Asset() : selectedAssetObject}
                        isOpen={isCreateReadinessRequirementModalOpen}
                        onClose={() => setCreateReadinessRequirementModalOpen(false)}
                        onSubmit={handleCreateReadinessRequirement}
                    />
                ),
                (
                    <ConfirmResolveProblemModal
                        isOpen={isConfirmResolveReadinessProblemModalOpen}
                        onClose={() => setConfirmResolveReadinessProblemModalOpen(false)}
                        onConfirm={handleResolveReadinessProblem}
                    />
                ),
                (
                    <RenewReadinessRequirementModal
                        isOpen={isRenewReadinessRequirementModalOpen}
                        onClose={() => setRenewReadinessRequirementModalOpen(false)}
                        onSubmit={handleRenewReadinessRequirement}
                    />
                ),
            ]}
        />
    )
}

export default Assets