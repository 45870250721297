import React from 'react';
import { Modal, Paper } from '@mui/material';
import ResetUserPassphraseLayout from '../layouts/ResetUserPassphraseLayout';

function ResetUserPassphraseModal(props) {
    return (
        <Modal
            open={props.isOpen}
            onClose={props.onClose}
        >
            <Paper
                sx={{
                    position: 'absolute',
                    width: '600px',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    padding: 2
                }}
            >
                <ResetUserPassphraseLayout
                    title={`Reset passphrase`}
                    onCancel={() => props.onClose()}
                    onSubmit={props.onSubmit}
                />
            </Paper>
        </Modal>
    );
}

export default ResetUserPassphraseModal;