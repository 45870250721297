import React, {useEffect, useLayoutEffect, useState} from "react"
import {Stack, Typography} from "@mui/material"

import OperatorEmergencyReservationFlatList from "../components/OperatorEmergencyReservationFlatList"
import OperatorEmergencyReservationActionBar from "../components/OperatorEmergencyReservationActionBar"
import createOperatorEmergencyReservation from "../../../data/operator/createOperatorEmergencyReservation"
import getActiveOperatorEmergencyReservations from "../../../data/operator/getActiveOperatorEmergencyReservations"
import getFutureOperatorEmergencyReservations from "../../../data/operator/getFutureOperatorEmergencyReservations"
import markOperatorEmergencyReservationAvailableById from "../../../data/operator/markOperatorEmergencyReservationAvailableById"
import markOperatorEmergencyReservationUnavailableById from "../../../data/operator/markOperatorEmergencyReservationUnavailableById"
import NewOperatorEmergencyReservationModal from "../modals/NewOperatorEmergencyReservationModal"
import ConfirmMarkOperatorEmergencyReservationAvailableModal from "../modals/ConfirmMarkOperatorEmergencyReservationAvailableModal"
import ConfirmMarkOperatorEmergencyReservationUnavailableModal from "../modals/ConfirmMarkOperatorEmergencyReservationUnavailableModal"
import ConfirmDeleteOperatorEmergencyReservationModal from "../modals/ConfirmDeleteOperatorEmergencyReservationModal"
import { OperatorEmergencyReservation } from "../../../classes/OperatorEmergencyReservation"
import getFutureAssetEmergencyReservations from "../../../data/asset/getFutureAssetEmergencyReservations"
import { AssetEmergencyReservation } from "../../../classes/AssetEmergencyReservation"
import EditOperatorEmergencyReservationModal from "../modals/EditOperatorEmergencyReservationModal"
import deleteOperatorEmergencyReservationById from "../../../data/operator/deleteOperatorEmergencyReservationById"
import updateOperatorEmergencyReservationById from "../../../data/operator/updateOperatorEmergencyReservationById"

function OperatorEmergencyReservationTab(props: { onNetworkError: Function }) {

    const [isNewOperatorEmergencyReservationModalOpen, setNewOperatorEmergencyReservationModalOpen] = useState<boolean>(false)
    const [isEditOperatorEmergencyReservationModalOpen, setEditOperatorEmergencyReservationModalOpen] = useState<boolean>(false)
    const [isConfirmMarkOperatorEmergencyReservationAvailableModalOpen, setConfirmMarkOperatorEmergencyReservationAvailableModalOpen] = useState<boolean>(false)
    const [isConfirmMarkOperatorEmergencyReservationUnavailableModalOpen, setConfirmMarkOperatorEmergencyReservationUnavailableModalOpen] = useState<boolean>(false)
    const [isConfirmDeleteOperatorEmergencyReservationModalOpen, setConfirmDeleteOperatorEmergencyReservationModalOpen] = useState<boolean>(false)

    const [activeOperatorEmergencyReservationList, setActiveOperatorEmergencyReservationList] = useState<OperatorEmergencyReservation[] | undefined>(undefined)
    const [futureOperatorEmergencyReservationList, setFutureOperatorEmergencyReservationList] = useState<OperatorEmergencyReservation[] | undefined>(undefined)
    const [selectedOperatorEmergencyReservationId, setSelectedOperatorEmergencyReservationId] = useState<number | null>(null)
    const [selectedOperatorEmergencyReservationObject, setSelectedOperatorEmergencyReservationObject] = useState<OperatorEmergencyReservation | undefined>(undefined)

    function reloadData() {
        getActiveOperatorEmergencyReservations()
            .then((result) => setActiveOperatorEmergencyReservationList(result))
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })

        getFutureOperatorEmergencyReservations()
            .then((result) => setFutureOperatorEmergencyReservationList(result))
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    useEffect(() => {
        reloadData()
        const intervalId = window.setInterval(reloadData, 20000)

        return () => window.clearInterval(intervalId)
    }, [])

    useLayoutEffect(() => {
        if (selectedOperatorEmergencyReservationId === null) {
            setSelectedOperatorEmergencyReservationObject(undefined)
        } else if (activeOperatorEmergencyReservationList !== undefined && futureOperatorEmergencyReservationList !== undefined) {
            activeOperatorEmergencyReservationList.map((o: OperatorEmergencyReservation) => {
                if (o.id == selectedOperatorEmergencyReservationId) {
                    setSelectedOperatorEmergencyReservationObject(o)
                }
            })

            futureOperatorEmergencyReservationList.map((o: OperatorEmergencyReservation) => {
                if (o.id == selectedOperatorEmergencyReservationId) {
                    setSelectedOperatorEmergencyReservationObject(o)
                }
            })
        }
    }, [selectedOperatorEmergencyReservationId, activeOperatorEmergencyReservationList, futureOperatorEmergencyReservationList])

    if (activeOperatorEmergencyReservationList === undefined || futureOperatorEmergencyReservationList === undefined) {
        return (<Typography textAlign="center" sx={{ transform: "translateY(250px)" }}>Loading operator emergency reservation data...</Typography>)
    }

    function handleCreateOperatorEmergencyReservation(inputReservation: OperatorEmergencyReservation) {
        createOperatorEmergencyReservation(inputReservation)
            .then(() => getActiveOperatorEmergencyReservations())
            .then(() => {
                reloadData()
                setSelectedOperatorEmergencyReservationId(null)
                setNewOperatorEmergencyReservationModalOpen(false)
            })
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    function handleMarkOperatorEmergencyReservationAvailable() {
        markOperatorEmergencyReservationAvailableById(selectedOperatorEmergencyReservationId!)
            .then(() => getActiveOperatorEmergencyReservations())
            .then(() => {
                reloadData()
                setConfirmMarkOperatorEmergencyReservationAvailableModalOpen(false)
            })
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    function handleMarkOperatorEmergencyReservationUnavailable() {
        markOperatorEmergencyReservationUnavailableById(selectedOperatorEmergencyReservationId!)
            .then(() => getActiveOperatorEmergencyReservations())
            .then((result) => {
                reloadData()
                setConfirmMarkOperatorEmergencyReservationUnavailableModalOpen(false)
            })
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    function handleEditOperatorEmergencyReservation(inputReservation: OperatorEmergencyReservation) {
        updateOperatorEmergencyReservationById(inputReservation)
            .then(() => {
                reloadData()
                setEditOperatorEmergencyReservationModalOpen(false)
            })
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    function handleDeleteOperatorEmergencyReservation() {
        deleteOperatorEmergencyReservationById(selectedOperatorEmergencyReservationId!)
            .then(() => {
                reloadData()
                setConfirmDeleteOperatorEmergencyReservationModalOpen(false)
            })
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    return (
        <Stack direction="column" padding={2} spacing={2}>
            <OperatorEmergencyReservationActionBar
                sx={{ width: "100%" }}
                onCreate={() => setNewOperatorEmergencyReservationModalOpen(true)}
                onMarkAvailable={() => setConfirmMarkOperatorEmergencyReservationAvailableModalOpen(true)}
                onMarkUnavailable={() => setConfirmMarkOperatorEmergencyReservationUnavailableModalOpen(true)}
                onEdit={() => setEditOperatorEmergencyReservationModalOpen(true)}
                onDelete={() => setConfirmDeleteOperatorEmergencyReservationModalOpen(true)}
                createDisabled={false}
                markAvailableDisabled={selectedOperatorEmergencyReservationObject === undefined ? true : selectedOperatorEmergencyReservationObject!.isAvailable}
                markUnavailableDisabled={selectedOperatorEmergencyReservationObject === undefined ? true : !selectedOperatorEmergencyReservationObject!.isAvailable}
                editDisabled={selectedOperatorEmergencyReservationId === null}
                deleteDisabled={selectedOperatorEmergencyReservationId === null}
            />
            <OperatorEmergencyReservationFlatList
                sx={{ width: "100%" }}
                activeReservations={activeOperatorEmergencyReservationList}
                futureReservations={futureOperatorEmergencyReservationList}
                selectedReservationObject={selectedOperatorEmergencyReservationObject}
                onReservationSelectionChanged={(id: number) => setSelectedOperatorEmergencyReservationId(id)}
            />
            <NewOperatorEmergencyReservationModal
                isOpen={isNewOperatorEmergencyReservationModalOpen}
                onClose={() => setNewOperatorEmergencyReservationModalOpen(false)}
                onSubmit={handleCreateOperatorEmergencyReservation}
            />
            <EditOperatorEmergencyReservationModal
                isOpen={isEditOperatorEmergencyReservationModalOpen}
                onClose={() => setEditOperatorEmergencyReservationModalOpen(false)}
                onSubmit={handleEditOperatorEmergencyReservation}
                reservationObject={selectedOperatorEmergencyReservationObject === undefined ? new OperatorEmergencyReservation() : selectedOperatorEmergencyReservationObject!}
            />
            <ConfirmMarkOperatorEmergencyReservationAvailableModal
                isOpen={isConfirmMarkOperatorEmergencyReservationAvailableModalOpen}
                onClose={() => setConfirmMarkOperatorEmergencyReservationAvailableModalOpen(false)}
                onConfirm={handleMarkOperatorEmergencyReservationAvailable}
            />
            <ConfirmMarkOperatorEmergencyReservationUnavailableModal
                isOpen={isConfirmMarkOperatorEmergencyReservationUnavailableModalOpen}
                onClose={() => setConfirmMarkOperatorEmergencyReservationUnavailableModalOpen(false)}
                onConfirm={handleMarkOperatorEmergencyReservationUnavailable}
            />
            <ConfirmDeleteOperatorEmergencyReservationModal
                isOpen={isConfirmDeleteOperatorEmergencyReservationModalOpen}
                onClose={() => setConfirmDeleteOperatorEmergencyReservationModalOpen(false)}
                onConfirm={handleDeleteOperatorEmergencyReservation}
            />
        </Stack>
    )
}

export default OperatorEmergencyReservationTab