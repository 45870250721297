import { Mission } from "./Mission"
import { Operator } from "./Operator"

export class MissionOperatorAssignment {
    id?: number
    missionId: number = 0
    operatorId: number = 0
    isRPIC: boolean = false
    isMO: boolean = false
    isVO: boolean = false

    missionObject?: Mission
    operatorObject?: Operator

    parseObject(input: any) {
        this.id = input.id
        this.missionId = input.missionId
        this.operatorId = input.operatorId
        this.isRPIC = input.isRPIC
        this.isMO = input.isMO
        this.isVO = input.isVO

        if (input.missionObject !== undefined) {
            const m = new Mission()
            m.parseObject(input.missionObject)
            this.missionObject = m
        }

        if (input.operatorObject !== undefined) {
            const o = new Operator()
            o.parseObject(input.operatorObject)
            this.operatorObject = o
        }
    }

    objectify(): object {
        return {
            id: this.id,
            missionId: this.missionId,
            operatorId: this.operatorId,
            isRPIC: this.isRPIC,
            isMO: this.isMO,
            isVO: this.isVO,
            missionObject: this.missionObject?.objectify(),
            operatorObject: this.operatorObject?.objectify()
        }
    }
}