import React, {useEffect, useState} from "react"
import {Stack, Typography} from "@mui/material"

import AvailableAssetsPanel from "../components/AvailableAssetsPanel"
import AvailableOperatorsPanel from "../components/AvailableOperatorsPanel"
import getAvailableAssets from "../../../data/asset/getAvailableAssets"
import getAvailableOperators from "../../../data/operator/getAvailableOperators"
import {Asset} from "../../../classes/Asset";
import {Operator} from "../../../classes/Operator";
import {OperatorAvailabilityStatus} from "../../../classes/OperatorAvailabilityStatus"
import { AssetAvailabilityStatus } from "../../../classes/AssetAvailabilityStatus"

import getAvailabilityStatusInOperators from "../../../data/operator/getAvailabilityStatusInOperators"
import getAvailabilityStatusInAssets from "../../../data/asset/getAvailabilityStatusInAssets"

function AvailableResourcesTab(props: { onNetworkError: Function }) {

    const [operatorAvailabilityStatuses, setOperatorAvailabilityStatuses] = useState<OperatorAvailabilityStatus[] | undefined>(undefined)
    const [assetAvailabilityStatuses, setAssetAvailabilityStatuses] = useState<AssetAvailabilityStatus[] | undefined>(undefined)

    function reloadData() {
        getAvailabilityStatusInAssets()
            .then((result) => setAssetAvailabilityStatuses(result))
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })

        getAvailabilityStatusInOperators()
            .then((result) => setOperatorAvailabilityStatuses(result))
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    useEffect(() => {
        reloadData()
        const intervalId = window.setInterval(reloadData, 20000)

        return () => window.clearInterval(intervalId)
    }, [])

    if (operatorAvailabilityStatuses === undefined || assetAvailabilityStatuses === undefined) {
        return (<Typography textAlign="center" sx={{ transform: "translateY(250px)" }}>Loading resource data...</Typography>)
    }

    return (
        <Stack direction="row" padding={2} spacing={2}>
            <AvailableAssetsPanel
                sx={{ width: "50%" }}
                availableAssetList={assetAvailabilityStatuses}
            />
            <AvailableOperatorsPanel
                sx={{ width: "50%" }}
                availableOperatorList={operatorAvailabilityStatuses}
            />
        </Stack>
    )
}

export default AvailableResourcesTab