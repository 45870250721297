import React from "react"
import { Modal } from "@mui/material"

import MessageBoxPanel from "../components/MessageBoxPanel"

export default function MessageBox(props: { isOpen: boolean, title: string, message: string, onOk: Function, sx?: object }) {

    return (
        <Modal
            open={props.isOpen}
            onClose={() => {}}
        >
            <MessageBoxPanel
                title={props.title}
                message={props.message}
                onOk={props.onOk}
                panelStyle={props.sx}
            />
        </Modal>
    )
}