
export class SysInfoBannerParams {
    bannerColor: string = ""
    textColor: string = ""
    textLeft: string = ""
    textCenter: string = ""
    textRight: string = ""

    parseObject(input: any) {
        this.bannerColor = input.bannerColor
        this.textColor = input.textColor
        this.textLeft = input.textLeft
        this.textCenter = input.textCenter
        this.textRight = input.textRight
    }
}