import React, {useEffect, useState} from 'react';
import { Modal, Paper } from '@mui/material';
import EditMissionTitleLayout from '../layouts/EditMissionTitleLayout';
import getOperations from "../../../data/operation/getOperations";
import {Mission} from "../../../classes/Mission";

function EditMissionTitleModal(props: {
    missionData: Mission,
    isOpen: boolean,
    onClose: Function,
    onSubmit: Function,
    onNetworkError: Function
}) {
    const [operationList, setOperationList] = useState(undefined)

    useEffect(() => {
        if (props.isOpen) {
            getOperations()
                .then((result) => setOperationList(result))
                .catch((err) => {
                    console.error(err)
                    props.onNetworkError()
                })
        }
    }, [props.isOpen])

    return (
        <Modal
            open={props.isOpen}
            onClose={() => props.onClose()}
        >
            <Paper
                sx={{
                    position: 'absolute',
                    width: '600px',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    padding: 2
                }}
            >
                <EditMissionTitleLayout
                    title="Edit Mission"
                    initInfo={props.missionData}
                    onCancel={() => props.onClose()}
                    onSubmit={props.onSubmit}
                    operationList={operationList}
                />
            </Paper>
        </Modal>
    );
}

export default EditMissionTitleModal;