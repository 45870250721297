import React from 'react';
import { useState } from 'react';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';

import MissionSummaryTab from '../pages/MissionSummaryTab';
import MissionAssignmentTab from '../pages/MissionAssignmentTab';
import MissionNotesTab from '../pages/MissionNotesTab';
import MissionObjectivesTab from '../pages/MissionObjectivesTab';
import MissionGeospatialInformationTab from '../pages/MissionGeospatialInformationTab';
import MissionDeconflictionTab from '../pages/MissionDeconflictionTab';
import {Mission} from "../../../classes/Mission";

function MissionTabbedLayout(props: { missionData: Mission, selectedTab: number, onChangeTab: Function, onRefresh: Function, onNetworkError: Function, modals?: any }) {

    function selectTab(event: any, newValue: number) {
        props.onChangeTab(newValue)
    }

    function renderTab() {
        if (props.selectedTab === 0) {
            return (
                <MissionSummaryTab
                    missionData={props.missionData}
                    onNetworkError={props.onNetworkError}
                    onRefresh={props.onRefresh}
                />
            )
        } else if (props.selectedTab === 1) {
            return (
                <MissionAssignmentTab
                    missionData={props.missionData}
                    onNetworkError={props.onNetworkError}
                    onRefresh={props.onRefresh}
                />
            );
        } else if (props.selectedTab == 2) {
            return (
                <MissionNotesTab
                    missionData={props.missionData}
                    onNetworkError={props.onNetworkError}
                    onRefresh={props.onRefresh}
                />
            );
        } else if (props.selectedTab == 3) {
            return (
                <MissionObjectivesTab
                    missionData={props.missionData}
                    onNetworkError={props.onNetworkError}
                    onRefresh={props.onRefresh}
                />
            );
        } else if (props.selectedTab == 4) {
            return (
                <MissionGeospatialInformationTab
                    missionData={props.missionData}
                    onNetworkError={props.onNetworkError}
                    onRefresh={props.onRefresh}
                />
            );
        } else if (props.selectedTab == 5) {
            return (
                <MissionDeconflictionTab
                    missionData={props.missionData}
                    onNetworkError={props.onNetworkError}
                    onRefresh={props.onRefresh}
                />
            );
        }
    }

    return (
        <Box sx={{ width: '100%' }}>
            {props.modals}
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={props.selectedTab} onChange={selectTab} centered variant="scrollable" scrollButtons="auto">
                    <Tab label="Summary" />
                    <Tab label="Assignments" />
                    <Tab label="Notes" />
                    <Tab label="Objectives" />
                    <Tab label="Geospatial Information" />
                    <Tab label="Organizational Deconfliction" />
                </Tabs>
            </Box>
            {renderTab()}
        </Box>
    );
}

export default MissionTabbedLayout