import React from 'react';
import { Paper, Stack, Typography, Divider, Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import dayjs from 'dayjs';
import {OperatorReadinessRequirement} from "../../../classes/OperatorReadinessRequirement";
import {Operator} from "../../../classes/Operator";

function OperatorReadinessRequirementsPanel(props: {
    operatorData: Operator,
    selectedReadinessRequirementData?: OperatorReadinessRequirement,
    onSelectReadinessRequirement: Function,
    sx?: object
}) {

    const columns = [
        { field: 'subject', headerName: 'Subject', width: 250 },
        { field: 'certificationExpires', headerName: 'Expires', width: 200, valueGetter: (params: any) => { return dayjs(params.value).format('MM/DD/YYYY').toString() } },
        { field: 'certificationRenewed', headerName: 'Last Renewed', width: 200, valueGetter: (params: any) => { return dayjs(params.value).format('MM/DD/YYYY').toString() } },
        { field: 'certificationIssued', headerName: 'Issued', width: 200, valueGetter: (params: any) => { return dayjs(params.value).format('MM/DD/YYYY').toString() } },
    ];

    return (
        <Paper sx={props.sx}>
            <Stack direction="column" spacing={1} padding={2}>
                <Typography variant="h5">Readiness Requirements</Typography>
                <Divider />
                <Box sx={{ height: 300 }}>
                    <DataGrid
                        rows={props.operatorData.readiness!.requirements}
                        columns={columns}
                        rowSelectionModel={props.selectedReadinessRequirementData === undefined ? [] : props.selectedReadinessRequirementData!.id}
                        onRowSelectionModelChange={(e) => props.onSelectReadinessRequirement(e)}
                    />
                </Box>
            </Stack>
        </Paper>
    );
}

export default OperatorReadinessRequirementsPanel;