
export class User {
    username?: string
    isEnabled: boolean = false
    isAdmin: boolean = false

    parseObject(input: any) {
        this.username = input.username
        this.isEnabled = input.isEnabled
        this.isAdmin = input.isAdmin
    }

    objectify(): object {
        return {
            username: this.username,
            isEnabled: this.isEnabled,
            isAdmin: this.isAdmin
        }
    }
}