import React, { useState } from 'react';
import { Paper, Stack, Typography, Divider, Button, Box } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import {Mission} from "../../../classes/Mission"
import {MissionOperatorAssignment} from "../../../classes/MissionOperatorAssignment"

function MissionAssignedOperatorsPanel(props: {
    missionData: Mission,
    onClickAssign: Function,
    onClickRemove: Function,
    sx?: object
}) {

    const [selectionModel, setSelectionModel] = useState([])

    const columns = [
        { field: 'callsign', headerName: 'Callsign', width: 120, valueGetter: (params: any) => params.row.operatorObject!.callsign},
        { field: 'rank', headerName: 'Rank', width: 150, valueGetter: (params: any) => params.row.operatorObject!.rank},
        { field: 'name', headerName: 'Name', width: 200, valueGetter: (params: any) => { return `${params.row.operatorObject!.firstName} ${params.row.operatorObject!.lastName}` }},
        { field: 'role', headerName: 'Role', width: 100, valueGetter: (params: any) => {
            var roles = ""

            if (params.row.isRPIC) {
                roles = roles.concat("RPIC ")
            }

            if (params.row.isMO) {
                roles = roles.concat(" MO ")
            }

            if (params.row.isVO) {
                roles = roles.concat(" VO")
            }

            return roles
        }},
        { field: 'error', headerName: 'Audit Flags', width: 100, valueGetter: (params: any) => {
            const matches = props.missionData.flags!.filter((f) => params.row.operatorId === f.operatorId)
            return matches.length > 0 ? matches.length : ""
        }}
    ]

    function getSelectedOperator() {
        const assignment = props.missionData.operatorAssignments!.filter(
            (a: MissionOperatorAssignment) => a.id === selectionModel[0]
        )

        if (assignment.length === 1) {
            return assignment[0].operatorId
        }

        return -1
    }

    const buttonBar = props.missionData.userPermissions!.modify && props.missionData.isDraft ? (
        <Stack direction="row" spacing={1}>
            <Button
                variant="contained"
                disabled={selectionModel.length !== 1}
                onClick={() => props.onClickRemove(getSelectedOperator())}
            >
                Remove
            </Button>
            <Button
                variant="contained"
                onClick={() => props.onClickAssign()}
            >
                Assign
            </Button>
        </Stack>
    ) : null

    return (
        <Paper sx={props.sx}>
            <Stack direction="column" spacing={1} padding={2}>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant='h5'>Operators</Typography>
                    {buttonBar}
                </Stack>
                <Divider />
                <Box sx={{ height: 400 }}>
                    <DataGrid
                        rows={props.missionData.operatorAssignments!.map((assignment: MissionOperatorAssignment) => assignment.objectify())}
                        columns={columns}
                        rowSelectionModel={selectionModel}
                        onRowSelectionModelChange={(e: any) => setSelectionModel(e)}
                    />
                </Box>
            </Stack>
        </Paper>
    );
}

export default MissionAssignedOperatorsPanel;