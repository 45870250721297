import React from "react"
import { Modal, Paper } from "@mui/material"
import UploadKMLFileLayout from "../layouts/UploadKMLFileLayout"
import {Mission} from "../../../classes/Mission"

export default function UploadKMLFileModal(props: {
    missionData: Mission,
    isOpen: boolean,
    onClose: Function,
    onSubmit: Function
}) {


    return (
        <Modal
            open={props.isOpen}
            onClose={() => props.onClose()}
        >
            <Paper
                sx={{
                    position: 'absolute',
                    width: '1200px',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    padding: 2
                }}
            >
                <UploadKMLFileLayout
                    title="Upload KML File"
                    missionData={props.missionData}
                    onCancel={() => props.onClose()}
                    onSubmit={props.onSubmit}
                />
            </Paper>
        </Modal>
    )
}