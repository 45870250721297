import React from 'react';
import { Paper, Stack, Button } from '@mui/material';
import {OperationObjective} from "../../../classes/OperationObjective";

function OperationObjectiveActionPanel(props: {
    objectiveData?: OperationObjective,
    onClickNew: Function,
    onClickEdit: Function,
    sx?: object
}) {

    return (
        <Paper sx={props.sx}>
            <Stack direction="row" justifyContent="center" spacing={1} padding={2}>
                <Button variant="contained" onClick={() => props.onClickNew()}>New</Button>
                <Button variant="contained" disabled={props.objectiveData === undefined} onClick={() => props.onClickEdit()}>Edit</Button>
            </Stack>
        </Paper>
    );
}

export default OperationObjectiveActionPanel;