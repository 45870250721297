import Cookies from 'universal-cookie';

import config from '../config';
import getCompleteApiUrl from '../getCompleteApiUrl';
import {Organization} from "../../classes/Organization";
import {ApiCallFailure} from "../../classes/exception/ApiCallFailure";

export default async function createOrganization(organization: Organization) {
    const cookies = new Cookies();

    if (cookies.get('accessToken') === undefined) {
        const error = new ApiCallFailure()
        error.connectionSuccessful = false
        error.errorType = "NO_ACCESS_TOKEN"

        throw error
    }

    var res: Response
    try {
        res = await fetch(getCompleteApiUrl(config, `/organizations`), {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${cookies.get('accessToken')}`
            },
            body: JSON.stringify({
                parentId: organization.parentId,
                name: organization.name,
                symbol: organization.symbol
            }),
        })
    } catch (error) {
        const apiFailure = new ApiCallFailure()
        apiFailure.connectionSuccessful = false

        throw apiFailure
    }

    if (res!.status === 201) {
        const responseObject = await res!.json()

        const responseOrganization = new Organization()
        responseOrganization.parseObject(responseObject)

        return responseOrganization
    }

    const callFailure = new ApiCallFailure()
    callFailure.connectionSuccessful = true
    callFailure.responseCode = res!.status
    callFailure.errorType = "REQUEST_REJECTED"

    throw callFailure
}