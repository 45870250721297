import { AssetReadinessProblem } from "./AssetReadinessProblem"
import { AssetReadinessRequirement } from "./AssetReadinessRequirement"

export class AssetReadiness {
    problems: AssetReadinessProblem[] = []
    requirements: AssetReadinessRequirement[] = []
    numberActiveProblems?: number = 0
    numberOutstandingRequirements?: number = 0

    parseObject(input: any) {
        this.problems = input.problems.map((problem: any) => {
            const p = new AssetReadinessProblem()
            p.parseObject(problem)
            return p
        })

        this.requirements = input.requirements.map((requirement: any) => {
            const r = new AssetReadinessRequirement()
            r.parseObject(requirement)
            return r
        })

        this.numberActiveProblems = input.numberActiveProblems
        this.numberOutstandingRequirements = input.numberOutstandingRequirements
    }

    objectify(): object {
        return {
            problems: this.problems.map((problem: AssetReadinessProblem) => problem.objectify()),
            requirements: this.requirements.map((requirement: AssetReadinessRequirement) => requirement.objectify()),
            numberActiveProblems: this.numberActiveProblems,
            numberOutstandingRequirements: this.numberOutstandingRequirements
        }
    }
}