import React, { useState } from "react"
import {
    Stack,
    Typography,
    FormControl,
    Button,
    TextField, Accordion, AccordionSummary, AccordionDetails, ListItemButton, Checkbox, FormControlLabel
} from "@mui/material"
import {OperatingAreaPoint} from "../../../classes/OperatingAreaPoint";
import {Point} from "../../../classes/Point"
import {Waypoint} from "../../../classes/Waypoint";
import {OperatingArea} from "../../../classes/OperatingArea";
import {FlightPath} from "../../../classes/FlightPath";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import {ExpandMore} from "@mui/icons-material";
import Paper from "@mui/material/Paper";
import {Mission} from "../../../classes/Mission";

export default function UploadKMLFileLayout(props: {
    title: string,
    missionData: Mission,
    onCancel: Function,
    onSubmit: Function
}) {

    const [isPendingSubmit, setPendingSubmit] = useState<boolean>(false)
    const [inputPoints, setInputPoints] = useState<Point[] | undefined>(undefined)
    const [inputFlightPaths, setInputFlightPaths] = useState<FlightPath[] | undefined>(undefined)
    const [inputOperatingAreas, setInputOperatingAreas] = useState<OperatingArea[] | undefined>(undefined)

    const [editingFlightPathIndex, setEditingFlightPathIndex] = useState<number | undefined>(undefined)

    const [editingOperatingAreaIndex, setEditingOperatingAreaIndex] = useState<number | undefined>(undefined)
    const [inputAltitudeFloor, setInputAltitudeFloor] = useState<string>("")
    const [inputAltitudeCeiling, setInputAltitudeCeiling] = useState<string>("")

    const altitudeFloor = Number.parseFloat(inputAltitudeFloor)
    const altitudeCeiling = Number.parseFloat(inputAltitudeCeiling)
    const isAltitudeValid = (!Number.isNaN(altitudeFloor) && !Number.isNaN(altitudeCeiling) && altitudeCeiling >= altitudeFloor)

    function checkInput(): boolean {
        if (inputPoints === undefined && inputFlightPaths === undefined && inputOperatingAreas === undefined) {
            return false
        }

        if (inputOperatingAreas !== undefined) {
            for (const oa of inputOperatingAreas!) {
                if (oa.altitudeFloor >= oa.altitudeCeil) {
                    return false
                }
            }
        }

        return true
    }

    function processKMLFile(inputFile: File) {
        setInputFlightPaths(undefined)
        setInputOperatingAreas(undefined)
        setInputPoints(undefined)

        const fileReader = new FileReader()

        fileReader.addEventListener("load", () => {
            const parser = new DOMParser()
            const xmlDoc = parser.parseFromString(fileReader.result as string, "text/xml")

            const filePaths: FlightPath[] = []
            const fileAreas: OperatingArea[] = []
            const filePoints: Point[] = []

            const xmlDocuments = xmlDoc.getElementsByTagName("Document")
            for (let i = 0; i < xmlDocuments[0].children.length; i++) {
                if (xmlDocuments[0].children[i].tagName === "name") {
                    console.log(xmlDocuments[0].children[i].textContent)
                } else if (xmlDocuments[0].children[i].tagName === "Placemark") {
                    const tmpOperatingArea = new OperatingArea()
                    const tmpFlightPath = new FlightPath()
                    const tmpPoint = new Point()

                    tmpOperatingArea.missionId = props.missionData.id!
                    tmpFlightPath.missionId = props.missionData.id!
                    tmpPoint.missionId = props.missionData.id!

                    for (let f = 0; f < xmlDocuments[0].children[i].children.length; f++) {
                        if (xmlDocuments[0].children[i].children[f].tagName === "name") {
                            tmpOperatingArea.label = xmlDocuments[0].children[i].children[f].textContent!
                            tmpFlightPath.label = xmlDocuments[0].children[i].children[f].textContent!
                            tmpPoint.label = xmlDocuments[0].children[i].children[f].textContent!
                        } else if (xmlDocuments[0].children[i].children[f].tagName === "Polygon") {
                            const xmlCoords = xmlDocuments[0].children[i].children[f].getElementsByTagName("coordinates")
                            const coords = xmlCoords[0].textContent!.trim().split(" ")
                            for (let s = 0; s < coords.length; s++) {
                                const operatingAreaPoint = new OperatingAreaPoint()
                                const pointCoords = coords[s].split(",")
                                operatingAreaPoint.latitude = Number.parseFloat(pointCoords[1])
                                operatingAreaPoint.longitude = Number.parseFloat(pointCoords[0])
                                operatingAreaPoint.sequence = s

                                tmpOperatingArea.points.push(operatingAreaPoint)
                            }
                        } else if (xmlDocuments[0].children[i].children[f].tagName === "LineString") {
                            const xmlCoords = xmlDocuments[0].children[i].children[f].getElementsByTagName("coordinates")
                            const coords = xmlCoords[0].textContent!.trim().split(" ")
                            for (let s = 0; s < coords.length; s++) {
                                const waypoint = new Waypoint()
                                const pointCoords = coords[s].split(",")
                                waypoint.latitude = Number.parseFloat(pointCoords[1])
                                waypoint.longitude = Number.parseFloat(pointCoords[0])
                                waypoint.altitude = Number.parseFloat(pointCoords[2])
                                waypoint.isAltitudeAGL = true
                                waypoint.sequence = s

                                tmpFlightPath.waypoints.push(waypoint)
                            }
                        } else if (xmlDocuments[0].children[i].children[f].tagName === "Point") {
                            const xmlCoords = xmlDocuments[0].children[i].children[f].getElementsByTagName("coordinates")
                            const pointCoords = xmlCoords[0].textContent!.trim().split(",")

                            tmpPoint.latitude = Number.parseFloat(pointCoords[1])
                            tmpPoint.longitude = Number.parseFloat(pointCoords[0])
                            tmpPoint.altitude = Number.parseFloat(pointCoords[2])
                            tmpPoint.isAltitudeAGL = true
                        }
                    }

                    if (tmpOperatingArea.points.length > 0) {
                        fileAreas.push(tmpOperatingArea)
                    } else if (tmpFlightPath.waypoints.length > 0) {
                        filePaths.push(tmpFlightPath)
                    } else {
                        filePoints.push(tmpPoint)
                    }
                }
            }

            if (filePaths.length > 0) {
                setInputFlightPaths(filePaths)
            } else {
                setInputFlightPaths(undefined)
            }

            if (fileAreas.length > 0) {
                setInputOperatingAreas(fileAreas)
            } else {
                setInputOperatingAreas(undefined)
            }

            if (filePoints.length > 0) {
                setInputPoints(filePoints)
            } else {
                setInputPoints(undefined)
            }
        }, false)

        fileReader.readAsText(inputFile)
    }

    function handleSubmit() {
        setPendingSubmit(true)
        props.onSubmit(inputOperatingAreas, inputFlightPaths, inputPoints)
    }

    const bottomControls = isPendingSubmit === true ? (<Typography>Loading...</Typography>) : [
        (<Button variant='text' onClick={() => props.onCancel()}>Cancel</Button>),
        (<Button
            type="submit"
            variant='contained'
            disabled={!checkInput() || editingOperatingAreaIndex !== undefined || editingFlightPathIndex !== undefined}
            onClick={handleSubmit}
        >
            Submit
        </Button>)
    ]

    const pts = inputPoints?.map((pt: Point, index: number) => {
        return (
            <Paper variant="outlined">
                <Stack direction="column" spacing={1} padding={1}>
                    <Typography>{pt.label}</Typography>
                    <Stack direction="row" justifyContent="space-evenly">
                        <FormControlLabel
                            label="Takeoff Point"
                            disabled={isPendingSubmit}
                            control={
                                <Checkbox
                                    checked={pt.isTakeoffPoint}
                                    onChange={(event: any) => {
                                        const points = inputPoints!.map((point: Point, index2: number) => {
                                            if (index2 === index) {
                                                point.isTakeoffPoint = event.target.checked
                                            }

                                            return point
                                        })

                                        setInputPoints(points)
                                    }}
                                />
                            }
                        />
                        <FormControlLabel
                            label="Landing Point"
                            disabled={isPendingSubmit}
                            control={
                                <Checkbox
                                    checked={pt.isLandingPoint}
                                    onChange={(event: any) => {
                                        const points = inputPoints!.map((point: Point, index2: number) => {
                                            if (index2 === index) {
                                                point.isLandingPoint = event.target.checked
                                            }

                                            return point
                                        })

                                        setInputPoints(points)
                                    }}
                                />
                            }
                        />
                    </Stack>
                    <Stack direction="column" spacing={0}>
                        <Stack direction="row" spacing={1} justifyContent="space-between">
                            <Typography>Latitude:</Typography>
                            <Typography>{pt.latitude}</Typography>
                        </Stack>
                        <Stack direction="row" spacing={1} justifyContent="space-between">
                            <Typography>Longitude:</Typography>
                            <Typography>{pt.longitude}</Typography>
                        </Stack>
                        <Stack direction="row" spacing={1} justifyContent="space-between">
                            <Typography>Altitude:</Typography>
                            <Typography>{pt.altitude}</Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </Paper>
        )
    })

    const editOA = (
        <Stack direction="row" spacing={1}>
            <TextField
                variant="standard"
                label="Floor (ft)"
                value={inputAltitudeFloor}
                onChange={(event: any) => setInputAltitudeFloor(event.target.value)}
            />
            <TextField
                variant="standard"
                label="Ceiling (ft)"
                value={inputAltitudeCeiling}
                onChange={(event: any) => setInputAltitudeCeiling(event.target.value)}
            />
            <Button
                variant="contained"
                disabled={!isAltitudeValid}
                onClick={() => {
                    inputOperatingAreas![editingOperatingAreaIndex!].altitudeCeil = Number.parseFloat(inputAltitudeCeiling)
                    inputOperatingAreas![editingOperatingAreaIndex!].altitudeFloor = Number.parseFloat(inputAltitudeFloor)
                    inputOperatingAreas![editingOperatingAreaIndex!].isAGL = true
                    setInputOperatingAreas(inputOperatingAreas!)
                    setEditingOperatingAreaIndex(undefined)
                    setInputAltitudeFloor("")
                    setInputAltitudeCeiling("")
                }}
            >
                Apply
            </Button>
        </Stack>
    )

    const oas = inputOperatingAreas?.map((oa: OperatingArea, index: number) => {
        const pts = oa.points.map((p: OperatingAreaPoint) => (
            <Stack direction="row" spacing={1} justifyContent="space-between">
                <Typography>{p.sequence}</Typography>
                <Typography>{p.latitude}</Typography>
                <Typography>{p.longitude}</Typography>
            </Stack>
        ))

        return (
            <Paper variant="outlined">
                <Stack direction="column" spacing={1} padding={1}>
                    <Typography>{oa.label}</Typography>
                    {editingOperatingAreaIndex === index ? editOA : (
                        <Stack direction="row" spacing={1} justifyContent="space-between">
                            <Stack direction="row" spacing={2}>
                                <Typography>{`Floor: ${oa.altitudeFloor} ft`}</Typography>
                                <Typography>{`Ceiling: ${oa.altitudeCeil} ft`}</Typography>
                            </Stack>
                            <Button
                                variant="text"
                                onClick={() => {
                                    setEditingOperatingAreaIndex(index)
                                }}
                            >
                                Edit
                            </Button>
                        </Stack>
                    )}
                    <Accordion sx={{ width: "100%" }}>
                        <AccordionSummary expandIcon={<ExpandMore />}><Typography>Points</Typography></AccordionSummary>
                        <AccordionDetails>{pts}</AccordionDetails>
                    </Accordion>
                </Stack>
            </Paper>
        );
    })

    const fltpths = inputFlightPaths?.map((fp: FlightPath, index: number) => {
        const pts = fp.waypoints.map((p: Waypoint) => (
            <Stack direction="row" spacing={1} justifyContent="space-between">
                <Typography>{p.sequence}</Typography>
                <Typography>{p.latitude}</Typography>
                <Typography>{p.longitude}</Typography>
                <Typography>{p.altitude}</Typography>
            </Stack>
        ))

        return (
            <Paper variant="outlined">
                <Stack direction="column" spacing={1} padding={1}>
                    <Typography>{fp.label}</Typography>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore />}><Typography>Waypoints</Typography></AccordionSummary>
                        <AccordionDetails>{pts}</AccordionDetails>
                    </Accordion>
                </Stack>
            </Paper>
        )
    });

    return (
        <Stack direction="column" spacing={1}>
            <Typography variant="h6">{props.title}</Typography>
            <Stack direction="row" spacing={1} justifyContent="space-evenly">
                <Stack direction="column" spacing={1} width="33%">
                    <Typography>Points</Typography>
                    <Stack direction="column" spacing={1} overflow="scroll" maxHeight="400px">
                        {pts}
                    </Stack>
                </Stack>
                <Stack direction="column" spacing={1} width="33%">
                    <Typography>Flight Paths</Typography>
                    <Stack direction="column" spacing={1} overflow="scroll" maxHeight="400px">
                        {fltpths}
                    </Stack>
                </Stack>
                <Stack direction="column" spacing={1} width="33%">
                    <Typography>Operating Areas</Typography>
                    <Stack direction="column" spacing={1} overflow="scroll" maxHeight="400px">
                        {oas}
                    </Stack>
                </Stack>
            </Stack>
            <TextField
                type="file"
                disabled={isPendingSubmit}
                onChange={(event: any) => processKMLFile(event.target.files[0])}
            />
            <Stack direction="row" spacing={1} justifyContent="right">{bottomControls}</Stack>
        </Stack>
    )
}