import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import Cookies from "universal-cookie"
import getBanner from "../../../data/sysinfo/getBanner"
import logout from "../../../data/auth/logout"

import { SysInfoBannerParams } from "../../../classes/SysInfoBannerParams"
import LoadingPanelPageLayout from "../../../common/layouts/LoadingPanelPageLayout"
import MessageBoxPageLayout from "../../../common/layouts/MessageBoxPageLayout"

export default function Logout() {
    const navigate = useNavigate()
    const cookies = new Cookies()

    const [bannerData, setBannerData] = useState<SysInfoBannerParams | undefined>(undefined)
    const [logoutFailed, setLogoutFailed] = useState<boolean>(false)

    useEffect(() => {
        if (bannerData === undefined) {
            getBanner().then((d) => setBannerData(d))
        }

        if (!logoutFailed) {
            logout()
                .then(() => {
                    cookies.remove("accessToken", { path: "/" })
                    navigate("/")
                })
                .catch(() => {
                    cookies.remove("accessToken", { path: "/" })
                    setLogoutFailed(true)
                })
        }
    }, [])

    if (bannerData === undefined) {
        return null
    }

    if (logoutFailed) {
        return (
            <MessageBoxPageLayout
                sysInfoBanner={bannerData}
                title="Logout Failed"
                message="No user is currently logged in.  You need to be logged in to log out.  Please log in to log out."
                onOk={() => navigate("/")}
            />
        )
    } else {
        return (<LoadingPanelPageLayout sysInfoBanner={bannerData} />) // TODO: Make this LoadingPanelPageLayout standard everywhere.
    }
}