import React from 'react'
import { Stack } from '@mui/material'

import OperatorAssignedMissionFlatList from '../components/OperatorAssignedMissionFlatList';
import {Operator} from "../../../classes/Operator";

function OperatorMissionsLayout(props: { operatorData: Operator }) {
    
    return (
        <Stack direction="column" padding={2} spacing={2}>
            <OperatorAssignedMissionFlatList sx={{ width: '100%' }} operatorData={props.operatorData} />
        </Stack>
    );
}

export default OperatorMissionsLayout