import React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import { DataGrid } from '@mui/x-data-grid';
import {Asset} from "../../../classes/Asset";

function AssetFlatList(props: {
    data: Asset[],
    selectedAssetId?: number,
    onAssetSelectionChanged: Function,
    sx?: object
}) {

    const columns = [
        { field: 'callsign', headerName: 'Callsign', width: 200 },
        { field: 'manufacturer', headerName: 'Manufacturer', width: 160, valueGetter: (params: any) => { return params.row.productObject.manufacturerObject.name }},
        { field: 'product', headerName: 'Product', width: 160, valueGetter: (params: any) => { return params.row.productObject.name }},
        { field: 'office', headerName: 'Office', width: 250, valueGetter: (params: any) => { return params.row.officeObject.name }},
        { field: 'serialNumber', headerName: 'Serial Number', width: 400 },
        { field: 'serialNumberRID', headerName: 'Remote ID', width: 400 }
    ];

    return (
        <Paper sx={props.sx}>
            <Stack direction="column" spacing={2} padding={2}>
                <Box sx={{ height: 600 }}>
                    <DataGrid
                        rows={props.data.map((asset: Asset) => asset.objectify())}
                        columns={columns}
                        rowSelectionModel={props.selectedAssetId === undefined ? [] : props.selectedAssetId!}
                        onRowSelectionModelChange={(e) => props.onAssetSelectionChanged(e)}
                    />
                </Box>
            </Stack>
        </Paper>
    );
}

export default AssetFlatList;