import dayjs, { Dayjs } from "dayjs"
import { Office } from "./Office"
import { Organization } from "./Organization"
import { Operation } from "./Operation"
import { Deconfliction } from "./Deconfliction"
import { MissionObjective } from "./MissionObjective"
import { MissionNote } from "./MissionNote"
import { FlightPath } from "./FlightPath"
import { OperatingArea } from "./OperatingArea"
import { Point } from "./Point"
import { MissionFlag } from "./MissionFlag"
import { MissionAssetAssignment } from "./MissionAssetAssignment"
import { MissionOperatorAssignment } from "./MissionOperatorAssignment"
import { MissionUserPermissions } from "./MissionUserPermissions"

export class Mission {
    id?: number
    operationId: number = 0
    name: string = ""
    purpose: string = ""
    missionNumber?: string
    callsign?: string
    timeStartScheduled: Dayjs = dayjs()
    timeStartProjected: Dayjs = dayjs()
    timeStartActual?: Dayjs
    timeFinishScheduled: Dayjs = dayjs()
    timeFinishProjected: Dayjs = dayjs()
    timeFinishActual?: Dayjs
    originatingOfficeId: number = 0
    assignedOrganizationId?: number
    isDraft: boolean = false
    isComplete: boolean = false
    isCanceled: boolean = false
    isInProgress: boolean = false

    operationObject?: Operation
    originatingOfficeObject?: Office
    assignedOrganizationObject?: Organization
    operatorAssignments?: MissionOperatorAssignment[]
    assetAssignments?: MissionAssetAssignment[]
    deconflictions?: Deconfliction[]
    objectives?: MissionObjective[]
    notes?: MissionNote[]
    operatingAreas?: OperatingArea[]
    flightPaths?: FlightPath[]
    points?: Point[]
    flags?: MissionFlag[]
    userPermissions?: MissionUserPermissions

    parseObject(input: any) {
        this.id = input.id
        this.operationId = input.operationId
        this.name = input.name
        this.purpose = input.purpose
        this.missionNumber = input.missionNumber
        this.callsign = input.callsign
        this.timeStartScheduled = dayjs(input.timeStartScheduled)
        this.timeStartProjected = dayjs(input.timeStartProjected)
        this.timeStartActual = input.timeStartActual === undefined ? undefined : dayjs(input.timeStartActual)
        this.timeFinishScheduled = dayjs(input.timeFinishScheduled)
        this.timeFinishProjected = dayjs(input.timeFinishProjected)
        this.timeFinishActual = input.timeFinishActual === undefined ? undefined : dayjs(input.timeFinishActual)
        this.originatingOfficeId = input.originatingOfficeId
        this.assignedOrganizationId = input.assignedOrganizationId
        this.isDraft = input.isDraft
        this.isComplete = input.isComplete
        this.isCanceled = input.isCanceled
        this.isInProgress = input.isInProgress

        if (input.operationObject !== undefined) {
            const o = new Operation()
            o.parseObject(input.operationObject)
            this.operationObject = o
        }

        if (input.originatingOfficeObject !== undefined) {
            const o = new Office()
            o.parseObject(input.originatingOfficeObject)
            this.originatingOfficeObject = o
        }

        if (input.assignedOrganizationObject !== undefined) {
            const o = new Organization()
            o.parseObject(input.assignedOrganizationObject)
            this.assignedOrganizationObject = o
        }

        if (input.operatorAssignments !== undefined) {
            this.operatorAssignments = input.operatorAssignments.map((d: any) => {
                const assignment = new MissionOperatorAssignment()
                assignment.parseObject(d)
                return assignment
            })
        }

        if (input.assetAssignments !== undefined) {
            this.assetAssignments = input.assetAssignments.map((d: any) => {
                const assignment = new MissionAssetAssignment()
                assignment.parseObject(d)
                return assignment
            })
        }

        if (input.deconflictions !== undefined) {
            this.deconflictions = input.deconflictions.map((d: any) => {
                const deconfliction = new Deconfliction()
                deconfliction.parseObject(d)
                return deconfliction
            })
        }

        if (input.objectives !== undefined) {
            this.objectives = input.objectives.map((d: any) => {
                const objective = new MissionObjective()
                objective.parseObject(d)
                return objective
            })
        }

        if (input.notes !== undefined) {
            this.notes = input.notes.map((n: any) => {
                const note = new MissionNote()
                note.parseObject(n)
                return note
            })
        }

        if (input.operatingAreas !== undefined) {
            this.operatingAreas = input.operatingAreas.map((a: any) => {
                const area = new OperatingArea()
                area.parseObject(a)
                return area
            })
        }

        if (input.flightPaths !== undefined) {
            this.flightPaths = input.flightPaths.map((p: any) => {
                const path = new FlightPath()
                path.parseObject(p)
                return path
            })
        }

        if (input.points !== undefined) {
            this.points = input.points.map((p: any) => {
                const point = new Point()
                point.parseObject(p)
                return point
            })
        }

        if (input.flags !== undefined) {
            this.flags = input.flags.map((f: any) => {
                const flag = new MissionFlag()
                flag.parseObject(f)
                return flag
            })
        }

        if (input.userPermissions !== undefined) {
            const p = new MissionUserPermissions()
            p.parseObject(input.userPermissions)
            this.userPermissions = p
        }
    }

    objectify(): object {
        return {
            id: this.id,
            operationId: this.operationId,
            name: this.name,
            purpose: this.purpose,
            missionNumber: this.missionNumber,
            callsign: this.callsign,
            timeStartScheduled: this.timeStartScheduled.format(),
            timeStartProjected: this.timeStartProjected.format(),
            timeStartActual: this.timeStartActual?.format(),
            timeFinishScheduled: this.timeFinishScheduled.format(),
            timeFinishProjected: this.timeFinishProjected.format(),
            timeFinishActual: this.timeFinishActual?.format(),
            originatingOfficeId: this.originatingOfficeId,
            assignedOrganizationId: this.assignedOrganizationId,
            isDraft: this.isDraft,
            isComplete: this.isComplete,
            isCanceled: this.isCanceled,
            isInProgress: this.isInProgress,
            operationObject: this.operationObject?.objectify(),
            originatingOfficeObject: this.originatingOfficeObject?.objectify(),
            assignedOrganizationObject: this.assignedOrganizationObject?.objectify(),
            operatorAssignments: this.operatorAssignments?.map((a: MissionOperatorAssignment) => a.objectify()),
            assetAssignments: this.assetAssignments?.map((a: MissionAssetAssignment) => a.objectify()),
            deconflictions: this.deconflictions?.map((d: Deconfliction) => d.objectify()),
            objectives: this.objectives?.map((o: MissionObjective) => o.objectify()),
            notes: this.notes?.map((n: MissionNote) => n.objectify()),
            operatingAreas: this.operatingAreas?.map((a: OperatingArea) => a.objectify()),
            flightPaths: this.flightPaths?.map((p: FlightPath) => p.objectify()),
            points: this.points?.map((o: Point) => o.objectify()),
            flags: this.flags?.map((f: MissionFlag) => f.objectify()),
            userPermissions: this.userPermissions?.objectify()
        }
    }
}