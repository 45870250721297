import React, { useLayoutEffect } from 'react';
import { useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import Joi from 'joi';
import {OperatingAreaPoint} from "../../../classes/OperatingAreaPoint";
import {OperatingArea} from "../../../classes/OperatingArea";
import Box from "@mui/material/Box";

function EditMissionOperatingAreaLayout(props: {
    title: string,
    initData: OperatingArea,
    onSubmit: Function,
    onCancel: Function
}) {
    const [isPendingSubmit, setPendingSubmit] = useState<boolean>(false);
    const [inputLabel, setInputLabel] = useState<string>(props.initData.label)
    const [inputAltFloor, setInputAltFloor] = useState<string>(props.initData.altitudeFloor.toString())
    const [inputAltCeil, setInputAltCeil] = useState<string>(props.initData.altitudeCeil.toString())
    const [inputAltIsAGL, setInputAltIsAGL] = useState<number>(props.initData.isAGL ? 1 : 0)
    const [inputPoints, setInputPoints] = useState<OperatingAreaPoint[]>(props.initData.points)

    const [editingPointIndex, setEditingPointIndex] = useState<number | undefined>(undefined)
    const [inputLat, setInputLat] = useState<string>("");
    const [inputLon, setInputLon] = useState<string>("");

    const isLabelValid = Joi.string().min(0).max(100).required().validate(inputLabel)
    const isAltFloorValid = Joi.string().min(1).required().validate(inputAltFloor);
    const isAltCeilValid = Joi.string().min(1).required().validate(inputAltCeil);
    const isPointsValid = Joi.array().min(3).required().validate(inputPoints);
    const isValid = (!isLabelValid.error && !isAltFloorValid.error && !isAltCeilValid.error && !isPointsValid.error);

    const isLatValid = Joi.string().min(1).required().validate(inputLat);
    const isLonValid = Joi.string().min(1).required().validate(inputLon);
    const isLatLonValid = (!isLatValid.error && !isLonValid.error);

    useLayoutEffect(() => {
        if (editingPointIndex === undefined) {
            setInputLat("")
            setInputLon("")
        } else {
            setInputLat(inputPoints[editingPointIndex].latitude.toString())
            setInputLon(inputPoints[editingPointIndex].longitude.toString())
        }
    }, [editingPointIndex])

    function handleSubmit() {
        setPendingSubmit(true)

        const inputArea = props.initData
        inputArea.label = inputLabel
        inputArea.altitudeFloor = Number.parseFloat(inputAltFloor)
        inputArea.altitudeCeil = Number.parseFloat(inputAltCeil)
        inputArea.isAGL = (inputAltIsAGL === 1)
        inputArea.points = inputPoints

        props.onSubmit(inputArea)
    }

    function addPoint() {
        const inputPoint = new OperatingAreaPoint()
        inputPoint.latitude = Number.parseFloat(inputLat)
        inputPoint.longitude = Number.parseFloat(inputLon)

        if (editingPointIndex === undefined) {
            inputPoint.sequence = inputPoints.length
            inputPoints.push(inputPoint)
        } else {
            inputPoint.sequence = inputPoints[editingPointIndex].sequence
            inputPoints[editingPointIndex] = inputPoint
        }

        setInputPoints(inputPoints)
        setEditingPointIndex(undefined)
        setInputLat("")
        setInputLon("")
    }

    function deletePoint(index: number) {
        const newPoints = inputPoints.filter((value, pIndex) =>
            index !== pIndex)

        newPoints.forEach((value, pIndex) => {
            value.sequence = pIndex
        })

        setInputPoints(newPoints)
    }

    const bottomControls = isPendingSubmit === true ? (<Typography>Loading...</Typography>) : [(<Button variant='text' onClick={() => props.onCancel()}>Cancel</Button>), (<Button variant='contained' disabled={!isValid} onClick={handleSubmit}>Submit</Button>)];

    return (
        <Stack direction="column" spacing={1}>
            <Typography variant="h6">{props.title}</Typography>
            <TextField
                fullWidth
                type="text"
                variant="outlined"
                label="Label"
                disabled={isPendingSubmit}
                value={inputLabel}
                onChange={(e) => setInputLabel(e.target.value)}
            />
            <Stack direction="row" spacing={1}>
                <TextField
                    fullWidth
                    type="text"
                    variant="outlined"
                    label="Altitude Floor"
                    disabled={isPendingSubmit}
                    value={inputAltFloor}
                    onChange={(e: any) => setInputAltFloor(e.target.value)}
                />
                <TextField
                    fullWidth
                    type="text"
                    variant="outlined"
                    label="Altitude Ceiling"
                    disabled={isPendingSubmit}
                    value={inputAltCeil}
                    onChange={(e: any) => setInputAltCeil(e.target.value)}
                />
                <FormControl fullWidth disabled={isPendingSubmit}>
                    <InputLabel id="agl-msl">Measurement</InputLabel>
                    <Select
                        labelId="agl-msl"
                        label="Measurement"
                        disabled={isPendingSubmit}
                        value={inputAltIsAGL}
                        onChange={(event: any) => setInputAltIsAGL(event.target.value)}
                    >
                        <MenuItem value={0}>MSL</MenuItem>
                        <MenuItem value={1}>AGL</MenuItem>
                    </Select>
                </FormControl>
            </Stack>
            <Box width="100%">
                <List>
                    {inputPoints.map((p, index) => (
                        <ListItem>
                            <Stack direction="row" justifyContent="space-between" width="100%">
                                <Typography>{`${p.sequence}: (${p.latitude}, ${p.longitude})`}</Typography>
                                <Stack direction="row" spacing={1}>
                                    <Button
                                        variant="contained"
                                        onClick={() => setEditingPointIndex(index)}
                                        disabled={index === editingPointIndex}
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        variant="contained"
                                        onClick={() => deletePoint(index)}
                                    >
                                        Delete
                                    </Button>
                                </Stack>
                            </Stack>
                        </ListItem>
                    ))}
                </List>
            </Box>
            <Stack direction="row" spacing={1}>
                <TextField
                    fullWidth
                    type="text"
                    variant="outlined"
                    label="Latitude"
                    disabled={isPendingSubmit}
                    value={inputLat}
                    onChange={(e: any) => setInputLat(e.target.value)}
                />
                <TextField
                    fullWidth
                    type="text"
                    variant="outlined"
                    label="Longitude"
                    disabled={isPendingSubmit}
                    value={inputLon}
                    onChange={(e: any) => setInputLon(e.target.value)}
                />
                <Button
                    variant="contained"
                    disabled={!isLatLonValid || isPendingSubmit}
                    onClick={addPoint}
                >
                    {editingPointIndex === undefined ? "Add" : "Set"}
                </Button>
            </Stack>
            <Stack direction="row" spacing={1} justifyContent="right">{bottomControls}</Stack>
        </Stack>
    );
}

export default EditMissionOperatingAreaLayout;