import React from 'react';
import { Paper, Stack, Button } from '@mui/material';
import {Mission} from "../../../classes/Mission";
import {Deconfliction} from "../../../classes/Deconfliction";

function MissionDeconflictionActionPanel(props: {
    missionData: Mission,
    deconflictionData?: Deconfliction,
    onClickNew: Function,
    onClickDelete: Function,
    onClickEdit: Function,
    onClickAnswer: Function,
    sx?: object
}) {

    return (
        <Paper sx={props.sx}>
            <Stack direction="row" justifyContent="center" spacing={1} padding={2}>
                {props.missionData.userPermissions!.modify && props.missionData.isDraft ?
                    (<Button variant="contained" onClick={() => props.onClickNew()}>New</Button>) : null}
                {props.missionData.userPermissions!.modify && props.missionData.isDraft ?
                    (<Button variant="contained" disabled={props.deconflictionData === undefined} onClick={() => props.onClickDelete()}>Delete</Button>) : null}
                {props.missionData.userPermissions!.modify && props.missionData.isDraft ?
                    (<Button variant="contained" disabled={props.deconflictionData === undefined} onClick={() => props.onClickEdit()}>Edit</Button>) : null}
                <Button
                    variant="contained"
                    disabled={props.missionData.isDraft || props.deconflictionData === undefined || props.deconflictionData?.userPermissions?.answer === false}
                    onClick={() => props.onClickAnswer()}
                >
                    Answer
                </Button>
            </Stack>
        </Paper>
    );
}

export default MissionDeconflictionActionPanel;