import React from 'react';
import { useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers';

import Joi from 'joi';
import dayjs, {Dayjs} from 'dayjs';
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import {Operation} from "../../../classes/Operation";
import {Organization} from "../../../classes/Organization";

function EditOperationLayout(props: {
    initInfo: Operation,
    title: string,
    onCancel: Function,
    onSubmit: Function,
    organizationList?: Organization[]
}) {

    const [isPendingSubmit, setPendingSubmit] = useState<boolean>(false);
    const [inputOrganizationId, setInputOrganizationId] = useState<number>(props.initInfo.organizationId);
    const [inputName, setInputName] = useState<string>(props.initInfo.name);
    const [inputPurpose, setInputPurpose] = useState<string>(props.initInfo.purpose);
    const [inputTimeStart, setInputTimeStart] = useState<Dayjs>(props.initInfo.timeStart);
    const [inputTimeEnd, setInputTimeEnd] = useState<Dayjs>(props.initInfo.timeEnd);

    const timeStart = dayjs(inputTimeStart)
    const timeEnd = dayjs(inputTimeEnd)

    const isOrganizationIdValid = Joi.number().integer().required().validate(inputOrganizationId)
    const isNameValid = Joi.string().min(1).max(100).required().validate(inputName)
    const isPurposeValid = Joi.string().min(1).max(2000).required().validate(inputPurpose)
    const isTimeStartValid = Joi.date().required().validate(timeStart.format())
    const isTimeEndValid = Joi.date().required().validate(timeEnd.format())
    const isValid = (!isOrganizationIdValid.error && !isNameValid.error && !isPurposeValid.error && !isTimeStartValid.error && !isTimeEndValid.error)

    function handleSubmit() {
        setPendingSubmit(true)

        const inputOperation = props.initInfo
        inputOperation.organizationId = inputOrganizationId
        inputOperation.name = inputName
        inputOperation.purpose = inputPurpose
        inputOperation.timeStart = timeStart
        inputOperation.timeEnd = timeEnd

        props.onSubmit(inputOperation)
    }

    const bottomControls = isPendingSubmit === true ? (<Typography>Loading...</Typography>) : [(<Button variant='text' onClick={() => props.onCancel()}>Cancel</Button>), (<Button variant='contained' disabled={!isValid} onClick={handleSubmit}>Submit</Button>)];

    if (props.organizationList === undefined) {
        return (<Typography variant="h6">Loading...</Typography>)
    }

    return (
        <Stack direction="column" spacing={1}>
            <Typography variant="h6">{props.title}</Typography>
            <FormControl fullWidth disabled={isPendingSubmit}>
                <InputLabel id="operation-select">Select Organization</InputLabel>
                <Select
                    labelId="operation-select"
                    label="Select Organization"
                    value={inputOrganizationId}
                    onChange={(event) => setInputOrganizationId(event.target.value as number)}
                >
                    {props.organizationList.map((a) => (
                        <MenuItem value={a.id}>{`${a.name} (${a.symbol})`}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <TextField
                type="text"
                variant="outlined"
                label="Operation Name"
                error={inputName.length > 0 && isNameValid.error !== undefined}
                disabled={isPendingSubmit}
                value={inputName}
                onChange={(e) => setInputName(e.target.value)}
            />
            <Stack direction="row" spacing={1} justifyContent="space-evenly">
                <DatePicker
                    sx={{ width: "50%" }}
                    label="Start Time"
                    slotProps={{
                        textField: {
                            error: isTimeStartValid.error !== undefined
                        },
                    }}
                    disabled={isPendingSubmit}
                    value={timeStart}
                    onChange={(event: any) => setInputTimeStart(event)}
                />
                <DatePicker
                    sx={{ width: "50%" }}
                    label="End Time"
                    slotProps={{
                        textField: {
                        error: isTimeEndValid.error !== undefined
                        },
                    }}
                    disabled={isPendingSubmit}
                    value={timeEnd}
                    onChange={(event: any) => setInputTimeEnd(event)}
                />
            </Stack>
            <TextField
                type="text"
                variant="outlined"
                label="Purpose"
                multiline
                rows={4}
                error={inputPurpose.length > 0 && isPurposeValid.error !== undefined}
                disabled={isPendingSubmit}
                value={inputPurpose}
                onChange={(e) => setInputPurpose(e.target.value)}
            />
            <Stack direction="row" spacing={1} justifyContent="right">{bottomControls}</Stack>
        </Stack>
    );
}

export default EditOperationLayout;