import React, {useEffect, useState} from 'react';
import { Modal, Paper } from '@mui/material';
import EditOperationLayout from '../layouts/EditOperationLayout';
import getMyOrganizations from "../../../data/organization/getMyOrganizations";
import {Organization} from "../../../classes/Organization";
import {Operation} from "../../../classes/Operation";

function NewOperationModal(props: {
    isOpen: boolean,
    onClose: Function,
    onSubmit: Function,
    onNetworkError: Function
}) {
    const [organizationList, setOrganizationList] = useState<Organization[] | undefined>(undefined)

    useEffect(() => {
        if (props.isOpen) {
            getMyOrganizations()
                .then((result) => setOrganizationList(result))
                .catch((err) => {
                    console.error(err)
                    props.onNetworkError()
                })
        }
    }, [props.isOpen])

    return (
        <Modal
            open={props.isOpen}
            onClose={() => props.onClose()}
        >
            <Paper
                sx={{
                    position: 'absolute',
                    width: '600px',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    padding: 2
                }}
            >
                <EditOperationLayout
                    title="Create Operation"
                    initInfo={new Operation()}
                    onCancel={() => props.onClose()}
                    onSubmit={props.onSubmit}
                    organizationList={organizationList}
                />
            </Paper>
        </Modal>
    );
}

export default NewOperationModal;