import React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import { DataGrid } from '@mui/x-data-grid';
import {Operator} from "../../../classes/Operator";

function OperatorFlatList(props: {
    data: Operator[],
    selectedOperatorId?: number,
    onOperatorSelectionChanged: Function,
    sx?: object
}) {

    const columns = [
        { field: 'callsign', headerName: 'Callsign', width: 200 },
        { field: 'rank', headerName: 'Ready', width: 120 },
        { field: 'firstName', headerName: 'First Name', width: 300 },
        { field: 'lastName', headerName: 'Last Name', width: 300 },
        { field: 'office', headerName: 'Office', width: 250, valueGetter: (params: any) => { return params.row.officeObject.name }}
    ];

    return (
        <Paper sx={props.sx}>
            <Stack direction="column" spacing={2} padding={2}>
                <Box sx={{ height: 600 }}>
                    <DataGrid
                        rows={props.data.map((operator: Operator) => operator.objectify())}
                        columns={columns}
                        rowSelectionModel={props.selectedOperatorId === undefined ? [] : props.selectedOperatorId!}
                        onRowSelectionModelChange={(e) => props.onOperatorSelectionChanged(e)}
                    />
                </Box>
            </Stack>
        </Paper>
    );
}

export default OperatorFlatList;