import React, {useEffect, useState} from 'react';
import { Stack } from '@mui/material';

import MissionObjectiveActionPanel from '../components/MissionObjectiveActionPanel';
import MissionObjectivePanel from '../components/MissionObjectivePanel';
import addObjectiveToMissionById from "../../../data/mission/addObjectiveToMissionById";
import NewMissionObjectiveModal from "../modals/NewMissionObjectiveModal";
import {MissionObjective} from "../../../classes/MissionObjective";
import {Mission} from "../../../classes/Mission";

function MissionObjectivesTab(props: { missionData: Mission, onRefresh: Function, onNetworkError: Function }) {
    const [isCreateMissionObjectiveModalOpen, setCreateMissionObjectiveModalOpen] = useState(false);

    const [selectedObjectiveId, setSelectedObjectiveId] = useState<number | null>(null);
    const [selectedObjectiveObject, setSelectedObjectiveObject] = useState<MissionObjective | undefined>(undefined);

    useEffect(() => {
        if (selectedObjectiveId === null) {
            setSelectedObjectiveObject(undefined)
        } else if (props.missionData !== undefined) {
            props.missionData.objectives!.map((o: any) => {
                if (o.id == selectedObjectiveId) {
                    setSelectedObjectiveObject(o)
                }
            })
        }
    }, [selectedObjectiveId, props.missionData])

    function handleCreateMissionObjective(inputObjective: MissionObjective) {
        addObjectiveToMissionById(inputObjective)
            .then(() => props.onRefresh())
            .then(() => setCreateMissionObjectiveModalOpen(false))
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    return (
        <Stack direction="column" padding={2} spacing={2}>
            {props.missionData.userPermissions!.modify && props.missionData.isDraft ? (<MissionObjectiveActionPanel
                sx={{ width: "100%" }}
                onClickNew={() => setCreateMissionObjectiveModalOpen(true)}
                // onClickEdit={() => {}} // TODO: Fix this.
                // objectiveData={selectedObjectiveObject} // TODO: Fix this.
            />) : null}
            <MissionObjectivePanel
                sx={{ width: "100%" }}
                missionData={props.missionData}
                objectiveData={selectedObjectiveObject}
                onObjectiveSelectionChanged={(e: any) => setSelectedObjectiveId(e[0])}
            />
            <NewMissionObjectiveModal
                isOpen={isCreateMissionObjectiveModalOpen}
                missionData={props.missionData}
                onClose={() => setCreateMissionObjectiveModalOpen(false)}
                onSubmit={handleCreateMissionObjective}
            />
        </Stack>
    )
}

export default MissionObjectivesTab;