import React from 'react';
import { Paper, Stack, Typography, Divider, TextField } from '@mui/material';

import {Operation} from "../../../classes/Operation";

function OperationTitlePanel(props: { operationData: Operation, sx?: object }) {

    const timeStart = props.operationData.timeStart
    const timeEnd = props.operationData.timeEnd

    return (
        <Paper sx={props.sx}>
            <Stack direction="column" spacing={1} padding={2}>
                <Typography variant='h5'>{props.operationData.name}</Typography>
                <Divider />
                <Stack direction="row" justifyContent="space-evenly" spacing={1}>
                    <TextField InputProps={{ readOnly: true }} label="Organization" value={`${props.operationData.organizationObject?.name} (${props.operationData.organizationObject?.symbol})`} sx={{ width: "33%" }} />
                    <TextField InputProps={{ readOnly: true }} label="Start Date" value={timeStart.format('MM/DD/YYYY')} sx={{ width: "33%" }} />
                    <TextField InputProps={{ readOnly: true }} label="End Date" value={timeEnd.format('MM/DD/YYYY')} sx={{ width: "33%" }} />
                </Stack>
                <TextField InputProps={{ readOnly: true }} label="Purpose" value={props.operationData.purpose} multiline rows={6} />
            </Stack>
        </Paper>
    );
}

export default OperationTitlePanel;