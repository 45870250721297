import Cookies from "universal-cookie"

import config from "../config"
import getCompleteApiUrl from "../getCompleteApiUrl"
import { Asset } from "../../classes/Asset"
import { ApiCallFailure } from "../../classes/exception/ApiCallFailure"
import {AssetEmergencyReservation} from "../../classes/AssetEmergencyReservation"

export default async function updateAssetEmergencyReservationById(reservation: AssetEmergencyReservation) {
    const cookies = new Cookies()

    if (cookies.get("accessToken") === undefined) {
        const error = new ApiCallFailure()
        error.connectionSuccessful = false
        error.errorType = "NO_ACCESS_TOKEN"

        throw error
    }

    var res: Response
    try {
        res = await fetch(getCompleteApiUrl(config, `/assets/emergencies/id/${reservation.id!}`), {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${cookies.get("accessToken")}`
            },
            body: JSON.stringify({
                assetId: reservation.assetId,
                isAvailable: reservation.isAvailable,
                timeBegin: reservation.timeBegin.format(),
                timeEnd: reservation.timeEnd.format(),
            })
        })
    } catch (error) {
        const apiFailure = new ApiCallFailure()
        apiFailure.connectionSuccessful = false

        throw apiFailure
    }

    if (res!.status === 200) {
        const responseObject = await res!.json()

        const responseReservation = new AssetEmergencyReservation()
        responseReservation.parseObject(responseObject)

        return responseReservation
    }

    const callFailure = new ApiCallFailure()
    callFailure.connectionSuccessful = true
    callFailure.responseCode = res!.status
    callFailure.errorType = "REQUEST_REJECTED"

    throw callFailure
}