import React from 'react';
import { Stack } from '@mui/material';

import OperationActionPanel from '../components/OperationActionPanel';
import OperationTitlePanel from '../components/OperationTitlePanel';
import { Operation } from "../../../classes/Operation"

function OperationSummaryLayout(props: {
    operationData: Operation,
    onClickEdit: Function
}) {

    return (
        <Stack direction="row" padding={2} spacing={2}>
            <OperationActionPanel
                sx={{ width: "200px" }}
                onClickEdit={props.onClickEdit}
            />
            <OperationTitlePanel
                sx={{ width: "calc(100% - 200px)" }}
                operationData={props.operationData}
            />
        </Stack>
    );
}

export default OperationSummaryLayout;