import React from 'react';
import { Paper, Stack, Typography, Divider, TextField, Button } from '@mui/material';
import {Mission} from "../../../classes/Mission";

function MissionTitlePanel(props: {
    missionData: Mission,
    onClickEdit: Function,
    onClickAssignMissionNumber: Function,
    onClickAssignCallsign: Function,
    onClickFinalizeDraft: Function,
    onClickRedraft: Function
    sx?: object
}) {

    const buttonBar = (
        <Stack direction="row" spacing={1} justifyContent="center" width="100%">
            <Button
                variant="contained"
                onClick={() => props.onClickFinalizeDraft()}
                disabled={!props.missionData.isDraft}
            >
                Finalize Draft
            </Button>
            <Button
                variant="contained"
                onClick={() => props.onClickRedraft()}
                disabled={props.missionData.isDraft || props.missionData.isCanceled || props.missionData.isComplete || props.missionData.isInProgress}
            >
                Revert to Draft
            </Button>
            <Button
                variant="contained"
                onClick={() => props.onClickAssignMissionNumber()}
                disabled={props.missionData.isCanceled || props.missionData.isComplete || props.missionData.isInProgress}
            >
                Assign Mission Number
            </Button>
            <Button
                variant="contained"
                onClick={() => props.onClickAssignCallsign()}
                disabled={props.missionData.isCanceled || props.missionData.isComplete || props.missionData.isInProgress}
            >
                Assign Callsign
            </Button>
            <Button
                variant="contained"
                onClick={() => props.onClickEdit()}
                disabled={!props.missionData.isDraft}
            >
                Edit
            </Button>
        </Stack>
    )

    return (
        <Paper sx={props.sx}>
            <Stack direction="column" spacing={1} padding={2}>
                <Stack direction="row" justifyContent="space-between">
                    {props.missionData.userPermissions!.modify ?
                        buttonBar : (<Typography variant='h5'>Summary</Typography>)}
                </Stack>
                <Divider />
                <TextField InputProps={{ readOnly: true }} label="Title" value={props.missionData.name} />
                <TextField InputProps={{ readOnly: true }} label="Operation" value={props.missionData.operationObject?.name} />
                <Stack direction="row" spacing={1}>
                    <TextField InputProps={{ readOnly: true }} fullWidth label="Mission Number" value={props.missionData.missionNumber === undefined ? "(Not Assigned)" : props.missionData.missionNumber} />
                    <TextField InputProps={{ readOnly: true }} fullWidth label="Callsign" value={props.missionData.callsign === undefined ? "(Not Assigned)" : props.missionData.callsign} />
                </Stack>
                <TextField InputProps={{ readOnly: true }} label="Purpose" value={props.missionData.purpose} multiline rows={6} fullWidth />
            </Stack>
        </Paper>
    );
}

export default MissionTitlePanel;