import React, {useState, useEffect} from 'react';
import { Modal, Paper } from '@mui/material';
import EditProductLayout from '../layouts/EditProductLayout';
import getManufacturers from "../../../data/manufacturer/getManufacturers";
import {Manufacturer} from "../../../classes/Manufacturer";
import {Product} from "../../../classes/Product";

function UpdateProductModal(props: {
    isOpen: boolean,
    selectedProductObject: Product,
    onClose: Function,
    onSubmit: Function,
    onNetworkError: Function
}) {
    const [manufacturerList, setManufacturerList] = useState<Manufacturer[] | undefined>(undefined)

    useEffect(() => {
        getManufacturers()
            .then((result) => setManufacturerList(result))
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }, [])

    return (
        <Modal
            open={props.isOpen}
            onClose={() => props.onClose()}
        >
            <Paper
                sx={{
                    position: 'absolute',
                    width: '600px',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    padding: 2
                }}
            >
                <EditProductLayout
                    title="Update Product"
                    initInfo={props.selectedProductObject}
                    manufacturerList={manufacturerList}
                    onCancel={() => props.onClose()}
                    onSubmit={props.onSubmit}
                />
            </Paper>
        </Modal>
    )
}

export default UpdateProductModal