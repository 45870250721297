import {Manufacturer} from "./Manufacturer";

export class Product {
    id?: number
    manufacturerId: number = 0
    name: string = ""

    manufacturerObject?: Manufacturer

    constructor(manufacturerId?: number, name?: string) {
        this.manufacturerId = manufacturerId === undefined ? 0 : manufacturerId!
        this.name = name === undefined ? "" : name!
    }

    parseObject(input: any) {
        this.id = input.id
        this.manufacturerId = input.manufacturerId
        this.name = input.name

        if (input.manufacturerObject !== undefined) {
            const m = new Manufacturer()
            m.parseObject(input.manufacturerObject)
            this.manufacturerObject = m
        }
    }

    objectify(): object {
        return {
            id: this.id,
            manufacturerId: this.manufacturerId,
            name: this.name,
            manufacturerObject: this.manufacturerObject?.objectify()
        }
    }
}