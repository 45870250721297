import React from "react"
import { Dayjs } from "dayjs"
import { useTheme, Box } from "@mui/material"

function ScheduleSegment(props: { width: number, timelineStartTime: Dayjs, timelineEndTime: Dayjs, segmentStartTime: Dayjs, segmentEndTime: Dayjs }) {
    const theme = useTheme()

    const timelineTimeWindow = props.timelineEndTime.diff(props.timelineStartTime)

    const segmentWidth = (props.segmentEndTime.diff(props.segmentStartTime) / timelineTimeWindow) * props.width
    const segmentOffset = (props.segmentStartTime.diff(props.timelineStartTime) / timelineTimeWindow) * props.width

    return (
        <Box
            sx={{
                position: "absolute",
                backgroundColor: theme.palette.primary.main,
                width: `${segmentWidth}px`,
                height: "30px",
                transform: `translateX(${segmentOffset}px)`,
            }}
        />
    )
}

export default ScheduleSegment