export class Point {
    id?: number
    missionId: number = 0
    label: string = ""
    isTakeoffPoint: boolean = false
    isLandingPoint: boolean = false
    latitude: number = 0.0
    longitude: number = 0.0
    altitude: number = 0.0
    isAltitudeAGL: boolean = false

    parseObject(input: any) {
        this.id = input.id
        this.missionId = input.missionId
        this.label = input.label
        this.isTakeoffPoint = input.isTakeoffPoint
        this.isLandingPoint = input.isLandingPoint
        this.latitude = input.latitude
        this.longitude = input.longitude
        this.altitude = input.altitude
        this.isAltitudeAGL = input.isAltitudeAGL
    }

    objectify(): object {
        return {
            id: this.id,
            missionId: this.missionId,
            label: this.label,
            isTakeoffPoint: this.isTakeoffPoint,
            isLandingPoint: this.isLandingPoint,
            latitude: this.latitude,
            longitude: this.longitude,
            altitude: this.altitude,
            isAltitudeAGL: this.isAltitudeAGL
        }
    }
}