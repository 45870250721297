import React from 'react';
import { useState, useEffect, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, List, Stack } from '@mui/material';
import PrimaryLayout from '../../../common/layouts/PrimaryLayout';
import OperatorListItem from '../components/OperatorListItem';

import NewReadinessProblemModal from '../modals/NewReadinessProblemModal';
import NewReadinessRequirementModal from '../modals/NewReadinessRequirementModal';
import ConfirmResolveProblemModal from '../modals/ConfirmResolveProblemModal';
import RenewReadinessRequirementModal from '../modals/RenewReadinessRequirementModal';


import config from '../../../data/config';
import getCompleteUiUrl from '../../../data/getCompleteUiUrl';
import getOperatorProfiles from '../../../data/operator/getOperatorProfiles';
import createReadinessRequirementInOperatorById from '../../../data/operator/createReadinessRequirementInOperatorById';
import createReadinessProblemInOperatorById from '../../../data/operator/createReadinessProblemInOperatorById';
import resolveReadinessProblemById from '../../../data/operator/resolveReadinessProblemById';
import renewReadinessRequirementById from '../../../data/operator/renewReadinessRequirementById';
import ListContentView from '../../../common/layouts/ListContentView';
import OperatorTabbedLayout from '../layouts/OperatorTabbedLayout';
import {Operator} from "../../../classes/Operator";
import {OperatorReadinessProblem} from "../../../classes/OperatorReadinessProblem";
import {OperatorReadinessRequirement} from "../../../classes/OperatorReadinessRequirement";
import {Dayjs} from "dayjs";
import {SysInfoBannerParams} from "../../../classes/SysInfoBannerParams";
import {User} from "../../../classes/User";
import getBanner from "../../../data/sysinfo/getBanner";
import self from "../../../data/user/self";

function Operators() {
    const navigate = useNavigate()

    const [bannerData, setBannerData] = useState<SysInfoBannerParams | undefined>(undefined)
    const [userData, setUserData] = useState<User | undefined>(undefined)

    const [operatorList, setOperatorList] = useState<Operator[] | undefined>(undefined);
    const [selectedOperatorId, setSelectedOperatorId] = useState<number | null>(null);
    const [selectedOperatorObject, setSelectedOperatorObject] = useState<Operator | undefined>(undefined);
    const [selectedReadinessProblemId, setSelectedReadinessProblemId] = useState<number | null>(null);
    const [selectedReadinessProblemObject, setSelectedReadinessProblemObject] = useState<OperatorReadinessProblem | undefined>(undefined);
    const [selectedReadinessRequirementId, setSelectedReadinessRequirementId] = useState<number | null>(null);
    const [selectedReadinessRequirementObject, setSelectedReadinessRequirementObject] = useState<OperatorReadinessRequirement | undefined>(undefined);

    const [isCreateReadinessProblemModalOpen, setCreateReadinessProblemModalOpen] = useState<boolean>(false);
    const [isCreateReadinessRequirementModalOpen, setCreateReadinessRequirementModalOpen] = useState<boolean>(false);
    const [isConfirmResolveReadinessProblemModalOpen, setConfirmResolveReadinessProblemModalOpen] = useState<boolean>(false);
    const [isRenewReadinessRequirementModalOpen, setRenewReadinessRequirementModalOpen] = useState<boolean>(false);

    useEffect(() => {
        if (bannerData === undefined) {
            getBanner()
                .then((d) => setBannerData(d))
                .catch((err) => {
                    console.error(err)
                    // TODO: props.onNetworkError()
                })
        }

        if (userData === undefined) {
            self()
                .then((d) => setUserData(d))
                .catch((err) => {
                    console.error(err)
                    // TODO: props.onNetworkError()
                })
        }
    }, [])

    useEffect(() => {
        getOperatorProfiles()
            .then((result) => setOperatorList(result))
            .catch((err) => {
                console.error(err)
                // TODO: props.onNetworkError()
            })
    }, [userData])

    useLayoutEffect(() => {
        if (selectedOperatorId === null) {
            setSelectedOperatorObject(undefined)
        } else if (operatorList !== undefined) {
            operatorList.map((o: Operator) => {
                if (o.id == selectedOperatorId) {
                    setSelectedOperatorObject(o)
                }
            })
        }
    }, [selectedOperatorId, operatorList])

    useLayoutEffect(() => {
        if (selectedReadinessProblemId === null) {
            setSelectedReadinessProblemObject(undefined)
        } else if (selectedOperatorObject !== undefined) {
            selectedOperatorObject.readiness!.problems.map((p) => {
                if (p.id == selectedReadinessProblemId) {
                    setSelectedReadinessProblemObject(p);
                }
            })
        }
    }, [selectedReadinessProblemId, selectedOperatorObject])

    useLayoutEffect(() => {
        if (selectedReadinessRequirementId === null) {
            setSelectedReadinessRequirementObject(undefined)
        } else if (selectedOperatorObject !== undefined) {
            selectedOperatorObject.readiness!.requirements.map((p) => {
                if (p.id == selectedReadinessRequirementId) {
                    setSelectedReadinessRequirementObject(p);
                }
            })
        }
    }, [selectedReadinessRequirementId, selectedOperatorObject])

    if (bannerData === undefined || userData === undefined) {
        return null // TODO: Make this into a loading page for all pages.
    }

    const buttonBar = (
        <Stack direction="row" padding={1} spacing={1}>
        </Stack>
    );

    const listMessage = operatorList === undefined ? "Loading operators..." : `${operatorList.length} operators loaded.`;

    const operatorListItems = operatorList === undefined ? null : operatorList.map((operatorObject) => (
        <OperatorListItem selected={selectedOperatorId === operatorObject.id} onSelect={() => setSelectedOperatorId(operatorObject.id!)} operatorObject={operatorObject} />
    ));
    
    const list = (<List>{operatorListItems}</List>);

    function content() {
        if (selectedOperatorId === null) {
            return (<Typography textAlign="center" sx={{ transform: "translateY(250px)" }}>Select an operator to view his/her data.</Typography>);
        } else if (selectedOperatorId != null && selectedOperatorObject === undefined) {
            return (<Typography textAlign="center" sx={{ transform: "translateY(250px)" }}>Loading operator data...</Typography>);
        } else if (selectedOperatorId != null && selectedOperatorObject !== undefined) {
            if (selectedOperatorId == selectedOperatorObject.id) {
                return (
                    <OperatorTabbedLayout
                        operatorData={selectedOperatorObject}
                        readinessProblemData={selectedReadinessProblemObject}
                        readinessRequirementData={selectedReadinessRequirementObject}
                        onSelectReadinessProblem={(e: number[]) => setSelectedReadinessProblemId(e[0])}
                        onSelectReadinessRequirement={(e: number[]) => setSelectedReadinessRequirementId(e[0])}
                        onClickCreateReadinessProblem={() => setCreateReadinessProblemModalOpen(true)}
                        onClickCreateReadinessRequirement={() => setCreateReadinessRequirementModalOpen(true)}
                        onClickResolveReadinessProblem={() => setConfirmResolveReadinessProblemModalOpen(true)}
                        onClickRenewReadinessRequirement={() => setRenewReadinessRequirementModalOpen(true)}
                    />
                );
            }
        }
    }

    function handleCreateReadinessProblem(inputProblem: OperatorReadinessProblem) {
        createReadinessProblemInOperatorById(inputProblem)
            .then(() => getOperatorProfiles())
            .then((result) => {
                setOperatorList(result);
                setSelectedOperatorObject(undefined);
                setCreateReadinessProblemModalOpen(false);
            })
            .catch((err) => {
                console.error(err)
                // TODO: props.onNetworkError()
            })
    }

    function handleCreateReadinessRequirement(inputRequirement: OperatorReadinessRequirement) {
        createReadinessRequirementInOperatorById(inputRequirement)
            .then(() => getOperatorProfiles())
            .then((result) => {
                setOperatorList(result);
                setSelectedOperatorObject(undefined);
                setCreateReadinessRequirementModalOpen(false);
            })
            .catch((err) => {
                console.error(err)
                // TODO: props.onNetworkError()
            })
    }

    function handleResolveReadinessProblem() {
        resolveReadinessProblemById(selectedReadinessProblemId!)
            .then(() => getOperatorProfiles())
            .then((result) => {
                setOperatorList(result);
                setSelectedOperatorObject(undefined);
                setConfirmResolveReadinessProblemModalOpen(false);
            })
            .catch((err) => {
                console.error(err)
                // TODO: props.onNetworkError()
            })
    }

    function handleRenewReadinessRequirement(expirationDate: Dayjs) {
        renewReadinessRequirementById(selectedReadinessRequirementId!, expirationDate)
            .then(() => getOperatorProfiles())
            .then((result) => {
                setOperatorList(result);
                setSelectedOperatorObject(undefined);
                setRenewReadinessRequirementModalOpen(false);
            })
            .catch((err) => {
                console.error(err)
                // TODO: props.onNetworkError()
            })
    }

    return (
        <PrimaryLayout
            sysInfoBanner={bannerData}
            userData={userData}
            navId="operators"
            title="Operators"
            content={
                <ListContentView
                    buttonBar={buttonBar}
                    list={list}
                    content={content()}
                    selectionMessage={listMessage}
                />
            }
            modals={[
                (
                    <NewReadinessProblemModal
                        operatorData={selectedOperatorObject === undefined ? new Operator() : selectedOperatorObject!}
                        isOpen={isCreateReadinessProblemModalOpen}
                        onClose={() => setCreateReadinessProblemModalOpen(false)}
                        onSubmit={handleCreateReadinessProblem}
                    />
                ),
                (
                    <NewReadinessRequirementModal
                        operatorData={selectedOperatorObject === undefined ? new Operator() : selectedOperatorObject!}
                        isOpen={isCreateReadinessRequirementModalOpen}
                        onClose={() => setCreateReadinessRequirementModalOpen(false)}
                        onSubmit={handleCreateReadinessRequirement}
                    />
                ),
                (
                    <ConfirmResolveProblemModal
                        isOpen={isConfirmResolveReadinessProblemModalOpen}
                        onClose={() => setConfirmResolveReadinessProblemModalOpen(false)}
                        onConfirm={handleResolveReadinessProblem}
                    />
                ),
                (
                    <RenewReadinessRequirementModal
                        isOpen={isRenewReadinessRequirementModalOpen}
                        onClose={() => setRenewReadinessRequirementModalOpen(false)}
                        onSubmit={handleRenewReadinessRequirement}
                    />
                ),
            ]}
        />
    );
}

export default Operators