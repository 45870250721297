export class Waypoint {
    id?: number
    pathId: number = 0
    sequence: number = 0
    latitude: number = 0.0
    longitude: number = 0.0
    altitude: number = 0.0
    isAltitudeAGL: boolean = false

    parseObject(input: any) {
        this.id = input.id
        this.pathId = input.pathId
        this.sequence = input.sequence
        this.latitude = input.latitude
        this.longitude = input.longitude
        this.altitude = input.altitude
        this.isAltitudeAGL = input.isAltitudeAGL
    }

    objectify(): object {
        return {
            id: this.id,
            pathId: this.pathId,
            sequence: this.sequence,
            latitude: this.latitude,
            longitude: this.longitude,
            altitude: this.altitude,
            isAltitudeAGL: this.isAltitudeAGL
        }
    }
}