import React from 'react';
import { useState, useEffect, useLayoutEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { List, Typography, Stack, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import NewIcon from '@mui/icons-material/Add';
import PrimaryLayout from '../../../common/layouts/PrimaryLayout';
import OperationTabbedLayout from '../layouts/OperationTabbedLayout';
import ListContentView from '../../../common/layouts/ListContentView';
import OperationListItem from '../components/OperationListItem';
import NewOperationModal from '../modals/NewOperationModal';
import EditOperationModal from '../modals/EditOperationModal';
import NewOperationObjectiveModal from '../modals/NewOperationObjectiveModal';

import getOperations from '../../../data/operation/getOperations';
import getOngoingOperations from '../../../data/operation/getOngoingOperations';
import getFutureOperations from '../../../data/operation/getFutureOperations';
import createOperation from '../../../data/operation/createOperation';
import updateOperationById from '../../../data/operation/updateOperationById';
import addObjectiveToOperationById from '../../../data/operation/addObjectiveToOperationById';
import {Operation} from "../../../classes/Operation";
import {OperationObjective} from "../../../classes/OperationObjective";
import {SysInfoBannerParams} from "../../../classes/SysInfoBannerParams";
import {User} from "../../../classes/User";
import getBanner from "../../../data/sysinfo/getBanner";
import self from "../../../data/user/self";
import NetworkError from "../../../common/components/NetworkError";

function Operations() {
    const navigate = useNavigate()

    const [bannerData, setBannerData] = useState<SysInfoBannerParams | undefined>(undefined)
    const [userData, setUserData] = useState<User | undefined>(undefined)

    // TODO: Implement onNetworkError state here.

    const [isCreateOperationModalOpen, setCreateOperationModalOpen] = useState<boolean>(false);
    const [isEditOperationModalOpen, setEditOperationModalOpen] = useState<boolean>(false);
    const [isCreateOperationObjectiveModalOpen, setCreateOperationObjectiveModalOpen] = useState<boolean>(false);

    const [selectedOperationListFilter, setOperationListFilter] = useState<number>(1);
    const [operationList, setOperationList] = useState<Operation[] | undefined>(undefined);
    const [selectedOperationId, setSelectedOperationId] = useState<number | null>(null);
    const [selectedOperationObject, setSelectedOperationObject] = useState<Operation | undefined>(undefined);
    const [selectedObjectiveId, setSelectedObjectiveId] = useState<number | null>(null)
    const [selectedObjectiveObject, setSelectedObjectiveObject] = useState<OperationObjective | undefined>(undefined);

    async function getFilteredOperations(): Promise<Operation[]> {
        if (selectedOperationListFilter === 1) {
            return await getOperations();
        } else if (selectedOperationListFilter === 2) {
            return await getOngoingOperations();
        } else if (selectedOperationListFilter === 3) {
            return await getFutureOperations();
        } else {
            return []
        }
    }

    function resetData() {
        setOperationList(undefined);
        setSelectedOperationId(null);
        setSelectedOperationObject(undefined);
        setSelectedObjectiveId(null);
        setSelectedObjectiveObject(undefined);
    }

    function filterSelected(filterIndex: number) {
        setOperationListFilter(filterIndex);
        resetData();
    }

    useEffect(() => {
        if (bannerData === undefined) {
            getBanner()
                .then((d) => setBannerData(d))
                .catch((err) => {
                    console.error(err)
                    // TODO: props.onNetworkError()
                })
        }

        if (userData === undefined) {
            self()
                .then((d) => setUserData(d))
                .catch((err) => {
                    console.error(err)
                    // TODO: props.onNetworkError()
                })
        }
    }, [])

    useEffect(() => {
        if (userData !== undefined) {
            getFilteredOperations()
                .then((result) => setOperationList(result))
                .catch((err) => {
                    console.error(err)
                    // TODO: props.onNetworkError()
                })
        }
    }, [userData])

    useLayoutEffect(() => {
        if (selectedOperationId === null) {
            setSelectedOperationObject(undefined)
        } else if (operationList !== undefined) {
            operationList.map((o) => {
                if (o.id == selectedOperationId) {
                    setSelectedOperationObject(o);
                }
            })
        }
    }, [selectedOperationId, operationList])

    useLayoutEffect(() => {
        if (selectedObjectiveId === null) {
            setSelectedObjectiveObject(undefined)
        } else if (selectedOperationObject !== undefined) {
            selectedOperationObject.objectives!.map((o) => {
                if (o.id == selectedObjectiveId) {
                    setSelectedObjectiveObject(o);
                }
            })
        }
    }, [selectedObjectiveId, selectedOperationObject])

    if (bannerData === undefined || userData === undefined) {
        return null // TODO: Make this into a loading page for all pages.
    }

    const buttonBar = (
        <Stack direction="row" padding={1} spacing={1}>
            <FormControl fullWidth>
                <InputLabel id="operation-filter-select">Select Filter</InputLabel>
                <Select
                    labelId="operation-filter-select"
                    label="Select Filter"
                    value={selectedOperationListFilter}
                    onChange={(event) => filterSelected(event.target.value as number)}
                >
                    <MenuItem value={1}>All Operations</MenuItem>
                    <MenuItem value={2}>Ongoing Operations</MenuItem>
                    <MenuItem value={3}>Future Operations</MenuItem>
                </Select>
            </FormControl>
            <Button variant="contained" startIcon={<NewIcon />} onClick={() => setCreateOperationModalOpen(true)}>New</Button>
        </Stack>
    );

    const listMessage = (operationList === undefined || operationList === null) ? "Loading operations..." : `${operationList.length} operations loaded.`;

    const operationListItems = (operationList === undefined || operationList === null) ? null : operationList.map((operationObject) => (
        <OperationListItem selected={selectedOperationId === operationObject.id} onSelect={() => setSelectedOperationId(operationObject.id!)} operationObject={operationObject} />
    ));

    const list = (<List>{operationListItems}</List>);

    function handleCreateOperation(operation: Operation) {
        createOperation(operation)
            .then(() => getFilteredOperations())
            .then((result) => {
                setOperationList(result);
                setCreateOperationModalOpen(false);
            })
            .catch((err) => {
                console.error(err)
                // TODO: props.onNetworkError()
            })
    }

    function handleEditOperation(operation: Operation) {
        updateOperationById(operation)
            .then(() => getFilteredOperations())
            .then((result) => {
                setOperationList(result);
                setSelectedOperationObject(undefined);
                setEditOperationModalOpen(false);
            })
            .catch((err) => {
                console.error(err)
                // TODO: props.onNetworkError()
            })
    }

    function handleCreateOperationObjective(objective: OperationObjective) {
        addObjectiveToOperationById(objective)
            .then(() => getFilteredOperations())
            .then((result) => {
                setOperationList(result);
                setSelectedOperationObject(undefined);
                setCreateOperationObjectiveModalOpen(false);
            })
            .catch((err) => {
                console.error(err)
                // TODO: props.onNetworkError()
            })
    }

    function content() {
        if (selectedOperationId == null) {
            return (<Typography textAlign="center" sx={{ transform: "translateY(250px)" }}>Select an operation to view its data.</Typography>);
        } else if (selectedOperationId != null && selectedOperationObject === undefined) {
            return (<Typography textAlign="center" sx={{ transform: "translateY(250px)" }}>Loading operation data...</Typography>);
        } else if (selectedOperationId != null && selectedOperationObject !== undefined) {
            if (selectedOperationId == selectedOperationObject.id) {
                return (
                    <OperationTabbedLayout
                        operationData={selectedOperationObject}
                        objectiveData={selectedObjectiveObject}
                        onObjectiveSelectionChanged={(e: number[]) => setSelectedObjectiveId(e[0])}
                        onClickEditOperation={() => setEditOperationModalOpen(true)}
                        onClickNewObjective={() => setCreateOperationObjectiveModalOpen(true)}
                        onClickEditObjective={() => alert("The editing feature for " + selectedObjectiveObject!.description + " is still under construction.")}
                    />
                );
            }
        }
    }

    return (
        <PrimaryLayout
            sysInfoBanner={bannerData}
            userData={userData}
            navId="operations"
            title="Operations"
            content={
                <ListContentView
                    buttonBar={buttonBar}
                    list={list}
                    content={content()}
                    selectionMessage={listMessage}
                />
            }
            modals={[
                (
                    <NewOperationModal
                        isOpen={isCreateOperationModalOpen}
                        onClose={() => setCreateOperationModalOpen(false)}
                        onSubmit={handleCreateOperation}
                        onNetworkError={() => {}}
                    />
                ),
                (
                    <EditOperationModal
                        isOpen={isEditOperationModalOpen}
                        operationData={selectedOperationObject === undefined ? new Operation() : selectedOperationObject}
                        onClose={() => setEditOperationModalOpen(false)}
                        onSubmit={handleEditOperation}
                        onNetworkError={() => {}}
                    />
                ),
                (
                    <NewOperationObjectiveModal
                        isOpen={isCreateOperationObjectiveModalOpen}
                        operationData={selectedOperationObject === undefined ? new Operation() : selectedOperationObject}
                        onClose={() => setCreateOperationObjectiveModalOpen(false)}
                        onSubmit={handleCreateOperationObjective}
                        onNetworkError={() => {}}
                    />
                )
            ]}
        />
    );
}

export default Operations;