import React, {useEffect, useState} from "react"
import { Modal, Paper } from "@mui/material"
import ManageUserOrganizationsLayout from "../layouts/ManageUserOrganizationsLayout"
import getOrganizations from "../../../data/organization/getOrganizations";
import getOrganizationsInUserByUsername from "../../../data/organization/getOrganizationsInUserByUsername";
import addUserByUsernameToOrganizationById from "../../../data/organization/addUserByUsernameToOrganizationById";
import removeUserByUsernameFromOrganizationById
    from "../../../data/organization/removeUserByUsernameFromOrganizationById";

function ManageUserOrganizationsModal(props: {
    isOpen: boolean,
    userName: string,
    onClose: Function
}) {
    const [organizationList, setOrganizationList] = useState<any>(undefined)
    const [userOrganizationList, setUserOrganizationList] = useState<any>(undefined)

    useEffect(() => {
        if (props.isOpen && props.userName != null) {
            getOrganizations()
                .then((result) => setOrganizationList(result))
                .catch((err) => {
                    console.error(err)
                    // TODO: props.onNetworkError()
                })

            getOrganizationsInUserByUsername(props.userName)
                .then((result) => setUserOrganizationList(result))
                .catch((err) => {
                    console.error(err)
                    // TODO: props.onNetworkError()
                })
        } else {
            setOrganizationList(undefined)
            setUserOrganizationList(undefined)
        }
    }, [props.userName, props.isOpen])

    function addUserToOrganization(organizationId: number) {
        addUserByUsernameToOrganizationById(organizationId, props.userName)
            .then((result) => getOrganizationsInUserByUsername(props.userName))
            .then((result) => setUserOrganizationList(result))
            .catch((err) => {
                console.error(err)
                // TODO: props.onNetworkError()
            })
    }

    function removeUserFromOrganization(organizationId: number) {
        removeUserByUsernameFromOrganizationById(organizationId, props.userName)
            .then((result) => getOrganizationsInUserByUsername(props.userName))
            .then((result) => setUserOrganizationList(result))
            .catch((err) => {
                console.error(err)
                // TODO: props.onNetworkError()
            })
    }

    return (
        <Modal
            open={props.isOpen}
            onClose={() => props.onClose()}
        >
            <Paper
                sx={{
                    position: "absolute",
                    width: "600px",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    padding: 2
                }}
            >
                <ManageUserOrganizationsLayout
                    title="Manage User Organizations"
                    userName={props.userName}
                    organizationList={organizationList}
                    userOrganizationList={userOrganizationList}
                    onAddUserToOrganization={addUserToOrganization}
                    onRemoveUserFromOrganization={removeUserFromOrganization}
                    onCancel={() => props.onClose()}
                />
            </Paper>
        </Modal>
    )
}

export default ManageUserOrganizationsModal