import React from "react"
import {Snackbar} from "@mui/material";
import Alert from "@mui/material/Alert";

function NetworkError(props: { open: boolean, onClose: Function }) {

    return (
        <Snackbar
            autoHideDuration={5000}
            anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
            open={props.open}
            onClose={() => props.onClose()}
        >
            <Alert
                severity="error"
                onClose={() => props.onClose()}
                variant="filled"
                sx={{ width: "100$" }}
            >
                Failed to contact the SAMS server.
            </Alert>
        </Snackbar>
    )
}

export default NetworkError