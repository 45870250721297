import React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import { DataGrid } from '@mui/x-data-grid';
import {Product} from "../../../classes/Product";

function ProductFlatList(props: {
    data: Product[],
    selectedProductId?: number,
    onProductSelectionChanged: Function,
    sx?: object
}) {
    
    const columns = [
        { field: 'manufacturerName', headerName: 'Manufacturer', width: 200, valueGetter: (params: any) => { return params.row.manufacturerObject.name }},
        { field: 'name', headerName: 'Name', width: 400 }
    ];

    return (
        <Paper sx={props.sx}>
            <Stack direction="column" spacing={2} padding={2}>
                <Box sx={{ height: 600 }}>
                    <DataGrid
                        rows={props.data.map((product: Product) => product.objectify())}
                        columns={columns}
                        rowSelectionModel={props.selectedProductId === undefined ? [] : props.selectedProductId}
                        onRowSelectionModelChange={(e) => props.onProductSelectionChanged(e)}
                    />
                </Box>
            </Stack>
        </Paper>
    );
}

export default ProductFlatList;