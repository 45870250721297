import React from "react"
import Stack from "@mui/material/Stack"
import Button from "@mui/material/Button"
import Paper from "@mui/material/Paper"

function OperatorEmergencyReservationActionBar(props: {
    onCreate: Function,
    onMarkAvailable: Function,
    onMarkUnavailable: Function,
    onEdit: Function,
    onDelete: Function,
    createDisabled: boolean,
    markAvailableDisabled: boolean,
    markUnavailableDisabled: boolean,
    editDisabled: boolean,
    deleteDisabled: boolean,
    sx?: object
}) {

    return (
        <Paper sx={props.sx}>
            <Stack direction="row" justifyContent="center" spacing={2} padding={2}>
                <Button variant="contained" disabled={props.createDisabled} onClick={() => props.onCreate()}>Create</Button>
                <Button variant="contained" disabled={props.markAvailableDisabled} onClick={() => props.onMarkAvailable()}>Mark Available</Button>
                <Button variant="contained" disabled={props.markUnavailableDisabled} onClick={() => props.onMarkUnavailable()}>Mark Unavailable</Button>
                <Button variant="contained" disabled={props.editDisabled} onClick={() => props.onEdit()}>Edit</Button>
                <Button variant="contained" disabled={props.deleteDisabled} onClick={() => props.onDelete()}>Delete</Button>
            </Stack>
        </Paper>
    )
}

export default OperatorEmergencyReservationActionBar