import dayjs, { Dayjs } from "dayjs"

export class OperatorReadinessProblem {
    id?: number
    operatorId: number = 0
    subject: string = ""
    description: string = ""
    started: Dayjs = dayjs()
    resolutionExpected: Dayjs = dayjs()
    resolutionCertified?: Dayjs

    parseObject(input: any) {
        this.id = input.id
        this.operatorId = input.operatorId
        this.subject = input.subject
        this.description = input.description
        this.started = dayjs(input.started)
        this.resolutionExpected = dayjs(input.resolutionExpected)
        this.resolutionCertified = input.resolutionCertified === undefined ? undefined : dayjs(input.resolutionCertified)
    }

    objectify(): object {
        return {
            id: this.id,
            operatorId: this.operatorId,
            subject: this.subject,
            description: this.description,
            started: this.started.format(),
            resolutionExpected: this.resolutionExpected.format(),
            resolutionCertified: this.resolutionCertified?.format()
        }
    }
}