import React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import { DataGrid } from '@mui/x-data-grid';
import {Organization} from "../../../classes/Organization";

function OrganizationFlatList(props: {
    data: Organization[],
    selectedOrganizationId?: number,
    onOrganizationSelectionChanged: Function,
    sx?: object
}) {

    // TODO: Add more information here.
    const columns = [
        { field: 'name', headerName: 'Name', width: 400 },
    ];

    return (
        <Paper sx={props.sx}>
            <Stack direction="column" spacing={2} padding={2}>
                <Box sx={{ height: 600 }}>
                    <DataGrid
                        rows={props.data.map((organization: Organization) => organization.objectify())}
                        columns={columns}
                        rowSelectionModel={props.selectedOrganizationId === undefined ? [] : props.selectedOrganizationId!}
                        onRowSelectionModelChange={(e) => props.onOrganizationSelectionChanged(e)}
                    />
                </Box>
            </Stack>
        </Paper>
    );
}

export default OrganizationFlatList;