import React from 'react';
import { useState } from 'react';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';

import AssetSummaryLayout from './AssetSummaryLayout';
import AssetReadinessLayout from './AssetReadinessLayout';
import AssetMissionsLayout from './AssetMissionsLayout';
import AssetEmergencyReservationsLayout from './AssetEmergencyReservationsLayout';
import {Asset} from "../../../classes/Asset";
import {AssetReadinessRequirement} from "../../../classes/AssetReadinessRequirement";
import {AssetReadinessProblem} from "../../../classes/AssetReadinessProblem";

function AssetTabbedLayout(props: {
    assetData: Asset,
    readinessProblemData?: AssetReadinessProblem,
    readinessRequirementData?: AssetReadinessRequirement,
    onSelectReadinessProblem: Function,
    onSelectReadinessRequirement: Function,
    onClickCreateReadinessRequirement: Function,
    onClickCreateReadinessProblem: Function,
    onClickRenewReadinessRequirement: Function,
    onClickResolveReadinessProblem: Function,
    modals?: any
}) {
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);

    function selectTab(event: any, newValue: any) {
        setSelectedTabIndex(newValue);
    }

    function renderTab() {
        if (selectedTabIndex === 0) {
            return (
                <AssetSummaryLayout
                    assetData={props.assetData}
                />
            );
        } else if (selectedTabIndex === 1) {
            return (
                <AssetReadinessLayout
                    assetData={props.assetData}
                    readinessProblemData={props.readinessProblemData}
                    readinessRequirementData={props.readinessRequirementData}
                    onSelectReadinessProblem={props.onSelectReadinessProblem}
                    onSelectReadinessRequirement={props.onSelectReadinessRequirement}
                    onClickCreateRequirement={props.onClickCreateReadinessRequirement}
                    onClickCreateProblem={props.onClickCreateReadinessProblem}
                    onClickRenewRequirement={props.onClickRenewReadinessRequirement}
                    onClickResolveProblem={props.onClickResolveReadinessProblem}
                />
            );
        } else if (selectedTabIndex === 2) {
            return (
                <AssetMissionsLayout
                    assetData={props.assetData}
                />
            );
        } else if (selectedTabIndex === 3) {
            return (
                <AssetEmergencyReservationsLayout
                    assetData={props.assetData}
                />
            );
        }
    }

    return (
        <Box sx={{ width: '100%' }}>
            {props.modals}
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={selectedTabIndex} onChange={selectTab} centered variant="scrollable" scrollButtons="auto">
                    <Tab label="Summary" />
                    <Tab label="Readiness" />
                    <Tab label="Assigned Missions" />
                    <Tab label="Emergency Reservations" />
                </Tabs>
            </Box>
            {renderTab()}
        </Box>
    );
}

export default AssetTabbedLayout;