import React from "react"
import { Button, Stack, Typography } from "@mui/material"

import InputPanel from "./InputPanel"

export default function MessageBoxPanel(props: { title: string, message: string, onOk: Function, panelStyle?: object }) {

    return (
        <InputPanel
            title={props.title}
            leftControl={<Stack direction="row" spacing={1}></Stack>}
            rightControl={<Button variant="contained" onClick={() => props.onOk()}>Ok</Button>}
            panelStyle={props.panelStyle}
        >
            <Typography variant="body1">{props.message}</Typography>
        </InputPanel>
    )
}