import React from 'react';
import { Paper, Stack, Typography, Divider, TextField } from '@mui/material';
import {Operator} from "../../../classes/Operator";

function OperatorTitlePanel(props: {
    operatorData: Operator,
    sx?: object
}) {

    return (
        <Paper sx={props.sx}>
            <Stack direction="column" spacing={1} padding={2}>
                <Typography variant="h5">Operator Summary</Typography>
                <Divider />
                <Stack direction="row" spacing={1}>
                    <TextField InputProps={{ readOnly: true }} label="Rank" value={props.operatorData.rank} sx={{ width: '15%' }} />
                    <TextField InputProps={{ readOnly: true }} label="First Name" value={props.operatorData.firstName} sx={{ width: '35%' }} />
                    <TextField InputProps={{ readOnly: true }} label="Last Name" value={props.operatorData.lastName} sx={{ width: '50%' }} />
                </Stack>
                <TextField InputProps={{ readOnly: true }} label="Callsign" value={props.operatorData.callsign} />
                <TextField InputProps={{ readOnly: true }} label="Office" value={props.operatorData.officeObject?.name} />
                <TextField InputProps={{ readOnly: true }} label="Organization" value={props.operatorData.officeObject?.organizationObject?.name} />
            </Stack>
        </Paper>
    );
}

export default OperatorTitlePanel;