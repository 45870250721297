import React from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"

import Welcome from "../features/welcome/pages/Welcome"
import Login from "../features/auth/pages/Login"
import Logout from "../features/auth/pages/Logout"
import Schedule from "../features/schedule/pages/Schedule"
import Missions from "../features/missions/pages/Missions"
import IndividualMissionPrintable from "../features/missions/pages/IndividualMissionPrintable"
import Operations from "../features/operations/pages/Operations"
import Assets from "../features/assets/pages/Assets"
import Operators from "../features/operators/pages/Operators"
import Emergencies from "../features/emergencies/pages/Emergencies"
import Administration from "../features/administration/pages/Administration"
import ResetPassphrase from "../features/account/pages/ResetPassphrase"

import config from "../data/config"

function PrimaryRouter() {

    return (
        <BrowserRouter>
            <Routes>
                <Route path={config.uiRoute}>
                    <Route index={true} element={<Welcome />} />
                    <Route index={false} path="admin" element={<Administration />} />
                    <Route index={false} path="emergencies" element={<Emergencies />} />
                    <Route index={false} path="assets" element={<Assets />} />
                    <Route index={false} path="operators" element={<Operators />} />
                    <Route index={false} path="operations" element={<Operations />} />
                    <Route path="missions">
                        <Route index={true} element={<Missions />} />
                        <Route path="id/:missionId">
                            <Route index={true} element={null} />
                            <Route index={false} path="printable" element={<IndividualMissionPrintable />} />
                        </Route>
                    </Route>

                    <Route index={false} path="schedule" element={<Schedule />} />
                    <Route index={false} path="login" element={<Login />} />
                    <Route index={false} path="logout" element={<Logout />} />
                    <Route path="account">
                        <Route index={true} element={<h1>Under construction</h1>} />
                        <Route index={false} path="resetpassphrase" element={<ResetPassphrase />} />
                    </Route>
                </Route>
                <Route path="/" element={<Welcome />} />
            </Routes>
        </BrowserRouter>
    );
}

export default PrimaryRouter