import React from 'react';
import { useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import Joi from 'joi';
import { InputLabel, MenuItem, FormControl, Select } from '@mui/material';
import {Asset} from "../../../classes/Asset";

function AssetMissionAssetLayout(props: {
    title: string,
    assetList?: Asset[],
    onSubmit: Function,
    onCancel: Function
}) {

    const [isPendingSubmit, setPendingSubmit] = useState<boolean>(false);
    const [inputAssetId, setInputAssetId] = useState<number | undefined>(undefined);

    const isAssetIdValid = Joi.number().integer().positive().required().validate(inputAssetId);
    const isValid = (!isAssetIdValid.error);

    function handleSubmit() {
        setPendingSubmit(true);
        props.onSubmit(inputAssetId);
    }

    const bottomControls = isPendingSubmit === true ? (<Typography>Loading...</Typography>) : [(<Button variant='text' onClick={() => props.onCancel()}>Cancel</Button>), (<Button variant='contained' disabled={!isValid} onClick={handleSubmit}>Submit</Button>)];

    if (props.assetList === undefined) {
        return (<Typography variant="h6">Loading...</Typography>)
    }

    return (
        <Stack direction="column" spacing={1}>
            <Typography variant="h6">{props.title}</Typography>
            <FormControl fullWidth disabled={isPendingSubmit}>
                <InputLabel id="asset-select">Select Asset</InputLabel>
                <Select
                    labelId="asset-select"
                    label="Select Asset"
                    disabled={isPendingSubmit}
                    value={inputAssetId}
                    onChange={(event: any) => setInputAssetId(event.target.value)}
                >
                    <MenuItem value={undefined}>none</MenuItem>
                    {props.assetList.map((p) => (<MenuItem value={p.id}>{p.callsign}</MenuItem>))}
                </Select>
            </FormControl>
            <Stack direction="row" spacing={1} justifyContent="right">{bottomControls}</Stack>
        </Stack>
    );
}

export default AssetMissionAssetLayout;