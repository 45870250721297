import React from 'react';
import { useState } from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import dayjs, {Dayjs} from 'dayjs';
import Joi from 'joi';
import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import {OperatorReadinessRequirement} from "../../../classes/OperatorReadinessRequirement";

function EditReadinessRequirementLayout(props: {
    title: string,
    initData: OperatorReadinessRequirement,
    onCancel: Function,
    onSubmit: Function
}) {

    const [isPendingSubmit, setPendingSubmit] = useState<boolean>(false);
    const [inputSubject, setInputSubject] = useState<string>("");
    const [inputCertificationIssued, setInputCertificationIssued] = useState<Dayjs>(dayjs());
    const [inputCertificationExpires, setInputCertificationExpires] = useState<Dayjs>(dayjs());

    const isSubjectValid = Joi.string().min(1).max(100).required().validate(inputSubject);
    const isCertificationIssuedValid = Joi.date().required().validate(inputCertificationIssued.format());
    const isCertificationExpiresValid = Joi.date().required().validate(inputCertificationExpires.format());
    const isValid = (!isSubjectValid.error && !isCertificationIssuedValid.error && !isCertificationExpiresValid.error);

    function handleSubmit() {
        setPendingSubmit(true);

        const inputRequirement = props.initData
        inputRequirement.subject = inputSubject
        inputRequirement.certificationIssued = inputCertificationIssued
        inputRequirement.certificationExpires = inputCertificationExpires

        props.onSubmit(inputRequirement)
    }

    const bottomControls = isPendingSubmit === true ? (<Typography>Loading...</Typography>) : [(<Button variant='text' onClick={() => props.onCancel()}>Cancel</Button>), (<Button variant='contained' disabled={!isValid} onClick={handleSubmit}>Submit</Button>)];

    return (
        <Stack direction="column" spacing={1}>
            <Typography variant="h6">{props.title}</Typography>
            <TextField
                type="text"
                variant="outlined"
                label="Subject"
                error={inputSubject.length > 0 && isSubjectValid.error !== undefined}
                disabled={isPendingSubmit}
                onChange={(e) => setInputSubject(e.target.value)}
            />
            <DatePicker
                label="Certification Issued"
                slotProps={{
                    textField: {
                        error: isCertificationIssuedValid.error !== undefined
                    },
                }}
                disabled={isPendingSubmit}
                value={inputCertificationIssued}
                onChange={(event: any) => setInputCertificationIssued(event)}
            />
            <DatePicker
                label="Certification Exipres"
                slotProps={{
                    textField: {
                        error: isCertificationExpiresValid.error !== undefined
                    },
                }}
                disabled={isPendingSubmit}
                value={inputCertificationExpires}
                onChange={(event: any) => setInputCertificationExpires(event)}
            />
            <Stack direction="row" spacing={1} justifyContent="right">{bottomControls}</Stack>
        </Stack>
    );
}

export default EditReadinessRequirementLayout;