import Cookies from "universal-cookie"

import config from "../config"
import getCompleteApiUrl from "../getCompleteApiUrl"
import { Product } from "../../classes/Product"
import {ApiCallFailure} from "../../classes/exception/ApiCallFailure";

export default async function updateProductById(product: Product) {
    const cookies = new Cookies()

    if (cookies.get("accessToken") === undefined) {
        const error = new ApiCallFailure()
        error.connectionSuccessful = false
        error.errorType = "NO_ACCESS_TOKEN"

        throw error
    }

    var res: Response
    try {
        res = await fetch(getCompleteApiUrl(config, `/products/id/${product.id}`), {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${cookies.get("accessToken")}`
            },
            body: JSON.stringify({
                manufacturerId: product.manufacturerId,
                name: product.name,
            }),
        })
    } catch (error) {
        const apiFailure = new ApiCallFailure()
        apiFailure.connectionSuccessful = false

        throw apiFailure
    }

    if (res!.status === 200) {
        const responseObject = await res!.json()

        const responseProduct = new Product()
        responseProduct.parseObject(responseObject)

        return responseProduct
    }

    const callFailure = new ApiCallFailure()
    callFailure.connectionSuccessful = true
    callFailure.responseCode = res!.status
    callFailure.errorType = "REQUEST_REJECTED"

    throw callFailure
}