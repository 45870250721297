import React, {useEffect, useLayoutEffect, useState} from "react"
import Stack from "@mui/material/Stack"

import UserActionBar from "../components/UserActionBar"
import UserFlatList from "../components/UserFlatList"
import getUsers from "../../../data/user/getUsers"
import {Typography} from "@mui/material"
import createUser from "../../../data/user/createUser"
import resetPassphraseForUserByUsername from "../../../data/user/resetPassphraseForUserByUsername"
import enableUserByUsername from "../../../data/user/enableUserByUsername"
import disableUserByUsername from "../../../data/user/disableUserByUsername"
import NewUserModal from "../modals/NewUserModal"
import ResetUserPassphraseModal from "../modals/ResetUserPassphraseModal"
import ManageUserOfficesModal from "../modals/ManageUserOfficesModal"
import ManageUserOrganizationsModal from "../modals/ManageUserOrganizationsModal"
import {User} from "../../../classes/User"

function UserTab(props: { onNetworkError: Function }) {
    const [isCreateUserModalOpen, setCreateUserModalOpen] = useState<boolean>(false)
    const [isResetUserPassphraseModalOpen, setResetUserPassphraseModalOpen] = useState<boolean>(false)
    const [isManageUserOfficesModalOpen, setManageUserOfficesModalOpen] = useState<boolean>(false)
    const [isManageUserOrganizationsModalOpen, setManageUserOrganizationsModalOpen] = useState<boolean>(false)
    // TODO: Modal for enabling or disabling a user.

    const [userList, setUserList] = useState<User[] | undefined>(undefined)
    const [selectedUserName, setSelectedUserName] = useState<string | null>(null)
    const [selectedUserObject, setSelectedUserObject] = useState<User | undefined>(undefined)

    useEffect(() => {
        getUsers()
            .then((result) => setUserList(result))
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }, [])

    useLayoutEffect(() => {
        if (selectedUserName === null) {
            setSelectedUserObject(undefined)
        } else if (userList !== undefined) {
            userList!.map((o: User) => {
                if (o.username == selectedUserName) {
                    setSelectedUserObject(o)
                }
            })
        }
    }, [selectedUserName, userList])

    if (userList === undefined) {
        return (<Typography textAlign="center" sx={{ transform: "translateY(250px)" }}>Loading user data...</Typography>)
    }

    function handleCreateUser(userName: string, passphrase: string, isAdmin: boolean, isEnabled: boolean) {
        createUser(userName, passphrase, isEnabled, isAdmin)
            .then(() => getUsers())
            .then((result) => {
                setUserList(result)
                setCreateUserModalOpen(false)
            })
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    function handleResetUserPassphrase(passphrase: string) {
        resetPassphraseForUserByUsername(selectedUserName!, passphrase)
            .then(() => getUsers())
            .then((result) => {
                setUserList(result)
                setResetUserPassphraseModalOpen(false)
            })
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    function handleEnableUser() {
        enableUserByUsername(selectedUserName!)
            .then(() => getUsers())
            .then((result) => setUserList(result))
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    function handleDisableUser() {
        disableUserByUsername(selectedUserName!)
            .then(() => getUsers())
            .then((result) => setUserList(result))
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    return (
        <Stack direction="column" spacing={2} padding={2}>
            <UserActionBar
                sx={{ width: "100%" }}
                onCreate={() => setCreateUserModalOpen(true)}
                onResetPassphrase={() => setResetUserPassphraseModalOpen(true)}
                onEnable={() => handleEnableUser()}
                onDisable={() => handleDisableUser()}
                onManageOffices={() => setManageUserOfficesModalOpen(true)}
                onManageOrganizations={() => setManageUserOrganizationsModalOpen(true)}
                createDisabled={false}
                resetPassphraseDisabled={selectedUserName === null}
                enableDisabled={selectedUserName === null}
                disableDisabled={selectedUserName === null}
                manageOfficesDisabled={selectedUserName === null}
                manageOrganizationsDisabled={selectedUserName === null}
            />
            <UserFlatList
                sx={{ width: "100%" }}
                data={userList}
                selectedUserName={selectedUserName === null ? undefined : selectedUserName}
                onUserSelectionChanged={(userName: string) => setSelectedUserName(userName)}
            />
            <NewUserModal
                isOpen={isCreateUserModalOpen}
                onClose={() => setCreateUserModalOpen(false)}
                onSubmit={handleCreateUser}
            />
            <ResetUserPassphraseModal
                isOpen={isResetUserPassphraseModalOpen}
                onClose={() => setResetUserPassphraseModalOpen(false)}
                onSubmit={handleResetUserPassphrase}
                userData={selectedUserObject}
            />
            <ManageUserOfficesModal
                isOpen={isManageUserOfficesModalOpen}
                onClose={() => setManageUserOfficesModalOpen(false)}
                userName={selectedUserName === null ? "" : selectedUserName}
            />
            <ManageUserOrganizationsModal
                isOpen={isManageUserOrganizationsModalOpen}
                onClose={() => setManageUserOrganizationsModalOpen(false)}
                userName={selectedUserName === null ? "" : selectedUserName}
            />
        </Stack>
    )
}

export default UserTab