import Cookies from "universal-cookie"

import config from "../config"
import getCompleteApiUrl from "../getCompleteApiUrl"
import {OperatingArea} from "../../classes/OperatingArea"
import {ApiCallFailure} from "../../classes/exception/ApiCallFailure";

export default async function addOperatingAreaToMissionById(operatingArea: OperatingArea) {
    const cookies = new Cookies()

    if (cookies.get("accessToken") === undefined) {
        const error = new ApiCallFailure()
        error.connectionSuccessful = false
        error.errorType = "NO_ACCESS_TOKEN"

        throw error
    }

    var res: Response
    try {
        res = await fetch(getCompleteApiUrl(config, `/missions/areas`), {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${cookies.get("accessToken")}`
            },
            body: JSON.stringify({
                missionId: operatingArea.missionId,
                label: operatingArea.label,
                points: operatingArea.points.map((p) => p.objectify()),
                isAGL: operatingArea.isAGL,
                altitudeFloor: operatingArea.altitudeFloor,
                altitudeCeil: operatingArea.altitudeCeil,
            }),
        })
    } catch (error) {
        const apiFailure = new ApiCallFailure()
        apiFailure.connectionSuccessful = false

        throw apiFailure
    }
    
    if (res!.status === 200) {
        const responseObject = await res!.json()
        
        const responseArea = new OperatingArea()
        responseArea.parseObject(responseObject)
        
        return responseArea
    }

    const callFailure = new ApiCallFailure()
    callFailure.connectionSuccessful = true
    callFailure.responseCode = res!.status
    callFailure.errorType = "REQUEST_REJECTED"

    throw callFailure
}