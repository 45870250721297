import Cookies from 'universal-cookie';

import config from '../config';
import getCompleteApiUrl from '../getCompleteApiUrl';
import {ApiCallFailure} from "../../classes/exception/ApiCallFailure";

async function disableUserByUsername(username: string) {
    const cookies = new Cookies();

    if (cookies.get('accessToken') === undefined) {
        const error = new ApiCallFailure()
        error.connectionSuccessful = false
        error.errorType = "NO_ACCESS_TOKEN"

        throw error
    }

    var res: Response
    try {
        res = await fetch(getCompleteApiUrl(config, `/users/id/${username}/disable`), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${cookies.get('accessToken')}`
            }
        })
    } catch (error) {
        const apiFailure = new ApiCallFailure()
        apiFailure.connectionSuccessful = false

        throw apiFailure
    }

    if (res!.status === 200) {
        return
    }

    const callFailure = new ApiCallFailure()
    callFailure.connectionSuccessful = true
    callFailure.responseCode = res!.status
    callFailure.errorType = "REQUEST_REJECTED"

    throw callFailure
}

export default disableUserByUsername;