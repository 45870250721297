import React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import { Typography, Stack, Divider, LinearProgress } from '@mui/material';
import { Error, Warning, Info, CheckCircle, Cancel } from '@mui/icons-material';

import dayjs from 'dayjs';
import {Mission} from "../../../classes/Mission";

function MissionListItem(props: {
    missionObject: Mission,
    selected: boolean,
    onSelect: Function
}) {

    const currentTime = dayjs();
    const timeStartScheduled = dayjs(props.missionObject.timeStartScheduled);
    const timeStartProjected = dayjs(props.missionObject.timeStartProjected);
    const timeStartActual = dayjs(props.missionObject.timeStartActual);
    const timeFinishScheduled = dayjs(props.missionObject.timeFinishScheduled);
    const timeFinishProjected = dayjs(props.missionObject.timeFinishProjected);
    const timeFinishActual = dayjs(props.missionObject.timeFinishActual);

    // TODO: Move this function to a single one that can be imported.
    function getStatusString() {
        if (props.missionObject.isDraft) {
            return "Draft"
        } else if (props.missionObject.isComplete) {
            return "Complete"
        } else if (props.missionObject.isCanceled) {
            return "Canceled"
        } else if (props.missionObject.isInProgress) {
            return "In Progress"
        } else {
            if (timeStartProjected.isSame(timeStartScheduled) && currentTime.isBefore(timeStartScheduled)) {
                return "On Time"
            } else if (timeStartProjected.isAfter(timeStartScheduled) || currentTime.isAfter(timeStartScheduled)) {
                return "Delayed"
            } else if (timeStartProjected.isBefore(timeStartScheduled)) {
                return "Starting Early"
            }
        }
    }

    // TODO: Move this function to a single one that can be imported.
    function getProgressValue() {
        if (props.missionObject.isInProgress) {
            const minutesDuration = timeFinishProjected.diff(timeStartActual);
            const minutesElapsed = dayjs().diff(timeStartActual);

            const progress = (minutesElapsed / minutesDuration) * 100;
            if (progress >= 100) {
                return 100;
            } else if (progress <= 0) {
                return 0;
            } else {
                return progress;
            }
        } else if (props.missionObject.isComplete) {
            return 100;
        } else if (props.missionObject.isCanceled) {
            return 0;
        } else {
            return 0;
        }
    }

    function getOrganizationString() {
        if (props.missionObject.assignedOrganizationId === undefined) {
            return "[No Org]";
        } else if (props.missionObject.assignedOrganizationObject!.symbol.length > 0) {
            return `${props.missionObject.assignedOrganizationObject!.symbol}`
        } else {
            return `${props.missionObject.assignedOrganizationObject!.name}`
        }
    }

    function getRPICString() {
        const rpicAssignments = props.missionObject.operatorAssignments!.filter((assignment) => assignment.isRPIC)

        if (rpicAssignments.length === 1) {
            return rpicAssignments[0].operatorObject!.callsign
        } else if (rpicAssignments.length > 1) {
            return "[Multiple RPIC]"
        }

        return "[No RPIC]"
    }

    function getFlagIcons() {
        if (props.missionObject.isComplete) {
            return (<CheckCircle color="disabled" />)
        } else if (props.missionObject.isCanceled) {
            return (<Cancel color="disabled" />)
        }

        var errors = 0;
        var warnings = 0;
        var infos = 0;

        for (const f of props.missionObject.flags!) {
            if (f.getSeverityCategory() === "error") {
                errors++;
            } else if (f.getSeverityCategory() === "warning") {
                warnings++;
            } else if (f.getSeverityCategory() === "info") {
                infos++;
            }
        }

        return (
            <Stack direction="row">
                {errors > 0 ? (<Error color="error" />) : null}
                {warnings > 0 ? (<Warning color="warning" />) : null}
                {infos > 0 ? (<Info color="info" />) : null}
                {(errors == 0 && warnings == 0 && infos == 0) ? (<CheckCircle color="success" />) : null}
            </Stack>
        );
    }

    function showError(): boolean {
        if (props.missionObject.isInProgress) {
            return (!props.missionObject.isComplete && !props.missionObject.isCanceled && currentTime.isAfter(timeFinishProjected))
        } else {
            return (!props.missionObject.isComplete && !props.missionObject.isCanceled && currentTime.isAfter(timeStartProjected))
        }
    }

    function getTimingInfo() {
        if (props.missionObject.isComplete) {
            return [
                (
                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant='body2'>{timeStartActual.format('MM/DD/YYYY HH:mm')}</Typography>
                        <Typography variant='body2'>{"-"}</Typography>
                        <Typography variant='body2'>{timeFinishActual.format('MM/DD/YYYY HH:mm')}</Typography>
                    </Stack>
                ), (
                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant='body2'>{`Lasted ${timeFinishActual.diff(timeStartActual, 'minute')} minutes`}</Typography>
                        <Typography variant='body2'>{getStatusString()}</Typography>
                    </Stack>
                )
            ];
        } else if (props.missionObject.isInProgress) {
            return [
                (
                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant='body2'>{timeStartActual.format('MM/DD/YYYY HH:mm')}</Typography>
                        <Typography variant='body2'>{"-"}</Typography>
                        <Typography variant='body2'>{timeFinishProjected.format('MM/DD/YYYY HH:mm')}</Typography>
                    </Stack>
                ), (
                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant='body2' color={showError() ? "error" : "inherit"}>{`Elapsed ${dayjs().diff(timeStartActual, 'minute')} minutes`}</Typography>
                        <Typography variant='body2'>{getStatusString()}</Typography>
                    </Stack>
                )
            ];
        } else if (props.missionObject.isCanceled) {
            return [
                (
                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant='body2'>{timeStartScheduled.format('MM/DD/YYYY HH:mm')}</Typography>
                        <Typography variant='body2'>{"-"}</Typography>
                        <Typography variant='body2'>{timeFinishScheduled.format('MM/DD/YYYY HH:mm')}</Typography>
                    </Stack>
                ), (
                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant='body2'>{''}</Typography>
                        <Typography variant='body2'>{getStatusString()}</Typography>
                    </Stack>
                )
            ];
        } else {
            return [
                (
                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant='body2'>{timeStartProjected.format('MM/DD/YYYY HH:mm')}</Typography>
                        <Typography variant='body2'>{"-"}</Typography>
                        <Typography variant='body2'>{timeFinishProjected.format('MM/DD/YYYY HH:mm')}</Typography>
                    </Stack>
                ), (
                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant='body2' color={showError() ? "error" : "inherit"}>{`Starting in ${timeStartProjected.diff(dayjs(), 'minute')} minutes`}</Typography>
                        <Typography variant='body2'>{getStatusString()}</Typography>
                    </Stack>
                )
            ];
        }
    }

    return (
        <ListItemButton selected={props.selected} onClick={() => props.onSelect()}>
            <Stack direction="column" sx={{ width: '100%' }}>
                <Divider />
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body1">{props.missionObject.name}</Typography>
                    {getFlagIcons()}
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body2">{props.missionObject.missionNumber === undefined ? "[No Mission Number]" : props.missionObject.missionNumber}</Typography>
                    <Typography variant="body2">{props.missionObject.callsign === undefined ? "[No Callsign]" : props.missionObject.callsign}</Typography>
                </Stack>
                <LinearProgress variant="determinate" value={getProgressValue()} />
                {getTimingInfo()}
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body2">{getOrganizationString()}</Typography>
                    <Typography variant='body2'>{getRPICString()}</Typography>
                </Stack>
                <Divider />
            </Stack>
        </ListItemButton>
    );
}

export default MissionListItem;