import React from 'react';
import { useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import Joi from 'joi';
import { FormControlLabel, FormLabel, Radio, FormControl, RadioGroup } from '@mui/material';
import {Deconfliction} from "../../../classes/Deconfliction";

function AnswerMissionDeconflictionLayout(props: {
    title: string,
    initInfo: Deconfliction,
    onSubmit: Function,
    onCancel: Function
}) {

    const [isPendingSubmit, setPendingSubmit] = useState(false);
    const [inputConcur, setInputConcur] = useState<number>(0);
    const [inputReason, setInputReason] = useState<string>("");

    // Joi validations.
    const isConcurValid = Joi.number().integer().validate(inputConcur);
    const isReasonValid = Joi.string().min(1).max(256).required().validate(inputReason);
    const isValid = (!isConcurValid.error && !isReasonValid.error);

    function handleSubmit() {
        setPendingSubmit(true);

        const inputDeconfliction = props.initInfo
        inputDeconfliction.isConcur = (inputConcur > 0)
        inputDeconfliction.reason = inputReason

        props.onSubmit(inputDeconfliction)
    }

    const bottomControls = isPendingSubmit === true ? (<Typography>Loading...</Typography>) : [(<Button variant='text' onClick={() => props.onCancel()}>Cancel</Button>), (<Button variant='contained' disabled={!isValid} onClick={handleSubmit}>Submit</Button>)];

    return (
        <Stack direction="column" spacing={1}>
            <Typography variant="h6">{props.title}</Typography>
            <FormControl fullWidth disabled={isPendingSubmit}>
                <RadioGroup
                    row
                    sx={{ justifyContent: "center" }}
                    value={inputConcur}
                    onChange={(e: any) => setInputConcur(e.target.value)}
                >
                    <FormControlLabel value={1} control={<Radio />} label="Concur" />
                    <FormControlLabel value={0} control={<Radio />} label="Non-Concur" />
                </RadioGroup>
            </FormControl>
            <TextField
                type="text"
                variant="outlined"
                label="Reason"
                multiline
                rows={3}
                error={inputReason!.length > 0 && isReasonValid.error !== undefined}
                disabled={isPendingSubmit}
                value={inputReason}
                onChange={(e) => setInputReason(e.target.value)}
            />
            <Stack direction="row" spacing={1} justifyContent="right">{bottomControls}</Stack>
        </Stack>
    );
}

export default AnswerMissionDeconflictionLayout;