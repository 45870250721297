import React from 'react';
import { Stack, Typography } from '@mui/material';

import AssetMissionFlatList from '../components/AssetMissionFlatList';
import {Asset} from "../../../classes/Asset";

function AssetMissionsLayout(props: {
    assetData: Asset
}) {

    return (
        <Stack direction="column" padding={2} spacing={2}>
            <AssetMissionFlatList sx={{ width: '100%' }} assetData={props.assetData} />
        </Stack>
    );
}

export default AssetMissionsLayout;