import React from 'react';
import { Stack } from '@mui/material';

import OperatorReadinessStatusPanel from '../components/OperatorReadinessStatusPanel';
import OperatorReadinessActionPanel from '../components/OperatorReadinessActionPanel';
import OperatorReadinessRequirementsPanel from '../components/OperatorReadinessRequirementsPanel';
import OperatorReadinessProblemsPanel  from '../components/OperatorReadinessProblemsPanel';
import {Operator} from "../../../classes/Operator";
import {OperatorReadinessProblem} from "../../../classes/OperatorReadinessProblem";
import {OperatorReadinessRequirement} from "../../../classes/OperatorReadinessRequirement";

function OperatorReadinessLayout(props: {
    operatorData: Operator,
    readinessProblemData?: OperatorReadinessProblem,
    readinessRequirementData?: OperatorReadinessRequirement,
    onSelectReadinessProblem: Function,
    onSelectReadinessRequirement: Function,
    onClickCreateProblem: Function,
    onClickResolveProblem: Function,
    onClickCreateRequirement: Function,
    onClickRenewRequirement: Function
}) {
    
    return (
        <Stack direction="column" padding={2} spacing={2}>
            <OperatorReadinessStatusPanel operatorData={props.operatorData} />
            <OperatorReadinessActionPanel
                createProblemDisabled={false}
                createRequirementDisabled={false}
                resolveProblemDisabled={props.readinessProblemData === undefined}
                renewRequirementDisabled={props.readinessRequirementData === undefined}
                onCreateProblem={props.onClickCreateProblem}
                onCreateRequirement={props.onClickCreateRequirement}
                onResolveProblem={props.onClickResolveProblem}
                onRenewRequirement={props.onClickRenewRequirement}
            />
            <OperatorReadinessProblemsPanel
                operatorData={props.operatorData}
                sx={{ width: '100%' }}
                selectedReadinessProblemData={props.readinessProblemData}
                onSelectReadinessProblem={props.onSelectReadinessProblem}
            />
            <OperatorReadinessRequirementsPanel
                operatorData={props.operatorData}
                sx={{ height: '100%' }}
                selectedReadinessRequirementData={props.readinessRequirementData}
                onSelectReadinessRequirement={props.onSelectReadinessRequirement}
            />
        </Stack>
    );
}

export default OperatorReadinessLayout;