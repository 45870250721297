import React from "react"
import { Modal, Paper } from "@mui/material"
import EditMissionPointLayout from "../layouts/EditMissionPointLayout"
import { Point } from "../../../classes/Point"
import { Mission } from "../../../classes/Mission"

export default function NewMissionPointModal(props: {
    isOpen: boolean,
    missionData: Mission,
    onClose: Function,
    onSubmit: Function
}) {

    const newPoint = new Point()
    newPoint.missionId = props.missionData.id!

    return (
        <Modal
            open={props.isOpen}
            onClose={() => props.onClose()}
        >
            <Paper
                sx={{
                    position: 'absolute',
                    width: '600px',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    padding: 2
                }}
            >
                <EditMissionPointLayout
                    title="New Point"
                    initData={newPoint}
                    onCancel={() => props.onClose()}
                    onSubmit={props.onSubmit}
                />
            </Paper>
        </Modal>
    )
}