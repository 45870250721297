export class DeconflictionUserPermissions {
    answer: boolean = false

    parseObject(input: any) {
        this.answer = input.answer
    }

    objectify(): object {
        return {
            answer: this.answer
        }
    }
}