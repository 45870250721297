import React from 'react';
import { useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { DateTimePicker } from '@mui/x-date-pickers';

import Joi from 'joi';
import dayjs, {Dayjs} from 'dayjs';
import {Mission} from "../../../classes/Mission";
import {TextField} from "@mui/material";

function DelayMissionLayout(props: {
    title: string,
    initInfo: Mission,
    onSubmit: Function,
    onCancel: Function
}) {

    const [isPendingSubmit, setPendingSubmit] = useState<boolean>(false)
    const [inputMinutesDelay, setInputMinutesDelay] = useState<string>(props.initInfo.timeStartScheduled.diff(props.initInfo.timeStartProjected, "minutes").toString())

    const isMinutesDelayValid = Joi.number().integer().validate(inputMinutesDelay)
    const isValid = (!isMinutesDelayValid.error)

    function handleSubmit() {
        setPendingSubmit(true)

        const delayedStartTime = props.initInfo.timeStartScheduled.add(Number.parseInt(inputMinutesDelay), "minutes")
        const delayedFinishTime = props.initInfo.timeFinishScheduled.add(Number.parseInt(inputMinutesDelay), "minutes")

        props.onSubmit(delayedStartTime, delayedFinishTime)
    }

    const bottomControls = isPendingSubmit === true ? (<Typography>Loading...</Typography>) : [(<Button variant='text' onClick={() => props.onCancel()}>Cancel</Button>), (<Button variant='contained' disabled={!isValid} onClick={handleSubmit}>Submit</Button>)];

    return (
        <Stack direction="column" spacing={1}>
            <Typography variant="h6">{props.title}</Typography>
            <TextField
                label="Delay in Minutes"
                type="number"
                value={inputMinutesDelay}
                onChange={(e) => setInputMinutesDelay(e.target.value)}
            />
            <Stack direction="row" spacing={1} justifyContent="right">{bottomControls}</Stack>
         </Stack>
    );
}

export default DelayMissionLayout;