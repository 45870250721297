import React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import { Typography, Stack, Divider } from '@mui/material';
import { Error, CheckCircle } from '@mui/icons-material';
import {Asset} from "../../../classes/Asset";

function AssetListItem(props: {
    assetObject: Asset,
    selected: boolean,
    onSelect: Function,
}) {

    function getFlagIcons() {

        const isErrorVisible = props.assetObject.readiness!.numberOutstandingRequirements! > 0 || props.assetObject.readiness!.numberActiveProblems! > 0

        return (
            <Stack direction="row">
                {isErrorVisible ? (<Error color="error" />) : (<CheckCircle color="success" />)}
            </Stack>
        );
    }

    return (
        <ListItemButton selected={props.selected} onClick={() => props.onSelect()}>
            <Stack direction="column" sx={{ width: '100%' }}>
                <Divider />
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body1">{props.assetObject.callsign}</Typography>
                    {getFlagIcons()}
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body2">{`${props.assetObject.productObject?.manufacturerObject?.name} ${props.assetObject.productObject?.name}`}</Typography>
                    <Typography variant="body2">{`${props.assetObject.officeObject?.name}`}</Typography>
                </Stack>
                <Divider />
            </Stack>
        </ListItemButton>
    );
}

export default AssetListItem;