import React, { useLayoutEffect } from 'react';
import { useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import Joi from 'joi';
import {Waypoint} from "../../../classes/Waypoint";
import {FlightPath} from "../../../classes/FlightPath";
import Box from "@mui/material/Box";

function EditMissionFlightPathLayout(props: {
    title: string,
    initData: FlightPath,
    onSubmit: Function,
    onCancel: Function
}) {
    const [isPendingSubmit, setPendingSubmit] = useState<boolean>(false)
    const [inputLabel, setInputLabel] = useState<string>(props.initData.label)
    const [inputWaypoints, setInputWaypoints] = useState<Waypoint[]>(props.initData.waypoints)

    const [editingWaypointIndex, setEditingWaypointIndex] = useState<number | undefined>(undefined)
    const [inputLat, setInputLat] = useState<string>("")
    const [inputLon, setInputLon] = useState<string>("")
    const [inputAlt, setInputAlt] = useState<string>("")
    const [inputAltIsAGL, setInputAltIsAGL] = useState<number>(1)

    const isLabelValid = Joi.string().min(0).max(100).required().validate(inputLabel)
    const isWaypointsValid = Joi.array().min(2).required().validate(inputWaypoints)
    const isValid = (!isLabelValid.error && !isWaypointsValid.error);

    const isLatValid = Joi.string().min(1).required().validate(inputLat);
    const isLonValid = Joi.string().min(1).required().validate(inputLon);
    const isAltValid = Joi.string().min(1).required().validate(inputAlt);
    const isLatLonValid = (!isLatValid.error && !isLonValid.error && !isAltValid.error);

    useLayoutEffect(() => {
        if (editingWaypointIndex === undefined) {
            setInputLat("")
            setInputLon("")
            setInputAlt("")
            setInputAltIsAGL(1)
        } else {
            setInputLat(inputWaypoints[editingWaypointIndex].latitude.toString())
            setInputLon(inputWaypoints[editingWaypointIndex].longitude.toString())
            setInputAlt(inputWaypoints[editingWaypointIndex].altitude.toString())
            setInputAltIsAGL(inputWaypoints[editingWaypointIndex].isAltitudeAGL ? 1 : 0)
        }
    }, [editingWaypointIndex])

    function handleSubmit() {
        setPendingSubmit(true);

        const inputPath = props.initData
        inputPath.label = inputLabel
        inputPath.waypoints = inputWaypoints

        props.onSubmit(inputPath)
    }

    function addWaypoint() {
        const inputWaypoint = new Waypoint()
        inputWaypoint.latitude = Number.parseFloat(inputLat)
        inputWaypoint.longitude = Number.parseFloat(inputLon)
        inputWaypoint.altitude = Number.parseFloat(inputAlt)
        inputWaypoint.isAltitudeAGL = (inputAltIsAGL === 1)

        if (editingWaypointIndex === undefined) {
            inputWaypoint.sequence = inputWaypoints.length
            inputWaypoints.push(inputWaypoint)
        } else {
            inputWaypoint.sequence = inputWaypoints[editingWaypointIndex].sequence
            inputWaypoints[editingWaypointIndex] = inputWaypoint
        }

        setInputWaypoints(inputWaypoints)
        setEditingWaypointIndex(undefined)
        setInputLat("")
        setInputLon("")
        setInputAlt("")
    }

    function deleteWaypoint(index: number) {
        const newWaypoints = inputWaypoints.filter((value, pIndex) =>
            index !== pIndex)

        newWaypoints.forEach((value, pIndex) => {
            value.sequence = pIndex
        })

        setInputWaypoints(newWaypoints)
    }

    const bottomControls = isPendingSubmit === true ? (<Typography>Loading...</Typography>) : [(<Button variant='text' onClick={() => props.onCancel()}>Cancel</Button>), (<Button variant='contained' disabled={!isValid} onClick={handleSubmit}>Submit</Button>)];

    return (
        <Stack direction="column" spacing={1}>
            <Typography variant="h6">{props.title}</Typography>
            <TextField
                fullWidth
                type="text"
                variant="outlined"
                label="Label"
                disabled={isPendingSubmit}
                value={inputLabel}
                onChange={(e) => setInputLabel(e.target.value)}
            />
            <Box width="100%">
                <List>
                    {inputWaypoints.map((p, index) => (
                        <ListItem>
                            <Stack direction="row" justifyContent="space-between" width="100%">
                                <Typography>{`${p.sequence}: (${p.latitude}, ${p.longitude}, ${p.altitude})`}</Typography>
                                <Stack direction="row" spacing={1}>
                                    <Button
                                        variant="contained"
                                        onClick={() => setEditingWaypointIndex(index)}
                                        disabled={index === editingWaypointIndex}
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        variant="contained"
                                        onClick={() => deleteWaypoint(index)}
                                    >
                                        Delete
                                    </Button>
                                </Stack>
                            </Stack>
                        </ListItem>
                    ))}
                </List>
            </Box>
            <Stack direction="row" spacing={1}>
                <TextField
                    fullWidth
                    type="text"
                    variant="outlined"
                    label="Latitude"
                    disabled={isPendingSubmit}
                    value={inputLat}
                    onChange={(e) => setInputLat(e.target.value)}
                />
                <TextField
                    fullWidth
                    type="text"
                    variant="outlined"
                    label="Longitude"
                    disabled={isPendingSubmit}
                    value={inputLon}
                    onChange={(e: any) => setInputLon(e.target.value)}
                />
                <TextField
                    fullWidth
                    type="text"
                    variant="outlined"
                    label="Altitude"
                    disabled={isPendingSubmit}
                    value={inputAlt}
                    onChange={(e: any) => setInputAlt(e.target.value)}
                />
                <FormControl fullWidth disabled={isPendingSubmit}>
                    <InputLabel id="agl-msl">Measurement</InputLabel>
                    <Select
                        labelId="agl-msl"
                        label="Measurement"
                        disabled={isPendingSubmit}
                        value={inputAltIsAGL}
                        onChange={(event: any) => setInputAltIsAGL(event.target.value)}
                    >
                        <MenuItem value={0}>MSL</MenuItem>
                        <MenuItem value={1}>AGL</MenuItem>
                    </Select>
                </FormControl>
                <Button
                    variant="contained"
                    disabled={!isLatLonValid || isPendingSubmit}
                    onClick={addWaypoint}
                >
                    {editingWaypointIndex === undefined ? "Add" : "Set"}
                </Button>
            </Stack>
            <Stack direction="row" spacing={1} justifyContent="right">{bottomControls}</Stack>
        </Stack>
    );
}

export default EditMissionFlightPathLayout;