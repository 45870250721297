import React from 'react'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import { Typography, Divider } from '@mui/material'

import { DataGrid } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { Operator } from "../../../classes/Operator"
import { OperatorEmergencyReservation } from "../../../classes/OperatorEmergencyReservation"
import {AssetEmergencyReservation} from "../../../classes/AssetEmergencyReservation";

export default function OperatorEmergencyReservationFlatList(props: {
    operatorData: Operator,
    sx?: object
}) {

    const columns = [
        { field: 'isAvailable', headerName: 'Available for Tasking', width: 250 },
        { field: 'timeBegin', headerName: 'Time Reservation Begins', width: 250, valueGetter: (params: any) => { return dayjs(params.value).format('MM/DD/YYYY HH:mm').toString() } },
        { field: 'timeEnd', headerName: 'Time Reservation Ends', width: 250, valueGetter: (params: any) => { return dayjs(params.value).format('MM/DD/YYYY HH:mm').toString() } },
    ];

    return (
        <Paper sx={props.sx}>
            <Stack direction="column" spacing={2} padding={2}>
                <Typography variant="h5">Emergency Reservations</Typography>
                <Divider />
                <Box sx={{ height: 600 }}>
                    <DataGrid rows={props.operatorData.emergencyReservations!.map((e: OperatorEmergencyReservation) => e.objectify())} columns={columns} />
                </Box>
            </Stack>
        </Paper>
    );
}