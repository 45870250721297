import React, {useEffect, useState} from 'react';
import { Modal, Paper } from '@mui/material';
import TaskMissionOrganizationLayout from '../layouts/TaskMissionOrganizationLayout'
import getOrganizations from "../../../data/organization/getOrganizations"
import {Mission} from "../../../classes/Mission";

function TaskMissionOrganizationModal(props: {
    isOpen: boolean,
    onClose: Function,
    missionData: Mission,
    onSubmit: Function
    onNetworkError: Function
}) {
    const [organizationList, setOrganizationList] = useState(undefined)

    useEffect(() => {
        if (props.isOpen) {
            getOrganizations()
                .then((result) => setOrganizationList(result))
                .catch((err) => {
                    console.error(err)
                    props.onNetworkError()
                })
        }
    }, [props.isOpen])

    return (
        <Modal
            open={props.isOpen}
            onClose={() => props.onClose()}
        >
            <Paper
                sx={{
                    position: 'absolute',
                    width: '400px',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    padding: 2
                }}
            >
                <TaskMissionOrganizationLayout
                    title="Task Organization"
                    initInfo={props.missionData}
                    onCancel={() => props.onClose()}
                    onSubmit={props.onSubmit}
                    organizationList={organizationList}
                />
            </Paper>
        </Modal>
    );
}

export default TaskMissionOrganizationModal;