import React from "react"
import {
    Typography,
    Box
} from "@mui/material"

function ScheduleItemTitle(props: { title: string }) {

    return (
        <Box
            sx={{
                width: "350px",
                height: "30px"
            }}
        >
            <Typography>{props.title}</Typography>
        </Box>
    )
}

export default ScheduleItemTitle