import React from 'react';
import { Modal, Paper } from '@mui/material';
import DelayMissionLayout from '../layouts/DelayMissionLayout';
import {Mission} from "../../../classes/Mission";

function DelayMissionModal(props: {
    missionData: Mission,
    isOpen: boolean,
    onClose: Function,
    onSubmit: Function
}) {
    return (
        <Modal
            open={props.isOpen}
            onClose={() => props.onClose()}
        >
            <Paper
                sx={{
                    position: 'absolute',
                    width: '600px',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    padding: 2
                }}
            >
                <DelayMissionLayout
                    title="Delay Mission"
                    initInfo={props.missionData}
                    onCancel={() => props.onClose()}
                    onSubmit={props.onSubmit}
                />
            </Paper>
        </Modal>
    );
}

export default DelayMissionModal;