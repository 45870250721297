import dayjs, { Dayjs } from "dayjs"

export class MissionFlag {
    id?: number
    missionId: number = 0
    type: string = ""
    severity: number = 0
    raised: Dayjs = dayjs()
    cleared?: Dayjs
    conflictingMissionId?: number
    operatorId?: number
    assetId?: number

    getSeverityCategory(): string {
        if (this.severity === 1) {
            return "error"
        } else if (this.severity === 2) {
            return "warning"
        }

        return ""
    }

    getString(): string {
        if (this.type === "") {
            return ""
        } else if (this.type === "NO_MISSION_NUMBER") {
            return "No mission number is assigned."
        } else if (this.type === "NO_MISSION_CALLSIGN") {
            return "No mission callsign is assigned."
        } else if (this.type === "NO_ASSIGNED_ORGANIZATION") {
            return "No organization is assigned to execute this mission."
        } else if (this.type === "NO_ASSIGNED_OPERATOR") {
            return "No operators are assigned to execute this mission."
        } else if (this.type === "OPERATOR_NOT_READY") {
            return "An assigned operator is not ready."
        } else if (this.type === "OPERATOR_RESERVED_FOR_EMERGENCY_DISPATCH") {
            return "An assigned operator is reserved for emergency dispatch only."
        } else if (this.type === "NO_ASSIGNED_ASSET") {
            return "No assets are assigned to execute this mission."
        } else if (this.type === "ASSET_NOT_READY") {
            return "An assigned asset is not ready."
        } else if (this.type === "ASSET_RESERVED_FOR_EMERGENCY_DISPATCH") {
            return "An assigned asset is reserved for emergency dispatch only."
        } else if (this.type === "MISSION_SCHEDULE_CONFLICT") {
            return "A scheduling conflict was found."
        } else if (this.type === "NO_ASSIGNED_RPIC") {
            return "No operators are designated as RPIC."
        } else if (this.type === "MULTIPLE_ASSIGNED_RPIC") {
            return "Multiple operators are designated as RPIC."
        } else if (this.type === "NO_ASSIGNED_VO") {
            return "No operators are assigned as VO."
        } else if (this.type === "NO_OPERATING_AREA") {
            return "No operating area exists for this mission."
        } else if (this.type === "NO_TAKEOFF_LANDING_POINTS") {
            return "No takeoff/landing points."
        } else if (this.type === "POINT_OUTSIDE_OF_OPERATING_AREA") {
            return "A point was found outside of this mission's operating area."
        } else if (this.type === "FLIGHT_PATH_OUTSIDE_OF_OPERATING_AREA") {
            return "A flight path was found outside of this mission's operating area."
        } else if (this.type === "OPERATING_AREA_COLLISION") {
            return "This mission's operating area collides with another mission's operating area."
        } else if (this.type === "NO_DECONFLICTIONS") {
            return "No deconflictions exist for this mission."
        } else if (this.type === "NONCONCUR_DECONFLICTIONS") {
            return "Some deconflictions have been marked non-concur."
        } else if (this.type === "PENDING_DECONFLICTIONS") {
            return "Some deconflictions are pending."
        }

        return this.type
    }

    parseObject(input: any) {
        this.id = input.id
        this.missionId = input.missionId
        this.type = input.type
        this.severity = input.severity
        this.raised = dayjs(input.raised)

        if (input.cleared !== undefined) {
            this.cleared = dayjs(input.cleared)
        }

        this.conflictingMissionId = input.conflictingMissionId
        this.operatorId = input.operatorId
        this.assetId = input.assetId
    }

    objectify(): object {
        return {
            id: this.id,
            missionId: this.missionId,
            type: this.type,
            severity: this.severity,
            raised: this.raised.format(),
            cleared: this.cleared?.format(),
            conflictingMissionId: this.conflictingMissionId,
            operatorId: this.operatorId,
            assetId: this.assetId
        }
    }
}