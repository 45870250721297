import React from 'react';
import { useState } from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import dayjs, {Dayjs} from 'dayjs';
import Joi from 'joi';
import {OperatorReadinessProblem} from "../../../classes/OperatorReadinessProblem"

function EditReadinessProblemLayout(props: {
    title: string,
    initData: OperatorReadinessProblem,
    onCancel: Function,
    onSubmit: Function
}) {

    const [isPendingSubmit, setPendingSubmit] = useState<boolean>(false);
    const [inputSubject, setInputSubject] = useState<string>("");
    const [inputDescription, setInputDescription] = useState<string>("");
    const [inputStarted, setInputStarted] = useState<Dayjs>(dayjs());
    const [inputResolutionExpected, setInputResolutionExpected] = useState<Dayjs>(dayjs());

    const isSubjectValid = Joi.string().min(1).max(100).required().validate(inputSubject);
    const isDescriptionValid = Joi.string().max(2000).required().validate(inputDescription);
    const isStartedValid = Joi.date().required().validate(inputStarted.format());
    const isResolutionExpectedValid = Joi.date().required().validate(inputResolutionExpected.format());
    const isValid = (!isSubjectValid.error && !isDescriptionValid.error && !isStartedValid.error && !isResolutionExpectedValid.error);

    function handleSubmit() {
        setPendingSubmit(true);

        const inputProblem = props.initData
        inputProblem.subject = inputSubject
        inputProblem.description = inputDescription
        inputProblem.started = inputStarted
        inputProblem.resolutionExpected = inputResolutionExpected

        props.onSubmit(inputProblem)
    }

    const bottomControls = isPendingSubmit === true ? (<Typography>Loading...</Typography>) : [(<Button variant='text' onClick={() => props.onCancel()}>Cancel</Button>), (<Button variant='contained' disabled={!isValid} onClick={handleSubmit}>Submit</Button>)];

    return (
        <Stack direction="column" spacing={1}>
            <Typography variant="h6">{props.title}</Typography>
            <TextField
                type="text"
                variant="outlined"
                label="Subject"
                error={inputSubject.length > 0 && isSubjectValid.error !== undefined}
                disabled={isPendingSubmit}
                onChange={(e) => setInputSubject(e.target.value)}
            />
            <TextField
                type="text"
                variant="outlined"
                label="Description"
                multiline
                rows={4}
                error={inputDescription.length > 0 && isDescriptionValid.error !== undefined}
                disabled={isPendingSubmit}
                onChange={(e) => setInputDescription(e.target.value)}
            />
            <DatePicker
                label="Date Problem Started"
                slotProps={{
                    textField: {
                        error: isStartedValid.error !== undefined
                    },
                }}
                disabled={isPendingSubmit}
                value={inputStarted}
                onChange={(event: any) => setInputStarted(event)}
            />
            <DatePicker
                label="Date Resolution Expected"
                slotProps={{
                    textField: {
                        error: isResolutionExpectedValid.error !== undefined
                    },
                }}
                disabled={isPendingSubmit}
                value={inputResolutionExpected}
                onChange={(event: any) => setInputResolutionExpected(event)}
            />
            <Stack direction="row" spacing={1} justifyContent="right">{bottomControls}</Stack>
        </Stack>
    );
}

export default EditReadinessProblemLayout;