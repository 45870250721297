import React from 'react';
import { Stack } from '@mui/material';

import OperatorTitlePanel from '../components/OperatorTitlePanel';
import {Operator} from "../../../classes/Operator";

function OperatorSummaryLayout(props: { operatorData: Operator }) {

    return (
        <Stack direction="column" padding={2} spacing={2}>
            <OperatorTitlePanel sx={{ width: '100%' }} operatorData={props.operatorData} />
        </Stack>
    );
}

export default OperatorSummaryLayout;