import React from "react"
import { Modal, Paper } from "@mui/material"
import EditMissionOperatingAreaLayout from "../layouts/EditMissionOperatingAreaLayout"
import { OperatingArea } from "../../../classes/OperatingArea"
import { OperatingAreaPoint } from "../../../classes/OperatingAreaPoint"
import {Mission} from "../../../classes/Mission";

export default function EditMissionOperatingAreaModal(props: {
    isOpen: boolean,
    missionData: Mission,
    selectedOperatingAreaId?: number,
    onClose: Function,
    onSubmit: Function
}) {

    if (props.selectedOperatingAreaId === undefined) {
        return null
    }

    function findOperatingArea(): OperatingArea {
        for (const area of props.missionData.operatingAreas!) {
            if (area.id === props.selectedOperatingAreaId) {
                return area
            }
        }

        return new OperatingArea()
    }

    return (
        <Modal
            open={props.isOpen}
            onClose={() => props.onClose()}
        >
            <Paper
                sx={{
                    position: 'absolute',
                    width: '800px',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    padding: 2
                }}
            >
                <EditMissionOperatingAreaLayout
                    title="Edit Operating Area"
                    initData={findOperatingArea()}
                    onCancel={() => props.onClose()}
                    onSubmit={props.onSubmit}
                />
            </Paper>
        </Modal>
    );
}