import config from '../config';

import getCompleteApiUrl from '../getCompleteApiUrl';

async function getWelcomeMessage() {

    return fetch(getCompleteApiUrl(config, `/sysinfo/welcome`), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    }).then((response) => {
        if (response.status === 200) {
            return response.json();
        } else {
            throw 1
        }
    });
}

export default getWelcomeMessage;