import React from 'react';
import { useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import Joi from 'joi';
import {Asset} from "../../../classes/Asset";
import {Office} from "../../../classes/Office";
import {Product} from "../../../classes/Product";

function EditAssetLayout(props: {
    title: string,
    initInfo: Asset,
    officeList?: Office[],
    productList?: Product[],
    onSubmit: Function,
    onCancel: Function
}) {

    const [isPendingSubmit, setPendingSubmit] = useState(false);
    const [inputProductId, setInputProductId] = useState(props.initInfo.productId);
    const [inputOfficeId, setInputOfficeId] = useState(props.initInfo.officeId);
    const [inputSerialNumber, setInputSerialNumber] = useState(props.initInfo.serialNumber);
    const [inputSerialNumberRemoteId, setInputSerialNumberRemoteId] = useState(props.initInfo.serialNumberRID)
    const [inputCallsign, setInputCallsign] = useState(props.initInfo.callsign);

    const isProductIdValid = Joi.number().integer().positive().required().validate(inputProductId);
    const isOfficeIdValid = Joi.number().integer().positive().required().validate(inputOfficeId);
    const isSerialNumberValid = Joi.string().min(1).max(100).required().validate(inputSerialNumber);
    const isSerialNumberRemoteIdValid = Joi.string().min(1).max(100).required().validate(inputSerialNumberRemoteId)
    const isCallsignValid = Joi.string().min(1).max(30).required().validate(inputCallsign);
    const isValid = (!isProductIdValid.error && !isOfficeIdValid.error && !isSerialNumberValid.error && !isSerialNumberRemoteIdValid.error && !isCallsignValid.error);

    function handleSubmit() {
        setPendingSubmit(true)

        const inputAsset = props.initInfo
        inputAsset.productId = inputProductId
        inputAsset.officeId = inputOfficeId
        inputAsset.serialNumber = inputSerialNumber
        inputAsset.serialNumberRID = inputSerialNumberRemoteId
        inputAsset.callsign = inputCallsign

        props.onSubmit(inputAsset)
    }

    const bottomControls = isPendingSubmit === true ? (<Typography>Loading...</Typography>) : [(<Button variant='text' onClick={() => props.onCancel()}>Cancel</Button>), (<Button variant='contained' disabled={!isValid} onClick={handleSubmit}>Submit</Button>)];

    if (props.officeList === undefined) {
        return (<Typography variant="h6">Loading...</Typography>)
    } else if (props.productList === undefined) {
        return (<Typography variant="h6">Loading...</Typography>)
    }

    props.productList!.map((d: Product) => console.log(d.objectify()))

    return (
        <Stack direction="column" spacing={2}>
            <Typography variant="h6">{props.title}</Typography>
            <FormControl fullWidth disabled={isPendingSubmit}>
                <InputLabel id="product-select">Select Product</InputLabel>
                <Select
                    labelId="product-select"
                    label="Select Product"
                    value={inputProductId}
                    onChange={(event: any) => setInputProductId(event.target.value)}
                >
                    {props.productList.map((a: Product) => (<MenuItem value={a.id}>{a.name}</MenuItem>))}
                </Select>
            </FormControl>
            <FormControl fullWidth disabled={isPendingSubmit}>
                <InputLabel id="office-select">Select Office</InputLabel>
                <Select
                    labelId="office-select"
                    label="Select Office"
                    value={inputOfficeId}
                    onChange={(event: any) => setInputOfficeId(event.target.value)}
                >
                    {props.officeList.map((p: Office) => (<MenuItem value={p.id}>{`${p.name} (${p.symbol})`}</MenuItem>))}
                </Select>
            </FormControl>
            <TextField
                type="text"
                variant="outlined"
                label="Serial Number"
                disabled={isPendingSubmit}
                value={inputSerialNumber}
                onChange={(e) => setInputSerialNumber(e.target.value)}
            />
            <TextField
                type="text"
                variant="outlined"
                label="Remote ID"
                disabled={isPendingSubmit}
                value={inputSerialNumberRemoteId}
                onChange={(e) => setInputSerialNumberRemoteId(e.target.value)}
            />
            <TextField
                type="text"
                variant="outlined"
                label="Callsign"
                disabled={isPendingSubmit}
                value={inputCallsign}
                onChange={(e) => setInputCallsign(e.target.value)}
            />
            <Stack direction="row" spacing={1} justifyContent="right">{bottomControls}</Stack>
        </Stack>
    );
}

export default EditAssetLayout;