import React from 'react';
import { Modal, Box, Paper } from '@mui/material';
import EditManufacturerLayout from '../layouts/EditManufacturerLayout';
import {Manufacturer} from "../../../classes/Manufacturer";

function NewManufacturerModal(props: {
    isOpen: boolean,
    onSubmit: Function,
    onClose: Function,
    onNetworkError: Function
}) {

    const newManufacturer = new Manufacturer()

    return (
        <Modal
            open={props.isOpen}
            onClose={() => props.onClose()}
        >
            <Paper
                sx={{
                    position: 'absolute',
                    width: '600px',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    padding: 2
                }}
            >
                <EditManufacturerLayout
                    title="Create Manufacturer"
                    initInfo={newManufacturer}
                    onCancel={() => props.onClose()}
                    onSubmit={props.onSubmit}
                />
            </Paper>
        </Modal>
    );
}

export default NewManufacturerModal;