import React from 'react';
import { useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import Joi from 'joi';
import {Manufacturer} from "../../../classes/Manufacturer";
import {Product} from "../../../classes/Product";

function EditProductLayout(props: {
    title: string,
    initInfo: Product,
    manufacturerList?: Manufacturer[],
    onSubmit: Function,
    onCancel: Function
}) {

    const [isPendingSubmit, setPendingSubmit] = useState(false);
    const [inputManufacturerId, setInputManufacturerId] = useState(props.initInfo.manufacturerId);
    const [inputName, setInputName] = useState(props.initInfo.name);

    const isManufacturerIdValid = Joi.number().integer().positive().required().validate(inputManufacturerId);
    const isNameValid = Joi.string().min(1).max(100).required().validate(inputName);
    const isValid = (!isManufacturerIdValid.error && !isNameValid.error);

    function handleSubmit() {
        setPendingSubmit(true);

        const inputProduct = props.initInfo
        inputProduct.manufacturerId = inputManufacturerId
        inputProduct.name = inputName

        props.onSubmit(inputProduct)
    }

    const bottomControls = isPendingSubmit === true ? (<Typography>Loading...</Typography>) : [(<Button variant='text' onClick={() => props.onCancel()}>Cancel</Button>), (<Button variant='contained' disabled={!isValid} onClick={handleSubmit}>Submit</Button>)];

    if (props.manufacturerList === undefined) {
        return (<Typography variant="h6">Loading...</Typography>)
    }

    return (
        <Stack direction="column" spacing={2}>
            <Typography variant="h6">{props.title}</Typography>
            <FormControl fullWidth disabled={isPendingSubmit}>
                <InputLabel id="manufacturer-select">Select Manufacturer</InputLabel>
                <Select
                    labelId="manufacturer-select"
                    label="Select Manufacturer"
                    value={inputManufacturerId}
                    onChange={(event: any) => setInputManufacturerId(event.target.value)}
                >
                    {props.manufacturerList.map((p: Manufacturer) => (<MenuItem value={p.id}>{p.name}</MenuItem>))}
                </Select>
            </FormControl>
            <TextField
                type="text"
                variant="outlined"
                label="Product Name"
                disabled={isPendingSubmit}
                value={inputName}
                onChange={(e) => setInputName(e.target.value)}
            />
            <Stack direction="row" spacing={1} justifyContent="right">{bottomControls}</Stack>
        </Stack>
    );
}

export default EditProductLayout;