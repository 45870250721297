import Cookies from "universal-cookie"

import config from "../config"
import getCompleteApiUrl from "../getCompleteApiUrl"

import {Operation} from "../../classes/Operation"
import {ApiCallFailure} from "../../classes/exception/ApiCallFailure";

export default async function createOperation(operation: Operation) {
    const cookies = new Cookies()

    if (cookies.get("accessToken") === undefined) {
        const error = new ApiCallFailure()
        error.connectionSuccessful = false
        error.errorType = "NO_ACCESS_TOKEN"

        throw error
    }

    var res: Response
    try {
        res = await fetch(getCompleteApiUrl(config, `/operations`), {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${cookies.get("accessToken")}`
            },
            body: JSON.stringify({
                organizationId: operation.organizationId,
                name: operation.name,
                purpose: operation.purpose,
                timeStart: operation.timeStart.format(),
                timeEnd: operation.timeEnd.format()
            }),
        })
    } catch (error) {
        const apiFailure = new ApiCallFailure()
        apiFailure.connectionSuccessful = false

        throw apiFailure
    }
    
    if (res!.status === 201) {
        const responseObject = await res!.json()
        
        const responseOperation = new Operation()
        responseOperation.parseObject(responseObject)
        
        return responseOperation
    }

    const callFailure = new ApiCallFailure()
    callFailure.connectionSuccessful = true
    callFailure.responseCode = res!.status
    callFailure.errorType = "REQUEST_REJECTED"

    throw callFailure
}