import React from "react"
import { Typography, CircularProgress, Stack, Paper } from "@mui/material"
import LoadingPanel from "../components/LoadingPanel"
import { SysInfoBannerParams } from "../../classes/SysInfoBannerParams"

import PanelPageLayout from "./PanelPageLayout"

export default function LoadingPanelPageLayout(props: { sysInfoBanner: SysInfoBannerParams }) {

    const panelStyle = {
        position: "absolute",
        width: "200px",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        padding: 1
    }

    return (
        <PanelPageLayout sysInfoBanner={props.sysInfoBanner}>
            <LoadingPanel panelStyle={panelStyle} />
        </PanelPageLayout>
    )
}