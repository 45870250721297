import React, {useState} from "react"
import Stack from "@mui/material/Stack"
import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import {Typography, Divider, ToggleButton, ToggleButtonGroup} from "@mui/material"

import { DataGrid } from "@mui/x-data-grid"
import dayjs from "dayjs"
import {OperatorEmergencyReservation} from "../../../classes/OperatorEmergencyReservation";

function OperatorEmergencyReservationFlatList(props: {
    activeReservations: OperatorEmergencyReservation[],
    futureReservations: OperatorEmergencyReservation[],
    selectedReservationObject?: OperatorEmergencyReservation,
    onReservationSelectionChanged: Function,
    sx?: object
}) {

    const [selectedView, setSelectedView] = useState("active")

    const columns = [
        { field: "callsign", headerName: "Operator Callsign/Name", width: 350, valueGetter: (params: any) => { return `${params.row.operatorObject.callsign} (${params.row.operatorObject.rank} ${params.row.operatorObject.firstName} ${params.row.operatorObject.lastName})` } },
        { field: "isAvailable", headerName: "Available for Tasking", width: 250 },
        { field: "timeBegin", headerName: "Time Reservation Begins", width: 250, valueGetter: (params: any) => { return dayjs(params.value).format("MM/DD/YYYY HH:mm").toString() } },
        { field: "timeEnd", headerName: "Time Reservation Ends", width: 250, valueGetter: (params: any) => { return dayjs(params.value).format("MM/DD/YYYY HH:mm").toString() } },
    ]

    function getSelectedView() {
        if (selectedView === "active") {
            return props.activeReservations.map((r: OperatorEmergencyReservation) => r.objectify())
        } else if (selectedView === "future") {
            return props.futureReservations.map((r: OperatorEmergencyReservation) => r.objectify())
        }

        return []
    }

    return (
        <Paper sx={props.sx}>
            <Stack direction="column" spacing={2} padding={2}>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h5">Operator Reservations</Typography>
                    <ToggleButtonGroup
                        size="small"
                        color="primary"
                        value={selectedView}
                        exclusive
                        onChange={(e: any) => setSelectedView(e.target.value)}
                    >
                        <ToggleButton value="active">Active</ToggleButton>
                        <ToggleButton value="future">Future</ToggleButton>
                    </ToggleButtonGroup>
                </Stack>
                <Divider />
                <Box sx={{ height: 600 }}>
                    <DataGrid
                        rows={getSelectedView()}
                        columns={columns}
                        rowSelectionModel={props.selectedReservationObject === undefined ? [] : props.selectedReservationObject!.id}
                        onRowSelectionModelChange={(e) => props.onReservationSelectionChanged(e[0])}
                    />
                </Box>
            </Stack>
        </Paper>
    )
}

export default OperatorEmergencyReservationFlatList