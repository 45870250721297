import React from 'react';
import { Paper, Stack, Box } from '@mui/material';

import { DataGrid } from '@mui/x-data-grid';
import {Mission} from "../../../classes/Mission";
import {MissionNote} from "../../../classes/MissionNote";

function MissionNotePanel(props: {
    missionData: Mission,
    sx?: object
}) {

    const columns = [
        { field: 'timestamp', headerName: 'Timestamp', width: 200 },
        { field: 'authorId', headerName: 'Author', width: 200 },
        { field: 'note', headerName: 'Note', width: 800 }
    ];

    return (
        <Paper sx={props.sx}>
            <Stack direction="column" spacing={1} padding={2}>
            <Box sx={{ height: 400 }}>
                    <DataGrid
                        rows={props.missionData.notes!.map((note: MissionNote) => note.objectify())}
                        columns={columns}
                    />
                </Box>
            </Stack>
        </Paper>
    );
}

export default MissionNotePanel;