import React from 'react';
import { Paper, Stack, Box } from '@mui/material';

import { DataGrid } from '@mui/x-data-grid';
import {MissionObjective} from "../../../classes/MissionObjective";
import {Mission} from "../../../classes/Mission";

function MissionObjectivePanel(props: {
    missionData: Mission,
    objectiveData?: MissionObjective,
    onObjectiveSelectionChanged: Function,
    sx?: object
}) {

    const columns = [
        { field: 'description', headerName: 'Description', width: 500 },
        { field: 'accomplished', headerName: 'Accomplished?', width: 150 }
    ];

    return (
        <Paper sx={props.sx}>
            <Stack direction="column" spacing={1} padding={2}>
            <Box sx={{ height: 400 }}>
                    <DataGrid
                        rows={props.missionData.objectives!.map((objective: MissionObjective) => objective.objectify())}
                        columns={columns}
                        rowSelectionModel={props.objectiveData === undefined ? [] : props.objectiveData!.id}
                        onRowSelectionModelChange={(e) => props.onObjectiveSelectionChanged(e)}
                    />
                </Box>
            </Stack>
        </Paper>
    );
}

export default MissionObjectivePanel;