import { Dayjs } from "dayjs"
import {Organization} from "./Organization";
import {OperationObjective} from "./OperationObjective";
import {MissionObjective} from "./MissionObjective";
import dayjs from "dayjs"

export class Operation {
    id?: number
    organizationId: number = 0
    name: string = ""
    purpose: string = ""
    timeStart: Dayjs = dayjs()
    timeEnd: Dayjs = dayjs()

    organizationObject?: Organization
    objectives?: OperationObjective[]

    parseObject(input: any) {
        this.id = input.id
        this.organizationId = input.organizationId
        this.name = input.name
        this.purpose = input.purpose
        this.timeStart = dayjs(input.timeStart)
        this.timeEnd = dayjs(input.timeEnd)

        if (input.organizationObject !== undefined) {
            const o = new Organization()
            o.parseObject(input.organizationObject)
            this.organizationObject = o
        }

        if (input.objectives !== undefined) {
            this.objectives = input.objectives.map((d: any) => {
                const objective = new MissionObjective()
                objective.parseObject(d)
                return objective
            })
        }
    }

    objectify(): object {
        return {
            id: this.id,
            organizationId: this.organizationId,
            name: this.name,
            purpose: this.purpose,
            timeStart: this.timeStart.format(),
            timeEnd: this.timeEnd.format(),
            organizationObject: this.organizationObject?.objectify(),
            objectives: this.objectives?.map((o: OperationObjective) => o.objectify())
        }
    }
}