import React from "react"
import { ThemeProvider, createTheme } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"

import PrimaryRouter from "../routers/PrimaryRouter"

const darkTheme = createTheme({
    palette: {
        mode: "dark",
        background: {
            default: "#0A0A0A",
            paper: "#111111"
        },
        primary: {
            main: "#006699",
        },
        secondary: {
            main: "#004455",
        },
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                colorPrimary: {
                    backgroundColor: "#003348"
                },
            },
        },
    },
});

const lightTheme = createTheme({
    palette: {
        mode: "light",
        background: {
            default: "#E0E0E0",
            paper: "#EEEEEE"
        },
        primary: {
            main: "#006699",
        },
        secondary: {
            main: "#004455",
        },
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                colorPrimary: {
                    backgroundColor: "#006699"
                },
            },
        },
    },
});

function AppProvider() {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ThemeProvider theme={darkTheme}>
                <CssBaseline />
                <PrimaryRouter />
            </ThemeProvider>
        </LocalizationProvider>
    );
}

export default AppProvider