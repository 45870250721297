import dayjs, { Dayjs } from "dayjs"
import { Mission } from "./Mission"
import { Operator } from "./Operator"
import { OperatorReadiness } from "./OperatorReadiness"
import { OperatorEmergencyReservation } from "./OperatorEmergencyReservation"

export class OperatorAvailabilityStatus {
    static STATUS_UNSCHEDULED = 1
    static STATUS_RESERVED_FOR_EMERGENCY_DISPATCH_AVAILABLE = 2
    static STATUS_RESERVED_FOR_EMERGENCY_DISPATCH_UNAVAILABLE = 4
    static STATUS_CURRENTLY_EXECUTING_MISSION = 5
    static STATUS_NOT_READY = 6

    operatorId: number = 0
    statusCode: number = 0

    operatorObject?: Operator
    emergencyReservations?: OperatorEmergencyReservation[]
    currentMissions?: Mission[]

    parseObject(input: any) {
        this.operatorId = input.operatorId
        this.statusCode = input.statusCode

        if (input.operatorObject !== undefined) {
            this.operatorObject = new Operator()
            this.operatorObject.parseObject(input.operatorObject)
        }

        this.emergencyReservations = input.emergencyReservations?.map((r: any) => {
            const reservationObject = new OperatorEmergencyReservation()
            reservationObject.parseObject(r)
            return reservationObject
        })

        this.currentMissions = input.currentMissions?.map((r: any) => {
            const missionObject = new Mission()
            missionObject.parseObject(r)
            return missionObject
        })
    }

    objectify(): object {
        return {
            id: this.operatorId,
            operatorId: this.operatorId,
            statusCode: this.statusCode,
            operatorObject: this.operatorObject?.objectify(),
            emergencyReservations: this.emergencyReservations?.map((r) => r.objectify()),
            currentMissions: this.currentMissions?.map((m) => m.objectify())
        }
    }
}