import React from 'react';
import { useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import Joi from 'joi';
import {Manufacturer} from "../../../classes/Manufacturer";

function EditManufacturerLayout(props: {
    title: string,
    initInfo: Manufacturer,
    onSubmit: Function,
    onCancel: Function
}) {

    const [isPendingSubmit, setPendingSubmit] = useState(false);
    const [inputName, setInputName] = useState(props.initInfo.name);

    const isNameValid = Joi.string().min(1).max(100).required().validate(inputName);
    const isValid = (!isNameValid.error);

    function handleSubmit() {
        setPendingSubmit(true);

        const inputManufacturer = props.initInfo
        inputManufacturer.name = inputName

        props.onSubmit(inputManufacturer)
    }

    const bottomControls = isPendingSubmit === true ? (<Typography>Loading...</Typography>) : [(<Button variant='text' onClick={() => props.onCancel()}>Cancel</Button>), (<Button variant='contained' disabled={!isValid} onClick={handleSubmit}>Submit</Button>)];

    return (
        <Stack direction="column" spacing={2}>
            <Typography variant="h6">{props.title}</Typography>
            <TextField
                type="text"
                variant="outlined"
                label="Manufacturer Name"
                disabled={isPendingSubmit}
                value={inputName}
                onChange={(e) => setInputName(e.target.value)}
            />
            <Stack direction="row" spacing={1} justifyContent="right">{bottomControls}</Stack>
        </Stack>
    );
}

export default EditManufacturerLayout;