import Cookies from "universal-cookie"
import { sha512 } from "js-sha512"

import config from "../config"
import getCompleteApiUrl from "../getCompleteApiUrl"
import {ApiCallFailure} from "../../classes/exception/ApiCallFailure";

export default async function login(credentialsObject: { username: string, passphrase: string }) {
    const cookies = new Cookies()

    const passphraseHash = sha512.update(credentialsObject.passphrase).hex()

    var res: Response
    try {
        res = await fetch(getCompleteApiUrl(config, "/auth/login"), {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                username: credentialsObject.username,
                passphraseHash,
            })
        })
    } catch (error) {
        const apiFailure = new ApiCallFailure()
        apiFailure.connectionSuccessful = false

        throw apiFailure
    }

    // TODO: Process 'no account' and 'incorrect password' responses from the Api.
    if (res!.status === 200) {
        const responseObject = await res!.json()

        if (responseObject.token !== undefined) {
            cookies.set("accessToken", responseObject.token, { path: "/" })
        }

        return responseObject
    } else if (res!.status === 401) {
        const responseObject = await res!.json()

        return responseObject
    } else if (res!.status === 403) {
        const responseObject = await res!.json()

        return responseObject
    }

    const callFailure = new ApiCallFailure()
    callFailure.connectionSuccessful = true
    callFailure.responseCode = res!.status
    callFailure.errorType = "REQUEST_REJECTED"

    throw callFailure
}