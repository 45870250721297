import { OperatorReadinessProblem } from "./OperatorReadinessProblem"
import { OperatorReadinessRequirement } from "./OperatorReadinessRequirement"

export class OperatorReadiness {
    problems: OperatorReadinessProblem[] = []
    requirements: OperatorReadinessRequirement[] = []
    numberActiveProblems?: number = 0
    numberOutstandingRequirements?: number = 0

    parseObject(input: any) {
        this.problems = input.problems.map((problem: any) => {
            const p = new OperatorReadinessProblem()
            p.parseObject(problem)
            return p
        })

        this.requirements = input.requirements.map((requirement: any) => {
            const r = new OperatorReadinessRequirement()
            r.parseObject(requirement)
            return r
        })

        this.numberActiveProblems = input.numberActiveProblems
        this.numberOutstandingRequirements = input.numberOutstandingRequirements
    }

    objectify(): object {
        return {
            problems: this.problems.map((problem: OperatorReadinessProblem) => problem.objectify()),
            requirements: this.requirements.map((requirement: OperatorReadinessRequirement) => requirement.objectify()),
            numberActiveProblems: this.numberActiveProblems,
            numberOutstandingRequirements: this.numberOutstandingRequirements
        }
    }
}