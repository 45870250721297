import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import MessageLayout from '../layouts/MessageLayout';

import config from '../../../data/config'
import getCompleteUiUrl from '../../../data/getCompleteUiUrl'
import getWelcomeMessage from '../../../data/sysinfo/getWelcomeMessage'
import getBanner from "../../../data/sysinfo/getBanner"

import { SysInfoBannerParams } from "../../../classes/SysInfoBannerParams"

export default function Welcome() {
    const navigate = useNavigate();

    const [bannerData, setBannerData] = useState<SysInfoBannerParams | undefined>(undefined)
    const [welcomeMessage, setWelcomeMessage] = useState<any>(undefined)

    useEffect(() => {
        if (bannerData === undefined) {
            getBanner().then((d) => setBannerData(d))
        }

        if (welcomeMessage === undefined) {
            getWelcomeMessage().then((d) => setWelcomeMessage(d))
        }
    }, [])

    if (bannerData === undefined || welcomeMessage === undefined) {
        return null
    }

    return (
        <MessageLayout
            sysInfoBanner={bannerData}
            subject={welcomeMessage.title}
            message={welcomeMessage.message}
            onSubmit={() => navigate(getCompleteUiUrl(config, "/login"))}
        />
    )
}