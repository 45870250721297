import React, { useState } from "react"
import {
    Stack,
    Typography,
    Button,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem, FormControlLabel, Checkbox
} from "@mui/material"
import Joi from "joi"

import { Point } from "../../../classes/Point"

export default function EditMissionPointLayout(props: {
    title: string,
    initData: Point,
    onSubmit: Function,
    onCancel: Function
}) {

    const [isPendingSubmit, setPendingSubmit] = useState<boolean>(false)
    const [inputLabel, setInputLabel] = useState<string>(props.initData.label)
    const [inputIsTakeoffPoint, setInputIsTakeoffPoint] = useState<boolean>(props.initData.isTakeoffPoint)
    const [inputIsLandingPoint, setInputIsLandingPoint] = useState<boolean>(props.initData.isLandingPoint)
    const [inputLat, setInputLat] = useState<string>(props.initData.latitude.toString())
    const [inputLon, setInputLon] = useState<string>(props.initData.longitude.toString())
    const [inputAlt, setInputAlt] = useState<string>(props.initData.altitude.toString())
    const [inputAltIsAGL, setInputAltIsAGL] = useState<number>(props.initData.isAltitudeAGL ? 1 : 0)

    const isLabelValid = Joi.string().min(0).max(100).required().validate(inputLabel)
    const isLatValid = Joi.string().min(1).required().validate(inputLat)
    const isLonValid = Joi.string().min(1).required().validate(inputLon)
    const isAltValid = Joi.string().min(1).required().validate(inputAlt)
    const isValid = (!isLabelValid.error && !isLatValid.error && !isLonValid.error && !isAltValid.error)

    function handleSubmit() {
        setPendingSubmit(true)

        const inputPoint = props.initData
        inputPoint.label = inputLabel
        inputPoint.isTakeoffPoint = inputIsTakeoffPoint
        inputPoint.isLandingPoint = inputIsLandingPoint
        inputPoint.latitude = Number.parseFloat(inputLat)
        inputPoint.longitude = Number.parseFloat(inputLon)
        inputPoint.altitude = Number.parseFloat(inputAlt)
        inputPoint.isAltitudeAGL = (inputAltIsAGL === 1)

        props.onSubmit(inputPoint)
    }

    const bottomControls = isPendingSubmit === true ? (<Typography>Loading...</Typography>) : [(<Button variant='text' onClick={() => props.onCancel()}>Cancel</Button>), (<Button variant='contained' disabled={!isValid} onClick={handleSubmit}>Submit</Button>)];

    return (
        <Stack direction="column" spacing={1}>
            <Typography variant="h6">{props.title}</Typography>
            <TextField
                fullWidth
                type="text"
                variant="outlined"
                label="Label"
                disabled={isPendingSubmit}
                value={inputLabel}
                onChange={(e) => setInputLabel(e.target.value)}
            />
            <Stack direction="row" spacing={1}>
                <TextField
                    fullWidth
                    type="text"
                    variant="outlined"
                    label="Latitude"
                    disabled={isPendingSubmit}
                    value={inputLat}
                    onChange={(e) => setInputLat(e.target.value)}
                />
                <TextField
                    fullWidth
                    type="text"
                    variant="outlined"
                    label="Longitude"
                    disabled={isPendingSubmit}
                    value={inputLon}
                    onChange={(e: any) => setInputLon(e.target.value)}
                />
                <TextField
                    fullWidth
                    type="text"
                    variant="outlined"
                    label="Altitude"
                    disabled={isPendingSubmit}
                    value={inputAlt}
                    onChange={(e: any) => setInputAlt(e.target.value)}
                />
                <FormControl fullWidth disabled={isPendingSubmit}>
                    <InputLabel id="agl-msl">Measurement</InputLabel>
                    <Select
                        labelId="agl-msl"
                        label="Measurement"
                        disabled={isPendingSubmit}
                        value={inputAltIsAGL}
                        onChange={(event: any) => setInputAltIsAGL(event.target.value)}
                    >
                        <MenuItem value={0}>MSL</MenuItem>
                        <MenuItem value={1}>AGL</MenuItem>
                    </Select>
                </FormControl>
            </Stack>
            <Stack direction="row" spacing={4} justifyContent="center">
                <FormControlLabel
                    label="Takeoff Point"
                    disabled={isPendingSubmit}
                    control={<Checkbox checked={inputIsTakeoffPoint} onChange={(event: any) => setInputIsTakeoffPoint(event.target.checked)} />}
                />
                <FormControlLabel
                    label="Landing Point"
                    disabled={isPendingSubmit}
                    control={<Checkbox checked={inputIsLandingPoint} onChange={(event: any) => setInputIsLandingPoint(event.target.checked)} />}
                />
            </Stack>
            <Stack direction="row" spacing={1} justifyContent="right">{bottomControls}</Stack>
        </Stack>
    )
}