import React, {useState, useEffect, useLayoutEffect} from "react"
import {Stack, Typography} from "@mui/material"

import AssetEmergencyReservationFlatList from "../components/AssetEmergencyReservationFlatList"
import AssetEmergencyReservationActionBar from "../components/AssetEmergencyReservationActionBar"
import createAssetEmergencyReservation from "../../../data/asset/createAssetEmergencyReservation"
import getActiveAssetEmergencyReservations from "../../../data/asset/getActiveAssetEmergencyReservations"
import getFutureAssetEmergencyReservations from "../../../data/asset/getFutureAssetEmergencyReservations"
import markAssetEmergencyReservationAvailableById from "../../../data/asset/markAssetEmergencyReservationAvailableById"
import markAssetEmergencyReservationUnavailableById from "../../../data/asset/markAssetEmergencyReservationUnavailableById"
import NewAssetEmergencyReservationModal from "../modals/NewAssetEmergencyReservationModal"
import ConfirmMarkAssetEmergencyReservationAvailableModal from "../modals/ConfirmMarkAssetEmergencyReservationAvailableModal"
import ConfirmMarkAssetEmergencyReservationUnavailableModal from "../modals/ConfirmMarkAssetEmergencyReservationUnavailableModal"
import ConfirmDeleteAssetEmergencyReservationModal from "../modals/ConfirmDeleteAssetEmergencyReservationModal"
import { AssetEmergencyReservation } from "../../../classes/AssetEmergencyReservation"
import EditAssetEmergencyReservationModal from "../modals/EditAssetEmergencyReservationModal";
import deleteAssetEmergencyReservationById from "../../../data/asset/deleteAssetEmergencyReservationById"
import updateAssetEmergencyReservationById from "../../../data/asset/updateAssetEmergencyReservationById"

function AssetEmergencyReservationTab(props: { onNetworkError: Function }) {

    const [isNewAssetEmergencyReservationModalOpen, setNewAssetEmergencyReservationModalOpen] = useState<boolean>(false)
    const [isEditAssetEmergencyReservationModalOpen, setEditAssetEmergencyReservationModalOpen] = useState<boolean>(false)
    const [isConfirmMarkAssetEmergencyReservationAvailableModalOpen, setConfirmMarkAssetEmergencyReservationAvailableModalOpen] = useState<boolean>(false)
    const [isConfirmMarkAssetEmergencyReservationUnavailableModalOpen, setConfirmMarkAssetEmergencyReservationUnavailableModalOpen] = useState<boolean>(false)
    const [isConfirmDeleteAssetEmergencyReservationModalOpen, setConfirmDeleteAssetEmergencyReservationModalOpen] = useState<boolean>(false)

    const [activeAssetEmergencyReservationList, setActiveAssetEmergencyReservationList] = useState<AssetEmergencyReservation[] | undefined>(undefined)
    const [futureAssetEmergencyReservationList, setFutureAssetEmergencyReservationList] = useState<AssetEmergencyReservation[] | undefined>(undefined)
    const [selectedAssetEmergencyReservationId, setSelectedAssetEmergencyReservationId] = useState<number | null>(null)
    const [selectedAssetEmergencyReservationObject, setSelectedAssetEmergencyReservationObject] = useState<AssetEmergencyReservation | undefined>(undefined)

    function reloadData() {
        getActiveAssetEmergencyReservations()
            .then((result) => setActiveAssetEmergencyReservationList(result))
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })

        getFutureAssetEmergencyReservations()
            .then((result) => setFutureAssetEmergencyReservationList(result))
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    useEffect(() => {
        reloadData()
        const intervalId = window.setInterval(reloadData, 20000)

        return () => window.clearInterval(intervalId)
    }, [])

    useLayoutEffect(() => {
        if (selectedAssetEmergencyReservationId === null) {
            setSelectedAssetEmergencyReservationObject(undefined)
        } else if (activeAssetEmergencyReservationList !== undefined && futureAssetEmergencyReservationList !== undefined) {
            activeAssetEmergencyReservationList.map((o: AssetEmergencyReservation) => {
                if (o.id == selectedAssetEmergencyReservationId) {
                    setSelectedAssetEmergencyReservationObject(o)
                }
            })

            futureAssetEmergencyReservationList.map((o: AssetEmergencyReservation) => {
                if (o.id == selectedAssetEmergencyReservationId) {
                    setSelectedAssetEmergencyReservationObject(o)
                }
            })
        }
    }, [selectedAssetEmergencyReservationId, activeAssetEmergencyReservationList, futureAssetEmergencyReservationList])

    if (activeAssetEmergencyReservationList === undefined || activeAssetEmergencyReservationList === null || futureAssetEmergencyReservationList === undefined || futureAssetEmergencyReservationList === null) {
        return (<Typography textAlign="center" sx={{ transform: "translateY(250px)" }}>Loading asset emergency reservation data...</Typography>)
    }

    function handleCreateAssetEmergencyReservation(inputReservation: AssetEmergencyReservation) {
        createAssetEmergencyReservation(inputReservation)
            .then(() => {
                reloadData()
                setSelectedAssetEmergencyReservationId(null)
                setNewAssetEmergencyReservationModalOpen(false)
            })
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    function handleMarkAssetEmergencyReservationAvailable() {
        markAssetEmergencyReservationAvailableById(selectedAssetEmergencyReservationId!)
            .then(() => {
                reloadData()
                setConfirmMarkAssetEmergencyReservationAvailableModalOpen(false)
            })
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    function handleMarkAssetEmergencyReservationUnavailable() {
        markAssetEmergencyReservationUnavailableById(selectedAssetEmergencyReservationId!)
            .then(() => {
                reloadData()
                setConfirmMarkAssetEmergencyReservationUnavailableModalOpen(false)
            })
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    function handleEditAssetEmergencyReservation(inputReservation: AssetEmergencyReservation) {
        updateAssetEmergencyReservationById(inputReservation)
            .then(() => {
                reloadData()
                setEditAssetEmergencyReservationModalOpen(false)
            })
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    function handleDeleteAssetEmergencyReservation() {
        deleteAssetEmergencyReservationById(selectedAssetEmergencyReservationId!)
            .then(() => {
                reloadData()
                setConfirmDeleteAssetEmergencyReservationModalOpen(false)
            })
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    return (
        <Stack direction="column" padding={2} spacing={2}>
            <AssetEmergencyReservationActionBar
                sx={{ width: "100%" }}
                onCreate={() => setNewAssetEmergencyReservationModalOpen(true)}
                onMarkAvailable={() => setConfirmMarkAssetEmergencyReservationAvailableModalOpen(true)}
                onMarkUnavailable={() => setConfirmMarkAssetEmergencyReservationUnavailableModalOpen(true)}
                onEdit={() => setEditAssetEmergencyReservationModalOpen(true)}
                onDelete={() => setConfirmDeleteAssetEmergencyReservationModalOpen(true)}
                createDisabled={false}
                markAvailableDisabled={selectedAssetEmergencyReservationObject === undefined ? true : selectedAssetEmergencyReservationObject!.isAvailable}
                markUnavailableDisabled={selectedAssetEmergencyReservationObject === undefined ? true : !selectedAssetEmergencyReservationObject!.isAvailable}
                editDisabled={selectedAssetEmergencyReservationId === null}
                deleteDisabled={selectedAssetEmergencyReservationId === null}
            />
            <AssetEmergencyReservationFlatList
                sx={{ width: "100%" }}
                activeReservations={activeAssetEmergencyReservationList}
                futureReservations={futureAssetEmergencyReservationList}
                selectedReservationObject={selectedAssetEmergencyReservationObject}
                onReservationSelectionChanged={(id: number) => setSelectedAssetEmergencyReservationId(id)}
            />
            <NewAssetEmergencyReservationModal
                isOpen={isNewAssetEmergencyReservationModalOpen}
                onClose={() => setNewAssetEmergencyReservationModalOpen(false)}
                onSubmit={handleCreateAssetEmergencyReservation}
            />
            <EditAssetEmergencyReservationModal
                isOpen={isEditAssetEmergencyReservationModalOpen}
                onClose={() => setEditAssetEmergencyReservationModalOpen(false)}
                onSubmit={handleEditAssetEmergencyReservation}
                reservationObject={selectedAssetEmergencyReservationObject === undefined ? new AssetEmergencyReservation() : selectedAssetEmergencyReservationObject!}
            />
            <ConfirmMarkAssetEmergencyReservationAvailableModal
                isOpen={isConfirmMarkAssetEmergencyReservationAvailableModalOpen}
                onClose={() => setConfirmMarkAssetEmergencyReservationAvailableModalOpen(false)}
                onConfirm={handleMarkAssetEmergencyReservationAvailable}
            />
            <ConfirmMarkAssetEmergencyReservationUnavailableModal
                isOpen={isConfirmMarkAssetEmergencyReservationUnavailableModalOpen}
                onClose={() => setConfirmMarkAssetEmergencyReservationUnavailableModalOpen(false)}
                onConfirm={handleMarkAssetEmergencyReservationUnavailable}
            />
            <ConfirmDeleteAssetEmergencyReservationModal
                isOpen={isConfirmDeleteAssetEmergencyReservationModalOpen}
                onClose={() => setConfirmDeleteAssetEmergencyReservationModalOpen(false)}
                onConfirm={handleDeleteAssetEmergencyReservation}
            />
        </Stack>
    )
}

export default AssetEmergencyReservationTab