import React from "react"
import dayjs, {Dayjs} from "dayjs"
import {
    Box,
    Divider,
    Typography
} from "@mui/material"

function ScheduleItemTimelineMarker(props: { width: number, startTime: Dayjs, endTime: Dayjs, currentTime: Dayjs }) {

    const timeWindow = props.endTime.diff(props.startTime)

    function getTimescaleMarkers() {
        var markers = []
        var tmpTime = dayjs(props.startTime)

        while (tmpTime.isBefore(props.endTime)) {
            const nextTime = tmpTime.add(1, "hour")
            if (nextTime.isAfter(props.endTime)) {
                break
            }

            const offset = (nextTime.diff(props.startTime) / timeWindow) * props.width

            if (nextTime.get("date") === tmpTime.get("date")) {
                markers.push(
                    <Divider
                        orientation="vertical"
                        sx={{
                            position: "absolute",
                            height: "15px",
                            width: "1px",
                            transform: `translate(${offset}px)`
                        }}
                    />
                )
    
                markers.push(
                    <Typography
                        variant="caption"
                        sx={{
                            position: "absolute",
                            transform: `translate(${offset + 2}px)`
                        }}
                    >
                        {nextTime.format("HH:mm")}
                    </Typography>
                )
            } else {
                markers.push(
                    <Divider
                        orientation="vertical"
                        sx={{
                            position: "absolute",
                            height: "25px",
                            width: "1px",
                            transform: `translate(${offset}px)`
                        }}
                    />
                )
    
                markers.push(
                    <Typography
                        variant="subtitle2"
                        sx={{
                            position: "absolute",
                            transform: `translate(${offset + 2}px)`
                        }}
                    >
                        {nextTime.format("MMM D")}
                    </Typography>
                )
            }
            

            tmpTime = nextTime
        }

        return markers
    }

    function getCurrentTimeMarker() {
        const offset = (props.currentTime.diff(props.startTime) / timeWindow) * props.width

        return (
            <Divider
                orientation="vertical"
                color="white"
                sx={{
                    position: "absolute",
                    height: "30px",
                    width: "1px",
                    transform: `translate(${offset}px)`
                }}
            />
        )
    }

    return (
        <Box
            sx={{
                position: "relative",
                height: "30px",
            }}
        >
            {getTimescaleMarkers()}
            {getCurrentTimeMarker()}
        </Box>
    )
}

export default ScheduleItemTimelineMarker