import React from "react"
import { Modal, Paper } from "@mui/material"
import EditMissionFlightPathLayout from "../layouts/EditMissionFlightPathLayout"
import { FlightPath } from "../../../classes/FlightPath"
import { Mission } from "../../../classes/Mission"

export default function EditMissionFlightPathModal(props: {
    isOpen: boolean,
    missionData: Mission,
    selectedFlightPathId?: number,
    onClose: Function,
    onSubmit: Function
}) {

    if (props.selectedFlightPathId === undefined) {
        return null
    }

    function findFlightPath(): FlightPath {
        for (const path of props.missionData.flightPaths!) {
            if (path.id === props.selectedFlightPathId) {
                return path
            }
        }

        return new FlightPath()
    }

    return (
        <Modal
            open={props.isOpen}
            onClose={() => props.onClose()}
        >
            <Paper
                sx={{
                    position: 'absolute',
                    width: '800px',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    padding: 2
                }}
            >
                <EditMissionFlightPathLayout
                    title="Edit Flight Path"
                    initData={findFlightPath()}
                    onCancel={() => props.onClose()}
                    onSubmit={props.onSubmit}
                />
            </Paper>
        </Modal>
    )
}