import React, { useState } from "react"
import Joi from "joi"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import Stack from "@mui/material/Stack"
import {FormControl, InputLabel, MenuItem, Select, TextField, Checkbox, FormControlLabel} from "@mui/material";
import {Organization} from "../../../classes/Organization";
import {Office} from "../../../classes/Office";

function EditOfficeLayout(props: {
    title: string,
    initInfo: Office,
    organizationList?: Organization[],
    onSubmit: Function,
    onCancel: Function
}) {

    const [isPendingSubmit, setPendingSubmit] = useState(false)
    const [inputOrganizationId, setInputOrganizationId] = useState<any>(props.initInfo.organizationId)
    const [inputName, setInputName] = useState<any>(props.initInfo.name)
    const [inputSymbol, setInputSymbol] = useState<any>(props.initInfo.symbol)
    const [inputCanOriginateMissions, setInputCanOriginateMissions] = useState<any>(props.initInfo.canOriginateMissions)

    const isOrganizationIdValid = Joi.number().integer().positive().required().validate(inputOrganizationId)
    const isNameValid = Joi.string().min(1).max(100).required().validate(inputName)
    const isSymbolValid = Joi.string().max(20).required().validate(inputSymbol)
    const isValid: boolean = (!isOrganizationIdValid.error && !isNameValid.error && !isSymbolValid.error)

    function handleSubmit() {
        setPendingSubmit(true)

        const inputOffice = props.initInfo
        inputOffice.organizationId = inputOrganizationId
        inputOffice.name = inputName
        inputOffice.symbol = inputSymbol
        inputOffice.canOriginateMissions = inputCanOriginateMissions

        props.onSubmit(inputOffice)
    }

    const bottomControls = isPendingSubmit === true ? (<Typography>Loading...</Typography>) : [(<Button variant='text' onClick={() => props.onCancel()}>Cancel</Button>), (<Button variant='contained' disabled={!isValid} onClick={handleSubmit}>Submit</Button>)];

    if (props.organizationList === undefined || props.organizationList === null) {
        return (<Typography variant="h6">Loading...</Typography>)
    }

    return (
        <Stack direction="column" spacing={2}>
            <Typography variant="h6">{props.title}</Typography>
            <FormControl fullWidth disabled={isPendingSubmit}>
                <InputLabel id="organization-select">Select Organization</InputLabel>
                <Select
                    labelId="organization-select"
                    label="Select Organization"
                    value={inputOrganizationId}
                    onChange={(event) => setInputOrganizationId(event.target.value)}
                >
                    {props.organizationList.map((p: Organization) => (<MenuItem value={p.id}>{p.name}</MenuItem>))}
                </Select>
            </FormControl>
            <TextField
                type="text"
                variant="outlined"
                label="Office Name"
                disabled={isPendingSubmit}
                value={inputName}
                onChange={(e) => setInputName(e.target.value)}
            />
            <TextField
                type="text"
                variant="outlined"
                label="Office Symbol"
                disabled={isPendingSubmit}
                value={inputSymbol}
                onChange={(e) => setInputSymbol(e.target.value)}
            />
            <Stack direction="row" spacing={4} justifyContent="center">
                <FormControlLabel
                    label="Can Originate Missions"
                    disabled={isPendingSubmit}
                    control={<Checkbox checked={inputCanOriginateMissions} onChange={(event: any) => setInputCanOriginateMissions(event.target.checked)} />}
                />
            </Stack>
            <Stack direction="row" spacing={1} justifyContent="right">{bottomControls}</Stack>
        </Stack>
    )
}

export default EditOfficeLayout