import React from 'react';
import { Paper, Stack, Typography, Divider, TextField, InputAdornment } from '@mui/material';
import { Error, Warning, CheckCircle } from '@mui/icons-material';
import {Asset} from "../../../classes/Asset";

function AssetReadinessStatusPanel(props: {
    assetData: Asset,
    sx?: object
}) {

    const isReady = props.assetData.readiness!.numberOutstandingRequirements === 0 && props.assetData.readiness!.numberActiveProblems === 0;

    return (
        <Paper sx={props.sx}>
            <Stack direction="column" spacing={1} padding={2}>
                <Typography variant="h5">Readiness Status</Typography>
                <Divider />
                <Stack direction="row" spacing={1}>
                    <TextField
                        sx={{ width: '33%' }}
                        InputProps={{ 
                            readOnly: true,
                            startAdornment: (
                                <InputAdornment position="start">
                                    {isReady ? (<CheckCircle color="success" />) : (<Error color="error" />)}
                                </InputAdornment>
                            ),
                        }}
                        label="Current Status"
                        value={isReady ? "Ready" : "Not Ready"}
                    />
                    <TextField
                        sx={{ width: '33%' }}
                        InputProps={{ readOnly: true }}
                        label="Active Problems"
                        value={props.assetData.readiness!.numberActiveProblems}
                    />
                    <TextField
                        sx={{ width: '33%' }}
                        InputProps={{ readOnly: true }}
                        label="Outstanding Requirements"
                        value={props.assetData.readiness!.numberOutstandingRequirements}
                    />
                </Stack>
            </Stack>
        </Paper>
    );
}

export default AssetReadinessStatusPanel;