import React from "react"
import { Button, Stack, Typography } from "@mui/material"

import { SysInfoBannerParams } from "../../classes/SysInfoBannerParams"
import PanelPageLayout from "./PanelPageLayout"
import MessageBoxPanel from "../components/MessageBoxPanel"

export default function MessageBoxPageLayout(props: {
    sysInfoBanner: SysInfoBannerParams,
    title: string,
    message: string,
    onOk: Function
}) {

    const panelStyle = {
        position: "absolute",
        width: "500px",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        padding: 1
    }

    return (
        <PanelPageLayout sysInfoBanner={props.sysInfoBanner}>
            <MessageBoxPanel
                title={props.title}
                message={props.message}
                onOk={props.onOk}
                panelStyle={panelStyle}
            />
        </PanelPageLayout>
    )
}