import React, {useEffect, useState} from "react"
import { Modal, Paper } from "@mui/material"
import ManageUserOfficesLayout from "../layouts/ManageUserOfficesLayout"
import getOffices from "../../../data/office/getOffices";
import getOfficesInUserByUsername from "../../../data/office/getOfficesInUserByUsername";
import addUserByUsernameToOfficeById from "../../../data/office/addUserByUsernameToOfficeById";
import removeUserByUsernameFromOfficeById from "../../../data/office/removeUserByUsernameFromOfficeById";

function ManageUserOfficesModal(props: {
    isOpen: boolean,
    userName: string,
    onClose: Function
}) {
    const [officeList, setOfficeList] = useState<any>(undefined)
    const [userOfficeList, setUserOfficeList] = useState<any>(undefined)

    useEffect(() => {
        if (props.isOpen && props.userName != null) {
            getOffices()
                .then((result) => setOfficeList(result))
                .catch((err) => {
                    console.error(err)
                    // TODO: props.onNetworkError()
                })

            getOfficesInUserByUsername(props.userName)
                .then((result) => setUserOfficeList(result))
                .catch((err) => {
                    console.error(err)
                    // TODO: props.onNetworkError()
                })

        } else {
            setOfficeList(undefined)
            setUserOfficeList(undefined)
        }
    }, [props.userName, props.isOpen])

    function addUserToOffice(officeId: number) {
        addUserByUsernameToOfficeById(officeId, props.userName)
            .then((result) => getOfficesInUserByUsername(props.userName))
            .then((result) => setUserOfficeList(result))
            .catch((err) => {
                console.error(err)
                // TODO: props.onNetworkError()
            })
    }

    function removeUserFromOffice(officeId: number) {
        removeUserByUsernameFromOfficeById(officeId, props.userName)
            .then((result) => getOfficesInUserByUsername(props.userName))
            .then((result) => setUserOfficeList(result))
            .catch((err) => {
                console.error(err)
                // TODO: props.onNetworkError()
            })
    }

    return (
        <Modal
            open={props.isOpen}
            onClose={() => props.onClose()}
        >
            <Paper
                sx={{
                    position: "absolute",
                    width: "600px",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    padding: 2
                }}
            >
                <ManageUserOfficesLayout
                    title="Manage User Offices"
                    userName={props.userName}
                    officeList={officeList}
                    userOfficeList={userOfficeList}
                    onAddUserToOffice={addUserToOffice}
                    onRemoveUserFromOffice={removeUserFromOffice}
                    onCancel={() => props.onClose()}
                />
            </Paper>
        </Modal>
    )
}

export default ManageUserOfficesModal