import React from 'react';
import { Stack } from '@mui/material';

import OperatorEmergencyReservationFlatList from "../components/OperatorEmergencyReservationFlatList"
import { Operator } from "../../../classes/Operator"

export default function OperatorEmergencyReservationsLayout(props: {
    operatorData: Operator
}) {

    return (
        <Stack direction="column" padding={2} spacing={2}>
            <OperatorEmergencyReservationFlatList sx={{ width: '100%' }} operatorData={props.operatorData} />
        </Stack>
    )
}