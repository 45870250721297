import React, {useEffect, useState} from "react"
import PrimaryLayout from "../../../common/layouts/PrimaryLayout"

import EmergenciesTabbedLayout from "../layouts/EmergenciesTabbedLayout"
import NetworkError from "../../../common/components/NetworkError";
import {SysInfoBannerParams} from "../../../classes/SysInfoBannerParams";
import {User} from "../../../classes/User";
import getBanner from "../../../data/sysinfo/getBanner";
import self from "../../../data/user/self";

function Emergencies() {
    const [bannerData, setBannerData] = useState<SysInfoBannerParams | undefined>(undefined)
    const [userData, setUserData] = useState<User | undefined>(undefined)

    const [isNetworkErrorSnackbarOpen, setNetworkErrorSnackbarOpen] = useState<boolean>(false)

    useEffect(() => {
        if (bannerData === undefined) {
            getBanner()
                .then((d) => setBannerData(d))
                .catch((err) => {
                    console.log(err)
                    setNetworkErrorSnackbarOpen(true)
                })
        }

        if (userData === undefined) {
            self()
                .then((d) => setUserData(d))
                .catch((err) => {
                    console.log(err)
                    setNetworkErrorSnackbarOpen(true)
                })
        }
    }, [])

    if (bannerData === undefined || userData === undefined) {
        return (
            <NetworkError
                open={isNetworkErrorSnackbarOpen}
                onClose={() => setNetworkErrorSnackbarOpen(false)}
            />
        ) // TODO: Make this into a loading page for all pages.
    }

    function content() {
        return (
            <EmergenciesTabbedLayout
                onNetworkError={() => setNetworkErrorSnackbarOpen(true)}
            />
        )
    }

    return (
        <PrimaryLayout
            sysInfoBanner={bannerData}
            userData={userData}
            navId="emergencies"
            title="Emergencies"
            content={content()}
            modals={[
                (
                    <NetworkError
                        open={isNetworkErrorSnackbarOpen}
                        onClose={() => setNetworkErrorSnackbarOpen(false)}
                    />
                )
            ]}
        />
    )
}

export default Emergencies