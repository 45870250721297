import React, {useState} from 'react';
import { Stack } from '@mui/material';

import MissionTaskedOrganizationPanel from '../components/MissionTaskedOrganizationPanel'
import MissionOriginatingOfficePanel from '../components/MissionOriginatingOfficePanel'
import MissionAssignedOperatorsPanel from '../components/MissionAssignedOperatorsPanel'
import MissionAssignedAssetsPanel from '../components/MissionAssignedAssetsPanel'
import updateMissionById from "../../../data/mission/updateMissionById"
import assignAssetByIdToMissionById from "../../../data/mission/assignAssetByIdToMissionById"
import assignOperatorByIdToMissionById from "../../../data/mission/assignOperatorByIdToMissionById"
import TaskMissionOrganizationModal from "../modals/TaskMissionOrganizationModal"
import AssignMissionAssetModal from "../modals/AssignMissionAssetModal"
import AssignMissionOperatorModal from "../modals/AssignMissionOperatorModal"
import removeOperatorByIdFromMissionById from "../../../data/mission/removeOperatorByIdFromMissionById"
import removeAssetByIdFromMissionById from "../../../data/mission/removeAssetByIdFromMissionById"
import { Mission } from "../../../classes/Mission"

function MissionAssignmentTab(props: { missionData: Mission, onRefresh: Function, onNetworkError: Function }) {
    const [isTaskMissionOrganizationModalOpen, setTaskMissionOrganizationModalOpen] = useState(false);
    const [isAssignAssetModalOpen, setAssignAssetModalOpen] = useState(false);
    const [isAssignOperatorModalOpen, setAssignOperatorModalOpen] = useState(false);

    function handleTaskMissionOrganization(organizationId: number) {
        const editMission = props.missionData
        editMission.assignedOrganizationId = organizationId

        updateMissionById(editMission)
            .then(() => props.onRefresh())
            .then(() => setTaskMissionOrganizationModalOpen(false))
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    function handleAssignMissionAsset(assetId: number) {
        assignAssetByIdToMissionById(props.missionData.id!, assetId)
            .then(() => props.onRefresh())
            .then(() => setAssignAssetModalOpen(false))
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    function handleRemoveMissionAsset(assetId: number) {
        removeAssetByIdFromMissionById(props.missionData.id!, assetId)
            .then(() => props.onRefresh())
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    function handleAssignMissionOperator(operatorId: number, isRPIC: boolean, isMO: boolean, isVO: boolean) {
        assignOperatorByIdToMissionById(props.missionData.id!, operatorId, isRPIC, isMO, isVO)
            .then(() => props.onRefresh())
            .then(() => setAssignOperatorModalOpen(false))
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    function handleRemoveMissionOperator(operatorId: number) {
        removeOperatorByIdFromMissionById(props.missionData.id!, operatorId)
            .then(() => props.onRefresh())
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    return (
        <Stack direction="column" padding={2} spacing={2}>
            <Stack direction="row" spacing={2}>
                <MissionOriginatingOfficePanel
                    sx={{ width: "100%" }}
                    missionData={props.missionData}
                />
                <MissionTaskedOrganizationPanel
                    sx={{ width: "100%" }}
                    missionData={props.missionData}
                    onClickAssign={() => setTaskMissionOrganizationModalOpen(true)}
                />
            </Stack>
            <Stack direction="row" spacing={2}>
                <MissionAssignedAssetsPanel
                    sx={{ width: "100%" }}
                    missionData={props.missionData}
                    onClickAssign={() => setAssignAssetModalOpen(true)}
                    onClickRemove={handleRemoveMissionAsset}
                />
                <MissionAssignedOperatorsPanel
                    sx={{ width: "100%" }}
                    missionData={props.missionData}
                    onClickAssign={() => setAssignOperatorModalOpen(true)}
                    onClickRemove={handleRemoveMissionOperator}
                />
            </Stack>
            <TaskMissionOrganizationModal
                isOpen={isTaskMissionOrganizationModalOpen}
                onClose={() => setTaskMissionOrganizationModalOpen(false)}
                onSubmit={handleTaskMissionOrganization}
                missionData={props.missionData}
                onNetworkError={props.onNetworkError}
            />
            <AssignMissionAssetModal
                isOpen={isAssignAssetModalOpen}
                onClose={() => setAssignAssetModalOpen(false)}
                onSubmit={handleAssignMissionAsset}
                onNetworkError={props.onNetworkError}
            />
            <AssignMissionOperatorModal
                isOpen={isAssignOperatorModalOpen}
                onClose={() => setAssignOperatorModalOpen(false)}
                onSubmit={handleAssignMissionOperator}
                onNetworkError={props.onNetworkError}
            />
        </Stack>
    )
}

export default MissionAssignmentTab;