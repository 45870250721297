import React from "react"
import { useState } from "react"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import Box from "@mui/material/Box"

import AssetEmergencyReservationTab from "../pages/AssetEmergencyReservationTab"
import OperatorEmergencyReservationTab from "../pages/OperatorEmergencyReservationTab"
import AvailableResourcesTab from "../pages/AvailableResourcesTab"

function EmergenciesTabbedLayout(props: {
    onNetworkError: Function,
    modals?: any
}) {
    const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0)

    function selectTab(event: any, newValue: number) {
        setSelectedTabIndex(newValue)
    }

    function renderTab() {
        if (selectedTabIndex === 0) {
            return (<AvailableResourcesTab onNetworkError={props.onNetworkError} />)
        } else if (selectedTabIndex === 1) {
            return (<AssetEmergencyReservationTab onNetworkError={props.onNetworkError} />)
        } else if (selectedTabIndex === 2) {
            return (<OperatorEmergencyReservationTab onNetworkError={props.onNetworkError} />)
        }
    }

    return (
        <Box sx={{ width: "100%" }}>
            {props.modals}
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={selectedTabIndex} onChange={selectTab} centered variant="scrollable" scrollButtons="auto">
                    <Tab label="Available Resources" />
                    <Tab label="Reserved Assets" />
                    <Tab label="Reserved Operators" />
                </Tabs>
            </Box>
            {renderTab()}
        </Box>
    )
}

export default EmergenciesTabbedLayout