import React from "react"
import { useState } from "react"
import {
    Paper,
    Typography,
    TextField,
    Stack,
    Button
} from "@mui/material"
import InputPanelPageLayout from "../../../common/layouts/InputPanelPageLayout"
import { SysInfoBannerParams } from "../../../classes/SysInfoBannerParams"
import Joi from "joi"

function UsernameAndPassphraseLoginLayout(props: {
    sysInfoBanner: SysInfoBannerParams,
    errorMessage: string,
    isPendingSubmit: boolean,
    onSubmit: Function,
    onCancel: Function
}) {

    const [username, setUsername] = useState("")
    const [passphrase, setPassphrase] = useState("")
    
    const isUsernameValid = Joi.string().min(1).required().validate(username)
    const isPassphraseValid = Joi.string().min(1).required().validate(passphrase)
    const isValid = (!isUsernameValid.error && !isPassphraseValid.error)

    function handleSubmit() {
        props.onSubmit(username, passphrase)
    }

    const inputControls = (
        <Stack direction="column" spacing={1}>
            <TextField
                type="text"
                variant="outlined"
                label="Username"
                disabled={props.isPendingSubmit}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
                type="password"
                variant="outlined"
                label="Passphrase"
                disabled={props.isPendingSubmit}
                value={passphrase}
                onChange={(e) => setPassphrase(e.target.value)}
            />
        </Stack>
    )

    const bottomControls = props.isPendingSubmit ? (
        <Typography>Loading...</Typography>
    ) : (
        <Stack direction="row" spacing={1} justifyContent="end">
            <Button variant="text" onClick={() => props.onCancel()}>Cancel</Button>
            <Button variant="contained" disabled={!isValid} onClick={handleSubmit}>Login</Button>
        </Stack>
    )

    return (
        <InputPanelPageLayout
            sysInfoBanner={props.sysInfoBanner}
            title="Please log in:"
            leftControl={<Typography color="red">{props.errorMessage}</Typography>} // TODO: Make a ErrorMessageControl component that will center text.
            rightControl={bottomControls}
        >
            {inputControls}
        </InputPanelPageLayout>
    )
}

export default UsernameAndPassphraseLoginLayout