import React from 'react';
import {
    Paper,
    Typography,
    Stack,
    Button
} from '@mui/material';
import PanelPageLayout from "../../../common/layouts/PanelPageLayout";
import InputPanelPageLayout from "../../../common/layouts/InputPanelPageLayout";
import {SysInfoBannerParams} from "../../../classes/SysInfoBannerParams";

function MessageLayout(props: {
    sysInfoBanner: SysInfoBannerParams,
    subject: string,
    message: string,
    onSubmit: Function
}) {

    const paragraphs = props.message.split("\n")

    return (
        <InputPanelPageLayout
            sysInfoBanner={props.sysInfoBanner}
            title={props.subject}
            leftControl={<Typography></Typography>}
            rightControl={
                <Button
                    variant='contained'
                    onClick={() => props.onSubmit()}
                >
                    Proceed
                </Button>
            }
        >
            {paragraphs.map((p) => (<Typography>{p}</Typography>))}
        </InputPanelPageLayout>
    )
}

export default MessageLayout