import React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import { DataGrid } from '@mui/x-data-grid';
import {Manufacturer} from "../../../classes/Manufacturer";

function ManufacturerFlatList(props: {
    data: Manufacturer[],
    selectedManufacturerId?: number,
    onManufacturerSelectionChanged: Function,
    sx?: object
}) {
    
    const columns = [
        { field: 'name', headerName: 'Name', width: 400 },
    ];

    return (
        <Paper sx={props.sx}>
            <Stack direction="column" spacing={2} padding={2}>
                <Box sx={{ height: 600 }}>
                    <DataGrid
                        rows={props.data.map((manufacturer: Manufacturer) => manufacturer.objectify())}
                        columns={columns}
                        rowSelectionModel={props.selectedManufacturerId === undefined ? [] : props.selectedManufacturerId!}
                        onRowSelectionModelChange={(e) => props.onManufacturerSelectionChanged(e)}
                    />
                </Box>
            </Stack>
        </Paper>
    );
}

export default ManufacturerFlatList;