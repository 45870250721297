import React, { useState, useEffect } from 'react';
import { Modal, Box, Paper } from '@mui/material';
import EditOperatorLayout from '../layouts/EditOperatorLayout';
import getOrganizations from "../../../data/organization/getOrganizations";
import getOffices from "../../../data/office/getOffices";
import {Office} from "../../../classes/Office";
import {Operator} from "../../../classes/Operator";

function NewOperatorModal(props: {
    isOpen: boolean,
    onSubmit: Function,
    onClose: Function,
    onNetworkError: Function
}) {
    const [officeList, setOfficeList] = useState<Office[] | undefined>(undefined)

    useEffect(() => {
        getOffices()
            .then((result) => setOfficeList(result))
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }, [])

    const newOperator = new Operator()

    return (
        <Modal
            open={props.isOpen}
            onClose={() => props.onClose()}
        >
            <Paper
                sx={{
                    position: 'absolute',
                    width: '600px',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    padding: 2
                }}
            >
                <EditOperatorLayout
                    title="Create Operator"
                    initInfo={newOperator}
                    officeList={officeList}
                    onCancel={() => props.onClose()}
                    onSubmit={props.onSubmit}
                />
            </Paper>
        </Modal>
    );
}

export default NewOperatorModal;