import { OperatingAreaPoint } from "./OperatingAreaPoint"

export class OperatingArea {
    id?: number
    missionId: number = 0
    label: string = ""
    isAGL: boolean = false
    altitudeFloor: number = 0.0
    altitudeCeil: number = 0.0
    points: OperatingAreaPoint[] = []

    parseObject(input: any) {
        this.id = input.id
        this.missionId = input.missionId
        this.label = input.label
        this.isAGL = input.isAGL
        this.altitudeFloor = input.altitudeFloor
        this.altitudeCeil = input.altitudeCeil
        this.points = input.points.map((p: any) => {
            const point = new OperatingAreaPoint()
            point.parseObject(p)
            return point
        })
    }

    objectify(): object {
        return {
            id: this.id,
            missionId: this.missionId,
            label: this.label,
            isAGL: this.isAGL,
            altitudeFloor: this.altitudeFloor,
            altitudeCeil: this.altitudeCeil,
            points: this.points.map((p: OperatingAreaPoint) => p.objectify())
        }
    }
}