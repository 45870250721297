export class MissionUserPermissions {
    access: boolean = false
    modify: boolean = false

    parseObject(input: any) {
        this.access = input.access
        this.modify = input.modify
    }

    objectify(): object {
        return {
            access: this.access,
            modify: this.modify
        }
    }
}