import Cookies from "universal-cookie"

import config from "../config"
import getCompleteApiUrl from "../getCompleteApiUrl"

import {Operator} from "../../classes/Operator"
import {ApiCallFailure} from "../../classes/exception/ApiCallFailure";

export default async function createOperator(operator: Operator) {
    const cookies = new Cookies();

    if (cookies.get("accessToken") === undefined) {
        const error = new ApiCallFailure()
        error.connectionSuccessful = false
        error.errorType = "NO_ACCESS_TOKEN"

        throw error
    }

    var res: Response
    try {
        res = await fetch(getCompleteApiUrl(config, `/operators`), {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${cookies.get("accessToken")}`
            },
            body: JSON.stringify({
                officeId: operator.officeId,
                rank: operator.rank,
                firstName: operator.firstName,
                lastName: operator.lastName,
                callsign: operator.callsign
            }),
        })
    } catch (error) {
        const apiFailure = new ApiCallFailure()
        apiFailure.connectionSuccessful = false

        throw apiFailure
    }

    if (res!.status === 201) {
        const responseObject = await res!.json()

        const responseOperator = new Operator()
        responseOperator.parseObject(responseObject)

        return responseOperator
    }

    const callFailure = new ApiCallFailure()
    callFailure.connectionSuccessful = true
    callFailure.responseCode = res!.status
    callFailure.errorType = "REQUEST_REJECTED"

    throw callFailure
}