import React from 'react';
import { useState } from 'react';
import { Typography, Stack, Button } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import Joi from 'joi';

function RenewReadinessRequirementLayout(props: {
    onClose: Function,
    onSubmit: Function
}) {
    const [isPendingSubmit, setPendingSubmit] = useState(false);
    const [inputExpirationDate, setInputExpirationDate] = useState(dayjs());

    const isExpirationDateValid = Joi.date().required().validate(inputExpirationDate.format());
    const isValid = (!isExpirationDateValid.error);

    function handleSubmit() {
        setPendingSubmit(true)
        props.onSubmit(inputExpirationDate)
    }

    const bottomControls = isPendingSubmit === true ? (<Typography>Loading...</Typography>) : [(<Button variant='text' onClick={() => props.onClose()}>Cancel</Button>), (<Button variant='contained' disabled={!isValid} onClick={handleSubmit}>Proceed</Button>)];

    return (
        <Stack direction="column" spacing={1} padding={1}>
            <Typography variant="h6">Renew Requirement</Typography>
            <DatePicker
                label="Expiration Date"
                slotProps={{
                    textField: {
                        error: isExpirationDateValid.error !== undefined
                    },
                }}
                disabled={isPendingSubmit}
                value={inputExpirationDate}
                onChange={(event: any) => setInputExpirationDate(event)}
            />
            <Stack direction="row" spacing={1} justifyContent="right">{bottomControls}</Stack>
        </Stack>
    );
}

export default RenewReadinessRequirementLayout;