import React from 'react';
import { Paper, Stack, Button } from '@mui/material';

function OperationActionPanel(props: {
    onClickEdit: Function,
    sx?: object
}) {

    return (
        <Paper sx={props.sx}>
            <Stack direction="column" spacing={1} padding={2}>
                <Button variant="contained" onClick={() => props.onClickEdit()}>Edit</Button>
            </Stack>
        </Paper>
    );
}

export default OperationActionPanel;