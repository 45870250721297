import React, { useState, useEffect } from "react"
import { Modal, Paper } from "@mui/material"
import EditOfficeLayout from "../layouts/EditOfficeLayout"
import getOrganizations from "../../../data/organization/getOrganizations"
import {Organization} from "../../../classes/Organization";
import {Office} from "../../../classes/Office";

function UpdateOfficeModal(props: {
    isOpen: boolean,
    selectedOfficeObject: Office,
    onClose: any,
    onSubmit: Function,
    onNetworkError: Function
}) {
    const [organizationList, setOrganizationList] = useState<Organization[] | undefined>(undefined)

    useEffect(() => {
        getOrganizations()
            .then((result) => setOrganizationList(result))
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }, [])

    return (
        <Modal
            open={props.isOpen}
            onClose={() => props.onClose()}
        >
            <Paper
                sx={{
                    position: "absolute",
                    width: "600px",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    padding: 2
                }}
            >
                <EditOfficeLayout
                    title="Update Office"
                    initInfo={props.selectedOfficeObject}
                    organizationList={organizationList}
                    onSubmit={props.onSubmit}
                    onCancel={() => props.onClose()}
                />
            </Paper>
        </Modal>
    )
}

export default UpdateOfficeModal