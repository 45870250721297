import { Point } from "./Point"
import { FlightPath } from "./FlightPath"
import { OperatingArea } from "./OperatingArea"
import getFutureAssetEmergencyReservations from "../data/asset/getFutureAssetEmergencyReservations";

export class MapRenderer {

    // Define the size of the image.
    width: number = 0.0
    height: number = 0.0

    // Define the boundaries of the map image.
    lonLeft: number = 0.0
    lonRight: number = 0.0
    latTop: number = 0.0
    latBottom: number = 0.0

    // Data
    points?: Point[]
    paths?: FlightPath[]
    areas?: OperatingArea[]

    setViewport() {
        var lonInit = false
        var latInit = false

        if (this.points !== undefined) {
            for (let i = 0; i < this.points!.length; i++) {
                if (!lonInit) {
                    lonInit = true
                    this.lonLeft = this.points[i]!.longitude
                    this.lonRight = this.points[i]!.longitude
                }
                else if (this.points[i]!.longitude < this.lonLeft) {
                    this.lonLeft = this.points[i]!.longitude
                }
                else if (this.points[i]!.longitude > this.lonRight) {
                    this.lonRight = this.points[i]!.longitude
                }

                if (!latInit) {
                    latInit = true
                    this.latTop = this.points[i]!.latitude
                    this.latBottom = this.points[i]!.latitude
                }
                else if (this.points[i]!.latitude > this.latTop) {
                    this.latTop = this.points[i]!.latitude
                }
                else if (this.points[i]!.latitude < this.latBottom) {
                    this.latBottom = this.points[i]!.latitude
                }
            }
        }

        if (this.paths !== undefined) {
            for (let i = 0; i < this.paths!.length; i++) {
                for (let j = 0; j < this.paths[i]!.waypoints!.length; j++) {
                    if (!lonInit) {
                        lonInit = true
                        this.lonLeft = this.paths[i]!.waypoints[j]!.longitude
                        this.lonRight = this.paths[i]!.waypoints[j]!.longitude
                    }
                    else if (this.paths[i]!.waypoints[j]!.longitude < this.lonLeft) {
                        this.lonLeft = this.paths[i]!.waypoints[j]!.longitude
                    }
                    else if (this.paths[i]!.waypoints[j]!.longitude > this.lonRight) {
                        this.lonRight = this.paths[i]!.waypoints[j]!.longitude
                    }

                    if (!latInit) {
                        latInit = true
                        this.latTop = this.paths[i]!.waypoints[j]!.latitude
                        this.latBottom = this.paths[i]!.waypoints[j]!.latitude
                    }
                    else if (this.paths[i]!.waypoints[j]!.latitude > this.latTop) {
                        this.latTop = this.paths[i]!.waypoints[j]!.latitude
                    }
                    else if (this.paths[i]!.waypoints[j]!.latitude < this.latBottom) {
                        this.latBottom = this.paths[i]!.waypoints[j]!.latitude
                    }
                }
            }
        }

        if (this.areas !== undefined) {
            for (let i = 0; i < this.areas!.length; i++) {
                for (let j = 0; j < this.areas[i]!.points!.length; j++) {
                    if (!lonInit) {
                        lonInit = true
                        this.lonLeft = this.areas[i]!.points[j]!.longitude
                        this.lonRight = this.areas[i]!.points[j]!.longitude
                    }
                    else if (this.areas[i]!.points[j]!.longitude < this.lonLeft) {
                        this.lonLeft = this.areas[i]!.points[j]!.longitude
                    }
                    else if (this.areas[i]!.points[j]!.longitude > this.lonRight) {
                        this.lonRight = this.areas[i]!.points[j]!.longitude
                    }

                    if (!latInit) {
                        latInit = true
                        this.latTop = this.areas[i]!.points[j]!.latitude
                        this.latBottom = this.areas[i]!.points[j]!.latitude
                    }
                    else if (this.areas[i]!.points[j]!.latitude > this.latTop) {
                        this.latTop = this.areas[i]!.points[j]!.latitude
                    }
                    else if (this.areas[i]!.points[j]!.latitude < this.latBottom) {
                        this.latBottom = this.areas[i]!.points[j]!.latitude
                    }
                }
            }
        }
    }

    lonToX(lon: number): number {
        return (Math.abs(lon - this.lonLeft) / Math.abs(this.lonRight - this.lonLeft)) * this.width
    }

    latToY(lat: number): number {
        return (Math.abs(this.latTop - lat) / Math.abs(this.latBottom - this.latTop)) * this.height
    }

    xToLon(x: number): number {
        let a = Math.abs(this.lonRight - this.lonLeft) * (x / this.width)

        return this.lonLeft + a
    }

    yToLat(y: number): number {
        let a = Math.abs(this.latTop - this.latBottom) * (y / this.height)

        return this.latTop - a
    }

    render2d(ctx: CanvasRenderingContext2D) {
        // Draw points.
        ctx.fillStyle = "#FFFF00"
        ctx.strokeStyle = "#FFFF00"
        this.points?.forEach((point) => {
            ctx.beginPath()
            ctx.arc(this.lonToX(point.longitude), this.latToY(point.latitude), 4, 0, 2 * Math.PI)
            ctx.strokeText(point.label, this.lonToX(point.longitude) + 4, this.latToY(point.latitude) - 4)
            ctx.fill()
        })

        // Draw operating areas.
        ctx.fillStyle = "#FF0000"
        ctx.strokeStyle = "#FF0000"
        this.areas?.forEach((area) => {
            ctx.beginPath()
            area.points!.forEach((point, index) => {
                if (index === 0) {
                    ctx.moveTo(this.lonToX(point.longitude), this.latToY(point.latitude))
                }
                else {
                    ctx.lineTo(this.lonToX(point.longitude), this.latToY(point.latitude))
                }
            })

            ctx.closePath()
            ctx.stroke()
        })

        // Draw flight paths.
        ctx.fillStyle = "#0000FF"
        ctx.strokeStyle = "#0000FF"
        this.paths?.forEach((path) => {
            ctx.beginPath()
            path.waypoints!.forEach((waypoint, index) => {
                if (index === 0) {
                    ctx.strokeText(path.label, this.lonToX(waypoint.longitude) + 4, this.latToY(waypoint.latitude) - 4)
                    ctx.moveTo(this.lonToX(waypoint.longitude), this.latToY(waypoint.latitude))
                }
                else {
                    ctx.lineTo(this.lonToX(waypoint.longitude), this.latToY(waypoint.latitude))
                }
            })

            ctx.stroke()
        })

        this.paths?.forEach((path) => {
            path.waypoints.forEach((waypoint, index) => {
                ctx.beginPath()
                ctx.arc(this.lonToX(waypoint.longitude), this.latToY(waypoint.latitude), 2, 0, 2 * Math.PI)
                ctx.fill()
            })
        })
    }
}