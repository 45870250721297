import Cookies from "universal-cookie"

import config from "../config"
import getCompleteApiUrl from "../getCompleteApiUrl"
import {Mission} from "../../classes/Mission"
import {ApiCallFailure} from "../../classes/exception/ApiCallFailure";

export default async function updateMissionById(mission: Mission) {
    const cookies = new Cookies()

    if (cookies.get("accessToken") === undefined) {
        const error = new ApiCallFailure()
        error.connectionSuccessful = false
        error.errorType = "NO_ACCESS_TOKEN"

        throw error
    }

    var res: Response
    try {
        res = await fetch(getCompleteApiUrl(config, `/missions/id/${mission.id!}`), {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${cookies.get("accessToken")}`
            },
            body: JSON.stringify({
                operationId: mission.operationId,
                name: mission.name,
                purpose: mission.purpose,
                originatingOfficeId: mission.originatingOfficeId,
                assignedOrganizationId: mission.assignedOrganizationId
            }),
        })
    } catch (error) {
        const apiFailure = new ApiCallFailure()
        apiFailure.connectionSuccessful = false

        throw apiFailure
    }

    if (res.status === 200) {
        const responseObject = await res.json()

        const responseMission = new Mission()
        responseMission.parseObject(responseObject)

        return responseMission
    }

    const callFailure = new ApiCallFailure()
    callFailure.connectionSuccessful = true
    callFailure.responseCode = res!.status
    callFailure.errorType = "REQUEST_REJECTED"

    throw callFailure
}