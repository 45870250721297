import React, { useState } from "react"
import { Modal, Paper } from "@mui/material"
import EditOperatorEmergencyReservationLayout from "../layouts/EditOperatorEmergencyReservationLayout"
import getOperators from "../../../data/operator/getOperators"
import {OperatorEmergencyReservation} from "../../../classes/OperatorEmergencyReservation"

export default function EditOperatorEmergencyReservationModal(props: {
    isOpen: boolean,
    onSubmit: Function,
    onClose: Function,
    reservationObject: OperatorEmergencyReservation
}) {
    const [operatorList, setOperatorList] = useState<any>(undefined)

    if (operatorList === undefined) {
        setOperatorList(null)
        getOperators()
            .then((result) => setOperatorList(result))
            .catch((err) => console.log("Could not connect to the server." + err))
    }

    return (
        <Modal
            open={props.isOpen}
            onClose={() => props.onClose()}
        >
            <Paper
                sx={{
                    position: "absolute",
                    width: "600px",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    padding: 2
                }}
            >
                <EditOperatorEmergencyReservationLayout
                    title="Edit Operator Emergency Reservation"
                    initInfo={props.reservationObject}
                    onCancel={() => props.onClose()}
                    onSubmit={props.onSubmit}
                    operatorList={operatorList}
                />
            </Paper>
        </Modal>
    )
}