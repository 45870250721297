import React, {useState, useEffect, useLayoutEffect} from "react"
import Stack from "@mui/material/Stack"

import ManufacturerActionBar from "../components/ManufacturerActionBar"
import ManufacturerFlatList from "../components/ManufacturerFlatList"
import NewManufacturerModal from "../modals/NewManufacturerModal"
import UpdateManufacturerModal from "../modals/UpdateManufacturerModal"
import getManufacturers from "../../../data/manufacturer/getManufacturers"
import {Typography} from "@mui/material"
import createManufacturer from "../../../data/manufacturer/createManufacturer"
import updateManufacturerById from "../../../data/manufacturer/updateManufacturerById"
import {Manufacturer} from "../../../classes/Manufacturer";

function ManufacturerTab(props: { onNetworkError: Function }) {
    const [isCreateManufacturerModalOpen, setCreateManufacturerModalOpen] = useState<boolean>(false)
    const [isUpdateManufacturerModalOpen, setUpdateManufacturerModalOpen] = useState<boolean>(false)

    const [manufacturerList, setManufacturerList] = useState<Manufacturer[] | undefined>(undefined)
    const [selectedManufacturerId, setSelectedManufacturerId] = useState<number | null>(null)
    const [selectedManufacturerObject, setSelectedManufacturerObject] = useState<Manufacturer | undefined>(undefined)

    useEffect(() => {
        getManufacturers()
            .then((result) => setManufacturerList(result))
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }, [])

    useLayoutEffect(() => {
        if (selectedManufacturerId === null) {
            setSelectedManufacturerObject(undefined)
        } else if (manufacturerList !== undefined) {
            manufacturerList.map((o: Manufacturer) => {
                if (o.id == selectedManufacturerId) {
                    setSelectedManufacturerObject(o)
                }
            })
        }
    }, [selectedManufacturerId, manufacturerList])

    if (manufacturerList === undefined) {
        return (<Typography textAlign="center" sx={{ transform: "translateY(250px)" }}>Loading manufacturer data...</Typography>)
    }

    function handleCreateManufacturer(inputManufacturer: Manufacturer) {
        createManufacturer(inputManufacturer)
            .then(() => getManufacturers())
            .then((result) => {
                setManufacturerList(result)
                setCreateManufacturerModalOpen(false)
            })
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    function handleUpdateManufacturer(inputManufacturer: Manufacturer) {
        updateManufacturerById(inputManufacturer)
            .then(() => getManufacturers())
            .then((result) => {
                setManufacturerList(result)
                setUpdateManufacturerModalOpen(false)
            })
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    return (
        <Stack direction="column" spacing={2} padding={2}>
            <ManufacturerActionBar
                sx={{ width: "100%" }}
                onCreate={() => setCreateManufacturerModalOpen(true)}
                onUpdate={() => setUpdateManufacturerModalOpen(true)}
                createDisabled={false}
                updateDisabled={selectedManufacturerId === null}
            />
            <ManufacturerFlatList
                sx={{ width: "100%" }}
                data={manufacturerList}
                selectedManufacturerId={selectedManufacturerId === null ? undefined : selectedManufacturerId}
                onManufacturerSelectionChanged={(id: number) => setSelectedManufacturerId(id)}
            />
            <NewManufacturerModal
                isOpen={isCreateManufacturerModalOpen}
                onClose={() => setCreateManufacturerModalOpen(false)}
                onSubmit={handleCreateManufacturer}
                onNetworkError={props.onNetworkError}
            />
            <UpdateManufacturerModal
                isOpen={isUpdateManufacturerModalOpen}
                onClose={() => setUpdateManufacturerModalOpen(false)}
                selectedManufacturerObject={selectedManufacturerObject === undefined ? new Manufacturer() : selectedManufacturerObject}
                onSubmit={handleUpdateManufacturer}
            />
        </Stack>
    )
}

export default ManufacturerTab