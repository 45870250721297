import React from 'react';
import { Stack } from '@mui/material';

import MissionNoteInputPanel from '../components/MissionNoteInputPanel';
import MissionNotePanel from '../components/MissionNotePanel';
import addNoteToMissionById from "../../../data/mission/addNoteToMissionById";
import {MissionNote} from "../../../classes/MissionNote";
import {Mission} from "../../../classes/Mission";

function MissionNotesTab(props: { missionData: Mission, onRefresh: Function, onNetworkError: Function }) {

    function handleSubmitNote(note: string) {
        const inputNote = new MissionNote()
        inputNote.missionId = props.missionData.id!
        inputNote.note = note

        addNoteToMissionById(inputNote)
            .then(() => props.onRefresh())
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    return (
        <Stack direction="column" padding={2} spacing={2}>
            {props.missionData.userPermissions!.modify ? (<MissionNoteInputPanel sx={{ width: "100%" }} onSubmit={handleSubmitNote} />) : null}
            <MissionNotePanel
                sx={{ width: "100%" }}
                missionData={props.missionData}
            />
        </Stack>
    )
}

export default MissionNotesTab;