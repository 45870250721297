import React, {useState, useEffect, useLayoutEffect} from "react"
import Stack from "@mui/material/Stack"

import AssetActionBar from "../components/AssetActionBar"
import AssetFlatList from "../components/AssetFlatList"
import NewAssetModal from "../modals/NewAssetModal"
import UpdateAssetModal from "../modals/UpdateAssetModal"
import getAssets from "../../../data/asset/getAssets"
import {Typography} from "@mui/material"
import createAsset from "../../../data/asset/createAsset"
import updateAssetById from "../../../data/asset/updateAssetById"
import {Asset} from "../../../classes/Asset";

function AssetTab(props: { onNetworkError: Function }) {
    const [isCreateAssetModalOpen, setCreateAssetModalOpen] = useState<boolean>(false)
    const [isUpdateAssetModalOpen, setUpdateAssetModalOpen] = useState<boolean>(false)

    const [assetList, setAssetList] = useState<Asset[] | undefined>(undefined)
    const [selectedAssetId, setSelectedAssetId] = useState<number | null>(null)
    const [selectedAssetObject, setSelectedAssetObject] = useState<Asset | undefined>(undefined)

    useEffect(() => {
        getAssets()
            .then((result) => setAssetList(result))
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }, [])

    useLayoutEffect(() => {
        if (selectedAssetId === null) {
            setSelectedAssetObject(undefined)
        } else if (assetList !== undefined) {
            assetList.map((o: Asset) => {
                if (o.id == selectedAssetId) {
                    setSelectedAssetObject(o)
                }
            })
        }
    }, [selectedAssetId, assetList])

    if (assetList === undefined) {
        return (<Typography textAlign="center" sx={{ transform: "translateY(250px)" }}>Loading asset data...</Typography>)
    }

    function handleCreateAsset(inputAsset: Asset) {
        createAsset(inputAsset)
            .then(() => getAssets())
            .then((result) => {
                setAssetList(result)
                setCreateAssetModalOpen(false)
            })
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    function handleUpdateAsset(inputAsset: Asset) {
        updateAssetById(inputAsset)
            .then(() => getAssets())
            .then((result) => {
                setAssetList(result)
                setUpdateAssetModalOpen(false)
            })
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    return (
        <Stack direction="column" spacing={2} padding={2}>
            <AssetActionBar
                sx={{ width: "100%" }}
                onCreate={() => setCreateAssetModalOpen(true)}
                onUpdate={() => setUpdateAssetModalOpen(true)}
                createDisabled={false}
                updateDisabled={selectedAssetId === null}
            />
            <AssetFlatList
                sx={{ width: "100%" }}
                data={assetList}
                selectedAssetId={selectedAssetId === null ? undefined : selectedAssetId}
                onAssetSelectionChanged={(id: number) => setSelectedAssetId(id)}
            />
            <NewAssetModal
                isOpen={isCreateAssetModalOpen}
                onClose={() => setCreateAssetModalOpen(false)}
                onSubmit={handleCreateAsset}
                onNetworkError={props.onNetworkError}
            />
            <UpdateAssetModal
                isOpen={isUpdateAssetModalOpen}
                onClose={() => setUpdateAssetModalOpen(false)}
                selectedAssetObject={selectedAssetObject === undefined ? new Asset() : selectedAssetObject}
                onSubmit={handleUpdateAsset}
                onNetworkError={props.onNetworkError}
            />
        </Stack>
    )
}

export default AssetTab