export class Manufacturer {
    id?: number
    name: string = ""

    constructor(name?: string) {
        this.name = name === undefined ? "" : name!
    }

    parseObject(input: any) {
        this.id = input.id
        this.name = input.name
    }

    objectify(): object {
        return {
            id: this.id,
            name: this.name
        }
    }
}