import React, {useEffect, useState} from 'react'
import { Modal, Paper } from '@mui/material'
import EditMissionLayout from '../layouts/EditMissionLayout'

import { Office } from "../../../classes/Office"
import { Operation } from "../../../classes/Operation"

import getOperations from "../../../data/operation/getOperations"
import getMyOffices from "../../../data/office/getMyOffices"
import {Mission} from "../../../classes/Mission"

import MessageBox from "../../../common/modals/MessageBox"

function NewMissionModal(props: {
    isOpen: boolean,
    onClose: Function,
    onSubmit: Function,
    onNetworkError: Function
}) {
    const [officeList, setOfficeList] = useState<Office[] | undefined>(undefined)
    const [operationList, setOperationList] = useState<Operation[] | undefined>(undefined)

    useEffect(() => {
        if (props.isOpen) {
            getMyOffices()
                .then((result) => result.filter((office) => office.canOriginateMissions))
                .then((result) => setOfficeList(result))
                .catch((err) => {
                    console.error(err)
                    props.onNetworkError()
                })

            getOperations()
                .then((result) => setOperationList(result))
                .catch((err) => {
                    console.error(err)
                    props.onNetworkError()
                })
        }
    }, [props.isOpen])

    if (officeList !== undefined) {
        if (officeList!.length === 0) {
            return (
                <MessageBox
                    isOpen={props.isOpen}
                    title="Cannot Originate Missions"
                    message="You are not assigned to any offices which may originate missions."
                    onOk={() => props.onClose()}
                    sx={{
                        position: 'absolute',
                        width: '600px',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        padding: 2
                    }}
                />
            )
        }
    }

    return (
        <Modal
            open={props.isOpen}
            onClose={() => props.onClose()}
        >
            <Paper
                sx={{
                    position: 'absolute',
                    width: '600px',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    padding: 2
                }}
            >
                <EditMissionLayout
                    title="Create Mission"
                    initInfo={new Mission()}
                    onCancel={() => props.onClose()}
                    onSubmit={props.onSubmit}
                    operationList={operationList}
                    officeList={officeList}
                />
            </Paper>
        </Modal>
    );
}

export default NewMissionModal;