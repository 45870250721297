import React, { useState, useEffect, useRef } from "react"
import { useParams } from "react-router-dom"
import {
    Typography,
    Stack,
    Divider
} from "@mui/material"

import dayjs from "dayjs"

import { Mission } from "../../../classes/Mission"
import { Asset } from "../../../classes/Asset"
import { Operator } from "../../../classes/Operator"
import { MissionAssetAssignment } from "../../../classes/MissionAssetAssignment"
import { MissionOperatorAssignment } from "../../../classes/MissionOperatorAssignment"

import getMissionById from "../../../data/mission/getMissionById"
import { MissionNote } from "../../../classes/MissionNote"
import { Point } from "../../../classes/Point"
import { FlightPath } from "../../../classes/FlightPath"
import { OperatingArea } from "../../../classes/OperatingArea"
import Box from "@mui/material/Box";
import {Waypoint} from "../../../classes/Waypoint";
import {OperatingAreaPoint} from "../../../classes/OperatingAreaPoint";
import {Deconfliction} from "../../../classes/Deconfliction";
import {MapRenderer} from "../../../classes/MapRenderer";

export default function IndividualMissionPrintable() {
    const { missionId } = useParams()
    const canvasRef = useRef<HTMLCanvasElement>(null)

    const  [missionObject, setMissionObject] = useState<Mission | undefined>(undefined)

    if (missionObject !== undefined) {
        document.title = 'SAMS - Mission Details - ' + missionObject.name
    }

    useEffect(() => {
        getMissionById(Number.parseInt(missionId!))
            .then((result) => setMissionObject(result))
            .catch((err) => console.error(err))
    }, [])

    useEffect(() => {
        if (missionObject !== undefined) {
            const canvas = canvasRef.current
            const context = canvas!.getContext("2d")

            context!.canvas.width = 500
            context!.canvas.height = 500

            const renderer = new MapRenderer()
            renderer.width = context!.canvas.width
            renderer.height = context!.canvas.height
            renderer.points = missionObject!.points
            renderer.paths = missionObject!.flightPaths
            renderer.areas = missionObject!.operatingAreas

            renderer.setViewport()
            renderer.render2d(context!)
        }
    }, [missionObject])

    if (missionObject === undefined)  {
        return (<Typography>Loading...</Typography>)
    }

    return (
        <Stack direction="column" spacing={1} padding={1} sx={{backgroundColor: "white"}}>
            {missionObject!.isDraft ? (
                <Typography color="red" variant="h6" textAlign="center">NOTICE: This mission is currently in a draft state.</Typography>
            ) : null}
            <Typography color="black" variant="h5">{missionObject!.name}</Typography>
            <Typography color="black">{`Operation: ${missionObject!.operationObject!.name}`}</Typography>
            <Stack direction="row" justifyContent="space-between">
                <Typography color="black">
                    {`Mission Number: ${missionObject!.missionNumber === undefined ? "(Not Assigned)" : missionObject!.missionNumber}`}
                </Typography>
                <Typography color="black">
                    {`Callsign: ${missionObject!.callsign === undefined ? "(Not Assigned)" : missionObject!.callsign}`}
                </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
                <Typography color="black">
                    {`Originating Office: ${missionObject!.originatingOfficeObject!.name}`}
                </Typography>
                <Typography color="black">
                    {`Tasked Organization: ${missionObject!.assignedOrganizationId === undefined ? "(Not Assigned)" : missionObject!.assignedOrganizationObject!.name}`}
                </Typography>
            </Stack>
            <Typography color="black">{missionObject!.purpose}</Typography>
            <Divider color="black"/>
            <Typography color="black" variant="h6">Objectives</Typography>
            {missionObject!.objectives!.map((objective, index) => (
                <Typography color="black">{`- ${objective.description}`}</Typography>
            ))}
            <Divider color="black"/>
            <Typography color="black" variant="h6">Timeline</Typography>
            <Stack direction="row" justifyContent="space-evenly">
                <Stack direction="column">
                    <Typography color="black">
                        {`Scheduled Start Time: ${missionObject!.timeStartScheduled.format('MM/DD/YYYY HH:mm')}`}
                    </Typography>
                    <Typography color="black">
                        {`Projected Start Time: ${missionObject!.timeStartProjected.format('MM/DD/YYYY HH:mm')}`}
                    </Typography>
                    <Typography color="black">
                        {`Actual Start Time: ${missionObject!.timeStartActual === undefined ? "(Not Yet Started)" : missionObject!.timeStartActual!.format('MM/DD/YYYY HH:mm')}`}
                    </Typography>
                </Stack>
                <Stack direction="column">
                    <Typography color="black">
                        {`Scheduled Finish Time: ${missionObject!.timeFinishScheduled.format('MM/DD/YYYY HH:mm')}`}
                    </Typography>
                    <Typography color="black">
                        {`Projected Finish Time: ${missionObject!.timeFinishProjected.format('MM/DD/YYYY HH:mm')}`}
                    </Typography>
                    <Typography color="black">
                        {`Actual Finish Time: ${missionObject!.timeFinishActual === undefined ? "(Not Yet Finished)" : missionObject!.timeFinishActual!.format('MM/DD/YYYY HH:mm')}`}
                    </Typography>
                </Stack>
            </Stack>
            <Divider color="black"/>
            <Typography color="black" variant="h6">Assignments</Typography>
            <Stack direction="row" justifyContent="space-evenly">
                <Stack direction="column" width="50%">
                    <Typography color="black">Assets:</Typography>
                    {missionObject!.assetAssignments!.map((assignment: MissionAssetAssignment) => (
                        <Stack direction="row" justifyContent="space-evenly">
                            <Typography color="black" width="25%">{assignment.assetObject!.callsign}</Typography>
                            <Typography color="black"
                                        width="30%">{`${assignment.assetObject!.productObject!.manufacturerObject!.name} ${assignment.assetObject!.productObject!.name}`}</Typography>
                            <Typography color="black" width="45%">{assignment.assetObject!.serialNumber}</Typography>
                        </Stack>
                    ))}
                </Stack>
                <Divider color="black" orientation="vertical"/>
                <Stack direction="column" width="50%">
                    <Typography color="black">Operators:</Typography>
                    {missionObject!.operatorAssignments!.map((assignment: MissionOperatorAssignment) => (
                        <Stack direction="row" justifyContent="space-evenly">
                            <Typography color="black" width="25%">{assignment.operatorObject!.callsign}</Typography>
                            <Typography color="black" width="20%">{assignment.operatorObject!.rank}</Typography>
                            <Typography color="black"
                                        width="40%">{`${assignment.operatorObject!.firstName} ${assignment.operatorObject!.lastName}`}</Typography>
                            <Typography color="black" width="15%">
                                {`${assignment.isRPIC ? "RPIC " : ""} ${assignment.isMO ? " MO " : ""} ${assignment.isVO ? " VO" : ""}`}
                            </Typography>
                        </Stack>
                    ))}
                </Stack>
            </Stack>
            <Divider color="black"/>
            <Typography color="black" variant="h6">Notes</Typography>
            {missionObject!.notes!.map((note: MissionNote) => (
                <Stack direction="row" justifyContent="space-evenly">
                    <Typography color="black" width="25%">{note.timestamp.format()}</Typography>
                    <Typography color="black" width="15%">{note.authorId}</Typography>
                    <Typography color="black" width="60%">{note.note}</Typography>
                </Stack>
            ))}
            <Divider color="black"/>
            <Typography color="black" variant="h6">Geospatial Information</Typography>
            <Typography color="black">Map:</Typography>
            <Box
                sx={{
                    width: "100%",
                    height: "auto",
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderColor: "black"
                }}
            >
                <canvas ref={canvasRef} />
            </Box>
            <Stack direction="row" spacing={1}>
                <Stack direction="column" width="33%" spacing={1}>
                    <Typography color="black">Points:</Typography>
                    {missionObject!.points!.map((point: Point) => (
                        <Box
                            sx={{
                                borderStyle: "solid",
                                borderColor: "gray",
                                borderWidth: 1,
                            }}
                        >
                            <Stack direction="column" padding={1}>
                                <Typography color="black" overflow="clip">{point.label}</Typography>
                                <Stack direction="row" justifyContent="space-between" overflow="clip">
                                    <Typography color="black" variant="subtitle2">Latitude:</Typography>
                                    <Typography color="black" variant="subtitle2">{point.latitude}</Typography>
                                </Stack>
                                <Stack direction="row" justifyContent="space-between" overflow="clip">
                                    <Typography color="black" variant="subtitle2">Longitude:</Typography>
                                    <Typography color="black" variant="subtitle2">{point.longitude}</Typography>
                                </Stack>
                                <Stack direction="row" justifyContent="space-between" overflow="clip">
                                    <Typography color="black" variant="subtitle2">Altitude:</Typography>
                                    <Typography color="black" variant="subtitle2">{point.altitude}</Typography>
                                </Stack>
                                <Stack direction="row" justifyContent="space-between" overflow="clip">
                                    <Typography color="black" variant="subtitle2">Takeoff Point:</Typography>
                                    <Typography color="black" variant="subtitle2">{`${point.isTakeoffPoint}`}</Typography>
                                </Stack>
                                <Stack direction="row" justifyContent="space-between" overflow="clip">
                                    <Typography color="black" variant="subtitle2">Landing Point:</Typography>
                                    <Typography color="black" variant="subtitle2">{`${point.isLandingPoint}`}</Typography>
                                </Stack>
                            </Stack>
                        </Box>
                    ))}
                </Stack>
                <Stack direction="column" width="33%" spacing={1}>
                    <Typography color="black">Flight Paths:</Typography>
                    {missionObject!.flightPaths!.map((flightPath: FlightPath) => {
                        const pts = flightPath.waypoints.map((p: Waypoint) => (
                            <Stack direction="column">
                                <Divider color="gray"/>
                                <Stack direction="row" spacing={1} justifyContent="space-between" overflow="clip">
                                    <Typography color="black" variant="subtitle2">Sequence:</Typography>
                                    <Typography color="black" variant="subtitle2">{p.sequence}</Typography>
                                </Stack>
                                <Stack direction="row" spacing={1} justifyContent="space-between" overflow="clip">
                                    <Typography color="black" variant="subtitle2">Latitude:</Typography>
                                    <Typography color="black" variant="subtitle2">{p.latitude}</Typography>
                                </Stack>
                                <Stack direction="row" spacing={1} justifyContent="space-between" overflow="clip">
                                    <Typography color="black" variant="subtitle2">Longitude:</Typography>
                                    <Typography color="black" variant="subtitle2">{p.longitude}</Typography>
                                </Stack>
                                <Stack direction="row" spacing={1} justifyContent="space-between" overflow="clip">
                                    <Typography color="black" variant="subtitle2">Altitude:</Typography>
                                    <Typography color="black" variant="subtitle2">{p.altitude}</Typography>
                                </Stack>
                            </Stack>
                        ))

                        return (
                            <Box
                                sx={{
                                    borderStyle: "solid",
                                    borderColor: "gray",
                                    borderWidth: 1,
                                }}
                            >
                                <Stack direction="column" padding={1}>
                                    <Typography color="black" overflow="clip">{flightPath.label}</Typography>
                                    {pts}
                                </Stack>
                            </Box>
                        )
                    })}
                </Stack>
                <Stack direction="column" width="33%" spacing={1}>
                    <Typography color="black">Operating Areas:</Typography>
                    {missionObject!.operatingAreas!.map((operatingArea: OperatingArea) => {
                        const pts = operatingArea.points.map((p: OperatingAreaPoint) => (
                            <Stack direction="column">
                                <Divider color="gray"/>
                                <Stack direction="row" spacing={1} justifyContent="space-between" overflow="clip">
                                    <Typography color="black" variant="subtitle2">Sequence:</Typography>
                                    <Typography color="black" variant="subtitle2">{p.sequence}</Typography>
                                </Stack>
                                <Stack direction="row" spacing={1} justifyContent="space-between" overflow="clip">
                                    <Typography color="black" variant="subtitle2">Latitude:</Typography>
                                    <Typography color="black" variant="subtitle2">{p.latitude}</Typography>
                                </Stack>
                                <Stack direction="row" spacing={1} justifyContent="space-between" overflow="clip">
                                    <Typography color="black" variant="subtitle2">Longitude:</Typography>
                                    <Typography color="black" variant="subtitle2">{p.longitude}</Typography>
                                </Stack>
                            </Stack>
                        ))

                        return (
                            <Box
                                sx={{
                                    borderStyle: "solid",
                                    borderColor: "gray",
                                    borderWidth: 1,
                                }}
                            >
                                <Stack direction="column" padding={1}>
                                    <Typography color="black" overflow="clip">{operatingArea.label}</Typography>
                                    <Typography
                                        color="black">{`Floor: ${operatingArea.altitudeFloor}, Ceiling: ${operatingArea.altitudeCeil} ${operatingArea.isAGL ? "AGL" : "MSL"}`}</Typography>
                                    {pts}
                                </Stack>
                            </Box>
                        )
                    })}
                </Stack>
            </Stack>
            <Divider color="black"/>
            <Typography color="black" variant="h6">Organizational Deconfliction</Typography>
            {missionObject!.deconflictions!.map((deconfliction: Deconfliction) => {
                function getStatusString() {
                    if (deconfliction.isConcur === undefined) {
                        return "Pending";
                    }

                    return deconfliction.isConcur ? "Concur" : "Non-Concur"
                }

                return (
                    <Stack direction="row" justifyContent="space-evenly">
                        <Typography color="black"
                                    width="25%">{deconfliction.officeObject!.organizationObject!.name}</Typography>
                        <Typography color="black"
                                    width="25%">{`${deconfliction.officeObject!.name} (${deconfliction.officeObject!.symbol})`}</Typography>
                        <Typography color="black"
                                    width="40%">{`${deconfliction.description} ${deconfliction.reason === undefined ? "" : `ANSWER: ${deconfliction.reason}`}`}</Typography>
                        <Typography color="black" width="10%">{getStatusString()}</Typography>
                    </Stack>
                )
            })}
            <Divider color="black"/>
        </Stack>
    )
}