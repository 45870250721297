export class OperatingAreaPoint {
    id?: number
    areaId: number = 0
    sequence: number = 0
    latitude: number = 0.0
    longitude: number = 0.0

    parseObject(input: any) {
        this.id = input.id
        this.areaId = input.areaId
        this.sequence = input.sequence
        this.latitude = input.latitude
        this.longitude = input.longitude
    }

    objectify(): object {
        return {
            id: this.id,
            areaId: this.areaId,
            sequence: this.sequence,
            latitude: this.latitude,
            longitude: this.longitude
        }
    }
}