import React, { useState } from "react"
import {
    Stack,
    Typography,
    Button, Divider
} from "@mui/material"
import Joi from "joi"
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import {Office} from "../../../classes/Office";

function ManageUserOfficesLayout(props: {
    title: string,
    userName: string,
    officeList: Office[],
    userOfficeList: Office[],
    onAddUserToOffice: Function,
    onRemoveUserFromOffice: Function,
    onCancel: Function
}) {

    const [selectedOfficeId, setSelectedOfficeId] = useState<any>(null)

    if (props.officeList === undefined || props.officeList === null) {
        return (<Typography variant="h6">Loading...</Typography>)
    } else if (props.userOfficeList === undefined || props.userOfficeList === null) {
        return (<Typography variant="h6">Loading...</Typography>)
    }

    const officeListComponents = props.userOfficeList.map((o: any) => [(
        <Stack direction="row" justifyContent="space-between">
            <Typography>{o.name}</Typography>
            <Button variant="contained" onClick={() => props.onRemoveUserFromOffice(o.id)}>Remove</Button>
        </Stack>
    ), (<Divider />)])

    return (
        <Stack direction="column" spacing={2}>
            <Typography variant="h6">{props.title}</Typography>
            <Stack direction="row" spacing={1}>
                <FormControl fullWidth>
                    <InputLabel id="office-select">Select Office</InputLabel>
                    <Select
                        labelId="office-select"
                        label="Select Office"
                        value={selectedOfficeId}
                        onChange={(event) => setSelectedOfficeId(event.target.value)}
                    >
                        {props.officeList.map((p: Office) => (<MenuItem value={p.id}>{`${p.name} (${p.symbol})`}</MenuItem>))}
                    </Select>
                </FormControl>
                <Button variant="contained" onClick={() => props.onAddUserToOffice(selectedOfficeId)}>Add</Button>
            </Stack>

            <Typography>{`${props.userName}'s current offices:`}</Typography>
            {officeListComponents}

            <Stack direction="row" spacing={1} justifyContent="right">
                <Button variant="text" onClick={() => props.onCancel()}>Close</Button>
            </Stack>
        </Stack>
    )
}

export default ManageUserOfficesLayout