import React from 'react';
import { useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import Joi from 'joi';
import {Organization} from "../../../classes/Organization";

function EditOrganizationLayout(props: {
    title: string,
    initInfo: Organization,
    organizationList?: Organization[],
    onSubmit: Function,
    onCancel: Function
}) {

    const [isPendingSubmit, setPendingSubmit] = useState(false)
    const [inputParentId, setInputParentId] = useState<number | undefined>(props.initInfo.parentId)
    const [inputName, setInputName] = useState<string>(props.initInfo.name)
    const [inputSymbol, setInputSymbol] = useState<string>(props.initInfo.symbol)

    const isNameValid = Joi.string().min(1).max(100).required().validate(inputName)
    const isSymbolValid = Joi.string().max(20).required().validate(inputSymbol)
    const isValid = (!isNameValid.error && !isSymbolValid.error);

    function handleSubmit() {
        setPendingSubmit(true);

        const inputOrganization = props.initInfo
        inputOrganization.parentId = inputParentId
        inputOrganization.name = inputName
        inputOrganization.symbol = inputSymbol

        props.onSubmit(inputOrganization)
    }

    const bottomControls = isPendingSubmit === true ? (<Typography>Loading...</Typography>) : [(<Button variant='text' onClick={() => props.onCancel()}>Cancel</Button>), (<Button variant='contained' disabled={!isValid} onClick={handleSubmit}>Submit</Button>)];

    if (props.organizationList === undefined || props.organizationList === null) {
        return (<Typography variant="h6">Loading...</Typography>)
    }

    return (
        <Stack direction="column" spacing={2}>
            <Typography variant="h6">{props.title}</Typography>
            <FormControl fullWidth disabled={isPendingSubmit}>
                <InputLabel id="parent-organization-select">Parent Organization</InputLabel>
                <Select
                    labelId="parent-organization-select"
                    label="Parent Organization"
                    value={inputParentId}
                    onChange={(event: any) => setInputParentId(event.target.value)}
                >
                    <MenuItem value={undefined}>none</MenuItem>
                    {props.organizationList.map((p: Organization) => (<MenuItem value={p.id}>{p.name}</MenuItem>))}
                </Select>
            </FormControl>
            <TextField
                type="text"
                variant="outlined"
                label="Organization Name"
                disabled={isPendingSubmit}
                value={inputName}
                onChange={(e) => setInputName(e.target.value)}
            />
            <TextField
                type="text"
                variant="outlined"
                label="Organization Symbol"
                disabled={isPendingSubmit}
                value={inputSymbol}
                onChange={(e) => setInputSymbol(e.target.value)}
            />
            <Stack direction="row" spacing={1} justifyContent="right">{bottomControls}</Stack>
        </Stack>
    );
}

export default EditOrganizationLayout;