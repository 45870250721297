import React, { useEffect, useState } from "react"
import { Modal, Paper } from "@mui/material"

import AssignMissionNumberLayout from "../layouts/AssignMissionNumberLayout"

import { Mission } from "../../../classes/Mission"

export default function AssignMissionNumberModal(props: {
    missionData: Mission,
    isOpen: boolean,
    onClose: Function,
    onSubmit: Function
}) {

    return (
        <Modal
            open={props.isOpen}
            onClose={() => props.onClose()}
        >
            <Paper
                sx={{
                    position: 'absolute',
                    width: '600px',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    padding: 2
                }}
            >
                <AssignMissionNumberLayout
                    title="Assign Mission Number"
                    initInfo={props.missionData}
                    onSubmit={props.onSubmit}
                    onCancel={() => props.onClose()}
                />
            </Paper>
        </Modal>
    )
}