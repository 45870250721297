import React from 'react';
import { useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import Joi from 'joi';

function ResetUserPassphraseLayout(props: {
    title: string,
    onCancel: Function,
    onSubmit: Function
}) {

    const [isPendingSubmit, setPendingSubmit] = useState<boolean>(false);
    const [inputPassphrase, setInputPassphrase] = useState<string>("");

    const isPassphraseValid = Joi.string().min(8).required().validate(inputPassphrase);

    const isValid = (!isPassphraseValid.error);

    function handleSubmit() {
        setPendingSubmit(true);
        props.onSubmit(inputPassphrase);
    }

    const bottomControls = isPendingSubmit === true ? (<Typography>Loading...</Typography>) : [(<Button variant='text' onClick={() => props.onCancel()}>Cancel</Button>), (<Button variant='contained' disabled={!isValid} onClick={handleSubmit}>Submit</Button>)];

    return (
        <Stack direction="column" spacing={2}>
            <Typography variant="h6">{props.title}</Typography>
            <TextField
                type="password"
                variant="outlined"
                label="Set Passphrase"
                disabled={isPendingSubmit}
                value={inputPassphrase}
                onChange={(e) => setInputPassphrase(e.target.value)}
            />
            <Stack direction="row" spacing={1} justifyContent="right">{bottomControls}</Stack>
        </Stack>
    );
}

export default ResetUserPassphraseLayout;