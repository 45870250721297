import React from 'react';
import { Paper, Stack, Button } from '@mui/material';

function MissionObjectiveActionPanel(props: { onClickNew: Function, sx?: object }) {

    return (
        <Paper sx={props.sx}>
            <Stack direction="row" justifyContent="center" spacing={1} padding={2}>
                <Button variant="contained" onClick={() => props.onClickNew()}>New</Button>
            </Stack>
        </Paper>
    );
}

export default MissionObjectiveActionPanel;