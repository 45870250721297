import dayjs, { Dayjs } from "dayjs"
import { User } from "./User"

export class MissionNote {
    id?: number
    missionId: number = 0
    authorId?: string
    timestamp: Dayjs = dayjs()
    note: string = ""

    authorObject?: User

    parseObject(input: any) {
        this.id = input.id
        this.missionId = input.missionId
        this.authorId = input.authorId
        this.timestamp = dayjs(input.timestamp)
        this.note = input.note

        if (input.authorObject !== undefined) {
            const a = new User()
            a.parseObject(input.authorObject)
            this.authorObject = a
        }
    }

    objectify(): object {
        return {
            id: this.id,
            missionId: this.missionId,
            authorId: this.authorId,
            timestamp: this.timestamp.format(),
            note: this.note,
            authorObject: this.authorObject?.objectify()
        }
    }
}