import dayjs, { Dayjs } from "dayjs"

export class OperatorReadinessRequirement {
    id?: number
    operatorId: number = 0
    subject: string = ""
    certificationIsInvalidated: boolean = false
    certificationIssued: Dayjs = dayjs()
    certificationRenewed: Dayjs = dayjs()
    certificationExpires: Dayjs = dayjs()

    parseObject(input: any) {
        this.id = input.id
        this.operatorId = input.operatorId
        this.subject = input.subject
        this.certificationIsInvalidated = input.certificationIsInvalidated
        this.certificationIssued = dayjs(input.certificationIssued)
        this.certificationRenewed = dayjs(input.certificationRenewed)
        this.certificationExpires = dayjs(input.certificationExpires)
    }

    objectify(): object {
        return {
            id: this.id,
            operatorId: this.operatorId,
            subject: this.subject,
            certificationIsInvalidated: this.certificationIsInvalidated,
            certificationIssued: this.certificationIssued.format(),
            certificationRenewed: this.certificationRenewed.format(),
            certificationExpires: this.certificationExpires.format()
        }
    }
}