import React, { useState } from 'react'
import { useNavigate } from "react-router-dom"
import { Stack } from '@mui/material'

import MissionTitlePanel from '../components/MissionTitlePanel';
import MissionTimelinePanel from '../components/MissionTimelinePanel';
import EditMissionTitleModal from "../modals/EditMissionTitleModal";
import RescheduleMissionModal from "../modals/RescheduleMissionModal";
import DelayMissionModal from "../modals/DelayMissionModal";
import ConfirmStartMissionModal from "../modals/ConfirmStartMissionModal";
import ConfirmCompleteMissionModal from "../modals/ConfirmCompleteMissionModal";
import ConfirmCancelMissionModal from "../modals/ConfirmCancelMissionModal";
import updateMissionById from "../../../data/mission/updateMissionById";
import delayMissionById from "../../../data/mission/delayMissionById"
import rescheduleMissionById from "../../../data/mission/rescheduleMissionById"
import startMissionById from "../../../data/mission/startMissionById";
import completeMissionById from "../../../data/mission/completeMissionById";
import cancelMissionById from "../../../data/mission/cancelMissionById";
import MissionFlagPanel from '../components/MissionFlagPanel';
import {Mission} from "../../../classes/Mission";
import {Dayjs} from "dayjs";
import AssignCallsignModal from "../modals/AssignCallsignModal";
import AssignMissionNumberModal from "../modals/AssignMissionNumberModal";
import finalizeMissionById from "../../../data/mission/finalizeMissionById";
import redraftMissionById from "../../../data/mission/redraftMissionById";
import assignMissionNumberToMissionById from "../../../data/mission/assignMissionNumberToMissionById";
import assignCallsignToMissionById from "../../../data/mission/assignCallsignToMissionById";
import Button from "@mui/material/Button";
import config from "../../../data/config";
import ConfirmFinalizeMissionModal from "../modals/ConfirmFinalizeMissionModal"
import ConfirmRedraftMissionModal from "../modals/ConfirmRedraftMissionModal"
import ConfirmRedraftMissionPoint from "../modals/ConfirmRedraftMissionModal";

function MissionSummaryTab(props: { missionData: Mission, onRefresh: Function, onNetworkError: Function }) {
    const navigate = useNavigate()

    const [isEditMissionTitleModalOpen, setEditMissionTitleModalOpen] = useState(false)
    const [isAssignMissionNumberModalOpen, setAssignMissionNumberModalOpen] = useState(false)
    const [isAssignCallsignModalOpen, setAssignCallsignModalOpen] = useState(false)
    const [isRescheduleModalOpen, setRescheduleModalOpen] = useState(false)
    const [isDelayModalOpen, setDelayModalOpen] = useState(false)
    const [isConfirmStartMissionModalOpen, setConfirmStartMissionModalOpen] = useState(false);
    const [isConfirmCompleteMissionModalOpen, setConfirmCompleteMissionModalOpen] = useState(false);
    const [isConfirmCancelMissionModalOpen, setConfirmCancelMissionModalOpen] = useState(false);
    const [isConfirmFinalizeMissionModalOpen, setConfirmFinalizeMissionModalOpen] = useState<boolean>(false)
    const [isConfirmRedraftMissionModalOpen, setConfirmRedraftMissionModalOpen] = useState<boolean>(false)

    function handleEditMissionTitle(operationId: number, name: string, purpose: string) {
        const editMission = props.missionData
        editMission.operationId = operationId
        editMission.name = name
        editMission.purpose = purpose

        updateMissionById(editMission)
            .then(() => props.onRefresh())
            .then(() => setEditMissionTitleModalOpen(false))
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    function handleFinalizeDraft() {
        finalizeMissionById(props.missionData.id!)
            .then(() => props.onRefresh())
            .then(() => setConfirmFinalizeMissionModalOpen(false))
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    function handleRedraft() {
        redraftMissionById(props.missionData.id!)
            .then(() => props.onRefresh())
            .then(() => setConfirmRedraftMissionModalOpen(false))
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    function handleAssignMissionNumber(missionNumber: string) {
        assignMissionNumberToMissionById(props.missionData.id!, missionNumber)
            .then(() => props.onRefresh())
            .then(() => setAssignMissionNumberModalOpen(false))
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    function handleAssignCallsign(callsign: string) {
        assignCallsignToMissionById(props.missionData.id!, callsign)
            .then(() => props.onRefresh())
            .then(() => setAssignCallsignModalOpen(false))
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    function handleRescheduleMission(timeStart: Dayjs, timeFinish: Dayjs) {
        rescheduleMissionById(props.missionData.id!, timeStart, timeFinish)
            .then(() => props.onRefresh())
            .then(() => setRescheduleModalOpen(false))
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    function handleDelayMission(timeStart: Dayjs, timeFinish: Dayjs) {
        delayMissionById(props.missionData.id!, timeStart, timeFinish)
            .then(() => props.onRefresh())
            .then(() => setDelayModalOpen(false))
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    function handleStartMission() {
        startMissionById(props.missionData.id!)
            .then(() => props.onRefresh())
            .then(() => setConfirmStartMissionModalOpen(false))
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    function handleCompleteMission() {
        completeMissionById(props.missionData.id!)
            .then(() => props.onRefresh())
            .then(() => setConfirmCompleteMissionModalOpen(false))
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    function handleCancelMission() {
        cancelMissionById(props.missionData.id!)
            .then(() => props.onRefresh())
            .then(() => setConfirmCancelMissionModalOpen(false))
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    return (
        <Stack direction="column" padding={2} spacing={2}>
            <Stack direction="row" spacing={2}>
                <MissionTitlePanel
                    sx={{ width: "60%" }}
                    missionData={props.missionData}
                    onClickEdit={() => setEditMissionTitleModalOpen(true)}
                    onClickAssignCallsign={() => setAssignCallsignModalOpen(true)}
                    onClickAssignMissionNumber={() => setAssignMissionNumberModalOpen(true)}
                    onClickFinalizeDraft={() => setConfirmFinalizeMissionModalOpen(true)}
                    onClickRedraft={() => setConfirmRedraftMissionModalOpen(true)}
                />
                <MissionFlagPanel
                    sx={{ width: "40%" }}
                    missionData={props.missionData}
                />
            </Stack>
            <MissionTimelinePanel
                sx={{ width: '100%' }}
                missionData={props.missionData}
                onClickReschedule={() => setRescheduleModalOpen(true)}
                onClickDelay={() => setDelayModalOpen(true)}
                onClickCancel={() => setConfirmCancelMissionModalOpen(true)}
                onClickConfirmStart={() => setConfirmStartMissionModalOpen(true)}
                onClickConfirmCompletion={() => setConfirmCompleteMissionModalOpen(true)}
            />
            <Button
                variant="text"
                onClick={() => window.open(config.uiRoute + `/missions/id/${props.missionData.id!}/printable`, "_blank")}
            >
                Printer Friendly Page
            </Button>
            <EditMissionTitleModal
                isOpen={isEditMissionTitleModalOpen}
                onClose={() => setEditMissionTitleModalOpen(false)}
                missionData={props.missionData}
                onSubmit={handleEditMissionTitle}
                onNetworkError={props.onNetworkError}
            />
            <AssignCallsignModal
                isOpen={isAssignCallsignModalOpen}
                missionData={props.missionData}
                onSubmit={handleAssignCallsign}
                onClose={() => setAssignCallsignModalOpen(false)}
            />
            <AssignMissionNumberModal
                isOpen={isAssignMissionNumberModalOpen}
                missionData={props.missionData}
                onSubmit={handleAssignMissionNumber}
                onClose={() => setAssignMissionNumberModalOpen(false)}
            />
            <RescheduleMissionModal
                isOpen={isRescheduleModalOpen}
                onClose={() => setRescheduleModalOpen(false)}
                missionData={props.missionData}
                onSubmit={handleRescheduleMission}
            />
            <DelayMissionModal
                isOpen={isDelayModalOpen}
                onClose={() => setDelayModalOpen(false)}
                missionData={props.missionData}
                onSubmit={handleDelayMission}
            />
            <ConfirmStartMissionModal
                isOpen={isConfirmStartMissionModalOpen}
                onClose={() => setConfirmStartMissionModalOpen(false)}
                onConfirm={handleStartMission}
            />
            <ConfirmCompleteMissionModal
                isOpen={isConfirmCompleteMissionModalOpen}
                onClose={() => setConfirmCompleteMissionModalOpen(false)}
                onConfirm={handleCompleteMission}
            />
            <ConfirmCancelMissionModal
                isOpen={isConfirmCancelMissionModalOpen}
                onClose={() => setConfirmCancelMissionModalOpen(false)}
                onConfirm={handleCancelMission}
            />
            <ConfirmFinalizeMissionModal
                isOpen={isConfirmFinalizeMissionModalOpen}
                onClose={() => setConfirmFinalizeMissionModalOpen(false)}
                onConfirm={handleFinalizeDraft}
            />
            <ConfirmRedraftMissionPoint
                isOpen={isConfirmRedraftMissionModalOpen}
                onClose={() => setConfirmRedraftMissionModalOpen(false)}
                onConfirm={handleRedraft}
            />
        </Stack>
    )
}

export default MissionSummaryTab