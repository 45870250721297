import React, { useState } from "react"
import {
    Stack,
    Typography,
    Button, Divider
} from "@mui/material"
import Joi from "joi"
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import {Organization} from "../../../classes/Organization";

function ManageUserOrganizationsLayout(props: {
    title: string,
    userName: string,
    organizationList: Organization[],
    userOrganizationList: Organization[],
    onAddUserToOrganization: Function,
    onRemoveUserFromOrganization: Function,
    onCancel: Function
}) {

    const [selectedOrganizationId, setSelectedOrganizationId] = useState<any>(null)

    if (props.organizationList === undefined || props.organizationList === null) {
        return (<Typography variant="h6">Loading...</Typography>)
    } else if (props.userOrganizationList === undefined || props.userOrganizationList === null) {
        return (<Typography variant="h6">Loading...</Typography>)
    }

    const organizationListComponents = props.userOrganizationList.map((o: any) => [(
        <Stack direction="row" justifyContent="space-between">
            <Typography>{o.name}</Typography>
            <Button variant="contained" onClick={() => props.onRemoveUserFromOrganization(o.id)}>Remove</Button>
        </Stack>
    ), (<Divider />)])

    return (
        <Stack direction="column" spacing={2}>
            <Typography variant="h6">{props.title}</Typography>
            <Stack direction="row" spacing={1}>
                <FormControl fullWidth>
                    <InputLabel id="office-select">Select Office</InputLabel>
                    <Select
                        labelId="office-select"
                        label="Select Office"
                        value={selectedOrganizationId}
                        onChange={(event) => setSelectedOrganizationId(event.target.value)}
                    >
                        {props.organizationList.map((p: Organization) => (<MenuItem value={p.id}>{`${p.name} (${p.symbol})`}</MenuItem>))}
                    </Select>
                </FormControl>
                <Button variant="contained" onClick={() => props.onAddUserToOrganization(selectedOrganizationId)}>Add</Button>
            </Stack>

            <Typography>{`${props.userName}'s current organizations:`}</Typography>
            {organizationListComponents}

            <Stack direction="row" spacing={1} justifyContent="right">
                <Button variant="text" onClick={() => props.onCancel()}>Close</Button>
            </Stack>
        </Stack>
    )
}

export default ManageUserOrganizationsLayout