import {Product} from "./Product"
import {Office} from "./Office"
import {AssetReadiness} from "./AssetReadiness"
import {AssetEmergencyReservation} from "./AssetEmergencyReservation";
import {Mission} from "./Mission";

export class Asset {
    id?: number
    productId: number = 0
    officeId: number = 0
    serialNumber: string = ""
    serialNumberRID: string = ""
    callsign: string = ""

    productObject?: Product
    officeObject?: Office
    readiness?: AssetReadiness
    emergencyReservations?: AssetEmergencyReservation[]
    assignedMissionList?: Mission[]

    parseObject(input: any) {
        this.id = input.id
        this.productId = input.productId
        this.officeId = input.officeId
        this.serialNumber = input.serialNumber
        this.serialNumberRID = input.serialNumberRID
        this.callsign = input.callsign

        if (input.productObject !== undefined) {
            const p = new Product()
            p.parseObject(input.productObject)
            this.productObject = p
        }

        if (input.officeObject !== undefined) {
            const o = new Office()
            o.parseObject(input.officeObject)
            this.officeObject = o
        }

        if (input.readiness !== undefined) {
            const r = new AssetReadiness()
            r.parseObject(input.readiness)
            this.readiness = r
        }

        if (input.emergencyReservations !== undefined) {
            this.emergencyReservations = input.emergencyReservations.map((obj: any) => {
                const r = new AssetEmergencyReservation()
                r.parseObject(obj)
                return r
            })
        }

        if (input.assignedMissionList !== undefined) {
            this.assignedMissionList = input.assignedMissionList.map((obj: any) => {
                const m = new Mission()
                m.parseObject(obj)
                return m
            })
        }
    }

    objectify(): object {
        return {
            id: this.id,
            productId: this.productId,
            officeId: this.officeId,
            serialNumber: this.serialNumber,
            serialNumberRID: this.serialNumberRID,
            callsign: this.callsign,
            productObject: this.productObject?.objectify(),
            officeObject: this.officeObject?.objectify(),
            readiness: this.readiness?.objectify(),
            emergencyReservations: this.emergencyReservations?.map((r) => r.objectify()),
            assignedMissionList: this.assignedMissionList?.map((m) => m.objectify())
        }
    }
}