import React, {useState} from 'react';
import { Paper, Stack, Typography, Divider, Button, Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import {Mission} from "../../../classes/Mission";
import {Asset} from "../../../classes/Asset";
import {MissionAssetAssignment} from "../../../classes/MissionAssetAssignment";

function MissionAssignedAssetsPanel(props: {
    missionData: Mission,
    onClickAssign: Function,
    onClickRemove: Function,
    sx?: object
}) {

    const [selectionModel, setSelectionModel] = useState([])

    const columns = [
        { field: 'callsign', headerName: 'Callsign', width: 120, valueGetter: (params: any) => params.row.assetObject!.callsign },
        { field: 'model', headerName: 'Make / Model', width: 200, valueGetter: (params: any) => {
            return `${params.row.assetObject!.productObject!.manufacturerObject!.name} ${params.row.assetObject!.productObject!.name}`
        }},
        { field: 'serialNumber', headerName: 'Serial', width: 200, valueGetter: (params: any) => params.row.assetObject!.serialNumber },
        { field: 'error', headerName: 'Audit Flags', width: 100, valueGetter: (params: any) => {
            const matches = props.missionData.flags!.filter((f) => params.row.assetId === f.assetId)
            return matches.length > 0 ? matches.length : ""
        }}
    ]

    function getSelectedAsset() {
        const assignment = props.missionData.assetAssignments!.filter(
            (a: MissionAssetAssignment) => a.id === selectionModel[0]
        )

        if (assignment.length === 1) {
            return assignment[0].assetId
        }

        return -1
    }

    const buttonBar = props.missionData.userPermissions!.modify && props.missionData.isDraft ? (
        <Stack direction="row" spacing={1}>
            <Button
                variant="contained"
                disabled={selectionModel.length !== 1}
                onClick={() => props.onClickRemove(getSelectedAsset())}
            >
                Remove
            </Button>
            <Button
                variant="contained"
                onClick={() => props.onClickAssign()}
            >
                Assign
            </Button>
        </Stack>
    ) : null

    return (
        <Paper sx={props.sx}>
            <Stack direction="column" spacing={1} padding={2}>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant='h5'>Assets</Typography>
                    {buttonBar}
                </Stack>
                <Divider />
                <Box sx={{ height: 400 }}>
                    <DataGrid
                        rows={props.missionData.assetAssignments!.map((assignment: MissionAssetAssignment) => assignment.objectify())}
                        columns={columns}
                        rowSelectionModel={selectionModel}
                        onRowSelectionModelChange={(e: any) => setSelectionModel(e)}
                    />
                </Box>
            </Stack>
        </Paper>
    );
}

export default MissionAssignedAssetsPanel;