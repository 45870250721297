import React from 'react';
import { Paper, Stack, Typography, Divider, TextField } from '@mui/material';
import {Asset} from "../../../classes/Asset";

function AssetTitlePanel(props: {
    assetData: Asset,
    sx?: object
}) {

    return (
        <Paper sx={props.sx}>
            <Stack direction="column" spacing={1} padding={2}>
                <Typography variant="h5">Asset Summary</Typography>
                <Divider />
                <Stack direction="row" spacing={1}>
                    <TextField InputProps={{ readOnly: true }} label="Office" value={`${props.assetData.officeObject?.name} (${props.assetData.officeObject?.symbol})`} sx={{ width: '25%' }} />
                    <TextField InputProps={{ readOnly: true }} label="Callsign" value={props.assetData.callsign}  sx={{ width: '25%' }} />
                    <TextField InputProps={{ readOnly: true }} label="Serial Number" value={props.assetData.serialNumber}  sx={{ width: '25%' }} />
                    <TextField InputProps={{ readOnly: true }} label="Remote ID" value={props.assetData.serialNumberRID} sx={{ width: '25%' }} />
                </Stack>
                <Stack direction="row" spacing={1}>
                    <TextField InputProps={{ readOnly: true }} label="Manufacturer" value={props.assetData.productObject?.manufacturerObject?.name}  sx={{ width: '50%' }} />
                    <TextField InputProps={{ readOnly: true }} label="Product Name" value={props.assetData.productObject?.name}  sx={{ width: '50%' }} />
                </Stack>
            </Stack>
        </Paper>
    );
}

export default AssetTitlePanel;