import React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Typography, Divider } from '@mui/material';

import { DataGrid } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import {Asset} from "../../../classes/Asset";
import {Mission} from "../../../classes/Mission";

function AssetMissionFlatList(props: {
    assetData: Asset,
    sx?: object
}) {

    const columns = [
        { field: 'name', headerName: 'Name', width: 250 },
        { field: 'timeStartScheduled', headerName: 'Scheduled Start Time', width: 250, valueGetter: (params: any) => { return dayjs(params.value).format('MM/DD/YYYY HH:mm').toString() }  },
        { field: 'timeFinishScheduled', headerName: 'Scheduled Finish Time', width: 250, valueGetter: (params: any) => { return dayjs(params.value).format('MM/DD/YYYY HH:mm').toString() }  },
    ];

    return (
        <Paper sx={props.sx}>
            <Stack direction="column" spacing={2} padding={2}>
                <Typography variant="h5">Assigned Missions</Typography>
                <Divider />
                <Box sx={{ height: 600 }}>
                    <DataGrid rows={props.assetData.assignedMissionList!.map((m: Mission) => m.objectify())} columns={columns} />
                </Box>
            </Stack>
        </Paper>
    );
}

export default AssetMissionFlatList;