import React from 'react';
import { Paper, Stack, Typography, Divider, Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import dayjs from 'dayjs';
import {AssetReadinessRequirement} from "../../../classes/AssetReadinessRequirement";
import {Asset} from "../../../classes/Asset";

function AssetReadinessRequirementsPanel(props: {
    assetData: Asset,
    selectedReadinessRequirementData?: AssetReadinessRequirement,
    onSelectReadinessRequirement: Function,
    sx?: object
}) {

    const columns = [
        { field: 'subject', headerName: 'Subject', width: 250 },
        { field: 'certificationExpires', headerName: 'Expires', width: 200, valueGetter: (params: any) => { return dayjs(params.value).format('MM/DD/YYYY').toString() } },
        { field: 'certificationRenewed', headerName: 'Last Renewed', width: 200, valueGetter: (params: any) => { return dayjs(params.value).format('MM/DD/YYYY').toString() } },
        { field: 'certificationIssued', headerName: 'Issued', width: 200, valueGetter: (params: any) => { return dayjs(params.value).format('MM/DD/YYYY').toString() } },
    ];

    return (
        <Paper sx={props.sx}>
            <Stack direction="column" spacing={1} padding={2}>
                <Typography variant="h5">Readiness Requirements</Typography>
                <Divider />
                <Box sx={{ height: 300 }}>
                    <DataGrid
                        rows={props.assetData.readiness!.requirements.map((r: AssetReadinessRequirement) => r.objectify())}
                        columns={columns}
                        rowSelectionModel={props.selectedReadinessRequirementData === undefined ? [] : props.selectedReadinessRequirementData.id}
                        onRowSelectionModelChange={(e) => props.onSelectReadinessRequirement(e)}
                    />
                </Box>
            </Stack>
        </Paper>
    );
}

export default AssetReadinessRequirementsPanel;