import React from 'react';
import { Stack } from '@mui/material';

import AssetReadinessStatusPanel from '../components/AssetReadinessStatusPanel';
import AssetReadinessActionPanel from '../components/AssetReadinessActionPanel';
import AssetReadinessRequirementsPanel from '../components/AssetReadinessRequirementsPanel';
import AssetReadinessProblemsPanel  from '../components/AssetReadinessProblemsPanel';
import {Asset} from "../../../classes/Asset";
import {AssetReadinessRequirement} from "../../../classes/AssetReadinessRequirement";
import {AssetReadinessProblem} from "../../../classes/AssetReadinessProblem";

function AssetReadinessLayout(props: {
    assetData: Asset,
    readinessProblemData?: AssetReadinessProblem,
    readinessRequirementData?: AssetReadinessRequirement,
    onClickCreateProblem: Function,
    onClickCreateRequirement: Function,
    onClickResolveProblem: Function,
    onClickRenewRequirement: Function,
    onSelectReadinessProblem: Function,
    onSelectReadinessRequirement: Function
}) {

    return (
        <Stack direction="column" padding={2} spacing={2}>
            <AssetReadinessStatusPanel assetData={props.assetData} />
            <AssetReadinessActionPanel
                createProblemDisabled={false}
                createRequirementDisabled={false}
                resolveProblemDisabled={props.readinessProblemData === undefined}
                renewRequirementDisabled={props.readinessRequirementData === undefined}
                onCreateProblem={props.onClickCreateProblem}
                onCreateRequirement={props.onClickCreateRequirement}
                onResolveProblem={props.onClickResolveProblem}
                onRenewRequirement={props.onClickRenewRequirement}
            />
            <AssetReadinessProblemsPanel
                assetData={props.assetData}
                sx={{ width: '100%' }}
                selectedReadinessProblemData={props.readinessProblemData}
                onSelectReadinessProblem={props.onSelectReadinessProblem}
            />
            <AssetReadinessRequirementsPanel
                assetData={props.assetData}
                sx={{ height: '100%' }}
                selectedReadinessRequirementData={props.readinessRequirementData}
                onSelectReadinessRequirement={props.onSelectReadinessRequirement}
            />
        </Stack>
    );
}

export default AssetReadinessLayout;