import React from 'react';
import { useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import Joi from 'joi';
import {Operator} from "../../../classes/Operator";
import {Office} from "../../../classes/Office";

function EditOperatorLayout(props: {
    title: string,
    initInfo: Operator,
    officeList?: Office[],
    onSubmit: Function,
    onCancel: Function
}) {

    const [isPendingSubmit, setPendingSubmit] = useState(false);
    const [inputOfficeId, setInputOfficeId] = useState(props.initInfo.officeId);
    const [inputRank, setInputRank] = useState(props.initInfo.rank);
    const [inputFirstName, setInputFirstName] = useState(props.initInfo.firstName);
    const [inputLastName, setInputLastName] = useState(props.initInfo.lastName);
    const [inputCallsign, setInputCallsign] = useState(props.initInfo.callsign);

    const isOfficeIdValid = Joi.number().integer().positive().required().validate(inputOfficeId);
    const isRankValid = Joi.string().min(1).max(10).required().validate(inputRank);
    const isFirstNameValid = Joi.string().min(1).max(50).required().validate(inputFirstName);
    const isLastNameValid = Joi.string().min(1).max(50).required().validate(inputLastName);
    const isCallsignValid = Joi.string().min(1).max(30).required().validate(inputCallsign);
    const isValid = (!isOfficeIdValid.error && !isRankValid.error && !isFirstNameValid.error && !isLastNameValid.error && !isCallsignValid.error);

    function handleSubmit() {
        setPendingSubmit(true);

        const inputOperator = props.initInfo
        inputOperator.officeId = inputOfficeId
        inputOperator.rank = inputRank
        inputOperator.firstName = inputFirstName
        inputOperator.lastName = inputLastName
        inputOperator.callsign = inputCallsign

        props.onSubmit(inputOperator)
    }

    const bottomControls = isPendingSubmit === true ? (<Typography>Loading...</Typography>) : [(<Button variant='text' onClick={() => props.onCancel()}>Cancel</Button>), (<Button variant='contained' disabled={!isValid} onClick={handleSubmit}>Submit</Button>)];

    if (props.officeList === undefined || props.officeList === null) {
        return (<Typography variant="h6">Loading...</Typography>)
    }

    return (
        <Stack direction="column" spacing={2}>
            <Typography variant="h6">{props.title}</Typography>
            <FormControl fullWidth disabled={isPendingSubmit}>
                <InputLabel id="office-select">Select Office</InputLabel>
                <Select
                    labelId="office-select"
                    label="Select Office"
                    value={inputOfficeId}
                    onChange={(event: any) => setInputOfficeId(event.target.value)}
                >
                    {props.officeList.map((p: Office) => (<MenuItem value={p.id}>{`${p.name} (${p.symbol})`}</MenuItem>))}
                </Select>
            </FormControl>
            <TextField
                type="text"
                variant="outlined"
                label="Rank"
                disabled={isPendingSubmit}
                value={inputRank}
                onChange={(e) => setInputRank(e.target.value)}
            />
            <TextField
                type="text"
                variant="outlined"
                label="First Name"
                disabled={isPendingSubmit}
                value={inputFirstName}
                onChange={(e) => setInputFirstName(e.target.value)}
            />
            <TextField
                type="text"
                variant="outlined"
                label="Last Name"
                disabled={isPendingSubmit}
                value={inputLastName}
                onChange={(e) => setInputLastName(e.target.value)}
            />
            <TextField
                type="text"
                variant="outlined"
                label="Callsign"
                disabled={isPendingSubmit}
                value={inputCallsign}
                onChange={(e) => setInputCallsign(e.target.value)}
            />
            <Stack direction="row" spacing={1} justifyContent="right">{bottomControls}</Stack>
        </Stack>
    );
}

export default EditOperatorLayout;