import React from 'react';
import { Stack } from '@mui/material';

import AssetEmergencyReservationFlatList from '../components/AssetEmergencyReservationFlatList';
import {Asset} from "../../../classes/Asset";

function AssetEmergencyReservationsLayout(props: {
    assetData: Asset
}) {

    return (
        <Stack direction="column" padding={2} spacing={2}>
            <AssetEmergencyReservationFlatList sx={{ width: '100%' }} assetData={props.assetData} />
        </Stack>
    );
}

export default AssetEmergencyReservationsLayout;