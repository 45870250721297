import React from 'react';
import { Paper, Stack, Button } from '@mui/material';
import {Mission} from "../../../classes/Mission";

function MissionGeospatialActionPanel(props: {
    missionData: Mission,
    onClickNewPoint: Function,
    onClickNewFlightPath: Function,
    onClickNewOperatingArea: Function,
    onClickUploadKml: Function,
    onClickDownloadKml: Function,
    sx?: object
}) {

    return (
        <Paper sx={props.sx}>
            <Stack direction="row" justifyContent="center" spacing={1} padding={2}>
                <Button
                    variant="contained"
                    disabled={!props.missionData.isDraft || !props.missionData.userPermissions!.modify}
                    onClick={() => props.onClickNewPoint()}
                >
                    New Point
                </Button>
                <Button
                    variant="contained"
                    disabled={!props.missionData.isDraft || !props.missionData.userPermissions!.modify}
                    onClick={() => props.onClickNewFlightPath()}
                >
                    New Flight Path
                </Button>
                <Button
                    variant="contained"
                    disabled={!props.missionData.isDraft || !props.missionData.userPermissions!.modify}
                    onClick={() => props.onClickNewOperatingArea()}
                >
                    New Operating Area
                </Button>
                <Button
                    variant="contained"
                    disabled={!props.missionData.isDraft || !props.missionData.userPermissions!.modify}
                    onClick={() => props.onClickUploadKml()}
                >
                    Upload KML
                </Button>
                <Button
                    variant="contained"
                    onClick={() => props.onClickDownloadKml()}
                >
                    Download KML
                </Button>
            </Stack>
        </Paper>
    );
}

export default MissionGeospatialActionPanel;