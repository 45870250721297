import React from "react"
import Stack from "@mui/material/Stack"
import Button from "@mui/material/Button"
import Paper from "@mui/material/Paper"

function UserActionBar(props: {
    onCreate: Function,
    onResetPassphrase: Function,
    onEnable: Function,
    onDisable: Function,
    onManageOrganizations: Function,
    onManageOffices: Function,
    createDisabled: boolean,
    resetPassphraseDisabled: boolean,
    enableDisabled: boolean,
    disableDisabled: boolean,
    manageOrganizationsDisabled: boolean,
    manageOfficesDisabled: boolean,
    sx?: object
}) {

    return (
        <Paper sx={props.sx}>
            <Stack direction="row" justifyContent="center" spacing={1} padding={2}>
                <Button variant="contained" disabled={props.createDisabled} onClick={() => props.onCreate()}>Create</Button>
                <Button variant="contained" disabled={props.resetPassphraseDisabled} onClick={() => props.onResetPassphrase()}>Reset Passphrase</Button>
                <Button variant="contained" disabled={props.enableDisabled} onClick={() => props.onEnable()}>Enable</Button>
                <Button variant="contained" disabled={props.disableDisabled} onClick={() => props.onDisable()}>Disabled</Button>
                <Button variant="contained" disabled={props.manageOrganizationsDisabled} onClick={() => props.onManageOrganizations()}>Manage Organizations</Button>
                <Button variant="contained" disabled={props.manageOfficesDisabled} onClick={() => props.onManageOffices()}>Manage Offices</Button>
            </Stack>
        </Paper>
    )
}

export default UserActionBar