import React, { useState } from "react"
import Stack from "@mui/material/Stack"
import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import {Typography, Divider, ToggleButtonGroup, ToggleButton} from "@mui/material"

import { DataGrid } from "@mui/x-data-grid"
import dayjs from "dayjs"
import {AssetEmergencyReservation} from "../../../classes/AssetEmergencyReservation";

function AssetEmergencyReservationFlatList(props: {
    activeReservations: AssetEmergencyReservation[],
    futureReservations: AssetEmergencyReservation[],
    selectedReservationObject?: AssetEmergencyReservation,
    onReservationSelectionChanged: Function,
    sx?: object
}) {

    const [selectedView, setSelectedView] = useState("active")

    const columns = [
        { field: "callsign", headerName: "Asset Callsign", width: 250, valueGetter: (params: any) => { return params.row.assetObject.callsign } },
        { field: "isAvailable", headerName: "Available for Tasking", width: 250 },
        { field: "timeBegin", headerName: "Time Reservation Begins", width: 250, valueGetter: (params: any) => { return dayjs(params.value).format("MM/DD/YYYY HH:mm").toString() } },
        { field: "timeEnd", headerName: "Time Reservation Ends", width: 250, valueGetter: (params: any) => { return dayjs(params.value).format("MM/DD/YYYY HH:mm").toString() } },
    ]

    function getSelectedView() {
        if (selectedView === "active") {
            return props.activeReservations.map((r: AssetEmergencyReservation) => r.objectify())
        } else if (selectedView === "future") {
            return props.futureReservations.map((r: AssetEmergencyReservation) => r.objectify())
        }

        return []
    }

    return (
        <Paper sx={props.sx}>
            <Stack direction="column" spacing={2} padding={2}>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h5">Asset Reservations</Typography>
                    <ToggleButtonGroup
                        size="small"
                        color="primary"
                        value={selectedView}
                        exclusive
                        onChange={(e: any) => setSelectedView(e.target.value)}
                    >
                        <ToggleButton value="active">Active</ToggleButton>
                        <ToggleButton value="future">Future</ToggleButton>
                    </ToggleButtonGroup>
                </Stack>
                <Divider />
                <Box sx={{ height: 600 }}>
                    <DataGrid
                        rows={getSelectedView()}
                        columns={columns}
                        rowSelectionModel={props.selectedReservationObject === undefined ? [] : props.selectedReservationObject!.id}
                        onRowSelectionModelChange={(e) => props.onReservationSelectionChanged(e[0])}
                    />
                </Box>
            </Stack>
        </Paper>
    )
}

export default AssetEmergencyReservationFlatList