import React from "react"
import {
    Box,
    Paper,
    Stack,
    Typography,
    Divider, AlertColor,
} from "@mui/material"
import Alert from "@mui/material/Alert"
import { Mission } from "../../../classes/Mission"
import { MissionFlag } from "../../../classes/MissionFlag"

function MissionFlagPanel(props: { missionData: Mission, sx?: object }) {

    if (props.missionData.flags === undefined) {
        return null
    }

    const flags = props.missionData.flags!.map((f: MissionFlag) => {
        var subtitle = ""

        if (f.operatorId !== undefined && props.missionData.operatorAssignments !== undefined) {
            const matchingAssignments = props.missionData.operatorAssignments.filter((o) =>
                o.operatorId === f.operatorId!)

            if (matchingAssignments.length === 1) {
                const a = matchingAssignments[0]
                subtitle += `(${a.operatorObject!.callsign})`
            }
        }

        if (f.assetId !== undefined && props.missionData.assetAssignments !== undefined) {
            const matchingAssignments = props.missionData.assetAssignments?.filter((a) =>
                a.assetId === f.assetId!)

            if (matchingAssignments.length === 1) {
                const a = matchingAssignments[0]
                subtitle += `(${a.assetObject!.callsign})`
            }
        }

        return (
            <Alert variant="filled" severity={f.getSeverityCategory() as AlertColor}>
                {`${f.getString()} ${subtitle}`}
            </Alert>
        )
    })

    return (
        <Paper sx={props.sx}>
            <Stack direction="column" spacing={1} padding={2} sx={{ height: "100%" }}>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant='h5'>Audit Flags</Typography>
                </Stack>
                <Divider />
                <Box sx={{ width: "100%", height: "100%", overflow: "scroll" }}>
                    {flags.length > 0 ?
                        (<Stack direction="column" spacing={1}>{flags}</Stack>) :
                        (<Typography textAlign="center">All checks have passed.</Typography>)}
                </Box>
            </Stack>
        </Paper>
    )
}

export default MissionFlagPanel