import dayjs, { Dayjs } from "dayjs"
import { Asset } from "./Asset"

export class AssetEmergencyReservation {
    id?: number
    assetId: number = 0
    isAvailable: boolean = false
    timeBegin: Dayjs = dayjs()
    timeEnd: Dayjs = dayjs()

    assetObject?: Asset

    parseObject(input: any) {
        this.id = input.id
        this.assetId = input.assetId
        this.isAvailable = input.isAvailable
        this.timeBegin = dayjs(input.timeBegin)
        this.timeEnd = dayjs(input.timeEnd)

        if (input.assetObject !== undefined) {
            const a = new Asset()
            a.parseObject(input.assetObject)
            this.assetObject = a
        }
    }

    objectify(): object {
        return {
            id: this.id,
            assetId: this.assetId,
            isAvailable: this.isAvailable,
            timeBegin: this.timeBegin.format(),
            timeEnd: this.timeEnd.format(),
            assetObject: this.assetObject?.objectify()
        }
    }
}