import React from 'react';
import { useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import Joi from 'joi';
import {Mission} from "../../../classes/Mission";
import {Operation} from "../../../classes/Operation";

function EditMissionTitleLayout(props: {
    title: string,
    initInfo: Mission,
    operationList?: Operation[],
    onSubmit: Function,
    onCancel: Function
}) {

    const [isPendingSubmit, setPendingSubmit] = useState<boolean>(false);
    const [inputOperationId, setInputOperationId] = useState<number>(props.initInfo.operationId);
    const [inputName, setInputName] = useState<string>(props.initInfo.name);
    const [inputPurpose, setInputPurpose] = useState<string>(props.initInfo.purpose);

    const isOperationId = Joi.number().integer().positive().required().validate(inputOperationId);
    const isNameValid = Joi.string().min(1).max(100).required().validate(inputName);
    const isPurposeValid = Joi.string().min(1).max(2000).required().validate(inputPurpose);
    const isValid = (!isOperationId.error && !isNameValid.error && !isPurposeValid.error);

    function handleSubmit() {
        setPendingSubmit(true);
        props.onSubmit(inputOperationId, inputName, inputPurpose);
    }

    const bottomControls = isPendingSubmit === true ? (<Typography>Loading...</Typography>) : [(<Button variant='text' onClick={() => props.onCancel()}>Cancel</Button>), (<Button variant='contained' disabled={!isValid} onClick={handleSubmit}>Submit</Button>)];

    if (props.operationList === undefined || props.operationList === null) {
        return (<Typography variant="h6">Loading...</Typography>)
    }

    return (
        <Stack direction="column" spacing={1}>
            <Typography variant="h6">{props.title}</Typography>
            <FormControl fullWidth disabled={isPendingSubmit}>
                <InputLabel id="operation-select">Select Operation</InputLabel>
                <Select
                    labelId="operation-select"
                    label="Select Operation"
                    value={inputOperationId}
                    onChange={(event: any) => setInputOperationId(event.target.value)}
                >
                    {props.operationList.map((a) => (<MenuItem value={a.id}>{a.name}</MenuItem>))}
                </Select>
            </FormControl>
            <TextField
                type="text"
                variant="outlined"
                label="Mission Name"
                error={inputName.length > 0 && isNameValid.error !== undefined}
                disabled={isPendingSubmit}
                value={inputName}
                onChange={(e) => setInputName(e.target.value)}
            />
            <TextField
                type="text"
                variant="outlined"
                label="Purpose"
                multiline
                rows={4}
                error={inputPurpose.length > 0 && isPurposeValid.error !== undefined}
                disabled={isPendingSubmit}
                value={inputPurpose}
                onChange={(e) => setInputPurpose(e.target.value)}
            />
            <Stack direction="row" spacing={1} justifyContent="right">{bottomControls}</Stack>
        </Stack>
    );
}

export default EditMissionTitleLayout;