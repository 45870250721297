import React, {useEffect, useState} from 'react';
import { Modal, Paper } from '@mui/material';
import AssignMissionOperatorLayout from '../layouts/AssignMissionOperatorLayout';
import getOperators from "../../../data/operator/getOperators";

function AssignMissionOperatorModal(props: {
    isOpen: boolean,
    onClose: Function,
    onSubmit: Function,
    onNetworkError: Function,
}) {
    const [operatorList, setOperatorList] = useState(undefined)

    useEffect(() => {
        if (props.isOpen) {
            getOperators()
                .then((result) => setOperatorList(result))
                .catch((err) => {
                    console.error(err)
                    props.onNetworkError()
                })
        }
    }, [props.isOpen])

    return (
        <Modal
            open={props.isOpen}
            onClose={() => props.onClose()}
        >
            <Paper
                sx={{
                    position: 'absolute',
                    width: '400px',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    padding: 2
                }}
            >
                <AssignMissionOperatorLayout
                    title="Assign Operator"
                    onCancel={() => props.onClose()}
                    onSubmit={props.onSubmit}
                    operatorList={operatorList}
                />
            </Paper>
        </Modal>
    );
}

export default AssignMissionOperatorModal;