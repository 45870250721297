import Cookies from "universal-cookie"

import config from "../config"
import getCompleteApiUrl from "../getCompleteApiUrl"
import {Mission} from "../../classes/Mission"
import {ApiCallFailure} from "../../classes/exception/ApiCallFailure";

export default async function getMissionsTaskedToAllMyOrganizations(timeStartProjectedBefore?: string, timeStartProjectedAfter?: string, timeFinishProjectedBefore?: string, timeFinishProjectedAfter?: string, isDraft?: boolean, isComplete?: boolean, isCanceled?: boolean, isInProgress?: boolean) {
    const cookies = new Cookies()

    if (cookies.get("accessToken") === undefined) {
        const error = new ApiCallFailure()
        error.connectionSuccessful = false
        error.errorType = "NO_ACCESS_TOKEN"

        throw error
    }

    const queryStrings = [
        timeStartProjectedBefore === undefined ? "" : "&timeStartProjectedBefore=" + encodeURIComponent(timeStartProjectedBefore),
        timeStartProjectedAfter === undefined ? "" : "&timeStartProjectedAfter=" + encodeURIComponent(timeStartProjectedAfter),
        timeFinishProjectedBefore === undefined ? "" : "&timeFinishProjectedBefore=" + encodeURIComponent(timeFinishProjectedBefore),
        timeFinishProjectedAfter === undefined ? "" : "&timeFinishProjectedAfter=" + encodeURIComponent(timeFinishProjectedAfter),
        isDraft === undefined ? "" : "&isDraft=" + encodeURIComponent(isDraft),
        isComplete === undefined ? "" : "&isComplete=" + encodeURIComponent(isComplete),
        isCanceled === undefined ? "" : "&isCanceled=" + encodeURIComponent(isCanceled),
        isInProgress === undefined ? "" : "&isInProgress=" + encodeURIComponent(isInProgress)
    ]

    var res: Response
    try {
        res = await fetch(getCompleteApiUrl(config, "/missions/tasked_to_organization?" + queryStrings.join("")), {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${cookies.get("accessToken")}`
            }
        })
    } catch (error) {
        const apiFailure = new ApiCallFailure()
        apiFailure.connectionSuccessful = false

        throw apiFailure
    }

    if (res!.status === 200) {
        const responseObject = await res!.json()

        return responseObject.map((obj: object) => {
            const responseMission = new Mission()
            responseMission.parseObject(obj)
            return responseMission
        })
    }

    const callFailure = new ApiCallFailure()
    callFailure.connectionSuccessful = true
    callFailure.responseCode = res!.status
    callFailure.errorType = "REQUEST_REJECTED"

    throw callFailure
}