import React from "react"
import dayjs, { Dayjs } from "dayjs"
import {
    Stack,
    Divider,
    Box
} from "@mui/material"

import ScheduleSegment from "./ScheduleSegment"
import ScheduleItemTimeline from "./ScheduleItemTimeline"
import ScheduleItemTimelineMarker from "./ScheduleItemTimelineMarker"
import ScheduleItemTitle from "./ScheduleItemTitle"
import { Mission } from "../../../classes/Mission"

function SchedulePanel(props: { missionList: Mission[], startTime: Dayjs, endTime: Dayjs, width: number, sx?: object }) {

    const currentTime = dayjs()

    const startTime = props.startTime
    const endTime = props.endTime

    const displayMissions = props.missionList.filter((mission) => {
        return mission.timeStartProjected.isAfter(startTime) && mission.timeStartProjected.isBefore(endTime)
    })

    const timelines = displayMissions.map((mission) => {

        const missionStartTime = dayjs(mission.timeStartProjected)
        const missionEndTime = dayjs(mission.timeFinishProjected)

        return [(
            <ScheduleItemTimeline>
                <ScheduleSegment
                    width={props.width}
                    timelineStartTime={startTime}
                    timelineEndTime={endTime}
                    segmentStartTime={missionStartTime}
                    segmentEndTime={missionEndTime}
                />
            </ScheduleItemTimeline>
        ), (<Divider />)]
    })

    return (
        <Box sx={props.sx}>
            <Stack direction="row">
                <Divider orientation="vertical" flexItem />
                <Stack direction="column">
                    <Divider />
                    <ScheduleItemTitle title={`${startTime.format("MM/DD/YYYY HH:mm")} - ${endTime.format("MM/DD/YYYY HH:mm")}`} />
                    <Divider />
                    <Box sx={{ height: 8 }} />
                    <Divider />
                    {displayMissions.map((m) => [(<ScheduleItemTitle title={m.name} />), (<Divider />)])}
                </Stack>
                <Divider orientation="vertical" flexItem />
                <Box sx={{ overflowX: "scroll" }}>
                    <Stack direction="column" sx={{ width: `${props.width}px` }}>
                        <Divider />
                        <ScheduleItemTimelineMarker
                            width={props.width}
                            startTime={startTime}
                            endTime={endTime}
                            currentTime={currentTime}
                        />
                        <Divider />
                        <Box sx={{ height: 8 }} />
                        <Divider />
                        {timelines}
                    </Stack>
                </Box>
                <Divider orientation="vertical" flexItem />
            </Stack>
        </Box>
    )
}

export default SchedulePanel