import React from 'react';
import { Paper, Stack, Typography, Divider, Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import dayjs from 'dayjs';
import {AssetReadinessProblem} from "../../../classes/AssetReadinessProblem";
import {Asset} from "../../../classes/Asset";

function AssetReadinessProblemsPanel(props: {
    assetData: Asset,
    selectedReadinessProblemData?: AssetReadinessProblem,
    onSelectReadinessProblem: Function,
    sx?: object
}) {

    const columns = [
        { field: 'subject', headerName: 'Subject', width: 250 },
        { field: 'description', headerName: 'Description', width: 400 },
        { field: 'started', headerName: 'Problem Started', width: 200, valueGetter: (params: any) => { return dayjs(params.value).format('MM/DD/YYYY').toString() } },
        { field: 'resolutionExpected', headerName: 'Resolution Expected', width: 200, valueGetter: (params: any) => { return dayjs(params.value).format('MM/DD/YYYY').toString() } },
        { field: 'resolutionCertified', headerName: 'Resolution Certified', width: 200, valueGetter: (params: any) => { return params.value === undefined ? "" : dayjs(params.value).format('MM/DD/YYYY').toString() } },
    ];

    return (
        <Paper sx={props.sx}>
            <Stack direction="column" spacing={1} padding={2}>
                <Typography variant="h5">Readiness Problems</Typography>
                <Divider />
                <Box sx={{ height: 300 }}>
                    <DataGrid
                        rows={props.assetData.readiness!.problems.map((p: AssetReadinessProblem) => p.objectify())}
                        columns={columns}
                        rowSelectionModel={props.selectedReadinessProblemData === undefined ? [] : props.selectedReadinessProblemData!.id}
                        onRowSelectionModelChange={(e) => props.onSelectReadinessProblem(e)}
                    />
                </Box>
            </Stack>
        </Paper>
    );
}

export default AssetReadinessProblemsPanel;