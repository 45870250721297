import React from "react"
import {
    Paper,
    Stack,
    Typography,
    Divider,
    Button,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    AccordionActions
} from "@mui/material"
import { ExpandMore } from "@mui/icons-material"
import { Mission } from "../../../classes/Mission"
import { Point } from "../../../classes/Point"

export default function MissionPointsPanel(props: {
    missionData: Mission,
    onEdit: Function,
    onDelete: Function,
    sx?: object
}) {

    const points = props.missionData.points!.map((p: Point) => {
        return (
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography>{p.label}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack direction="column" spacing={0}>
                        <Stack direction="row" spacing={1} justifyContent="space-between">
                            <Typography>Latitude:</Typography>
                            <Typography>{p.latitude}</Typography>
                        </Stack>
                        <Stack direction="row" spacing={1} justifyContent="space-between">
                            <Typography>Longitude:</Typography>
                            <Typography>{p.longitude}</Typography>
                        </Stack>
                        <Stack direction="row" spacing={1} justifyContent="space-between">
                            <Typography>Altitude:</Typography>
                            <Typography>{p.altitude}</Typography>
                        </Stack>
                        <Stack direction="row" spacing={1} justifyContent="space-between">
                            <Typography>Takeoff Point:</Typography>
                            <Typography>{`${p.isTakeoffPoint}`}</Typography>
                        </Stack>
                        <Stack direction="row" spacing={1} justifyContent="space-between">
                            <Typography>Landing Point:</Typography>
                            <Typography>{`${p.isLandingPoint}`}</Typography>
                        </Stack>
                    </Stack>
                </AccordionDetails>
                {props.missionData.isDraft ? (
                    <AccordionActions>
                        <Button variant="contained" onClick={() => props.onDelete(p.id)}>Delete</Button>
                        <Button variant="contained" onClick={() => props.onEdit(p.id)}>Edit</Button>
                    </AccordionActions>
                ) : null}
            </Accordion>
        )
    })

    return (
        <Paper sx={props.sx}>
            <Stack direction="column" spacing={1} padding={2}>
                <Typography variant="h5">Points</Typography>
                <Divider />
                {points}
            </Stack>
        </Paper>
    )
}