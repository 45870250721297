import React, {useEffect, useState} from 'react';
import {Stack} from '@mui/material';

import MissionDeconflictionPanel from '../components/MissionDeconflictionPanel';
import MissionDeconflictionActionPanel from '../components/MissionDeconflictionActionPanel';
import AnswerMissionDeconflictionModal from "../modals/AnswerMissionDeconflictionModal";
import NewMissionDeconflictionModal from "../modals/NewMissionDeconflictionModal";
import EditMissionDeconflictionModal from "../modals/EditMissionDeconflictionModal"
import ConfirmDeleteMissionDeconflictionModal from "../modals/ConfirmDeleteMissionDeconflictionModal"
import createDeconflictionInMissionById from "../../../data/deconfliction/createDeconflictionInMissionById";
import updateDeconflictionInMissionById from "../../../data/deconfliction/updateDeconflictionInMissionById"
import deleteDeconflictionInMissionById from "../../../data/deconfliction/deleteDeconflictionInMissionById"
import answerDeconflictionById from "../../../data/deconfliction/answerDeconflictionById";
import {Deconfliction} from "../../../classes/Deconfliction";
import {Mission} from "../../../classes/Mission";

function MissionDeconflictionTab(props: { missionData: Mission, onRefresh: Function, onNetworkError: Function }) {
    const [isCreateMissionDeconflictionModalOpen, setCreateMissionDeconflictionModalOpen] = useState<boolean>(false)
    const [isEditMissionDeconflictionModalOpen, setEditMissionDeconflictionModalOpen] = useState<boolean>(false)
    const [isAnswerMissionDeconflictionModalOpen, setAnswerMissionDeconflictionModalOpen] = useState<boolean>(false)
    const [isConfirmDeleteMissionDeconflictionModalOpen, setConfirmDeleteMissionDeconflictionModalOpen] = useState<boolean>(false)

    const [selectedDeconflictionId, setSelectedDeconflictionId] = useState<number | undefined>(undefined)
    const [selectedDeconflictionObject, setSelectedDeconflictionObject] = useState<Deconfliction | undefined>(undefined)

    useEffect(() => {
        if (selectedDeconflictionId === undefined) {
            setSelectedDeconflictionObject(undefined)
        } else if (props.missionData !== undefined) {
            props.missionData.deconflictions!.map((o: Deconfliction) => {
                if (o.id == selectedDeconflictionId) {
                    setSelectedDeconflictionObject(o);
                }
            })
        }
    }, [selectedDeconflictionId, props.missionData])

    function handleCreateMissionDeconfliction(inputDeconfliction: Deconfliction) {
        createDeconflictionInMissionById(inputDeconfliction)
            .then(() => props.onRefresh())
            .then(() => setCreateMissionDeconflictionModalOpen(false))
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    function handleEditMissionDeconfliction(inputDeconfliction: Deconfliction) {
        updateDeconflictionInMissionById(inputDeconfliction)
            .then(() => props.onRefresh())
            .then(() => setEditMissionDeconflictionModalOpen(false))
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    function handleDeleteMissionDeconfliction() {
        deleteDeconflictionInMissionById(selectedDeconflictionId!)
            .then(() => props.onRefresh())
            .then(() => setConfirmDeleteMissionDeconflictionModalOpen(false))
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    function handleAnswerMissionDeconfliction(inputDeconfliction: Deconfliction) {
        answerDeconflictionById(inputDeconfliction.id!, inputDeconfliction.isConcur!, inputDeconfliction.reason!)
            .then(() => props.onRefresh())
            .then(() => setAnswerMissionDeconflictionModalOpen(false))
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    return (
        <Stack direction="column" padding={2} spacing={2}>
            <MissionDeconflictionActionPanel
                sx={{ width: '100%' }}
                onClickNew={() => setCreateMissionDeconflictionModalOpen(true)}
                onClickDelete={() => setConfirmDeleteMissionDeconflictionModalOpen(true)}
                onClickEdit={() => setEditMissionDeconflictionModalOpen(true)}
                onClickAnswer={() => setAnswerMissionDeconflictionModalOpen(true)}
                deconflictionData={selectedDeconflictionObject}
                missionData={props.missionData}
            />
            <MissionDeconflictionPanel
                sx={{ width: '100%' }}
                missionData={props.missionData}
                deconflictionData={selectedDeconflictionObject}
                onDeconflictionSelectionChanged={(e: any) => setSelectedDeconflictionId(e[0])}
            />
            <ConfirmDeleteMissionDeconflictionModal
                isOpen={isConfirmDeleteMissionDeconflictionModalOpen}
                onClose={() => setConfirmDeleteMissionDeconflictionModalOpen(false)}
                onConfirm={handleDeleteMissionDeconfliction}
            />
            <AnswerMissionDeconflictionModal
                deconflictionData={selectedDeconflictionObject === undefined ? new Deconfliction() : selectedDeconflictionObject}
                isOpen={isAnswerMissionDeconflictionModalOpen}
                onClose={() => setAnswerMissionDeconflictionModalOpen(false)}
                onSubmit={handleAnswerMissionDeconfliction}
            />
            <EditMissionDeconflictionModal
                missionData={props.missionData}
                selectedDeconflictionId={selectedDeconflictionId}
                isOpen={isEditMissionDeconflictionModalOpen}
                onClose={() => setEditMissionDeconflictionModalOpen(false)}
                onSubmit={handleEditMissionDeconfliction}
                onNetworkError={props.onNetworkError}
            />
            <NewMissionDeconflictionModal
                missionData={props.missionData}
                isOpen={isCreateMissionDeconflictionModalOpen}
                onClose={() => setCreateMissionDeconflictionModalOpen(false)}
                onSubmit={handleCreateMissionDeconfliction}
                onNetworkError={props.onNetworkError}
            />
        </Stack>
    )
}

export default MissionDeconflictionTab;