import React from "react"
import { Typography, CircularProgress, Stack, Paper } from "@mui/material"

export default function LoadingPanel(props: { panelStyle?: object }) {

    return (
        <Paper sx={props.panelStyle}>
            <Stack direction="row" padding={1} spacing={4}>
                <CircularProgress size={24} />
                <Typography variant="body1">Loading...</Typography>
            </Stack>
        </Paper>
    )
}