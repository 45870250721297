import React from 'react';
import { Paper, Stack, Button, Box } from '@mui/material';

import { DataGrid } from '@mui/x-data-grid';
import {Mission} from "../../../classes/Mission";
import {Deconfliction} from "../../../classes/Deconfliction";

function MissionDeconflictionPanel(props: {
    missionData: Mission,
    deconflictionData?: Deconfliction,
    onDeconflictionSelectionChanged: Function,
    sx?: object
}) {

    const columns = [
        { field: 'organization', headerName: 'Organization', width: 150, valueGetter: (params: any) => { return params.row.officeObject.organizationObject.name }},
        { field: 'office', headerName: 'Office', width: 200, valueGetter: (params: any) => { return `${params.row.officeObject.name} (${params.row.officeObject.symbol})` }},
        { field: 'description', headerName: 'Description', width: 350 },
        { field: 'isConcur', headerName: 'Concur/Non-Concur', width: 150, valueGetter: (params: any) => {
            if (params.row.isConcur === undefined) {
                return "Pending";
            }

            return params.row.isConcur ? "Concur" : "Non-Concur";
        } },
        { field: 'reason', headerName: 'Reason', width: 500 },
    ];

    return (
        <Paper sx={props.sx}>
            <Stack direction="column" spacing={1} padding={2}>
                <Box sx={{ height: 400 }}>
                    <DataGrid
                        rows={props.missionData.deconflictions!.map((deconfliction: Deconfliction) => deconfliction.objectify())}
                        columns={columns}
                        rowSelectionModel={props.deconflictionData === undefined ? [] : props.deconflictionData!.id}
                        onRowSelectionModelChange={(e) => props.onDeconflictionSelectionChanged(e)}
                    />
                </Box>
            </Stack>
        </Paper>
    );
}

export default MissionDeconflictionPanel;