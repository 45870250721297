import dayjs, { Dayjs } from "dayjs"
import { Operator } from "./Operator"

export class OperatorEmergencyReservation {
    id?: number
    operatorId: number = 0
    isAvailable: boolean = false
    timeBegin: Dayjs = dayjs()
    timeEnd: Dayjs = dayjs()

    operatorObject?: Operator

    parseObject(input: any) {
        this.id = input.id
        this.operatorId = input.operatorId
        this.isAvailable = input.isAvailable
        this.timeBegin = dayjs(input.timeBegin)
        this.timeEnd = dayjs(input.timeEnd)

        if (input.operatorObject !== undefined) {
            const o = new Operator()
            o.parseObject(input.operatorObject)
            this.operatorObject = o
        }
    }

    objectify(): object {
        return {
            id: this.id,
            operatorId: this.operatorId,
            isAvailable: this.isAvailable,
            timeBegin: this.timeBegin.format(),
            timeEnd: this.timeEnd.format(),
            operatorObject: this.operatorObject?.objectify(),
        }
    }
}