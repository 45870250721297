import config from "../config"
import getCompleteApiUrl from "../getCompleteApiUrl"
import { SysInfoBannerParams } from "../../classes/SysInfoBannerParams"
import { ApiCallFailure } from "../../classes/exception/ApiCallFailure"

export default async function getBanner() {
    var res: Response
    try {
        res = await fetch(getCompleteApiUrl(config, "/sysinfo/banner"), {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
    } catch(error) {
        const apiFailure = new ApiCallFailure()
        apiFailure.connectionSuccessful = false

        throw apiFailure
    }

    if (res!.status === 200) {
        const responseObject = await res!.json()

        const responseBanner = new SysInfoBannerParams()
        responseBanner.parseObject(responseObject)

        return responseBanner
    }

    const callFailure = new ApiCallFailure()
    callFailure.connectionSuccessful = true
    callFailure.responseCode = res!.status
    callFailure.errorType = "REQUEST_REJECTED"

    throw callFailure
}