import React from 'react';
import { Modal, Paper } from '@mui/material';
import EditMissionOperatingAreaLayout from '../layouts/EditMissionOperatingAreaLayout';
import {Mission} from "../../../classes/Mission";
import {OperatingArea} from "../../../classes/OperatingArea";

function NewMissionOperatingAreaModal(props: {
    isOpen: boolean,
    missionData: Mission,
    onClose: Function,
    onSubmit: Function
}) {

    const newArea = new OperatingArea()
    newArea.missionId = props.missionData.id!

    return (
        <Modal
            open={props.isOpen}
            onClose={() => props.onClose()}
        >
            <Paper
                sx={{
                    position: 'absolute',
                    width: '800px',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    padding: 2
                }}
            >
                <EditMissionOperatingAreaLayout
                    title="New Operating Area"
                    initData={newArea}
                    onCancel={() => props.onClose()}
                    onSubmit={props.onSubmit}
                />
            </Paper>
        </Modal>
    );
}

export default NewMissionOperatingAreaModal;