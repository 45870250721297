import React from 'react';
import { useState } from 'react';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';

import OperatorSummaryLayout from './OperatorSummaryLayout';
import OperatorReadinessLayout from './OperatorReadinessLayout';
import OperatorMissionsLayout from './OperatorMissionsLayout';
import {Operator} from "../../../classes/Operator";
import {OperatorReadinessProblem} from "../../../classes/OperatorReadinessProblem";
import {OperatorReadinessRequirement} from "../../../classes/OperatorReadinessRequirement";
import OperatorEmergencyReservationsLayout from "./OperatorEmergencyReservationsLayout";

function OperatorTabbedLayout(props: {
    operatorData: Operator,
    readinessProblemData?: OperatorReadinessProblem,
    readinessRequirementData?: OperatorReadinessRequirement,
    onSelectReadinessProblem: Function,
    onSelectReadinessRequirement: Function,
    onClickCreateReadinessProblem: Function,
    onClickResolveReadinessProblem: Function,
    onClickCreateReadinessRequirement: Function,
    onClickRenewReadinessRequirement: Function,
    modals?: any
}) {
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);

    function selectTab(event: any, newValue: any) {
        setSelectedTabIndex(newValue);
    }

    function renderTab() {
        if (selectedTabIndex === 0) {
            return (<OperatorSummaryLayout operatorData={props.operatorData} />)
        } else if (selectedTabIndex === 1) {
            return (
                <OperatorReadinessLayout
                    operatorData={props.operatorData}
                    readinessProblemData={props.readinessProblemData}
                    readinessRequirementData={props.readinessRequirementData}
                    onSelectReadinessProblem={props.onSelectReadinessProblem}
                    onSelectReadinessRequirement={props.onSelectReadinessRequirement}
                    onClickCreateRequirement={props.onClickCreateReadinessRequirement}
                    onClickCreateProblem={props.onClickCreateReadinessProblem}
                    onClickRenewRequirement={props.onClickRenewReadinessRequirement}
                    onClickResolveProblem={props.onClickResolveReadinessProblem}
                />
            )
        } else if (selectedTabIndex === 2) {
            return (<OperatorMissionsLayout operatorData={props.operatorData} />)
        } else if (selectedTabIndex === 3) {
            return (<OperatorEmergencyReservationsLayout operatorData={props.operatorData} />)
        }
    }

    return (
        <Box sx={{ width: '100%' }}>
            {props.modals}
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={selectedTabIndex} onChange={selectTab} centered variant="scrollable" scrollButtons="auto">
                    <Tab label="Summary" />
                    <Tab label="Readiness" />
                    <Tab label="Assigned Missions" />
                    <Tab label="Emergency Reservations" />
                </Tabs>
            </Box>
            {renderTab()}
        </Box>
    );
}

export default OperatorTabbedLayout;