import { Office } from "./Office"
import { OperatorReadiness } from "./OperatorReadiness"
import { OperatorEmergencyReservation } from "./OperatorEmergencyReservation"
import {Mission} from "./Mission";

export class Operator {
    id?: number
    officeId: number = 0
    rank: string = ""
    firstName: string = ""
    lastName: string = ""
    callsign: string = ""

    officeObject?: Office
    readiness?: OperatorReadiness
    emergencyReservations?: OperatorEmergencyReservation[]
    assignedMissionList?: Mission[]

    parseObject(input: any) {
        this.id = input.id
        this.officeId = input.officeId
        this.rank = input.rank
        this.firstName = input.firstName
        this.lastName = input.lastName
        this.callsign = input.callsign

        if (input.officeObject !== undefined) {
            const o = new Office()
            o.parseObject(input.officeObject)
            this.officeObject = o
        }

        if (input.readiness !== undefined) {
            const r = new OperatorReadiness()
            r.parseObject(input.readiness)
            this.readiness = r
        }

        if (input.emergencyReservations !== undefined) {
            this.emergencyReservations = input.emergencyReservations.map((obj: any) => {
                const r = new OperatorEmergencyReservation()
                r.parseObject(obj)
                return r
            })
        }

        if (input.assignedMissionList !== undefined) {
            this.assignedMissionList = input.assignedMissionList.map((obj: any) => {
                const m = new Mission()
                m.parseObject(obj)
                return m
            })
        }
    }

    objectify(): object {
        return {
            id: this.id,
            officeId: this.officeId,
            rank: this.rank,
            firstName: this.firstName,
            lastName: this.lastName,
            callsign: this.callsign,
            officeObject: this.officeObject === undefined ? undefined : this.officeObject.objectify(),
            readiness: this.readiness === undefined ? undefined : this.readiness.objectify(),
            emergencyReservations: this.emergencyReservations?.map((r) => r.objectify()),
            assignedMissionList: this.assignedMissionList?.map((m) => m.objectify())
        }
    }
}