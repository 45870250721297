import React, { ReactNode } from "react"
import {
    Paper,
    Stack,
    Typography,
    Divider
} from "@mui/material"

export default function InputPanel(props: {
    title: string,
    children?: ReactNode,
    leftControl?: ReactNode,
    rightControl?: ReactNode,
    panelStyle?: object
}) {

    return (
        <Paper sx={props.panelStyle}>
            <Stack direction="column" padding={1} spacing={1}>
                <Typography variant="h6">{props.title}</Typography>
                <Divider />
                {props.children}
                <Divider />
                <Stack direction="row" justifyContent="space-between">
                    {props.leftControl}
                    {props.rightControl}
                </Stack>
            </Stack>
        </Paper>
    )
}