import React, {useState, useEffect, useLayoutEffect} from "react"
import Stack from "@mui/material/Stack"

import OperatorActionBar from "../components/OperatorActionBar"
import OperatorFlatList from "../components/OperatorFlatList"
import {Typography} from "@mui/material"

import getOperators from "../../../data/operator/getOperators"
import createOperator from "../../../data/operator/createOperator"
import NewOperatorModal from "../modals/NewOperatorModal"
import UpdateOperatorModal from "../modals/UpdateOperatorModal"
import {Operator} from "../../../classes/Operator";

function OperatorTab(props: { onNetworkError: Function }) {
    const [isCreateOperatorModalOpen, setCreateOperatorModalOpen] = useState<boolean>(false)
    const [isUpdateOperatorModalOpen, setUpdateOperatorModalOpen] = useState<boolean>(false)

    const [operatorList, setOperatorList] = useState<Operator[] | undefined>(undefined)
    const [selectedOperatorId, setSelectedOperatorId] = useState<number | null>(null)
    const [selectedOperatorObject, setSelectedOperatorObject] = useState<Operator | undefined>(undefined)

    useEffect(() => {
        getOperators()
            .then((result) => setOperatorList(result))
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }, [])

    useLayoutEffect(() => {
        if (selectedOperatorId === null) {
            setSelectedOperatorObject(undefined)
        } else if (operatorList !== undefined) {
            operatorList.map((o: Operator) => {
                if (o.id == selectedOperatorId) {
                    setSelectedOperatorObject(o)
                }
            })
        }
    }, [selectedOperatorId, operatorList])

    if (operatorList === undefined) {
        return (<Typography textAlign="center" sx={{ transform: "translateY(250px)" }}>Loading operator data...</Typography>)
    }

    function handleCreateOperator(inputOperator: Operator) {
        createOperator(inputOperator)
            .then(() => getOperators())
            .then((result) => {
                setOperatorList(result)
                setCreateOperatorModalOpen(false)
            })
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    function handleUpdateOperator(inputOperator: Operator) {
        // TODO: Fill in this function.
        alert("This functionality has not yet been worked on. (Update operator).")
    }

    return (
        <Stack direction="column" spacing={2} padding={2}>
            <OperatorActionBar
                sx={{ width: "100%" }}
                onCreate={() => setCreateOperatorModalOpen(true)}
                onUpdate={() => setUpdateOperatorModalOpen(true)}
                createDisabled={false}
                updateDisabled={selectedOperatorId === null}
            />
            <OperatorFlatList
                sx={{ width: "100%" }}
                data={operatorList}
                selectedOperatorId={selectedOperatorId === null ? undefined : selectedOperatorId}
                onOperatorSelectionChanged={(id: number) => setSelectedOperatorId(id)}
            />
            <NewOperatorModal
                isOpen={isCreateOperatorModalOpen}
                onClose={() => setCreateOperatorModalOpen(false)}
                onSubmit={handleCreateOperator}
                onNetworkError={props.onNetworkError}
            />
            <UpdateOperatorModal
                isOpen={isUpdateOperatorModalOpen}
                onClose={() => setUpdateOperatorModalOpen(false)}
                selectedOperatorObject={selectedOperatorObject === undefined ? new Operator() : selectedOperatorObject}
                onSubmit={handleUpdateOperator}
                onNetworkError={props.onNetworkError}
            />
        </Stack>
    )
}

export default OperatorTab