import React, {useEffect, useState} from 'react';
import { Modal, Box, Paper } from '@mui/material';
import EditAssetLayout from '../layouts/EditAssetLayout';
import getProducts from "../../../data/product/getProducts";
import getOffices from "../../../data/office/getOffices";
import {Asset} from "../../../classes/Asset";
import {Office} from "../../../classes/Office";
import {Product} from "../../../classes/Product";

function UpdateAssetModal(props: {
    isOpen: boolean,
    selectedAssetObject: Asset,
    onClose: Function,
    onSubmit: Function,
    onNetworkError: Function
}) {
    const [productList, setProductList] = useState<Product[] | undefined>(undefined)
    const [officeList, setOfficeList] = useState<Office[] | undefined>(undefined)

    useEffect(() => {
        if (props.isOpen) {
            getProducts()
                .then((result) => setProductList(result))
                .catch((err) => {
                    console.error(err)
                    props.onNetworkError()
                })

            getOffices()
                .then((result) => setOfficeList(result))
                .catch((err) => {
                    console.error(err)
                    props.onNetworkError()
                })
        } else {
            setProductList(undefined)
            setOfficeList(undefined)
        }
    }, [props])

    return (
        <Modal
            open={props.isOpen}
            onClose={() => props.onClose()}
        >
            <Paper
                sx={{
                    position: 'absolute',
                    width: '600px',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    padding: 2
                }}
            >
                <EditAssetLayout
                    title="Update Asset"
                    initInfo={props.selectedAssetObject}
                    productList={productList}
                    officeList={officeList}
                    onCancel={() => props.onClose()}
                    onSubmit={props.onSubmit}
                />
            </Paper>
        </Modal>
    );
}

export default UpdateAssetModal;