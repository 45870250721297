import React from "react"
import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"

import UsernameAndPassphraseLoginLayout from "../layouts/UsernameAndPassphraseLoginLayout"

import config from "../../../data/config"
import getCompleteUiUrl from "../../../data/getCompleteUiUrl"
import getBanner from "../../../data/sysinfo/getBanner"
import login from "../../../data/auth/login"

import { SysInfoBannerParams } from "../../../classes/SysInfoBannerParams"

export default function Login() {
    const navigate = useNavigate()

    const [bannerData, setBannerData] = useState<SysInfoBannerParams | undefined>(undefined)
    const [isPendingSubmit, setPendingSubmit] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    useEffect(() => {
        if (bannerData === undefined) {
            getBanner().then((d) => setBannerData(d))
        }
    }, [])

    if (bannerData === undefined) {
        return null
    }

    function submitLogin(username: string, passphrase: string) {
        setPendingSubmit(true)
        setErrorMessage("")
        login({ username: username, passphrase: passphrase })
            .then((result) => {
                if (result === null) {
                    setErrorMessage("Could not connect to the server.")
                    setPendingSubmit(false)
                } else {
                    if (result.token === undefined) {
                        setErrorMessage(result.message)
                        setPendingSubmit(false)
                    } else {
                        navigate(getCompleteUiUrl(config, "/schedule"))
                    }
                }
            }).catch((error) => {
                // TODO: Check what type of error we have.  If it's a authentication error or a network error.
                setErrorMessage("Could not connect to the server.")
                setPendingSubmit(false)
            })
    }

    return (
        <UsernameAndPassphraseLoginLayout // TODO: Replace this with inputpanel.
            sysInfoBanner={bannerData}
            isPendingSubmit={isPendingSubmit}
            errorMessage={errorMessage}
            onSubmit={submitLogin}
            onCancel={() => navigate(config.uiRoute + "/")}
        />
    )
}