export class Organization {
    id?: number
    parentId?: number
    name: string = ""
    symbol: string = ""

    parentObject?: Organization

    parseObject(input: any) {
        this.id = input.id
        this.parentId = input.parentId
        this.name = input.name
        this.symbol = input.symbol

        if (input.parentObject !== undefined) {
            const p = new Organization()
            p.parseObject(input.parentObject)
            this.parentObject = p
        }
    }

    objectify(): object {
        return {
            id: this.id,
            parentId: this.parentId,
            name: this.name,
            symbol: this.symbol,
            parentObject: this.parentObject?.objectify()
        }
    }
}