import React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import { DataGrid } from '@mui/x-data-grid';

function UserFlatList(props: {
    data: any[],
    selectedUserName?: string,
    onUserSelectionChanged: Function,
    sx?: object
}) {

    const columns = [
        { field: 'username', headerName: 'Username', width: 300 },
        { field: 'isEnabled', headerName: 'Enabled?', width: 100 },
        { field: 'isAdmin', headerName: 'Admin?', width: 100 }
    ];

    function getRowId(row: any) {
        return row.username;
    }

    return (
        <Paper sx={props.sx}>
            <Stack direction="column" spacing={2} padding={2}>
                <Box sx={{ height: 600 }}>
                    <DataGrid
                        getRowId={getRowId}
                        rows={props.data.map((user: any) => user)}
                        columns={columns}
                        rowSelectionModel={props.selectedUserName === undefined ? [] : props.selectedUserName!}
                        onRowSelectionModelChange={(e) => props.onUserSelectionChanged(e[0])}
                    />
                </Box>
            </Stack>
        </Paper>
    );
}

export default UserFlatList;