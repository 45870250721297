import React from 'react';
import {
    Paper,
    Stack,
    Typography,
    Divider,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    AccordionActions, Button
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import {Mission} from "../../../classes/Mission";
import {OperatingArea} from "../../../classes/OperatingArea";
import {OperatingAreaPoint} from "../../../classes/OperatingAreaPoint";

function MissionOperatingAreasPanel(props: {
    missionData: Mission,
    onEdit: Function,
    onDelete: Function,
    sx?: object
}) {

    const oas = props.missionData.operatingAreas!.map((oa: OperatingArea) => {
        const pts = oa.points.map((p: OperatingAreaPoint) => (
            <Stack direction="row" spacing={1} justifyContent="space-between">
                <Typography>{p.sequence}</Typography>
                <Typography>{p.latitude}</Typography>
                <Typography>{p.longitude}</Typography>
            </Stack>
        ))

        return (
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography>{`${oa.label} (Floor: ${oa.altitudeFloor}, Ceiling: ${oa.altitudeCeil} ${oa.isAGL ? "AGL" : "MSL"})`}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {pts}
                </AccordionDetails>
                {props.missionData.isDraft ? (
                    <AccordionActions>
                        <Button variant="contained" onClick={() => props.onDelete(oa.id)}>Delete</Button>
                        <Button variant="contained" onClick={() => props.onEdit(oa.id)}>Edit</Button>
                    </AccordionActions>
                ) : null}
            </Accordion>
        );
    });

    return (
        <Paper sx={props.sx}>
            <Stack direction="column" spacing={1} padding={2}>
                <Typography variant='h5'>Operating Areas</Typography>
                <Divider />
                {oas}
            </Stack>
        </Paper>
    );
}

export default MissionOperatingAreasPanel;