import React, {useState} from 'react';
import { Stack } from '@mui/material';

import MissionMapPanel from "../components/MissionMapPanel"
import MissionGeospatialActionPanel from '../components/MissionGeospatialActionPanel';
import MissionFlightPathPanel from '../components/MissionFlightPathPanel';
import MissionPointsPanel from "../components/MissionPointsPanel"
import MissionOperatingAreasPanel from '../components/MissionOperatingAreasPanel';
import addFlightPathToMissionById from "../../../data/mission/addFlightPathToMissionById";
import addPointToMissionById from "../../../data/mission/addPointToMissionById"
import addOperatingAreaToMissionById from "../../../data/mission/addOperatingAreaToMissionById";
import NewMissionFlightPathModal from "../modals/NewMissionFlightPathModal";
import NewMissionOperatingAreaModal from "../modals/NewMissionOperatingAreaModal";
import NewMissionPointModal from "../modals/NewMissionPointModal"
import UploadKMLFileModal from "../modals/UploadKMLFileModal"
import { Point } from "../../../classes/Point"
import { FlightPath } from "../../../classes/FlightPath"
import { OperatingArea } from "../../../classes/OperatingArea"
import { Mission } from "../../../classes/Mission"
import EditMissionPointModal from "../modals/EditMissionPointModal"
import getKMLInMissionById from "../../../data/mission/getKMLInMissionById"
import ConfirmDeleteMissionPointModal from "../modals/ConfirmDeleteMissionPointModal"
import updateMissionPointById from "../../../data/mission/updateMissionPointById"
import deleteMissionPointById from "../../../data/mission/deleteMissionPointById"
import updateOperatingAreaById from "../../../data/mission/updateOperatingAreaById"
import deleteOperatingAreaById from "../../../data/mission/deleteOperatingAreaById"
import ConfirmDeleteMissionOperatingAreaModal from "../modals/ConfirmDeleteMissionOperatingAreaModal";
import EditMissionOperatingAreaModal from "../modals/EditMissionOperatingAreaModal";
import updateFlightPathById from "../../../data/mission/updateFlightPathById";
import deleteFlightPathById from "../../../data/mission/deleteFlightPathById";
import ConfirmDeleteMissionFlightPathModal from "../modals/ConfirmDeleteMissionFlightPathModal";
import EditMissionFlightPathModal from "../modals/EditMissionFlightPathModal";

function MissionGeospatialInformationTab(props: { missionData: Mission, onRefresh: Function, onNetworkError: Function }) {
    const [isCreateMissionPointModalOpen, setCreateMissionPointModalOpen] = useState<boolean>(false)
    const [isCreateMissionOperatingAreaModalOpen, setCreateMissionOperatingAreaModalOpen] = useState<boolean>(false)
    const [isCreateMissionFlightPathModalOpen, setCreateMissionFlightPathModalOpen] = useState<boolean>(false)
    const [isEditMissionPointModalOpen, setEditMissionPointModalOpen] = useState<boolean>(false)
    const [isEditMissionOperatingAreaModalOpen, setEditMissionOperatingAreaModalOpen] = useState<boolean>(false)
    const [isEditMissionFlightPathModalOpen, setEditMissionFlightPathModalOpen] = useState<boolean>(false)
    const [isConfirmDeleteMissionPointModalOpen, setConfirmDeleteMissionPointModalOpen] = useState<boolean>(false)
    const [isConfirmDeleteMissionOperatingAreaModalOpen, setConfirmDeleteMissionOperatingAreaModalOpen] = useState<boolean>(false)
    const [isConfirmDeleteMissionFlightPathModalOpen, setConfirmDeleteMissionFlightPathModalOpen] = useState<boolean>(false)
    const [isUploadKMLFileModalOpen, setUploadKMLFileModalOpen] = useState<boolean>(false)

    const [selectedPointId, setSelectedPointId] = useState<number | undefined>(undefined)
    const [selectedOperatingAreaId, setSelectedOperatingAreaId] = useState<number | undefined>(undefined)
    const [selectedFlightPathId, setSelectedFlightPathId] = useState<number | undefined>(undefined)

    function handleCreateMissionPoint(inputPoint: Point) {
        addPointToMissionById(inputPoint)
            .then(() => props.onRefresh())
            .then(() => setCreateMissionPointModalOpen(false))
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    function handleEditMissionPoint(inputPoint: Point) {
        updateMissionPointById(inputPoint)
            .then(() => props.onRefresh())
            .then(() => setEditMissionPointModalOpen(false))
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    function handleDeleteMissionPoint() {
        deleteMissionPointById(selectedPointId!)
            .then(() => props.onRefresh())
            .then(() => setConfirmDeleteMissionPointModalOpen(false))
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    function handleCreateMissionFlightPath(inputPath: FlightPath) {
        addFlightPathToMissionById(inputPath)
            .then(() => props.onRefresh())
            .then(() => setCreateMissionFlightPathModalOpen(false))
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    function handleEditMissionFlightPath(inputPath: FlightPath) {
        updateFlightPathById(inputPath)
            .then(() => props.onRefresh())
            .then(() => setEditMissionFlightPathModalOpen(false))
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    function handleDeleteMissionFlightPath() {
        deleteFlightPathById(selectedFlightPathId!)
            .then(() => props.onRefresh())
            .then(() => setConfirmDeleteMissionFlightPathModalOpen(false))
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    function handleCreateMissionOperatingArea(inputArea: OperatingArea) {
        addOperatingAreaToMissionById(inputArea)
            .then(() => props.onRefresh())
            .then(() => setCreateMissionOperatingAreaModalOpen(false))
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    function handleEditMissionOperatingArea(inputArea: OperatingArea) {
        updateOperatingAreaById(inputArea)
            .then(() => props.onRefresh())
            .then(() => setEditMissionOperatingAreaModalOpen(false))
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    function handleDeleteMissionOperatingArea() {
        deleteOperatingAreaById(selectedOperatingAreaId!)
            .then(() => props.onRefresh())
            .then(() => setConfirmDeleteMissionOperatingAreaModalOpen(false))
            .catch((err) => {
                console.error(err)
                props.onNetworkError()
            })
    }

    async function handleSubmitFromKML(inputAreas?: OperatingArea[], inputPaths?: FlightPath[], inputPoints?: Point[]) {
        var areaPromises = undefined
        var pathPromises = undefined
        var pointPromises = undefined
        try {
            areaPromises = inputAreas?.map((area: OperatingArea) => addOperatingAreaToMissionById(area))
            pathPromises = inputPaths?.map((path: FlightPath) => addFlightPathToMissionById(path))
            pointPromises = inputPoints?.map((point: Point) => addPointToMissionById(point))
        } catch (err) {
            console.error(err)
            props.onNetworkError()
        }

        if (areaPromises !== undefined) {
            await Promise.all(areaPromises)
        }

        if (pathPromises !== undefined) {
            await Promise.all(pathPromises)
        }

        if (pointPromises !== undefined) {
            await Promise.all(pointPromises)
        }

        await props.onRefresh()
        setUploadKMLFileModalOpen(false)
    }

    function handleDownloadKML() {
        getKMLInMissionById(props.missionData.id!)
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]))
                const link = document.createElement("a")
                link.href = url
                link.setAttribute("download", "Geospatial Info - " + props.missionData.name + ".kml")

                document.body.appendChild(link)
                link.click()

                link.parentNode!.removeChild(link)
            })
    }

    return (
        <Stack direction="column" padding={2} spacing={2}>
            <MissionGeospatialActionPanel
                sx={{ width: '100%' }}
                missionData={props.missionData}
                onClickNewPoint={() => setCreateMissionPointModalOpen(true)}
                onClickNewFlightPath={() => setCreateMissionFlightPathModalOpen(true)}
                onClickNewOperatingArea={() => setCreateMissionOperatingAreaModalOpen(true)}
                onClickUploadKml={() => setUploadKMLFileModalOpen(true)}
                onClickDownloadKml={handleDownloadKML}
            />
            <MissionMapPanel
                sx={{ width: "100%" }}
                missionData={props.missionData}
            />
            <Stack direction="row" spacing={2}>
                <MissionPointsPanel
                    sx={{ width: "100%" }}
                    onEdit={(id: number) => {
                        setSelectedPointId(id)
                        setEditMissionPointModalOpen(true)
                    }}
                    onDelete={(id: number) => {
                        setSelectedPointId(id)
                        setConfirmDeleteMissionPointModalOpen(true)
                    }}
                    missionData={props.missionData}
                />
                <MissionFlightPathPanel
                    sx={{ width: '100%' }}
                    onEdit={(id: number) => {
                        setSelectedFlightPathId(id)
                        setEditMissionFlightPathModalOpen(true)
                    }}
                    onDelete={(id: number) => {
                        setSelectedFlightPathId(id)
                        setConfirmDeleteMissionFlightPathModalOpen(true)
                    }}
                    missionData={props.missionData}
                />
                <MissionOperatingAreasPanel
                    sx={{ width: '100%' }}
                    onEdit={(id: number) => {
                        setSelectedOperatingAreaId(id)
                        setEditMissionOperatingAreaModalOpen(true)
                    }}
                    onDelete={(id: number) => {
                        setSelectedOperatingAreaId(id)
                        setConfirmDeleteMissionOperatingAreaModalOpen(true)
                    }}
                    missionData={props.missionData}
                />
            </Stack>
            <NewMissionPointModal
                isOpen={isCreateMissionPointModalOpen}
                missionData={props.missionData}
                onClose={() => setCreateMissionPointModalOpen(false)}
                onSubmit={handleCreateMissionPoint}
            />
            <EditMissionPointModal
                isOpen={isEditMissionPointModalOpen}
                missionData={props.missionData}
                selectedPointId={selectedPointId}
                onClose={() => setEditMissionPointModalOpen(false)}
                onSubmit={handleEditMissionPoint}
            />
            <ConfirmDeleteMissionPointModal
                isOpen={isConfirmDeleteMissionPointModalOpen}
                onClose={() => setConfirmDeleteMissionPointModalOpen(false)}
                onConfirm={handleDeleteMissionPoint}
            />
            <ConfirmDeleteMissionOperatingAreaModal
                isOpen={isConfirmDeleteMissionOperatingAreaModalOpen}
                onClose={() => setConfirmDeleteMissionOperatingAreaModalOpen(false)}
                onConfirm={handleDeleteMissionOperatingArea}
            />
            <ConfirmDeleteMissionFlightPathModal
                isOpen={isConfirmDeleteMissionFlightPathModalOpen}
                onClose={() => setConfirmDeleteMissionFlightPathModalOpen(false)}
                onConfirm={handleDeleteMissionFlightPath}
            />
            <NewMissionFlightPathModal
                isOpen={isCreateMissionFlightPathModalOpen}
                missionData={props.missionData}
                onClose={() => setCreateMissionFlightPathModalOpen(false)}
                onSubmit={handleCreateMissionFlightPath}
            />
            <EditMissionFlightPathModal
                isOpen={isEditMissionFlightPathModalOpen}
                missionData={props.missionData}
                selectedFlightPathId={selectedFlightPathId}
                onClose={() => setEditMissionFlightPathModalOpen(false)}
                onSubmit={handleEditMissionFlightPath}
            />
            <NewMissionOperatingAreaModal
                isOpen={isCreateMissionOperatingAreaModalOpen}
                missionData={props.missionData}
                onClose={() => setCreateMissionOperatingAreaModalOpen(false)}
                onSubmit={handleCreateMissionOperatingArea}
            />
            <EditMissionOperatingAreaModal
                isOpen={isEditMissionOperatingAreaModalOpen}
                missionData={props.missionData}
                selectedOperatingAreaId={selectedOperatingAreaId}
                onClose={() => setEditMissionOperatingAreaModalOpen(false)}
                onSubmit={handleEditMissionOperatingArea}
            />
            <UploadKMLFileModal
                missionData={props.missionData}
                isOpen={isUploadKMLFileModalOpen}
                onClose={() => setUploadKMLFileModalOpen(false)}
                onSubmit={handleSubmitFromKML}
            />
        </Stack>
    )
}

export default MissionGeospatialInformationTab;