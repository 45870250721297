import React from "react"
import Stack from "@mui/material/Stack"
import Paper from "@mui/material/Paper"
import Box from "@mui/material/Box"
import { Typography, Divider } from "@mui/material"

import { DataGrid } from "@mui/x-data-grid"
import { Operator } from "../../../classes/Operator"
import { OperatorAvailabilityStatus } from "../../../classes/OperatorAvailabilityStatus"

function AvailableOperatorsPanel(props: {
    availableOperatorList: OperatorAvailabilityStatus[],
    sx?: object
}) {

    const filteredReservations = props.availableOperatorList.filter((r) => {
        if (r.statusCode === OperatorAvailabilityStatus.STATUS_UNSCHEDULED
            || r.statusCode === OperatorAvailabilityStatus.STATUS_RESERVED_FOR_EMERGENCY_DISPATCH_AVAILABLE) {
            return true
        }
    })

    const columns = [
        { field: "callsign", headerName: "Callsign", width: 250, valueGetter: (params: any) => params.row.operatorObject!.callsign},
        { field: "reason", headerName: "Reason", width: 300, valueGetter: (params: any) => {
                if (params.row.statusCode === OperatorAvailabilityStatus.STATUS_UNSCHEDULED) {
                    return "Unscheduled"
                } else if (params.row.statusCode === OperatorAvailabilityStatus.STATUS_RESERVED_FOR_EMERGENCY_DISPATCH_AVAILABLE) {
                    return "Reserved - Available for Tasking"
                } else if (params.row.statusCode === OperatorAvailabilityStatus.STATUS_RESERVED_FOR_EMERGENCY_DISPATCH_UNAVAILABLE) {
                    return "Reserved - Not Available for Tasking"
                }
            }}
    ]

    return (
        <Paper sx={props.sx}>
            <Stack direction="column" spacing={2} padding={2}>
                <Typography variant="h5">Available Operators</Typography>
                <Divider />
                <Box sx={{ height: 500 }}>
                    <DataGrid
                        rows={filteredReservations.map((operator: OperatorAvailabilityStatus) => operator.objectify())}
                        columns={columns}
                    />
                </Box>
            </Stack>
        </Paper>
    )
}

export default AvailableOperatorsPanel