import React from 'react';
import { Paper, Stack, Typography, Divider, TextField, Button } from '@mui/material'
import {Mission} from "../../../classes/Mission"

function MissionOriginatingOfficePanel(props: { missionData: Mission, sx?: object }) {

    return (
        <Paper sx={props.sx}>
            <Stack direction="column" spacing={1} padding={2}>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant='h5'>Originating Office</Typography>
                </Stack>
                <Divider />
                <TextField
                    label="Organization Name"
                    InputProps={{ readOnly: true }}
                    value={`${props.missionData.originatingOfficeObject!.organizationObject!.name} (${props.missionData.originatingOfficeObject!.organizationObject!.symbol})`}
                />
                <TextField
                    label="Office"
                    InputProps={{ readOnly: true }}
                    value={`${props.missionData.originatingOfficeObject!.name} (${props.missionData.originatingOfficeObject!.symbol})`}
                />
            </Stack>
        </Paper>
    );
}

export default MissionOriginatingOfficePanel;