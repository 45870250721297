import React from "react"
import {ListItemIcon, ListItemText, ListItemButton, Tooltip} from "@mui/material"

function NavigationBarItem(props: { open: boolean, selected: boolean, onClick: Function, icon: any, label: string }) {
    return (
        <Tooltip title={props.open ? null : props.label} placement="right" arrow>
            <ListItemButton selected={props.selected} onClick={() => props.onClick()}>
                <ListItemIcon sx={{ padding: 1, minWidth: 0 }}>{props.icon}</ListItemIcon>
                {props.open && (<ListItemText primary={props.label}  sx={{ paddingRight: 4, paddingLeft: 4, width: 200 }} />)}
            </ListItemButton>
        </Tooltip>
    )
}

export default NavigationBarItem